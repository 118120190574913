import axios from "../../utils/axiosInstance";

export const getWhitelistsReactive = async () => {
    const response = await axios.post("/getWhitelistsReactive", {
    });
    return response.data;
};

export const removeFromWhitelistReactive = async (zenGroup, id, path) => {
    return await axios.post("/removeFromWhitelistReactive", {
        groupId: zenGroup,
        id,
        path: path
    });
};

export const userAddWhitelistReactive = async (path, zenGroupId, programArguments, whitelistName) => {
    const response = await axios.post("/userAddWhitelistReactive", {
        path: path,
        zenGroupId: zenGroupId,
        programArguments: programArguments,
        userSetFriendlyName: whitelistName
    })
    return response.data;
}
export const findByWhitelistIdListReactive = async (ids) => {
    const response = await axios.post("/findByWhitelistIdListReactive",{
        ids
    })
    return response.data
}
export const getUsersByIdsReactive = async (ids) => {
    const response = await axios.post("/getUsersByIdsReactive",{
        ids
    })
    return response.data
}
export const findBySingleWhitelistAppliedIdReactive = async (whitelistAppliedId) => {
    const response = await axios.post("/findBySingleWhitelistAppliedIdReactive",{
        whitelistAppliedId
    })
    return response.data
}
export const whitelistsPerGroupCountReactive = async () => {
    const response = await axios.post("/whitelistsPerGroupCountReactive",{})
    return response.data
}

export const changeWhitelistNameReactive = async (newWhitelistId, name) =>{
    return await axios.post("/changeWhitelistNameReactive", {
        whitelistId :newWhitelistId,
        newName : name,
    })
}

export const getWhitelistsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getWhitelistsGridFilterStateReactive", {});
    return response.data;
};

export const updateWhitelistsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateWhitelistsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateWhitelistsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateWhitelistsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getWhitelistsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getWhitelistsGridColumnStateReactive", {});
    return response.data;
};
export const updateWhitelistsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateWhitelistsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateWhitelistsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateWhitelistsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const scanServerAVs = async () => {
    return await axios.post("/scanServerAVs", {})
};