import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {LicenseManager} from "@ag-grid-enterprise/core";
//import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";


const licenseKey = process.env.REACT_APP_AG_GRID_LICENSE_KEY;
LicenseManager.setLicenseKey(licenseKey);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <React.StrictMode>
                <BrowserRouter>
                        <App />
                </BrowserRouter>
        </React.StrictMode>
);

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
