import React, {Component, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    changeWhitelistNameReactive,
    findByWhitelistIdListReactive,
    removeFromWhitelistReactive,
    scanServerAVs,
    updateWhitelistsGridColumnStateReactive,
    updateWhitelistsGridFilterModelReactive,
    updateWhitelistsGridUseColumnStateReactive,
    updateWhitelistsGridUseFilterStateReactive,
    userAddWhitelistReactive,
    whitelistsPerGroupCountReactive
} from "../api/whitelistsApi";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropdown from "react-dropdown";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {AgChartsReact} from "ag-charts-react";
import {handleGroupColumnChangeNameOnly, WhiteListCellEditingStopped} from "../../utils/gridCellEditing";
import {
    findZenGroupById,
    getZenGroupDropDownContents,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {LoadingButton} from '@mui/lab';
import {Box, Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {useLocation} from "react-router-dom";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

let gridColumnStateSessionVariableName = "whitelistsGridColumnState"

export default function Whitelists() {
    const { register, handleSubmit, watch, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroups, setZenGroups] = useState([]); // eslint-disable-line no-unused-vars
    const [zenGroup, setZenGroup] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [programArgumentsToggled, setProgramArgumentsToggled] = useState(true);
    const [gridApi, setGridApi] = useState();
    const [enableButtons, setEnableButtons] = useState(false);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(true);
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage() // eslint-disable-line no-unused-vars
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("whitelistsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const whitelistLocation = useLocation();
    const [createWhitelistDefaultPathValue, setCreateWhitelistDefaultPathValue] = useState();
    const [createWhitelistDefaultArgsValue, setCreateWhitelistDefaultArgsValue] = useState();
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true, true, true),
        { field: "whitelistDisplayName", name: "Whitelist Name", width : 330,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Whitelist's Name", "whitelistDisplayName")
            }
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},

        { field: "created", name: "Created", width: 300,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "path", name: "Path", width: 600,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "programArguments", name: "Program Arguments", width: 700,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "userCreatedItUsername", name: "Created By", width: 300,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
        },

    ])
    const [avScanButtonDisabled, setAVScanButtonDisabled] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setZenGroups(zenGroupSessionStorage)
            /*if(zenGroupSessionStorage !== null && zenGroupSessionStorage.length > 0){
                setZenGroup(zenGroupSessionStorage[0].id);
            }*/
        })()
        return () => controller?.abort();
    }, [zenGroupSessionStorage]);

    useEffect(() => {
        //if they click button then set timer
        if(avScanButtonDisabled){
            const scanTimeout = window.setTimeout(() => {
                setAVScanButtonDisabled(false)
            }, 900000); //15 mins in milliseconds
            //clear timer on unmount
            return () => window.clearTimeout(scanTimeout);
        }
    }, [avScanButtonDisabled])

    //api call for chart
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setChartIsLoading(true)
            whitelistsPerGroupCountReactive().then(whitelistsPerGroupList => {
                setChartIsLoading(false)
                if (whitelistsPerGroupList) {
                    let data = []
                    for (let i in whitelistsPerGroupList){
                        let group = findZenGroupById(whitelistsPerGroupList[i].zenGroupId)
                        if(group && group.friendlyName){
                            //found group in session
                            data.push({"zenGroupId":whitelistsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                                "count":whitelistsPerGroupList[i].count
                            })
                        }
                        else{
                            //else did not find group in session
                            data.push({"zenGroupId":whitelistsPerGroupList[i].zenGroupId,
                                "count":whitelistsPerGroupList[i].count
                            })
                        }
                    }
                    data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)

                    setChartData(data)
                } else {
                    setChartData([])
                }
            }).catch(function (error) {
                setChartIsLoading(false)
            })
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        if(whitelistLocation && whitelistLocation.state){
            window.scroll({behavior: "smooth", top: 0, left: 0})
            setModalIsOpen(true)
            if(whitelistLocation.state.zenGroupId){
                setZenGroup(findZenGroupById(whitelistLocation.state.zenGroupId).id)
            }
            if(whitelistLocation.state.path){
                setCreateWhitelistDefaultPathValue(whitelistLocation.state.path)
            }
            if(whitelistLocation.state.programArguments){
                setProgramArgumentsToggled(true)
                setCreateWhitelistDefaultArgsValue(whitelistLocation.state.programArguments)
            }
            else{
                setProgramArgumentsToggled(false)
            }
            //so on refresh or clicking back then forward tabs this modal does not keep popping up
            window.history.replaceState(whitelistLocation.state, '')
        }
    }, [whitelistLocation]);

    const createWhitelist = (data) => {
        if (data.path && data.path.trim().length > 0 && zenGroup) {
            setIsLoading(true);
            let programArguments = null;
            if(programArgumentsToggled){
                if(!data.programArguments){
                    NotificationManager.error("Please make sure all fields are filled out and try again.");
                    setIsLoading(false)
                    return;
                }
                else{
                    if(data.programArguments.trim().length < 1){
                        NotificationManager.error("Please make sure all fields are filled out and try again.");
                        setIsLoading(false)
                        return;
                    }
                    else{
                        programArguments = data.programArguments.trim()
                    }
                }
            }
            let whitelistName = null
            if(data.whitelistName && data.whitelistName.trim().length > 0){
                whitelistName = data.whitelistName.trim()
            }
            userAddWhitelistReactive(data.path.trim(), zenGroup, programArguments, whitelistName).then(response => {
                setIsLoading(false);
                if(response.whiteListExistedAlready === true){
                    NotificationManager.info("Whitelist existed already for this path and program argument combination");
                }
                else{
                    NotificationManager.success("Whitelist added successfully!");
                    setModalIsOpen(false);
                    reset({
                        path: "",
                        whitelistName: ""
                    })
                    resetGrid();
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };

    const removeWhitelist = (data) => {
        if(!data.delete || data.delete.toLowerCase() !== "delete"){
            return;
        }
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            //verify we have all our data needed to send removeFromWhitelist request
            setIsLoading(true)
            //loop through all the nodes
            for (let i in gridApi.getSelectedNodes()){
                let currentWhitelist = gridApi.getSelectedNodes()[i]
                if(currentWhitelist && currentWhitelist.data && currentWhitelist.data.whitelistId && currentWhitelist.data.zenGroupId && currentWhitelist.data.path){
                    removeFromWhitelistReactive(currentWhitelist.data.zenGroupId, currentWhitelist.data.whitelistId, currentWhitelist.data.path).then(response => {
                        gridApi.applyTransactionAsync({
                            remove: [currentWhitelist.data]
                        })
                    }).catch(function(error){
//                        if(error.message){
//                            NotificationManager.error(error.message);
//                        }
//                        else{
//                            NotificationManager.error("Unexpected error, please try again.");
//                        }
//                        setIsLoading(false)
                    })
                }
            }

            // successfully removed all
            setIsLoading(false)
            NotificationManager.success("Path(s) removed successfully");
            resetGrid();
            setDeleteIsOpen(false);
            reset({
                delete: ""
            })
        }
    };

    const deletePass = watch("delete");
    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Whitelists</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create Whitelist Modal*/}
            <Modal contentLabel="Create Whitelist"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       reset({
                           path: "",
                           whitelistName: ""
                       })
                       setModalIsOpen(false)
                       setProgramArgumentsToggled(true)
                       setZenGroup()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createWhitelist)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Add Path to Whitelist</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({
                                        path: "",
                                        whitelistName: ""
                                    })
                                    setModalIsOpen(false)
                                    setProgramArgumentsToggled(true)
                                    setZenGroup()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <label>Select which group to add to</label>
                            <Dropdown
                                options={getZenGroupDropDownContents()}
                                value={zenGroup}
                                onChange={({ value }) => {
                                    setZenGroup(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>You can use '*' as a wild card. e.g. C:\Program Files\Office\word*.exe</label>
                            <br />
                            <input
                                name="path"
                                type="text"
                                defaultValue={createWhitelistDefaultPathValue}
                                required
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("path")}
                                placeholder={"Path"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={programArgumentsToggled}
                                        name="toggleWhitelistProgramArguments"
                                        onChange={e => setProgramArgumentsToggled(e.target.checked)}
                                    />
                                } label={programArgumentsToggled ? "Limit Exception to Program + Arguments" : "Do Not Limit Exception to Program + Arguments"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${programArgumentsToggled ? "block": "hidden"} ml-1 mt-5`}>
                            <label>Program Arguments</label>
                            <br />
                            <input
                                name="programArguments"
                                type="text"
                                defaultValue={createWhitelistDefaultArgsValue}
                                required={programArgumentsToggled}
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("programArguments")}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for the whitelist</label>
                            <br />
                            <input
                                name="whitelistName"
                                type="text"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("whitelistName")}
                                placeholder={"Optional"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                        >
                                    Create
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            {/*Delete Whitelist Modal*/}
            <Modal contentLabel="Delete Whitelist"
                   isOpen={deleteIsOpen}
                   onRequestClose={() => {
                       setDeleteIsOpen(false)
                       reset({
                           delete: ""
                       })
                   }}
                   shouldCloseOnOverlayClick={true}
                   style={{
                       overlay: {
                       },
                       content : {
                           top                   : '30%',
                           left                  : '50%',
                           right                 : 'auto',
                           bottom                : 'auto',
                           marginRight           : '-50%',
                           transform             : 'translate(-50%, -50%)',
                           borderRadius          : '15px',
                           backgroundColor : 'white',
                       }
                   }}
            >
                <form onSubmit={handleSubmit(removeWhitelist)}>
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Remove from Whitelist</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setDeleteIsOpen(false)
                                    reset({
                                        delete: ""
                                    })
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <label>To confirm, type "delete" below</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                name="delete"
                                type="text"
                                required
                                {...register("delete")}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={!deletePass || deletePass.toLowerCase() !== "delete"} >
                                    Delete
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col ml-10 mr-10 mt-8 gap-y-3">
                    {privatePageHeaderHelper("Whitelists")}
                    <hr className="bg-black h-0.5" />
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={chartToggled}
                                    name="whitelistsPerGroupToggleToggle"
                                    onChange={e => setChartToggled(e.target.checked)}
                                />
                            } label={chartToggled ? "Showing Whitelists Per Group Chart" : "Hiding Whitelists Per Group Chart"}/>
                        </ThemeProvider>
                    </div>
                    {/*License Usage Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            {
                                autoSize: true,
                                data: chartData,
                                title: {
                                    text: 'Whitelists Per Group',
                                    fontSize: 18,
                                },
                                series: [
                                    {
                                        type: 'column',
                                        xKey: 'zenGroupId',
                                        yKeys: [
                                            'count',
                                        ],
                                        yNames: [
                                            'Number of Whitelists',
                                        ],
                                        fills: ['#e76a24'],
                                        strokes: ['#989898'],
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818"
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.yValue
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                ],
                                axes: [
                                    {
                                        type: 'category',
                                        position: 'bottom',
                                        label: {
                                            rotation: 50,
                                            formatter: function(params){
                                                if(!params.axis.boundSeries[0].data[params.index].zenGroupName){
                                                    let group = findZenGroupById(params.value)
                                                    if(group && group.friendlyName){
                                                        //found group in session
                                                        params.axis.boundSeries[0].data[params.index].zenGroupName = group.friendlyName
                                                    }
                                                    else{
                                                        return "Group not found"
                                                    }
                                                }
                                                let count = params.axis.boundSeries[0].data[params.index].count
                                                return params.axis.boundSeries[0].data[params.index].zenGroupName + " (" + count + ")"

                                            },
                                            fontSize: 11
                                        },
                                    },
                                    {
                                        type: 'number',
                                        position: 'left',
                                        //log shows now if you give undefined, not including min/max lets it set these automatically
                                        //min: chartData.length === 0 ? 0: undefined, //default min to 0 when chart data length is 0, else undefined leaves it to auto set the min according to the data given
                                        //max: chartData.length === 0 ? 20: undefined, //default max to 20 when chart data length is 0, else undefined leaves it to auto set the max according to the data given
                                    },
                                ],
                                legend: {
                                    spacing: 40,
                                    position: 'top',
                                },
                            }
                        } />
                        <BackDropChartLoadingOverlay opened={chartIsLoading} />
                    </div>
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            //icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Add a Process"}
                                            tooltipTitle={"Whitelist Process and Notify the Cyber Crucible Behavioural Analysis Team"}
                                            icon={
                                                <FontAwesomeIcon
                                                    className="object-contain"
                                                    icon="fa-duotone fa-magnifying-glass-arrow-right"
                                                />
                                            }
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setModalIsOpen(!modalIsOpen)
                                            }}/>
                                        <Tooltip title={<div className={"text-sm"}>{avScanButtonDisabled ? "AV Scan In Progress" : "Start scan for antivirus products for your server agents that would otherwise automatically occur nightly"}</div>}
                                                 placement={"top"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                                            <Box sx={{ boxShadow: 7, borderRadius: '28px'}}>
                                                <LoadingButton
                                                    loading={avScanButtonDisabled}
                                                    disabled={avScanButtonDisabled}
                                                    variant={"text"}
                                                    classes={{loadingIndicatorCenter: "px-2"}}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "white",
                                                            cursor: "pointer",
                                                            color: "#505050",
                                                        },
                                                        "&:disabled": {
                                                            backgroundColor: "#Fbfbfb",
                                                            cursor: "default",
                                                            color: "#Fbfbfb"
                                                        },
                                                        "&:hover .loading-button": {
                                                            backgroundColor: "white",
                                                            cursor: "pointer"
                                                        },
                                                        "&:disabled .loading-button": {
                                                            backgroundColor: "#Fbfbfb",
                                                            cursor: "default",
                                                            color: "#Fbfbfb"
                                                        },
                                                        color: "#505050",
                                                        background: "#F3f3f3",
                                                        borderRadius: "28px",
                                                        maxWidth: "40px", maxHeight: "40px", minWidth: "40px", minHeight: "40px"
                                                    }}
                                                    loadingPosition={"center"}
                                                    onClick={() => {
                                                        setAVScanButtonDisabled(true)
                                                        scanServerAVs()
                                                            .then(response => {
                                                                NotificationManager.success("AV Scan for your server agents successfully sent");
                                                            })
                                                            .catch(function (error) {
                                                                if(error.message){
                                                                    NotificationManager.error(error.message);
                                                                }
                                                                else{
                                                                    NotificationManager.error("Error sending request");
                                                                }
                                                            })

                                                    }}
                                                >
                                                    <CachedIcon className="text-black loading-button" sx={{borderRadius: "28px", color: "#505050"}}/>
                                                </LoadingButton>
                                            </Box>
                                        </Tooltip>
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Remove From Whitelist"}
                                            tooltipPlacement={"top"} disabled={!enableButtons}
                                            onClick={() => {
                                                setDeleteIsOpen(true)
                                            }}>
                                        </MuiIconButtonWithTooltipAndBox>
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false} sseDataPullActive={sseDataPullActive}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} excelExportFunction={excelExport}
                            />
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'whitelistsGridFilterState', updateWhitelistsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateWhitelistsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }
    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "whitelistId", "id", findByWhitelistIdListReactive, ["whitelistDisplayName"])
    }
    function resetGrid(){
        //  refresh the grid, only a workaround for now, will change when applying sse
        gridApi && gridApi.deselectAll();
        /*getWhitelistsReactive().then(data => {
            if(gridApi){
                //The ag grid gridApi.setRowData() clears all filters, so we need to get the filter model before, perform the setRowData, and then reapply the filter
                let filterModelBefore = gridApi.getFilterModel()
                gridApi.setRowData(data)
                gridApi.setFilterModel(filterModelBefore)
            }
        }).catch(function(error){})*/
        setProgramArgumentsToggled(true)
    }
    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "whitelistsGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        //params.api.getFilterInstance("zenGroupDisplayName");
        /*params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });*/
    };

    cellEditingStoppedFunctionCaller = (event) => {
        //add to this function if we want to allow editing to a cell, as of now (October 11th, 2021) we do
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else{
            WhiteListCellEditingStopped(event, changeWhitelistNameReactive,"whitelistDisplayName")
        }
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateWhitelistsGridColumnStateReactive)
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["whitelistId"] = objectBody["id"]
        objectBody["whitelistDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.whitelistId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }

        //check if we have the whitelist grid data in session storage, if so then use this for the rowData
        /*let whitelistsGridData = JSON.parse(decryptAndGetSessionVariable("whitelistGridList"))
        if(whitelistsGridData){
            this.gridApi.setRowData(whitelistsGridData)
        }
        else{
            const data = await getWhitelistsReactive()
            this.gridApi.setRowData(data)
        }*/
        onGridReadyHelper(gridReadyParams, "whitelistsGridFilterState");
        await loadDataWithSSEAndStartChangeStreamListener("/sse/getWhitelistsReactive", "/sse/listenToWhitelistEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'whitelistsGridFilterState', updateWhitelistsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        valueCache={true}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate,
                                    cellRenderer, cellRendererSelector, cellEditorType,
                                    hide, sortable, minWidth, width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector,
                                    valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={editableOptions}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
