import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";

/*
        Note: The gridApi destroyCalled variable lets us know if the grid was destroyed, which means the user went to a new page or hit refresh. We have a signal field in the
        startChangeStreamListener which is used to kill the listener in the caller's componentWillUnmount function so this is used as an extra precaution but should not happen.
*/

export const loadDataWithSSEAndStartChangeStreamListener = async (endpointUrl, changeStreamEndpointUrl, populateGridFunction, updateGridFunction, gridParams, setSSEDataPullActive,
                                                                  setAsyncTransactionWaitMillis, transactionsToApplyAfterClose, abortController) => {
    Auth.currentSession().then(response => {
        let accessToken = response.getAccessToken().getJwtToken()
        fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + endpointUrl, {
            method: "POST",
            headers: {
                "Accept": "text/event-stream",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            //Get trainingMode and demoMode settings dynamically from session
            body: JSON.stringify({
                trainingMode: getTrainingModeSetting(),
                demoMode: getDemoModeSetting()
            }),
            openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
                                    // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
            onopen(res) {
                if (res.ok && res.status === 200) {
                    //console.log("Connection made ", res);
                    startChangeStreamListener(changeStreamEndpointUrl, updateGridFunction, gridParams, abortController)

                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.error("Client side error ", res);
                }
                //console.time(`sseDataPull${endpointUrl}`)
            },
            onmessage(event) {
                let rowData = JSON.parse(event.data)
                populateGridFunction && populateGridFunction(rowData)
            },
            onclose() {
                //console.timeEnd(`sseDataPull${endpointUrl}`)
                //console.log("Connection closed by the server");
                setSSEDataPullActive && setSSEDataPullActive(false)
                //TODO: make this a standard variable (environment) for async transactions millis to apply change stream updated
                setAsyncTransactionWaitMillis && setAsyncTransactionWaitMillis(5000)
                if(!gridParams.api.destroyCalled){
                    gridParams && transactionsToApplyAfterClose && gridParams.api.applyTransactionAsync({
                        update: transactionsToApplyAfterClose
                    })
                }
            },
            onerror(err) {
                console.error("There was an error from server", err);
            },
        })
    })
};

async function startChangeStreamListener(changeStreamEndpointUrl, updateGridFunction, gridParams, abortController){
    Auth.currentSession().then(response => {
        let accessToken = response.getAccessToken().getJwtToken()
        fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + changeStreamEndpointUrl, {
            method: "POST",
            headers: {
                "Accept": "text/event-stream",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            //Get trainingMode and demoMode settings dynamically from session
            body: JSON.stringify({
                trainingMode: getTrainingModeSetting(),
                demoMode: getDemoModeSetting()
            }),
            signal: abortController?.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {
                    //console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.error("Client side error ", res);
                }
            },
            onmessage(event) {
                let data = JSON.parse(event.data)
                updateGridFunction && updateGridFunction(data)
            },
            onclose() {
                //console.debug("Connection closed by the server");
            },
            onerror(err) {
                console.error("There was an error from server", err);
            },
        })
    })
}


//the caller functions should handle any field changing in the objectBody since fields in grid column defs may not match the change stream event coming in
export function standardHandleUpdateAndReplaceEvent(objectBody, gridApi, sseDataPullActive, updateTransactionsToApply){
    //If the sseDataPull is still active, we need to gather updates from the change stream and add to a list, once the sseDataPullActive is set to false and the initial sse data pull is done we can
    // apply these updates to the grid
    if(sseDataPullActive){
        updateTransactionsToApply && updateTransactionsToApply.push(objectBody)
    }
    else{
        if(!gridApi.destroyCalled){
            gridApi && gridApi.applyTransactionAsync({
                update: [objectBody]
            })
        }
    }
}

//the caller functions should handle any field changing in the objectBody since fields in grid column defs may not match the change stream event coming in
export function standardHandleInsertEvent(objectBody, gridApi, sseDataPullActive){
    if(!sseDataPullActive && !gridApi.destroyCalled){
        //gridApi && gridApi protects against null pointer exception
        gridApi && gridApi.applyTransactionAsync({
            add: [objectBody]
        })
    }
}

//intended to be used with the initial sse data pull and populating the grid
export function standardHandlePopulateGrid(rowData, gridApi){
    if(!gridApi.destroyCalled){
        gridApi && gridApi.applyTransactionAsync({
            add: [rowData]
        })
    }
}
