import {getLicenseFriendlyNamesReactive} from "../pages/api/agentsApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import {getEditIconComponent} from "./customCellEditor";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./encryptDecryptHelper";
import {licensesNamesListWithAgentIdReactive} from "../pages/api/licensesApi";
import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";
import {Button, ThemeProvider, Tooltip} from "@mui/material";
import {cellButtonTheme} from "./muiStyling";
import {MuiIconButtonWithTooltip} from "../components/muiComponents";

/*
    This function assumes the license id is 'licenseId', and the license name column is 'licenseDisplayName'.
    I do not think we can add additional parameters to the function since it is directly tied to the ag-grid valueFormatter()
    function (https://www.ag-grid.com/react-data-grid/value-formatters/), so we have to work by assuming the info above.
 */

export const defaultLicenseColumnInit = {
    field: "licenseDisplayName",
    name: "License Name",
    width: 380,
    filter: 'agTextColumnFilter',
    filterParamsInHeader: {
    suppressSorting: true,
        buttons: ["reset", "apply"],
        
        filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
        suppressAndOrCondition: true,
    },
    valueFormatter: licenseNameValueFormatter,
    cellRenderer: licenseDisplayNameCellRenderer,
    sortable: true,
    editable: true,
    cellEditorType: "agTextCellEditor"
}

export const defaultLicenseColumnWithAssignLicenseToAgentOptionCellRenderer = (triggerAssignAgentLicense, setAgentToAssignLicense) => {
    return {
        field: "licenseDisplayName",
        name: "License Name",
        width: 380,
        filter: 'agTextColumnFilter',
        filterParamsInHeader: {
            suppressSorting: true,
            buttons: ["reset", "apply"],
            
            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
            suppressAndOrCondition: true,
        },
        valueFormatter: licenseNameValueFormatter,
        cellRenderer: (params) => {
            let spinnerDiv = ""
            let editNameIconDiv = ""
            if(params.data.licenseId === null || params.data.licenseDisplayName === " "){
                return (
                    <ThemeProvider theme = {cellButtonTheme}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                            }}
                        >Assign License
                        </Button>
                    </ThemeProvider>
                )
            }
            if(params.data.licenseId === params.valueFormatted){
                spinnerDiv = <FontAwesomeIcon
                    className="contain fa-pulse"
                    icon="fa-light fa-spinner"
                    size="lg"
                    name="LicenseNameLoading"
                />
            }else{
                spinnerDiv = ""
            }
            let agentLinkDiv = ""
            if(params.data.licenseId && params.node.data.licenseDisplayName){
                agentLinkDiv =
                    <Link to={{pathname:"/private/licenses"}} state={{licenseDisplayNameClicked: params.node.data.licenseDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        licenseIdClicked : params.node.data.licenseId, agentDisplayNameClicked: params.node.data.agentDisplayName}} className="">
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="object-contain"
                                    icon="fa-duotone fa-user-gear"
                                    size="xs"
                                />
                            }
                            tooltipTitle={"Click to Manage This License"}
                            tooltipPlacement={"bottom-start"}
                        />
                    </Link>
                editNameIconDiv = getEditIconComponent(params, "Click to Edit this License's Name", "licenseDisplayName")
            }
            let tempLicenseReassignIcon = ""
            if(params.data.licenseDisplayName && params.data.licenseDisplayName.startsWith('TEMP LICENSE FOR UNINSTALL')){
                tempLicenseReassignIcon =
                    <ThemeProvider theme = {cellButtonTheme}>
                        <Tooltip slotProps={{tooltip: {sx: {maxWidth: 700}}}} title={<div className={"text-sm"}>{"Click to assign this agent a non-temporary license"}</div>}
                            placement={"bottom-start"} enterDelay={750} arrow>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                    setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                    triggerAssignAgentLicense && triggerAssignAgentLicense()
                                }}>
                            <FontAwesomeIcon
                                icon="fa-duotone fa-user-plus"
                                size="lg"
                            />
                            </Button>
                        </Tooltip>
                    </ThemeProvider>
                editNameIconDiv = "" //reset editNameIconDiv since it's a temp license and they can't edit name anyway
            }
            return(
                <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {spinnerDiv}
                    {agentLinkDiv}
                    {tempLicenseReassignIcon}
                    {editNameIconDiv}
                    {params.valueFormatted}
                </div>
            )
        },
        sortable: true,
        editable: true,
        cellEditorType: "customNameCellEditor"
    }
}

export const defaultLicenseColumnInitNoFilters = {
    field: "licenseDisplayName",
    name: "License Name",
    width: 380,
    valueFormatter: licenseNameValueFormatter,
    cellRenderer: licenseDisplayNameCellRenderer,
    sortable: true,
    editable: true,
    cellEditorType: "agTextCellEditor"
}

export function licenseNameValueFormatter(params){
    if(params.node.data.licenseId){
        let licenseIdList = []
        licenseIdList.push(params.node.data.licenseId);
        getLicenseFriendlyNamesReactive(licenseIdList).then(function(licenseNameObjects){
            if(licenseNameObjects && licenseNameObjects.length>0) {
                if (licenseNameObjects[0].name) {
                    if(licenseNameObjects[0].name === params.node.data.licenseDisplayName){
                        //console.debug(licenseNameObjects[0].name+ " equals licenseDisplayName")
                    }else{
                        params.node.setDataValue("licenseDisplayName", licenseNameObjects[0].name)
                    }
                }else{
                    //in this case, stop showing spinning div since we have returned from api call
                    params.node.setDataValue("licenseDisplayName", " ")
                }
            }
            else{
                //in this case, stop showing spinning div since we have returned from api call
                params.node.setDataValue("licenseDisplayName", " ")
            }
        }).catch(function(error){
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.setDataValue("licenseDisplayName", " ")
        })
        if(params.node.data.licenseDisplayName){
            return params.node.data.licenseDisplayName
        }
        return params.node.data.licenseId
    }
}

export function licenseDisplayNameCellRenderer (params) {
    let spinnerDiv = ""
    if(params.data.licenseId === params.valueFormatted){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="LicenseNameLoading"
        />
    }else{
        spinnerDiv = ""
    }
        return(
            <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                {spinnerDiv}
                {params.valueFormatted}
            </div>
        )
}

export function licenseNameColumnCustomerHeaderTemplate (params) {
    console.log(params)
    return(
            '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    **<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    )
    /*
     '<div role="presentation" className="ag-cell-label-container flex flex-wrap sm:flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-x-1">'+' +
         '<span ref="eMenu" className="ag-header-icon ag-header-cell-menu-button"/>' +

            <div ref="eLabel" className="ag-header-cell-label" role="presentation">
                <FontAwesomeIcon size="md" className="" icon="fa-duotone fa-edit"/>
                <span ref="eSortOrder" className="ag-header-icon ag-sort-order"/>
                <span ref="eSortAsc" className="ag-header-icon ag-sort-ascending-icon"/>
                <span ref="eSortDesc" className="ag-header-icon ag-sort-descending-icon"/>
                <span ref="eSortNone" className="ag-header-icon ag-sort-none-icon"/>
                <span ref="eFilter" className="ag-header-icon ag-filter-icon"/>
            </div>
        </div>
     */
}

export const defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide = (triggerAssignAgentLicense, setAgentToAssignLicense) => {
    return {
        field: "licenseDisplayName",
        name: "License Name",
        width: 380,
        filter: 'agTextColumnFilter',
        filterParamsInHeader: {
            suppressSorting: true,
            buttons: ["reset", "apply"],
            
            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
            suppressAndOrCondition: true,
        },
        //valueGetter: licenseNameValueGetter,
        cellRenderer: (params) => {
            let spinnerDiv = ""
            let editNameIconDiv = ""
            if(params.data.licenseDisplayName === " "){
                return (
                    <ThemeProvider theme = {cellButtonTheme}>
                        <Tooltip title={<div className={"text-sm"}>{`The first available license in this agent's group (or the group's assigned distribution group) will be assigned to this agent`}</div>}
                                 placement={"bottom-start"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                        setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                        triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                                    }}
                            >Assign License
                            </Button>
                        </Tooltip>
                    </ThemeProvider>
                )
            }
            /*if((params.data.licenseId && !params.data.licenseDisplayName) || (params.data.licenseId === params.data.licenseDisplayName)){
                spinnerDiv = <FontAwesomeIcon
                    className="contain fa-pulse"
                    icon="fa-light fa-spinner"
                    size="lg"
                    name="LicenseNameLoading"
                />
            }else{
                spinnerDiv = ""
            }*/
            let agentLinkDiv = ""
            if(params.node.data.licenseDisplayName !== " "){
                agentLinkDiv =
                    <Link to={{pathname:"/private/licenses"}} state={{licenseDisplayNameClicked: params.node.data.licenseDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentDisplayNameClicked: params.node.data.agentDisplayName}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This License"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
                editNameIconDiv = getEditIconComponent(params, "Click to Edit this License's Name", "licenseDisplayName")
            }
            let tempLicenseReassignIcon = ""
            if(params.data.licenseDisplayName && params.data.licenseDisplayName.startsWith('TEMP LICENSE FOR UNINSTALL')){
                tempLicenseReassignIcon =
                    <Tooltip title={<div className={"text-sm"}>{"Click to assign this agent a non-temporary license"}</div>}
                             placement={"bottom-start"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                        <FontAwesomeIcon
                            icon="fa-duotone fa-user-plus"
                            className={"cursor-pointer"}
                            size="lg"
                            onClick={() => {
                                setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                            }}
                        />
                    </Tooltip>
                editNameIconDiv = "" //reset editNameIconDiv since it's a temp license and they can't edit name anyway
            }
            return(
                <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {spinnerDiv}
                    {agentLinkDiv}
                    {tempLicenseReassignIcon}
                    {editNameIconDiv}
                    {params.node.data.licenseDisplayName}
                </div>
            )
        },
        sortable: true,
        editable: true,
        cellEditorType: "customNameCellEditor"
    }
}

export function licenseNameValueGetter(params){
    let licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
    let rowData = params.node.data;
    if(rowData.licenseManuallyRemovedByUser || rowData.specialStatusMessage === "uninstalled"){
        rowData.licenseDisplayName = " "
        return " "
    }
    else{
        if(licensesWithAgentIdList){
            let licenseFound = null
            for(let i = 0; i < licensesWithAgentIdList.length; i++){
                if(licensesWithAgentIdList[i].agentId === rowData.agentId){
                    licenseFound = licensesWithAgentIdList[i]
                }
            }
            if(licenseFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(licenseFound.licenseName){
                    rowData.licenseDisplayName = licenseFound.licenseName
                }
                else{
                    rowData.licenseDisplayName = " "
                }
                return rowData.licenseDisplayName
            }
        }
    }

}


export function getAndStoreLicensesListWithAgentIdReactive(){
    licensesNamesListWithAgentIdReactive().then(licenses => {
        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licenses))
    }).catch(error => {})
}

export function getAndStoreLicensesListWithAgentIdReactiveWithSSE(){
    Auth.currentSession().then(response => {
        let licensesList = []
        let accessToken = response.getAccessToken().getJwtToken()
        fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/sse/licensesNamesListWithAgentIdReactive", {
            method: "POST",
            headers: {
                "Accept": "text/event-stream",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            //Get trainingMode and demoMode settings dynamically from session
            body: JSON.stringify({
                trainingMode: getTrainingModeSetting(),
                demoMode: getDemoModeSetting()
            }),
            openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
            // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
            onopen(res) {
                if (res.ok && res.status === 200) {
                    //console.log("Connection made ", res);

                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.error("Client side error ", res);
                }
                //console.time(`sseDataPull${endpointUrl}`)
            },
            onmessage(event) {
                let rowData = JSON.parse(event.data)
                licensesList.push(rowData)
            },
            onclose() {
                encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesList))
                licensesListWithAgentIdSessionStorageChangeStreamListener()
            },
            onerror(err) {
                console.error("There was an error from server", err);
            },
        })
    })
}

let changeStreamListenerActive = false
let gridApi = null
export function licensesListWithAgentIdSessionStorageChangeStreamListener(gridApiPassedIn=null){
    /*
        This will only run when changeStreamListenerActive is false. So the change stream listener will remain active and update the licensesWithAgentIdList session storage variable when navigating from
        page to page. The changeStreamListenerActive variable will reset to false when the page is refreshed and the CS listener is also killed when a page refresh happens, so when the sidebar component
        code calls to this function on a refresh the CS will start again. Otherwise, when sidebar component calls to this function on navigation to another tab, the current CS listener will keep running
        and we can avoid having more than one CS run at a time.
     */
    if(gridApiPassedIn != null){
        gridApi = gridApiPassedIn
    }
    if(!changeStreamListenerActive){
        changeStreamListenerActive = true
        Auth.currentSession().then(response => {
            let accessToken = response.getAccessToken().getJwtToken()
            //licensesWithAgentIdListInSession is the list we will modify when a license change stream comes in and update the licensesWithAgentIdList session storage variable with
            let licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
            fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/sse/listenToLicenseEvent", {
                method: "POST",
                headers: {
                    "Accept": "text/event-stream",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                },
                //Get trainingMode and demoMode settings dynamically from session
                body: JSON.stringify({
                    trainingMode: getTrainingModeSetting(),
                    demoMode: getDemoModeSetting()
                }),
                openWhenHidden: true, //we should leave this CS listener open when hidden so agents page license name column will always have the correct values
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        //console.log("Connection made ", res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.error("Client side error ", res);
                    }
                },
                onmessage(event) {
                    let changeStreamEvent = JSON.parse(event.data)
                    let licenseEventData = changeStreamEvent.body
                    let operationType = changeStreamEvent.operationType
                    let licenseObjectPreppedForStorage = {}
                    //the only fields we really care about for session storage list are licenseId, agentId, and licenseName
                    licenseObjectPreppedForStorage["licenseId"] = licenseEventData.id
                    licenseObjectPreppedForStorage["agentId"] = licenseEventData.agentId
                    licenseObjectPreppedForStorage["licenseName"] = licenseEventData["userSetFriendlyName"] ? licenseEventData["userSetFriendlyName"] : licenseEventData["friendlyName"]
                    if(!licensesWithAgentIdListInSession){
                        licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                    }
                    if(licensesWithAgentIdListInSession){
                        if(operationType === "UPDATE" || operationType === "REPLACE"){
                            let licenseFoundInSessionList = licensesWithAgentIdListInSession.find(licenseIterator => licenseIterator.licenseId === licenseEventData.id);
                            if(licenseFoundInSessionList){
                                //License was found in session storage. If licenseObjectPreppedForStorage.agentId is not null then we can check if the agentIds are different, else we can remove from session storage
                                let oldAgentId = licenseFoundInSessionList.agentId
                                if(licenseObjectPreppedForStorage.agentId){
                                    if(licenseObjectPreppedForStorage.agentId !== licenseFoundInSessionList.agentId){
                                        //assign agentId and update in session

                                        licenseFoundInSessionList.agentId = licenseObjectPreppedForStorage.agentId
                                        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                        //this license was assigned to another agent, so need to update both agents
                                        applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, oldAgentId, " ", null)
                                        applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["licenseName"], licenseObjectPreppedForStorage["licenseId"])
                                    }
                                    else{
                                        //else we got an update for this license but the agentIds are the same, check if it was only a license name change
                                        if(licenseObjectPreppedForStorage.licenseName !== licenseFoundInSessionList.licenseName){
                                            licenseFoundInSessionList.licenseName = licenseObjectPreppedForStorage.licenseName
                                            encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                            applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["licenseName"], licenseObjectPreppedForStorage["licenseId"])
                                        }
                                        //else do nothing
                                    }
                                }
                                else{
                                    //remove agentId and update in session storage
                                    //licenseFoundInSessionList.agentId = null

                                    //Remove license from list since it no longer will have an agentId field
                                    licensesWithAgentIdListInSession = licensesWithAgentIdListInSession.filter(function (value, index, arr) {
                                        return value.licenseId !== licenseFoundInSessionList.licenseId;
                                    })
                                    encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, oldAgentId, " ", null)

                                }
                            }
                            else{
                                //License was not found in session storage. So if licenseObjectPreppedForStorage.agentId is not null then we have to update session storage, else we don't
                                if(licenseObjectPreppedForStorage.agentId){
                                    licensesWithAgentIdListInSession.push(licenseObjectPreppedForStorage)
                                    encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["licenseName"], licenseObjectPreppedForStorage["licenseId"])

                                }
                            }

                        }
                        else if (operationType === "INSERT"){
                            if(licenseObjectPreppedForStorage.agentId){
                                licensesWithAgentIdListInSession.push(licenseObjectPreppedForStorage)
                                encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["licenseName"], licenseObjectPreppedForStorage["licenseId"])
                            }

                        }
                    }
                    else {
                        //TODO: how to handle this case when licensesWithAgentIdListInSession is null?
                    }
                },
                onclose() {
                    //console.debug("Connection closed by the server");
                },
                onerror(err) {
                    console.error("There was an error from server", err);
                },
            })
        })
    }
}

function applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, agentIdForRowNode, newLicenseDisplayNameValue, newLicenseIdField){
    if(gridApi && !gridApi.destroyCalled){
        //need to get row node of agent with matching agentId from license
        let agentRowNode = gridApi.getRowNode(agentIdForRowNode)
        if(agentRowNode){
            //if we found the agent, apply update
            agentRowNode.data.licenseDisplayName = newLicenseDisplayNameValue
            agentRowNode.data.licenseId = newLicenseIdField
            gridApi.applyTransactionAsync({
                update: [agentRowNode.data]
            })
        }
    }
}