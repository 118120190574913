import {getAllMasterRole} from "../pages/api/rolesApi";
import {encryptAndStoreSessionVariable} from "./encryptDecryptHelper";

export function getAndStoreAllRoleNamesOfCurrentUserInSession() {
    getAllMasterRole().then(response => {
        //response is all roles in groups the user is in
        encryptAndStoreSessionVariable("MasterRoleList", JSON.stringify(response))
        let zenGroupIdToRoleNameAndIdMap = new Map() //maps zenGroupId to a Map of roleName to masterRole id
        let roleIdToRoleNameMap = new Map() //maps masterRole id to the roleName
        response.forEach((value) => {
            roleIdToRoleNameMap.set(value.id, value.roleName)
            if (!zenGroupIdToRoleNameAndIdMap.has(value.zenGroupId)) {
                let roleNameToIdMapForZenGroup = new Map()
                roleNameToIdMapForZenGroup.set(value.roleName, value.id)
                zenGroupIdToRoleNameAndIdMap.set(value.zenGroupId, roleNameToIdMapForZenGroup)
            } else {
                let roleNameToIdMapForZenGroup = zenGroupIdToRoleNameAndIdMap.get(value.zenGroupId)
                roleNameToIdMapForZenGroup.set(value.roleName, value.id)
                zenGroupIdToRoleNameAndIdMap.set(value.zenGroupId, roleNameToIdMapForZenGroup)
            }
        })
        //need a replacer for stringify for Maps
               encryptAndStoreSessionVariable("roleIdToRoleNameMap", JSON.stringify(roleIdToRoleNameMap, replacerForMapStringify))
                encryptAndStoreSessionVariable("zenGroupIdToRoleNameAndIdMap", JSON.stringify(zenGroupIdToRoleNameAndIdMap, replacerForMapStringify))
            }).catch(error => {
                console.log(error)
    })
}

//same as getAndStoreAllRoleNamesOfCurrentUserInSession except this will be used on role page for refreshing grid column headers for any new/deleted role names.
export function getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid(resetGridFunction, refreshGridCellsFunction) {
    getAllMasterRole().then(response => {
        //response is all roles in groups the user is in
        encryptAndStoreSessionVariable("MasterRoleList", JSON.stringify(response))
        let zenGroupIdToRoleNameAndIdMap = new Map() //maps zenGroupId to a Map of roleName to masterRole id
        let roleIdToRoleNameMap = new Map() //maps masterRole id to the roleName
        response.forEach((value) => {
            roleIdToRoleNameMap.set(value.id, value.roleName)
            if (!zenGroupIdToRoleNameAndIdMap.has(value.zenGroupId)) {
                let roleNameToIdMapForZenGroup = new Map()
                roleNameToIdMapForZenGroup.set(value.roleName, value.id)
                zenGroupIdToRoleNameAndIdMap.set(value.zenGroupId, roleNameToIdMapForZenGroup)
            } else {
                let roleNameToIdMapForZenGroup = zenGroupIdToRoleNameAndIdMap.get(value.zenGroupId)
                roleNameToIdMapForZenGroup.set(value.roleName, value.id)
                zenGroupIdToRoleNameAndIdMap.set(value.zenGroupId, roleNameToIdMapForZenGroup)
            }
        })
        //need a replacer for stringify for Maps
        encryptAndStoreSessionVariable("roleIdToRoleNameMap", JSON.stringify(roleIdToRoleNameMap, replacerForMapStringify))
        encryptAndStoreSessionVariable("zenGroupIdToRoleNameAndIdMap", JSON.stringify(zenGroupIdToRoleNameAndIdMap, replacerForMapStringify))
        resetGridFunction && resetGridFunction()
        refreshGridCellsFunction && refreshGridCellsFunction()
    }).catch(error => {
        console.log(error)
    })
}

export function replacerForMapStringify(key, value) {
    if(value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()),
        };
    } else {
        return value;
    }
}

export function reviverForMapStringify(key, value) {
    if(typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}