export function encryptAndStoreSessionVariable(sessionVariableName, variableStored){
    let variableToBeStored = encryptOrDecrypt(variableStored);
    if(variableToBeStored){
        sessionStorage.setItem(sessionVariableName, variableToBeStored);
    }
}

export function decryptAndGetSessionVariable(sessionVariableName){
    let encryptedStoredValue = sessionStorage.getItem(sessionVariableName);
    if (encryptedStoredValue) {
        return encryptOrDecrypt(encryptedStoredValue);
    }
    else{
        return null;
    }
}

function encryptOrDecrypt(input) {
    let key = sessionStorage.getItem("username");
    if (key === null){
        return null;
    }

    let result = '';
    for (let i = 0; i < input.length; i++) {
        result += String.fromCharCode(input.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
}

