import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css'
import {decryptAndGetSessionVariable} from "./encryptDecryptHelper";
import {Tooltip} from "@mui/material";

export default forwardRef((props, ref) => {
    const [date, setDate] = useState(null);
    const [picker, setPicker] = useState(null);
    const refFlatPickr = useRef(null);
    const refInput = useRef();

    const onDateChanged = useCallback((selectedDates) => {
        setDate(selectedDates[0]);
        props.onDateChanged();
    },[props]);

    useEffect(() => {
        setPicker(flatpickr(refFlatPickr.current, {
            onChange: onDateChanged,
            dateFormat: "F d, Y h:i:S K",
            wrap: true,
            enableTime: true,
            enableSeconds: true
        }));
    }, [onDateChanged]);

    useEffect(() => {
        if (picker) {
            picker.calendarContainer.classList.add('ag-custom-component-popup');
        }
    }, [picker]);

    useEffect(() => {
        if (picker) {
            refInput.current.setAttribute('placeholder', "yyyy-mm-dd");
        }
    }, [picker]);

    useEffect(() => {
        if (picker) {
            picker.setDate(date);
        }
    }, [date, picker]);

    useImperativeHandle(ref, () => ({
        getDate() {
            return date;
        },

        setDate(date) {
            setDate(date);
        },

        setInputPlaceholder(placeholder) {
            if (refInput.current) {
                refInput.current.setAttribute('placeholder', placeholder);
            }
        },

        setInputAriaLabel(label) {
            if (refInput.current) {
                refInput.current.setAttribute('aria-label', label);
            }
        }

    }));
    return (
        <div className="ag-input-wrapper custom-date-filter"  role="presentation" ref={refFlatPickr}>
            <input type="text" ref={refInput} data-input style={{ width: "100%" }} />
            <Tooltip title={<div className={"text-sm"}>clear</div>}
                     placement={"top"} enterDelay={750} arrow>
                <a className='input-button' data-clear>
                    <i className='fa fa-times'/>
                </a>
            </Tooltip>
        </div>
    );
});
export function dateFilterParametersInHeader () {
    return {
        defaultOption: "inRange",
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        buttons: ["reset", "apply", "cancel"],
        comparator: function (filterLocalDate, cellValue) {
            return dateFilter(filterLocalDate, cellValue)
        }
    }
}
export function dateFilterParametersInHeaderSuppressSorting () {
    return {
        suppressSorting: true,
        
        defaultOption: "inRange",
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        suppressAndOrCondition: true,
        buttons: ["reset", "apply", "cancel"],
        comparator: function (filterLocalDate, cellValue) {
            return dateFilter(filterLocalDate, cellValue)
        }
    }
}
export function dateFilter (filterLocalDate, cellValue){

    let test = new Date()

    let testSessionTime = (new Date(Date.parse(test.toLocaleString('en-US', {
        timeZone: decryptAndGetSessionVariable("timezone")
    }))));

    let sessionTZOffset = Math.ceil((testSessionTime - test) / 36e5) * 60 * 60 * 1000;
    let browserTZOffset = filterLocalDate.getTimezoneOffset() * 60 * 1000

    cellValue = Math.floor((Date.parse(cellValue) - browserTZOffset)/1000)
    filterLocalDate = (filterLocalDate.getTime() - (sessionTZOffset) - browserTZOffset)/1000

    if (filterLocalDate === cellValue) {
        return 0;
    }

    if (cellValue < filterLocalDate) {
        return -1;
    }

    if (cellValue > filterLocalDate) {
        return 1;
    }
}
