import {Box, Button, IconButton, ThemeProvider, Tooltip} from "@mui/material";
import {buttonTheme} from "../utils/muiStyling";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export const MuiStandardButton = ({buttonText, variant, theme=buttonTheme, color, onClick, startIcon}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Button
                variant={"contained"}
                color={"primary"}
                disableFocusRipple={true}
                onClick={onClick}
                startIcon={null}
            >
                {buttonText}
            </Button>
        </ThemeProvider>
    );
};

export const MuiStandardButtonWithEndIconAndTooltip = ({buttonText, tooltipTitle, tooltipPlacement, theme=buttonTheme, color, onClick, endIcon, sx={}}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} enterDelay={750} arrow>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={sx}
                    disableFocusRipple={true}
                    onClick={onClick}
                    endIcon={endIcon}
                >
                    {buttonText}
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
};

export const MuiStandardButtonWithStartIconAndTooltip = ({buttonText, tooltipTitle, tooltipPlacement, theme=buttonTheme, color, onClick, startIcon, sx={}}) => {
    return (
        <ThemeProvider theme = {theme}>
            <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                     placement={tooltipPlacement} enterDelay={750} arrow>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={sx}
                    disableFocusRipple={true}
                    onClick={onClick}
                    startIcon={startIcon}
                >
                    {buttonText}
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
};

export const MuiCloseIconButton = ({onClick, sx = {color: "black"}}) => {
    return (
        <IconButton
            sx={sx}
            className={"self-center"}
            onClick={() => {
                onClick && onClick()
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}

// For icons that have an action tied to it
export const MuiIconButtonWithTooltip = ({onClick, disabled = false, icon, tooltipTitle, tooltipPlacement, followCursor = false,
                                             sx = {color: "black", width: 25, height: 25}
                                         }) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <IconButton
                sx={sx}
                disabled={disabled}
                className={"self-center object-contain"}
                onClick={() => {
                    onClick && onClick()
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export const MuiIconButtonWithTooltipAndBox = ({onClick, disabled = false, icon, tooltipTitle, tooltipPlacement, followCursor = false,
                                                   buttonsx={
                                                       "&:hover": {
                                                           backgroundColor: "white",
                                                           cursor: "pointer"
                                                       },
                                                       color: "#505050",
                                                       background: "#F3f3f3",
                                                       "&:disabled": {
                                                           backgroundColor: "#Fbfbfb",
                                                           cursor: "default",
                                                           color: "#C1c1c1"
                                                       },
                                                       borderRadius: "28px"
                                                   }, boxsx={ boxShadow: 7, borderRadius: '28px'}
                                               }) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box sx={boxsx}>
                <IconButton
                    sx={buttonsx}
                    disabled={disabled}
                    className={"self-center object-contain"}
                    onClick={() => {
                        onClick && onClick()
                    }}
                >
                    {icon}
                </IconButton>
            </Box>
        </Tooltip>
    )
}

// For icons that only have a tooltip for showing information
export const MuiIconWithTooltip = ({icon, tooltipTitle, tooltipPlacement, followCursor = false}) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box sx={{ width: 30, height: 30}}>
                {icon}
            </Box>
        </Tooltip>
    )
}

// For adding tooltip to text
export const MuiTextWithTooltip = ({text, tooltipTitle, tooltipPlacement, followCursor = false}) => {
    return (
        <Tooltip title={<div className={"text-sm"}>{tooltipTitle}</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                 placement={tooltipPlacement} followCursor={followCursor} enterDelay={750} arrow>
            <Box>
                {text}
            </Box>
        </Tooltip>
    )
}