import {getAgentsGridColumnStateReactive, getAgentsGridFilterStateReactive} from "../pages/api/agentsApi";
import {getDealsGridColumnStateReactive, getDealsGridFilterStateReactive} from "../pages/api/dealsApi";
import {getDecryptorsGridColumnStateReactive, getDecryptorsGridFilterStateReactive} from "../pages/api/decryptorsApi";
import {getGroupsGridColumnStateReactive, getGroupsGridFilterStateReactive} from "../pages/api/groupsApi";
import {getIncidentsGridColumnStateReactive, getIncidentsGridFilterStateReactive} from "../pages/api/incidentsApi";
import {
    getBrowserDataIncidentsGridColumnStateReactive,
    getBrowserDataIncidentsGridFilterStateReactive
} from "../pages/api/browserDataIncidentsApi";
import {getLicensesGridColumnStateReactive, getLicensesGridFilterStateReactive} from "../pages/api/licensesApi";
import {
    getSecurityNotificationsGridColumnStateReactive,
    getSecurityNotificationsGridFilterStateReactive
} from "../pages/api/notificationsApi";
import {
    getProcessCreationsGridColumnStateReactive,
    getProcessCreationsGridFilterStateReactive
} from "../pages/api/processCreationsApi";
import {
    getProcessInjectionsGridColumnStateReactive,
    getProcessInjectionsGridFilterStateReactive
} from "../pages/api/processInjectionsApi";
import {getRolesGridColumnStateReactive, getRolesGridFilterStateReactive} from "../pages/api/rolesApi";
import {
    getSalesNotificationsGridColumnStateReactive,
    getSalesNotificationsGridFilterStateReactive
} from "../pages/api/salesNotificationsApi";
import {getSchedulesGridColumnStateReactive, getSchedulesGridFilterStateReactive} from "../pages/api/schedulesApi";
import {
    getSessionDurationsGridColumnStateReactive,
    getSessionDurationsGridFilterStateReactive
} from "../pages/api/sessionExpirationApi";
import {getTasksGridColumnStateReactive, getTasksGridFilterStateReactive} from "../pages/api/tasksApi";
import {getWhitelistsGridColumnStateReactive, getWhitelistsGridFilterStateReactive} from "../pages/api/whitelistsApi";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./encryptDecryptHelper";
import {
    getSilentIncidentsGridColumnStateReactive,
    getSilentIncidentsGridFilterStateReactive
} from "../pages/api/silentResponsesApi";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";
import {
    getTrainingLicensesGridColumnStateReactive,
    getTrainingLicensesGridFilterStateReactive
} from "../pages/api/trainingLicensesApi";

//since the agents page is the page that users go to after successful login, we should call to the api for the agent filter and column states first before other pages.
export function getAgentsGridFilterAndColumnStateAndStoreInSession(){
    getAgentsGridFilterStateReactive().then(response => {
        encryptAndStoreSessionVariable("agentsGridFilterState", JSON.stringify(response));
    }).catch(error => {
        console.log(error);
    })
    getAgentsGridColumnStateReactive().then(response => {
        encryptAndStoreSessionVariable("agentsGridColumnState", JSON.stringify(response));
    }).catch(error => {
        console.log(error);
    })
}

//clearSessionStorageValues is used for toggling training/demo mode for clearing session storage variables or retriving them
export function getAndStoreAllFilterStatesInSession(clearSessionStorageValues){
    if(clearSessionStorageValues){
        sessionStorage.removeItem("agentsGridFilterState")
        sessionStorage.removeItem("dealsGridFilterState")
        sessionStorage.removeItem("decryptorsGridFilterState")
        sessionStorage.removeItem("groupsGridFilterState")
        sessionStorage.removeItem("incidentsGridFilterState")
        sessionStorage.removeItem("licensesGridFilterState")
        sessionStorage.removeItem("securityNotificationsGridFilterState")
        sessionStorage.removeItem("processCreationsGridFilterState")
        sessionStorage.removeItem("processInjectionsGridFilterState")
        sessionStorage.removeItem("rolesGridFilterState")
        sessionStorage.removeItem("salesNotificationsGridFilterState")
        sessionStorage.removeItem("schedulesGridFilterState")
        sessionStorage.removeItem("sessionDurationsGridFilterState")
        sessionStorage.removeItem("tasksGridFilterState")
        sessionStorage.removeItem("whitelistsGridFilterState")
        sessionStorage.removeItem("silentIncidentsGridFilterState")
        sessionStorage.removeItem("browserDataIncidentsGridFilterState")
        sessionStorage.removeItem("trainingLicensesGridFilterState")
    }
    else{
        getAgentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("agentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getDealsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("dealsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getDecryptorsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("decryptorsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getGroupsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("groupsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("incidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getLicensesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("licensesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSecurityNotificationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("securityNotificationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getProcessCreationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("processCreationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getProcessInjectionsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("processInjectionsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getRolesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("rolesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSalesNotificationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("salesNotificationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSchedulesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("schedulesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSessionDurationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("sessionDurationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getTasksGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("tasksGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getWhitelistsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("whitelistsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSilentIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("silentIncidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getBrowserDataIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("browserDataIncidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTrainingLicensesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("trainingLicensesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
    }
}

export function onFilterChangedHelper(params, gridFilterState, updateGridFilterModel){
    let filterModel = params.api.getFilterModel();
    let currentFilterState = decryptAndGetSessionVariable(gridFilterState);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentFilterState){
        try{
            let parsedFilterState = JSON.parse(currentFilterState);
            if (parsedFilterState.useFilterState === true) {
                //session storage
                parsedFilterState.filterModel = JSON.stringify(filterModel);
                encryptAndStoreSessionVariable(gridFilterState, JSON.stringify(parsedFilterState));
                //api call to update filter model for user only if we are not in training or demo mode
                if(!trainingMode && !demoMode){
                    updateGridFilterModel(JSON.stringify(filterModel)).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }

    }
    else{
        //session storage
        const currentFilterState = {
            "filterModel": JSON.stringify(filterModel),
            "useFilterState": true
        };
        encryptAndStoreSessionVariable(gridFilterState, JSON.stringify(currentFilterState));
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridFilterModel(JSON.stringify(filterModel)).then(response => {
            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function onGridReadyHelper(params, gridFilterState){
    let currentFilterState = decryptAndGetSessionVariable(gridFilterState);
    if (currentFilterState){
        try{
            let filterStateParsed = JSON.parse(currentFilterState);
            if (filterStateParsed.useFilterState){
                let parsedFilterModelVariable = JSON.parse(filterStateParsed.filterModel);
                params.api.setFilterModel(parsedFilterModelVariable);
            }
        } catch (error){
            console.log(error);
        }
    }
}

export function updateUseFilterStateHelper(newUseFilterState, gridUseFilterState, updateGridUseFilterState){
    let currentUseFilterState = decryptAndGetSessionVariable(gridUseFilterState);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentUseFilterState){
        try{
            let parsedUseFilterState = JSON.parse(currentUseFilterState);
            //session storage
            parsedUseFilterState.useFilterState = newUseFilterState;
            encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(parsedUseFilterState));
        }catch (error) {
            console.log(error);
        }
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseFilterState(newUseFilterState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
    else{
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseFilterState(newUseFilterState).then(response => {
                //sessionStorage
                const newFilterState = {
                    "filterModel": null,
                    "useFilterState": newUseFilterState
                };
                encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(newFilterState));
            }).catch(error => {
                console.log(error);
            })
        }
        else{
            //else only update in session
            const newFilterState = {
                "filterModel": null,
                "useFilterState": newUseFilterState
            };
            encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(newFilterState));
        }
    }
}

export function getUseFilterStateInSession(gridUseFilterState) {
    let currentUseFilterState = decryptAndGetSessionVariable(gridUseFilterState);
    if (currentUseFilterState) {
        try {
            let currentUseFilterStateParsed = JSON.parse(currentUseFilterState);
            return currentUseFilterStateParsed.useFilterState;
        } catch (error) {
            console.log(error);
        }
    }
    return true;
}

//clearSessionStorageValues is used for toggling training/demo mode for clearing session storage variables or retriving them
export function getAndStoreAllGridColumnStatesInSession(clearSessionStorageValues=false){
    if(clearSessionStorageValues){
        sessionStorage.removeItem("agentsGridColumnState")
        sessionStorage.removeItem("whitelistsGridColumnState")
        sessionStorage.removeItem("decryptorsGridColumnState")
        sessionStorage.removeItem("incidentsGridColumnState")
        sessionStorage.removeItem("tasksGridColumnState")
        sessionStorage.removeItem("processInjectionsGridColumnState")
        sessionStorage.removeItem("processCreationsGridColumnState")
        sessionStorage.removeItem("securityNotificationsGridColumnState")
        sessionStorage.removeItem("salesNotificationsGridColumnState")
        sessionStorage.removeItem("schedulesGridColumnState")
        sessionStorage.removeItem("sessionDurationsGridColumnState")
        sessionStorage.removeItem("groupsGridColumnState")
        sessionStorage.removeItem("rolesGridColumnState")
        sessionStorage.removeItem("licensesGridColumnState")
        sessionStorage.removeItem("partnerDealsGridColumnState")
        sessionStorage.removeItem("silentIncidentsGridColumnState")
        sessionStorage.removeItem("browserDataIncidentsGridColumnState")
        sessionStorage.removeItem("trainingLicensesGridColumnState")
    }
    else{
        getAgentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("agentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getWhitelistsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("whitelistsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDecryptorsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("decryptorsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("incidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTasksGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("tasksGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getProcessInjectionsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("processInjectionsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getProcessCreationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("processCreationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSecurityNotificationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("securityNotificationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSalesNotificationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("salesNotificationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSchedulesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("schedulesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSessionDurationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("sessionDurationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getGroupsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("groupsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getRolesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("rolesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getLicensesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("licensesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDealsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("partnerDealsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSilentIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("silentIncidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getBrowserDataIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("browserDataIncidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTrainingLicensesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("trainingLicensesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
    }
}

export function onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateGridColumnStateFunction){
    let columnState = params.columnApi.getColumnState()
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName)
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if(currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState)
            if(parsedColumnState.useColumnState === true){
                //update in sessionStorage
                parsedColumnState.columnStatesList = columnState
                encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(parsedColumnState))
                //api call to update column state for user only if we are not in training or demo mode
                if(!trainingMode && !demoMode){
                    updateGridColumnStateFunction(columnState).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }

        } catch (e) {
            console.log(e);
        }
    }
    else{
        const currentColumnState = {
            "useColumnState": true,
            "columnStatesList": columnState
        }

        encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(currentColumnState))
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridColumnStateFunction(columnState).then(response => {
            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            if (parsedColumnState.useColumnState){
                params.columnApi.applyColumnState({applyOrder: true, state: parsedColumnState.columnStatesList})
            }
        } catch (error){
            console.log(error);
        }
    }
}

//just for incidents grid, making sure number of incidents column is always visible and pinned
export function onGridReadyHelperForColumnStateForIncidentsGrid(params, gridColumnStateSessionVariableName){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            if (parsedColumnState.useColumnState){
                if(parsedColumnState.columnStatesList){
                    parsedColumnState.columnStatesList.forEach(function(columnState){
                        if(columnState && columnState.colId === "incidentsCount"){
                            columnState.hide = false
                            columnState.pinned = "left"
                        }
                    })
                    params.columnApi.applyColumnState({applyOrder: true, state: parsedColumnState.columnStatesList})
                }
            }
        } catch (error){
            console.log(error);
        }
    }
}

export function getUseColumnStateInSession(gridColumnStateSessionVariableName) {
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState) {
        try {
            let parsedColumnState = JSON.parse(currentColumnState);
            return parsedColumnState.useColumnState;
        } catch (error) {
            console.log(error);
        }
    }
    return true;
}

export function updateUseColumnStateHelper(newUseColumnState, gridColumnStateSessionVariableName, updateGridUseColumnStateFunction){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            //session storage
            parsedColumnState.useColumnState = newUseColumnState;
            encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(parsedColumnState));
        }catch (error) {
            console.log(error);
        }
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseColumnStateFunction(newUseColumnState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
    else{
        //sessionStorage
        const newFilterState = {
            "columnStatesList": null,
            "useColumnState": newUseColumnState
        };
        encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(newFilterState));
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseColumnStateFunction(newUseColumnState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
}