import {useForm} from "react-hook-form";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import Dropdown from "react-dropdown";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {userGroupListWithChannelInfoReactive} from "../pages/api/groupsApi";
import {redirectToStripeUserLoggedIn} from "./stripeRedirectToCheckout";
import {decryptAndGetSessionVariable} from "../utils/encryptDecryptHelper";
import {buttonTheme, switchTheme} from "../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton} from "./muiComponents";

export const StripeLoggedInModalSetup = ({ onClose, opened, setIsLoading, setIsOpened }) => {
    const {handleSubmit} = useForm();
    const [zenGroup, setZenGroup] = useState();
    const [zenGroups, setZenGroups] = useState([]);
    const [desktopToggled, setDesktopToggled] = useState(true);
    const [serverToggled, setServerToggled] = useState(true);


    useEffect(() => {
        async function populate() {
            if(opened){ //only call when opened
                let refreshToken = decryptAndGetSessionVariable("refresh_token")
                //only make api call if logged in
                if(refreshToken !== null){
                    try{
                        const result = await userGroupListWithChannelInfoReactive()
                        let zenGroups = []
                        //let userName = ""
                        let isMemberOfChannelPartnerCustomerGroup = false
                        let isMemberOfChannelPartnerGroup = false
                        result.forEach(group => {
                            //username and the member fields here should be the same for each result
                            //userName = group.username
                            isMemberOfChannelPartnerCustomerGroup = group.isMemberOfChannelPartnerCustomerGroup
                            isMemberOfChannelPartnerGroup = group.isMemberOfChannelPartnerGroup
                            //
                            zenGroups.push(group.zenGroup)
                        })

                        if(isMemberOfChannelPartnerCustomerGroup){
                            //we need to check if they are also a part of a channelPartner group
                            if(isMemberOfChannelPartnerGroup){
                                //good to show
                                setIsOpened(true)
                            }
                            else{
                                //don't allow tabs to show
                                setIsOpened(false)
                                NotificationManager.error("Your channel partner group is supposed to purchase licenses for you, please contact us at sales@cybercrucible.com to resolve this issue.");
                            }
                        }
                        else{
                            //good to show
                            setIsOpened(true)
                        }
                        setZenGroups(zenGroups);
                    }
                    catch(error){
                        if(error.message){
                            //NotificationManager.error(error.message);
                        }
                        else{
                            //TODO: display a standard error message here like error setting up page?
                        }
                        setIsOpened(false)
                    }
                    setIsLoading && setIsLoading(false);
                }
            }
        }
        populate();
    }, [opened]);

    const resetAll = () => {
        setZenGroup()
        onClose()
        setIsLoading && setIsLoading(false)
        setServerToggled(true)
        setDesktopToggled(true)
    }

    const onSubmit = async () => {
        if(zenGroup){
            //they need to select at least one license type
            if(!desktopToggled && !serverToggled){
                NotificationManager.info("You need to select at least one license type before continuing.");
                return
            }
            setIsLoading && setIsLoading(true)
            try{
                //stripe checkout setup
                await redirectToStripeUserLoggedIn(zenGroup, desktopToggled, serverToggled)
                setIsLoading && setIsLoading(false)
            }
            catch (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error continuing to payment");
                }
            }
            setIsLoading && setIsLoading(false)
        }
        else {
            NotificationManager.error("Please fill out all fields");
        }
    }
    return (
        <Modal
            onRequestClose={() => {
                resetAll()
            }}
            isOpen={opened}
            contentLabel={"Form Data Before Continuing to Payment"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-1 flex-col w-full gap-y-4">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Select a Group to Assign Licenses to Before Continuing to Payment</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetAll()
                            }}
                        />
                    </div>
                    <hr className="h-0.5" />
                    {/*Form content*/}
                    <label className="">
                        Select which type(s) of license you would like to purchase:
                    </label>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={desktopToggled}
                                    name="toggle"
                                    onChange={e => setDesktopToggled(e.target.checked)}
                                />
                            } label={"Desktop"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={serverToggled}
                                    name="toggle2"
                                    onChange={e => setServerToggled(e.target.checked)}
                                />
                            } label={"Server"}/>
                        </ThemeProvider>
                    </div>
                    <div className="">
                        <small>Select which group to purchase licenses for</small>
                        <Dropdown
                            options={zenGroups.map(({ id, friendlyName }) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={zenGroup}
                            onChange={({ value }) => {
                                setZenGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button
                            id={"continueToPaymentButton"}
                            variant={"contained"}
                            type={"submit"}
                            color={"primary"}>
                            Continue to Payment
                        </Button>
                    </ThemeProvider>
                </div>

            </form>
        </Modal>
    );
};
