import React, {Component, useState} from "react";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    findByProcessInjectionIdListReactive,
    processInjectionsListReactive,
    updateProcessInjectionsGridColumnStateReactive,
    updateProcessInjectionsGridFilterModelReactive,
    updateProcessInjectionsGridUseColumnStateReactive,
    updateProcessInjectionsGridUseFilterStateReactive
} from "../api/processInjectionsApi";
import SidebarMenu from "../../components/sideBarComponent";
import {defaultZenGroupColumnInitWithOptions} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {base64DecodedValueFormatter} from "../../utils/gridBase64ValueFormatter";
import {agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped,} from "../../utils/gridCellEditing";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useLocation} from "react-router-dom";
import {findZenGroupById} from "../../utils/zenGroupSessionStorageManager";
import {ClickToShowButtonsExpandingLeft} from "../../components/clickToShowButtons";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {ToggleButton, Tooltip} from "@mui/material";

let gridColumnStateSessionVariableName = "processInjectionsGridColumnState"
export default function ProcessInjections() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroups,setZenGroups] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [defaultZenGroup,setDefaultZenGroup] = useState(null);
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("processInjectionsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const processInjectionLocation = useLocation();
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptions(false, true, true),
        defaultAgentNameColumnInitWithOptions(false),
        { field: "injectorPath", name: "Injector Path", width: 500,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "injectorArgsEncoded", name: "Injector Arguments", width: 500,
            /*filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },*/
            sortable: false,
            valueFormatter: base64DecodedValueFormatter
        },
        { field: "injecteePath", name: "Injectee Path", width: 500,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "injecteeArgsEncoded", name: "Injectee Arguments", width: 500,
            /*filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },*/
            sortable: false,
            valueFormatter: base64DecodedValueFormatter
        },
        { field: "injectorPid", name: "Injector Pid", width: 200,
            filter: 'agNumberColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "injecteePid", name: "Injectee Pid", width: 200,
            filter: 'agNumberColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "timestamp", name: "Created", width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCollected", name: "Collected", width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "injecteeUniqueProcessIdentifier", name: "Injectee Unique Process Identifier", width: 280,
            filter: 'agTextColumnFilter',
            hide: true,
            suppressColumnsToolPanel: true
        },
        { field: "agentVersionFormatted", name: "Agent Version", width: 280,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            valueFormatter: agentVersionFormatter,
            cellRenderer: function(params){
                let spinnerDiv = ""
                if(params.node.data.agentId === params.valueFormatted ){
                    spinnerDiv = <FontAwesomeIcon
                        className="contain fa-pulse"
                        icon="fa-light fa-spinner"
                        size="lg"
                        name="agentVersionLoading"
                    />
                }else{
                    spinnerDiv = ""
                }
                return(
                    <div id ="fortooltip" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                        {spinnerDiv}
                        {params.valueFormatted}
                    </div>
                )
            },


        },
    ])
    var _ = require('lodash');

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Process Injections</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col ml-10 mr-10 mt-8 flex-nowrap gap-y-2">
                    {privatePageHeaderHelper("Process Injections")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi}
                                          refreshGridFunction = {refreshGrid}/>
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'processInjectionsGridFilterState', updateProcessInjectionsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateProcessInjectionsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                setZenGroups={setZenGroups}
                setDefaultZenGroup={setDefaultZenGroup}
                processInjectionLocation={processInjectionLocation}
            />
        );
    }

    async function refreshGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "procInjectionId", "id", findByProcessInjectionIdListReactive, null, "agentId",null)
    }
    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
    }

     */
}

let saveFilterChanges = true //used to help let code below know to save filters or not, when this is false that means we were redirected from incidents page and don't want to save filters since we will be auto filtering
class Grid extends Component {
    constructor(props, onClickRow, filterVals, setGridApi) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        /*params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            if(filterInstance.valueModel.allValues.length>0){
                this.props.setZenGroups(filterInstance.valueModel.allValues)
                let defaultZenGroup = filterInstance.valueModel.allValues.find(zenGroupObjectIterator => zenGroupObjectIterator.defaultGroup===true)
                console.log("finding defaultZenGroup in onFirstDataRendered")
                console.debug(defaultZenGroup)
            }
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
    };

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateProcessInjectionsGridColumnStateReactive)
    }

    onGridReady = async (params) => {
        /*params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            if(filterInstance.valueModel.allValues.length>0) {
                this.props.setZenGroups(filterInstance.valueModel.allValues)
                console.log(filterInstance.valueModel.allValues[0])
                let defaultZenGroup = filterInstance.valueModel.allValues.find(zenGroupObjectIterator => zenGroupObjectIterator.defaultGroup===true)
                console.log("finding defaultZenGroup in onGridReady")
                console.debug(defaultZenGroup)
            }
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.processInjectionLocation && this.props.processInjectionLocation.state && this.props.processInjectionLocation.state.zenGroupIdClicked
            && this.props.processInjectionLocation.state.uniqueProcessIdentifier){
            let zenGroupId = this.props.processInjectionLocation.state.zenGroupIdClicked
            let injecteeUniqueProcessIdentifier = this.props.processInjectionLocation.state.uniqueProcessIdentifier
            let locationFilterModel = {"injecteeUniqueProcessIdentifier": {
                    filterType: "text",
                    type: "equals",
                    filter: JSON.stringify(injecteeUniqueProcessIdentifier) //need to convert to string for ag grid filters
            }}
            let zenGroup = findZenGroupById(zenGroupId)
            if(zenGroup && zenGroup.friendlyName){
                locationFilterModel["zenGroupDisplayName"] = {filterType: "set", values: [zenGroup.friendlyName]}
            }
            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove from state so if they hit back tab then forward tab filter is not applied again
            window.history.replaceState(this.props.processInjectionLocation.state, '')
        } else {
            saveFilterChanges = true
            onGridReadyHelper(params, "processInjectionsGridFilterState");
        }
        //check if we want to apply saved column state

        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        //this.gridOptions.api.setFilter
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);
        var dataSource = {
            getRows: async function (params) {
                try{
                    let start = params.request.startRow;
                    let end = params.request.endRow;

                    let filters = masterFilterHelper(params.request.filterModel)
                    let sortModel = params.request.sortModel;
                    let sortModelForRequest = null;
                    if(sortModel && sortModel.length > 0){
                        try{ //extra precaution for array access on the sort model so it does not break
                            if(sortModel[0].colId && sortModel[0].sort){
                                sortModelForRequest = sortModel[0]
                            }
                        }
                        catch(error){
                            console.log("Error generating sort request")
                        }
                    }
                    let count = end - start;
                    let page = Math.floor(start / count)
                    let objects = await processInjectionsListReactive(count, page, filters, sortModelForRequest);
                    let finalPage = true
                    //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                    // endpoint instead of Mono with a Response object
                    if(objects && objects.length === count){
                        finalPage = false
                    }
                    let lastIdx = undefined
                    if (finalPage) {
                        lastIdx = (count * page) + objects.length
                    }
                    params.api.deselectAll();
                    const success = (objects.length !== 0);
                    if (success) {
                        params.api.hideOverlay()
                        params.successCallback(objects, lastIdx);
                    } else {
                        //params.api.showNoRowsOverlay();
                        params.successCallback(objects, lastIdx); //to stop loading circle from spinning and make it disappear, nothing in objects.
                        params.failCallback();
                    }

                }
                catch(error){
                    //console.log(error);
                    params.api.showNoRowsOverlay();
                    params.failCallback();
                }
            }
        }

        params.api.setServerSideDatasource(dataSource);
        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        serverSideInfiniteScroll={true}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'processInjectionsGridFilterState', updateProcessInjectionsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}

                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate,
                                    cellRenderer, cellRendererSelector, cellEditorType,
                                    hide, sortable, minWidth, width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector,
                                    keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight={false}
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    floatingFilter={true}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
