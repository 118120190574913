import React, {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import {MuiStandardButtonWithEndIconAndTooltip, MuiStandardButtonWithStartIconAndTooltip} from "./muiComponents";
import {Grow} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import {roundButtonTheme} from "../utils/muiStyling";

export const ClickToShowButtonsExpandingRight = ({buttonsDiv, buttonsText, tooltipTitle}) => {
    const [showButtons, setShowButtons] = useState(false);

    return (
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center">
            <MuiStandardButtonWithEndIconAndTooltip
                onClick={() => {
                    setShowButtons(!showButtons)
                }}
                endIcon={showButtons? <RemoveIcon className={"cursor-pointer"}/> : <AddIcon className={"cursor-pointer"}/>}
                sx={{
                    "&:hover": {
                        backgroundColor: "#f78f5e",
                        cursor: "pointer"
                    },
                    color: "white",
                    background: "#e76a24",
                    p: 1.3, pl: 1.8, pr: 1.8
                }}
                tooltipTitle={tooltipTitle}
                tooltipPlacement={"top"}
                buttonText={buttonsText}
                theme={roundButtonTheme}
            />
                <Grow children={buttonsDiv} in={showButtons} timeout={1000} unmountOnExit/>
        </div>
    );
};

export const ClickToShowButtonsExpandingLeft = ({buttonsDiv, buttonsText, tooltipTitle}) => {
    const [showButtons, setShowButtons] = useState(false);

    return (
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center justify-end">
            <Grow children={buttonsDiv} in={showButtons} timeout={1000} unmountOnExit/>
            <MuiStandardButtonWithStartIconAndTooltip
                onClick={() => {
                    setShowButtons(!showButtons)
                }}
                startIcon={showButtons? <RemoveIcon className={"cursor-pointer"}/> : <AddIcon className={"cursor-pointer"}/>}
                sx={{
                    "&:hover": {
                        backgroundColor: "#f78f5e",
                        cursor: "pointer"
                    },
                    color: "white",
                    background: "#e76a24",
                    p: 1.3, pl: 1.8, pr: 1.8
                }}
                tooltipTitle={tooltipTitle}
                tooltipPlacement={"top"}
                buttonText={buttonsText}
                theme={roundButtonTheme}
            />
        </div>
    );
};