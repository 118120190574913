import React from "react";
// The import below should be updated to match your Router component
import SitemapRoutes from "./sitemap-routes";
import DynamicSitemap from "react-dynamic-sitemap";

export default function Sitemap(props) {
    return (
        <DynamicSitemap routes={SitemapRoutes} prettify={true} {...props}/>
    );
}
