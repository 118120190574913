import axios from "../../utils/axiosInstance";

export const contactUsFormSubmitReactive = async (firstName, lastName, emailAddress, phoneNumber, message, phoneCountry, recaptchaValue) => {
    return await axios.post("/public/contactUsFormSubmitReactive", {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        message: message,
        phoneCountry: phoneCountry,
        recaptchaValue: recaptchaValue

    });
};

