import {useEffect, useState} from 'react';
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./encryptDecryptHelper";

export function getZenGroupSessionStorageOrDefault() {
    let zenGroupSessionStorageValue = decryptAndGetSessionVariable("zenGroupSessionStorage");
    if (!zenGroupSessionStorageValue) {
        return [];
    }
    try{
        let parsedGroups = JSON.parse(zenGroupSessionStorageValue);
        if(parsedGroups === null || parsedGroups === undefined){
            return []
        }
        else{
            return parsedGroups
        }
    } catch (error){
        console.error(error)
        return []
    }
}

export function useZenGroupSessionStorage() {
    const [zenGroupSessionStorage, setZenGroupSessionStorage] = useState(
        getZenGroupSessionStorageOrDefault()
    );
    useEffect(() => {
        encryptAndStoreSessionVariable("zenGroupSessionStorage", JSON.stringify(zenGroupSessionStorage));
    }, [zenGroupSessionStorage]);

    return [zenGroupSessionStorage, setZenGroupSessionStorage];
}

export function getDistributionGroups(sort=false) {
    //let zenGroupSessionStorageValue = sessionStorage.getItem("zenGroupSessionStorage");
    let distributionList = []
    let groups = getZenGroupSessionStorageOrDefault()
    if (groups && groups.length>0) {
        groups.forEach(zenGroup => {
            if (zenGroup.distributionGroup) {
                distributionList.push(zenGroup)
            }
        })
    }
    if(sort){
        distributionList.sort(function (a, b) {
            return a.friendlyName.toLowerCase().localeCompare(b.friendlyName.toLowerCase());
        });
    }

    return distributionList
}

export function getChannelGroups(){
    //let zenGroupSessionStorageValue = sessionStorage.getItem("zenGroupSessionStorage");
    let channelList = []
    let groups = getZenGroupSessionStorageOrDefault()
    if(groups && groups.length>0){
        groups.forEach(zenGroup => {
            if (zenGroup.channelPartner) {
                channelList.push(zenGroup)
            }
        })
    }
    return channelList
}

export function getZenGroupDropDownContents(){
    let groups = getZenGroupSessionStorageOrDefault()
    if(groups && groups.length>0) {
        return groups.map(({id, friendlyName}) => ({
            value: id,
            label: friendlyName || id,
        }))
    }
    return []
}

export function getResetTrainingGroupDataDropDownContents(groupIdsWithTrainingData){
    let groups = []
    if(groupIdsWithTrainingData !== null && groupIdsWithTrainingData !== undefined){
        groupIdsWithTrainingData.forEach(zenGroupId => {
            let group = findZenGroupById(zenGroupId)
            if(group && group.friendlyName){
                //we found group in session, add it to groups
                groups.push({"id": zenGroupId, "friendlyName": group.friendlyName})
            }
        })
    }
    if(groups && groups.length>0) {
        return groups.map(({id, friendlyName}) => ({
            value: id,
            label: friendlyName || id,
        }))
    }
    return []
}

export function getChannelGroupsDropDownContents(){
    let groups = getChannelGroups()
    if(groups && groups.length>0){
        return groups.map(({ id, organizationName, friendlyName }) => ({
            value: id,
            label: organizationName || friendlyName || id,
        }))
    }
    return []

}

export function getDistributionGroupsDropDownContents(){
    let groups =getDistributionGroups()
    if(groups && groups.length>0) {
        return groups.map(({id, organizationName, friendlyName}) => ({
            value: id,
            label: organizationName || friendlyName || id,
        }))
    }
}

export function getZenGroupFriendlyNames(){
    let groupFriendlyNames = [];
    let groups = getZenGroupSessionStorageOrDefault()
    if(groups && groups.length>0) {
        groups.forEach(groupObject => {
                groupFriendlyNames.push(groupObject.friendlyName)
            }
        )
    }
    return groupFriendlyNames
}

export function getDistributionZenGroupFriendlyNames(){
    let groupFriendlyNames = [];
    let groups = getZenGroupSessionStorageOrDefault()
    if(groups && groups.length>0) {
        groups.forEach(groupObject => {
                if(groupObject.distributionGroup){
                    groupFriendlyNames.push(groupObject.friendlyName)
                }
            }
        )
    }
    return groupFriendlyNames
}

export function getZenGroupOrOrganizationNames(){
    let groupFriendlyNames = [];
    let groups = getZenGroupSessionStorageOrDefault()
    if(groups && groups.length>0) {
        groups.forEach(groupObject => {
            if(groupObject.organizationName){
                groupFriendlyNames.push(groupObject.organizationName)
            }else {
                groupFriendlyNames.push(groupObject.friendlyName)
            }
        })
    }
    return groupFriendlyNames
}

export const findZenGroupByIdAsync = async(inputId) => {
    if(getZenGroupSessionStorageOrDefault()!=null) {
        let zenGroup = await getZenGroupSessionStorageOrDefault().find(zenGroupObject => zenGroupObject.id === inputId)
        if (zenGroup) {
            return zenGroup
        }
    }
    return {}
}
export function findZenGroupById(inputId){
    let zenGroup = getZenGroupSessionStorageOrDefault().find(zenGroupObject => zenGroupObject.id === inputId)
    /*console.log("Getting zenGroup")
    console.log(zenGroup)*/
    if(zenGroup){
        return zenGroup
    }else{
        //console.log("no zenGroup")
    }
    return {}
}

export function updateGroupNameInSession(inputId, newName){
    let zenGroups = getZenGroupSessionStorageOrDefault()
    if(zenGroups){
        //find specific group and update the name
        let updatedGroups = zenGroups.map(group => (group.id === inputId ? { ...group, friendlyName: newName } : group));
        //reset back in the session with updated name for the specific group
        encryptAndStoreSessionVariable("zenGroupSessionStorage", JSON.stringify(updatedGroups));
    }

}

export function findZenGroupByFriendlyName(inputFriendlyName){
    let zenGroup = getZenGroupSessionStorageOrDefault().find(zenGroupObject => zenGroupObject.friendlyName === inputFriendlyName)
    if(zenGroup){
        return zenGroup
    }
    return {}
}

export function getGroupNameForDealsGridForChangeStream(groupId){
    //CC users see all deals and regular users only see deals in their groups, need to handle both cases
    let matchingZenGroup = findZenGroupById(groupId)
    if(matchingZenGroup && matchingZenGroup.id){
        return matchingZenGroup.organizationName ? matchingZenGroup.organizationName : matchingZenGroup.friendlyName
    }
    else{
        //else check ccUsersDealsZenGroupSessionStorage
        let groupsForCCUsersOnDealsPageStorage = decryptAndGetSessionVariable("ccUsersDealsZenGroupSessionStorage");
        if (groupsForCCUsersOnDealsPageStorage) {
            //combine the two lists, cc groups list and regular groups list into set for values for this col
            try{
                let groupsForCCUsersOnDealsPage = JSON.parse(groupsForCCUsersOnDealsPageStorage);
                let zenGroup = groupsForCCUsersOnDealsPage.find(zenGroupObject => zenGroupObject.id === groupId)
                if(zenGroup){
                    return zenGroup.organizationName ? zenGroup.organizationName : zenGroup.friendlyName
                }
            } catch (error){}
        }
    }
    return null
}