import axios from "../../utils/axiosInstance";

export const getSilentIncidentsReactive = async () => {
    const response = await axios.post("/getSilentIncidentsReactive", {
    });
    return response.data;
};

export const findBySilentIncidentIdListReactive = async (ids) => {
    const response = await axios.post("/findBySilentIncidentIdListReactive",{
        ids
    })
    return response.data
}

export const changeSilentIncidentNameReactive = async (silentIncidentId, name) =>{
    return await axios.post("/changeSilentIncidentNameReactive", {
        silentIncidentId :silentIncidentId,
        newName : name,
    })
}
export const userCreateSilentIncidentReactive = async (path, zenGroupId, programArguments, userSetFriendlyName) => {
    const response = await axios.post("/userCreateSilentIncidentReactive", {
        path: path,
        zenGroupId: zenGroupId,
        programArguments: programArguments,
        userSetFriendlyName: userSetFriendlyName
    })
    return response.data;
}
export const deleteSilentIncidentReactive = async (silentIncidentId) => {
    return await axios.post("/deleteSilentIncidentReactive", {
        silentIncidentId: silentIncidentId
    });
};
export const getSilentIncidentsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getSilentIncidentsGridFilterStateReactive", {});
    return response.data;
};

export const updateSilentIncidentsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateSilentIncidentsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateSilentIncidentsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateSilentIncidentsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};
export const disableSilentIncidentReactive = async (silentIncidentId) => {
    return await axios.post("/disableSilentIncidentReactive", {
        silentIncidentId: silentIncidentId
    });
};
export const enableSilentIncidentReactive = async (silentIncidentId) => {
    return await axios.post("/enableSilentIncidentReactive", {
        silentIncidentId: silentIncidentId
    });
};

export const getSilentIncidentsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getSilentIncidentsGridColumnStateReactive", {});
    return response.data;
};
export const updateSilentIncidentsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateSilentIncidentsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateSilentIncidentsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateSilentIncidentsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};