import React, {useEffect, useState} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {getShortestSessionExpirationReactive} from "../pages/api/sessionExpirationApi";
import {Auth} from "aws-amplify";
import {handleLogout} from "../utils/axiosInstance";

let hourMillis = 3600000 //one hour in milliseconds
//let hourMillis = 5000 //testing time

export default function SessionTimer({loggedUser}) {
    const [timeOut, setTimeout] = useState();

    useEffect(() => {
        if(loggedUser){
            //if user is logged in, make api call to get the session duration
            getShortestSessionExpirationReactive().then(response => {
                if(response && response.duration !== null){
                    let duration = response.duration //in seconds
                    //convert seconds to milliseconds
                    let durationMillis = duration * 1000
                    setTimeout(durationMillis)
                }
                else{
                    //else use the default time of one hour for idle timeout to log users out
                    setTimeout(hourMillis)
                }
            }).catch(function (error) {
                //On error, use the default time of one hour for idle timeout to log users out
                setTimeout(hourMillis)
            })
        }
    }, [loggedUser]) //the code runs when loggedUser is changed in header.js

    useEffect(() => {
        //when the timeOut is set after the api call above (on error or success), reset the idle timer
        handleOnActive()
    }, [timeOut])

    const handleOnIdle = event => {
        //console.log('user is idle', event)
        //console.log('last active', new Date(getLastActiveTime()))
        if(loggedUser){
            //log them out
            Auth.signOut().then(response => {
                handleLogout()
            }).catch(function (error) {
                handleLogout()
            })
        }
        else{
            //console.log("user is idle but not logged in")
        }
    }

    const handleOnActive = event => {
        /*console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
        console.log('user is active')*/
    }

    const handleOnAction = event => {
        //console.log('user did something')
    }

    const { getRemainingTime, getLastActiveTime, start } = useIdleTimer({
        timeout: timeOut,
        startManually: true,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 250
    })

    return (
        <div/>
    )
}