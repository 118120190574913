import {getZenGroupFriendlyNamesReactive} from "../pages/api/agentsApi";
import {getAgentFriendlyNamesReactive} from "../pages/api/licensesApi";
import {getUsersByIdsReactive} from "../pages/api/whitelistsApi";

var _ = require('lodash');

//not used anymore, refreshGridZenGroupOnlyWithSetDataValue should be used instead
/*export async function refreshGridZenGroupOnly(gridApi, objectIdFieldName, newObjectIdName, findObjectsByIdFunc, otherFieldsToRefresh) {
    let gridIdsList = [];
    let zenGroupIdSet = new Set();
    gridApi.forEachNode(rowNode => {
        gridIdsList.push(rowNode.data[objectIdFieldName]);
        zenGroupIdSet.add(rowNode.data.zenGroupId);
    })
    if(gridIdsList.length > 0){
        let idsList = await findObjectsByIdFunc(gridIdsList)
        if(idsList.length > 0){
            let zenGroupNameList = await getZenGroupFriendlyNames(Array.from(zenGroupIdSet))
            gridApi.forEachNode(rowNode => {
                let currentId = rowNode.data[objectIdFieldName]
                let objectExists = false
                idsList.forEach(newObject => {
                    if(rowNode.data[objectIdFieldName] === newObject[newObjectIdName]){
                        objectExists = true
                        if(!_.isEqual(rowNode.data, newObject)){
                            let updated = false;
                            let updatedRow = rowNode.data
                            for (const [originalKey, originalValue] of Object.entries(rowNode.data)){
                                if(originalKey === "zenGroupDisplayName"){
                                    let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === rowNode.data["zenGroupId"]);
                                    if(zenGroupNameObject){
                                        if(zenGroupNameObject["name"] && zenGroupNameObject["name"] !== originalValue){
                                            updatedRow.zenGroupDisplayName = zenGroupNameObject["name"]
                                            updated = true
                                        }
                                    }
                                }
                                else if(otherFieldsToRefresh && otherFieldsToRefresh.includes(originalKey)){
                                    if (originalValue !== newObject[originalKey]) {
                                        updatedRow[originalKey] = newObject[originalKey];
                                        updated = true
                                    }
                                }
                                else{

                                }
                            }
                            if(updated){
                                rowNode.setData(updatedRow);
                            }
                        }
                    }
                })
                if(!objectExists){
                    //console.log(currentId)
                }
            })
        }
    }
}*/

export async function getZenGroupNameOnlyForInitialRender(objects, gridApi, params) {
    //NEW FUNCTIONALITY THAT SHOULD BE MADE ASYNC
    let zenGroupIdSet = new Set();
    objects.forEach(object => {
        zenGroupIdSet.add(object.zenGroupId);
    })
    let zenGroupNameList = await getZenGroupFriendlyNamesReactive(Array.from(zenGroupIdSet))
    objects.forEach(object => {
        //dynamic zenGroup Friendly Name Population
        let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === object.zenGroupId);
        if (zenGroupNameObject) {
            if (zenGroupNameObject["name"] && zenGroupNameObject["name"] !== object.zenGroupDisplayName) {
                object.zenGroupDisplayName = zenGroupNameObject["name"];
            }
        }else{
            if(zenGroupNameObject["name"]){
                object.zenGroupDisplayName = zenGroupNameObject["name"]
            }
        }
    });
    //END OF CODE THAT NEEDS TO BE MADE ASYNC
    gridApi.refreshCells()
    // var allColumnIds = [];
    // params.columnApi.getAllColumns().forEach(function (column) {
    //     allColumnIds.push(column.colId);
    // });
    // params.columnApi.autoSizeColumns(allColumnIds);
}

export async function getZenGroupNameAndUsernameOnlyForInitialRender(objects, gridApi, params, userIdFieldName, usernameFieldName) {
    let zenGroupIdSet = new Set();
    let userIdList = []
    objects.forEach(object => {
        zenGroupIdSet.add(object.zenGroupId);
        if(object[userIdFieldName]){
            userIdList.push(object[userIdFieldName])
        }
    })
    let zenGroupNameList = await getZenGroupFriendlyNamesReactive(Array.from(zenGroupIdSet))
    let userNameList = await getUsersByIdsReactive(userIdList)
    objects.forEach(object => {
        let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === object.zenGroupId);
        if (zenGroupNameObject) {
            if (zenGroupNameObject["name"] && zenGroupNameObject["name"] !== object.zenGroupDisplayName) {
                object.zenGroupDisplayName = zenGroupNameObject["name"];
            }
        }else{
            if(zenGroupNameObject["name"]){
                object.zenGroupDisplayName = zenGroupNameObject["name"]
            }
        }

        let userNameObject = userNameList.find(userNameIterator => userNameIterator.userId === object[userIdFieldName]);
        if(userNameObject){
            if(userNameObject["username"]){
                object[usernameFieldName] = userNameObject["username"]
            }
        }
    });
    gridApi.refreshCells()
}

export async function getZenGroupNameAndAgentInformationOnlyForInitialRender(objects, gridApi, params, agentIdFieldName, agentMachineNameField) {
    let zenGroupIdSet = new Set();
    let agentIdList = [];
    objects.forEach(object => {
        zenGroupIdSet.add(object.zenGroupId);
        if (object[agentIdFieldName]) {
            agentIdList.push(object[agentIdFieldName]);
        }
    })
        let agentNameList = await getAgentFriendlyNamesReactive(agentIdList);
        let zenGroupNameList = await getZenGroupFriendlyNamesReactive(Array.from(zenGroupIdSet))
        objects.forEach(object => {
            let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === object.zenGroupId);
            if (zenGroupNameObject) {
                if (zenGroupNameObject["name"] && zenGroupNameObject["name"] !== object.zenGroupDisplayName) {
                    object.zenGroupDisplayName = zenGroupNameObject["name"];
                }
            } else {
                if (zenGroupNameObject["name"]) {
                    object.zenGroupDisplayName = zenGroupNameObject["name"]
                }
            }

            //dynamic agent Friendly name
            let agentNameObject = agentNameList.find(agentNameObjectIterator => agentNameObjectIterator.agentId === object[agentIdFieldName]);
            if (agentNameObject) {
                if (agentMachineNameField) { //if we are showing the machineName on the grid
                    if (agentNameObject["machineName"]) {
                        object[agentMachineNameField] = agentNameObject["machineName"]
                    }
                }
                if (agentNameObject["name"]) {
                    object.agentDisplayName = agentNameObject["name"]
                }
            }
        });
gridApi.refreshCells()
}

export async function refreshGridZenGroupAndAgentInformation(gridApi, objectIdFieldName, newObjectIdName, findObjectsByIdFunc,
                                                             otherFieldsToRefresh, agentIdFieldName, agentMachineNameField,
                                                             incidentGridForceRefreshForSilentIcon=false) {
    let gridIdsList = [];
    let zenGroupIdSet = new Set();
    let agentIdList = [];
    gridApi.forEachNode(rowNode => {
        gridIdsList.push(rowNode.data[objectIdFieldName]);
        zenGroupIdSet.add(rowNode.data.zenGroupId);
        if(rowNode.data[agentIdFieldName]){
            agentIdList.push(rowNode.data[agentIdFieldName]);
        }
    })
    if(gridIdsList.length > 0){
        let idsList = await findObjectsByIdFunc(gridIdsList)
        if(idsList.length > 0){
            let zenGroupFilter = gridApi.getFilterInstance('zenGroupDisplayName');
            let agentNameList = await getAgentFriendlyNamesReactive(agentIdList);
            await zenGroupFilter.refreshFilterValues();
            let zenGroupNameList = await getZenGroupFriendlyNamesReactive(Array.from(zenGroupIdSet))
            gridApi.forEachNode(rowNode => {
                //let currentId = rowNode.data[objectIdFieldName]
                let objectExists = false
                idsList.forEach(newObject => {
                    if(rowNode.data[objectIdFieldName] === newObject[newObjectIdName]){
                        objectExists = true
                        if(!_.isEqual(rowNode.data, newObject)){
                            //let updated = false;
                            let updatedRow = rowNode.data
                            for (const [originalKey, originalValue] of Object.entries(rowNode.data)){
                                if(originalKey === "zenGroupDisplayName"){
                                    let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === rowNode.data["zenGroupId"]);
                                    if(zenGroupNameObject){
                                        if(zenGroupNameObject["name"] && zenGroupNameObject["name"] !== originalValue){
                                            rowNode.setDataValue("zenGroupDisplayName",zenGroupNameObject["name"]);
                                        }
                                    }
                                }
                                else if(originalKey === "agentDisplayName"){
                                    //dynamic agent Friendly name
                                    let agentNameObject = agentNameList.find(agentNameObjectIterator => agentNameObjectIterator.agentId === updatedRow[agentIdFieldName]);
                                    if (agentNameObject) {
                                        if (agentMachineNameField) { //if we are showing the machineName on the grid
                                            if (agentNameObject["machineName"]) {
                                                if(updatedRow[agentMachineNameField] !== agentNameObject["machineName"]) {
                                                    rowNode.setDataValue(agentMachineNameField, agentNameObject["machineName"]);
                                                }

                                            }
                                        }
                                        if (agentNameObject["name"]) {
                                            if(originalValue !== agentNameObject["name"]) {
                                                rowNode.setDataValue("agentDisplayName", agentNameObject["name"]);
                                            }
                                        }
                                    }

                                }
                                else if(otherFieldsToRefresh && otherFieldsToRefresh.includes(originalKey)){
                                    if (originalValue !== newObject[originalKey]) {
                                        if(incidentGridForceRefreshForSilentIcon){
                                            if(originalKey === "silent"){
                                                //force refresh with new silent boolean setting for incident so that the incident name icon is triggered to change
                                                rowNode.setDataValue(originalKey,newObject[originalKey]);
                                                gridApi.refreshCells({rowNodes: [rowNode], force: true, columns: ["incidentDisplayName"], suppressFlash: false})
                                            }
                                            else{
                                                rowNode.setDataValue(originalKey,newObject[originalKey]);
                                            }
                                        }
                                        else{
                                            rowNode.setDataValue(originalKey,newObject[originalKey]);
                                        }
                                    }
                                }
                                else{

                                }
                            }
                            //if(updated){
                               //rowNode.setData(updatedRow);
                            //}
                        }
                    }
                })
                if(!objectExists){
                    //console.log(currentId)
                }
            })
        }
    }
}

export async function refreshGridZenGroupOnlyWithSetDataValue(gridApi, objectIdFieldName, newObjectIdName, findObjectsByIdFunc, otherFieldsToRefresh){
    let gridIdsList = [];
    gridApi.forEachNode(rowNode => {
        gridIdsList.push(rowNode.data[objectIdFieldName]);
    })
    if(gridIdsList.length > 0){
        let idsList = await findObjectsByIdFunc(gridIdsList)
        if(idsList.length > 0){
            //let zenGroupNameList = await getZenGroupFriendlyNames(Array.from(zenGroupIdSet))
            let zenGroupFilter = gridApi.getFilterInstance('zenGroupDisplayName');
            //refresh zenGroupDisplayName filter values instead of the getZenGroupFriendlyNames() since group names may change, and the column uses the filter values for the group names in the cell
            await zenGroupFilter.refreshFilterValues();
            gridApi.forEachNode(rowNode => {
                //let currentId = rowNode.data[objectIdFieldName]
                let objectExists = false
                idsList.forEach(newObject => {
                    if(rowNode.data[objectIdFieldName] === newObject[newObjectIdName]){
                        objectExists = true
                        if(!_.isEqual(rowNode.data, newObject)){
                            for (const [originalKey, originalValue] of Object.entries(rowNode.data)){
                                if(originalKey === "zenGroupDisplayName"){
                                    let index = zenGroupFilter.valueModel.allValues.findIndex(element => element.includes(rowNode.data.zenGroupId))
                                    if(index !== -1){
                                        let group = JSON.parse(zenGroupFilter.valueModel.allValues[index]).friendlyName
                                        if(group && group !== originalValue){
                                            rowNode.setDataValue("zenGroupDisplayName",group);
                                        }
                                    }else{
                                        //console.log("index not found")
                                    }
                                }
                                else if(otherFieldsToRefresh && otherFieldsToRefresh.includes(originalKey)){
                                    if (originalValue !== newObject[originalKey]) {
                                        rowNode.setDataValue(originalKey, newObject[originalKey]);

                                    }
                                }
                            }
                            // if(updated){
                            //     rowNode.setData(updatedRow);
                            // }
                        }
                    }
                })
                if(!objectExists){
                    //console.log(currentId)
                }
            })
        }
    }
}


