import Modal from "react-modal";
import React from "react";
import {roundButtonTheme} from "../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";

Modal.setAppElement('#root')

export const InformationModal = ({ opened, onClose, text, contentLabel }) => (
    <Modal contentLabel={contentLabel} isOpen={opened} onRequestClose={onClose} shouldCloseOnOverlayClick={false}
           className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-lg inset-y-10 mx-auto rounded-2xl overflow-auto`}
           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
    >
        <div className="flex flex-col p-5 pl-8 pr-8">
            <div className="flex-1 mb-6 text-xl">
                <small>{text}</small>
            </div>
            <div className="flex flex-row flex-wrap">
                <ThemeProvider theme={roundButtonTheme}>
                    <Button variant={"contained"} color={"primary"} onClick={onClose}>
                        Ok
                    </Button>
                </ThemeProvider>
            </div>
        </div>
    </Modal>
);
