import axios from "../../utils/axiosInstance";

export const createCheckoutSessionReactive = async () => {
    const response = await axios.post("/public/createCheckoutSessionReactive", {});
    return response.data;
};

export const retrieveCheckoutSessionCustomerReactive = async (sessionId) => {
    const response = await axios.post("/public/retrieveCheckoutSessionCustomerReactive", {
        sessionId: sessionId
    });
    return response.data;
}
export const partnerCreateCheckoutSessionReactive = async (zenGroup, desktopCount, serverCount) => {
    const response = await axios.post("/partnerCreateCheckoutSessionReactive", {
        zenGroupId: zenGroup,
        desktopLicenseCount: desktopCount,
        serverLicenseCount: serverCount
    });
    return response.data;
};
export const userLoggedInCreateCheckoutSessionReactive = async (zenGroup, desktopToggled, serverToggled) => {
    const response = await axios.post("/userLoggedInCreateCheckoutSessionReactive", {
        zenGroupId: zenGroup,
        purchasingDesktopLicense: desktopToggled,
        purchasingServerLicense: serverToggled
    });
    return response.data;
};
export const partnerCompleteDealAndCreateCheckoutSessionReactive = async (partnerDealId, zenGroup, desktopCount, serverCount) => {
    const response = await axios.post("/partnerCompleteDealAndCreateCheckoutSessionReactive", {
        partnerDealId: partnerDealId,
        zenGroupIdToAssignLicensesTo: zenGroup,
        desktopLicenseCount: desktopCount,
        serverLicenseCount: serverCount
    });
    return response.data;
};
