import {
    createCheckoutSessionReactive,
    partnerCreateCheckoutSessionReactive,
    userLoggedInCreateCheckoutSessionReactive
} from "../pages/api/stripeApi";
import NotificationManager from "react-notifications/lib/NotificationManager";

export async function redirectToStripeCheckout(){
    var pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    try{
        let session = await createCheckoutSessionReactive()
        const stripe = window.Stripe(pk)
        stripe.redirectToCheckout({sessionId: session.sessionId})
    }
    catch(error){
        console.log(error)
    }
}
export async function returnSessionId(zenGroup, desktopLicenses, serverLicenses){
    try{
        let session = await partnerCreateCheckoutSessionReactive(zenGroup, desktopLicenses, serverLicenses)
        return session.sessionId
    }
    catch(error){
        if(error.message){
            NotificationManager.error(error.message);
        }
        else{
            NotificationManager.error("Error continuing to payment");
        }
        return null
    }
}
export async function redirectToStripeUserLoggedIn(zenGroup, desktopToggled, serverToggled){
    var pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    let session = null;
    try{
        session = await userLoggedInCreateCheckoutSessionReactive(zenGroup, desktopToggled, serverToggled)
    }
    catch(error){
        if(error.message){
            NotificationManager.error(error.message);
        }
        else{
            NotificationManager.error("Error continuing to payment");
        }
        return
    }
    try{
        const stripe = window.Stripe(pk)
        stripe.redirectToCheckout({sessionId: session.sessionId})
    }
    catch(error){
        console.log(error)
    }
}
