import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./encryptDecryptHelper";

let dataMemory = {};

export class AmplifyAuthStorage {
    static syncPromise = null;

    static setItem(key, value) {
        encryptAndStoreSessionVariable(key, value);
        dataMemory[key] = value;
        return dataMemory[key];
    }

    static getItem(key) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key)
            ? dataMemory[key]
            : undefined;
    }

    static removeItem(key) {
        sessionStorage.removeItem(key);
        return delete dataMemory[key];
    }

    static clear() {
        dataMemory = {};
        return dataMemory;
    }

    static sync() {
        if (!AmplifyAuthStorage.syncPromise) {
            AmplifyAuthStorage.syncPromise = new Promise((res, rej) => {
                for (let i = 0; i < sessionStorage.length; i++){
                    let key = sessionStorage.key(i)
                    dataMemory[key] = decryptAndGetSessionVariable(key);
                }
                res();
            });
        }
        return AmplifyAuthStorage.syncPromise;
    }
}