import React, {Component, useEffect, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {MultiFilterModule} from "@ag-grid-enterprise/multi-filter";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    browserDataIncidentsPerGroupCountReactive,
    findByBrowserDataIncidentIdListReactive,
    getBrowserDataIncidentsLazy,
    updateBrowserDataIncidentsGridColumnStateReactive,
    updateBrowserDataIncidentsGridFilterModelReactive,
    updateBrowserDataIncidentsGridUseColumnStateReactive,
    updateBrowserDataIncidentsGridUseFilterStateReactive
} from "../api/browserDataIncidentsApi";
import Modal from "react-modal";
import {ConfirmationModal} from "../../components/confirmationModal";
import {findBySingleWhitelistAppliedIdReactive} from "../api/whitelistsApi";
import SidebarMenu from "../../components/sideBarComponent";
import {useForm} from "react-hook-form";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {defaultZenGroupColumnInitWithOptions} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {browserDataIncidentsPageCellEditingStopped} from "../../utils/gridCellEditing";
import {AgChartsReact} from "ag-charts-react";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {Link} from "react-router-dom"
import {findZenGroupById, useZenGroupSessionStorage} from "../../utils/zenGroupSessionStorageManager";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    defaultIncidentNameColumnInitWithOptions,
    removePathFromStartOfProgramArgumentsHelper
} from "../../utils/incidentNameGridHelper";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {
    createSilentIncidentsFromIncidentsPageReactive,
    findSilentIncidentNamesForSingleIncidentIdReactive,
    findSuggestedPathsReactive,
    findSuggestedSilentIncidentPathsReactive,
    submitIncidentForReviewReactive,
    userAddWhitelistsFromIncidentsPageReactive,
    userSetIncidentAsNotSilentReactive
} from "../api/incidentsApi";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {buttonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltip, MuiIconWithTooltip} from "../../components/muiComponents";
import {ClickToShowButtonsExpandingLeft} from "../../components/clickToShowButtons";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

let keysToRefresh = ["currentLatestAgentVersion", "browserDataIncidentDisplayName", "silent"]
let gridColumnStateSessionVariableName = "browserDataIncidentsGridColumnState"
const processData = (data) => {
    let memString = ""
    const memVal = data.memory
    memString = memVal;
    if (memVal > 1000) {
        memString = (memVal / 1000).toFixed(2) + " KB"
    }
    if (memVal > 1000000) {
        memString = (memVal / 1000000).toFixed(2) + " MB"
    }
    if (memVal > 1000000000) {
        memString = (memVal / 1000000000).toFixed(2) + " GB"
    }
    if(data.lastResponseTimestamp){
        data.lastResponseTimestamp = new Date(data.lastResponseTimestamp).toUTCString()
    }

    let programArgumentsString = ""

    if(data) {
        programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
    }

    data.programArgumentsEditedString = programArgumentsString


    let gridData =
        {
        "Access Name": data.incidentDisplayName,
        "Machine Name": data.machineName,
        "Accessing Program Path": data.path,
        "Accessing Program Arguments": programArgumentsString,
        //"Agent Name": data.agentDisplayName,
        "Ram usage": memString,
        //"Access attempts": data.handles,
        //"Number of threads": data.threads,
        "Pid": data.pid,
        //not letting users respond to incidents anymore, so don't show in details grid
        /*"Last user to respond to incident": data.lastResponderName ? data.lastResponderName : "",
        "Last response date": data.lastResponseTimestamp ? data.lastResponseTimestamp : "",
        "Last response task": data.lastResponseTask ? data.lastResponseTask : "",
        "Last response status": data.lastResponse ? data.lastResponse : "",*/
    };
    return Object.keys(gridData).map((key) => ({
        description: key,
        value: gridData[key],
    }))
};

export default function BrowserDataIncidents() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [activeIncident, setActiveIncident] = useState();
    const [clickedIconIncidentData, setClickedIconIncidentData] = useState();
    const [showNoSuggestedPathsFoundIcon, setShowNoSuggestedPathsFoundIcon] = useState(false);
    const [showNoSuggestedSilentIncidentPathsFoundIcon, setShowNoSuggestedSilentIncidentPathsFoundIcon] = useState(false);
    const [openResponseHistoryModal, setOpenResponseHistoryModal] = useState();
    // eslint-disable-next-line no-unused-vars
    const [ignoreButtonDisabled, setIgnoreButtonDisabled] = useState(true);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    const [showIgnoreConfirmModal, setShowIgnoreConfirmModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const { handleSubmit, reset } = useForm();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [suggestedPaths, setSuggestedPaths] = useState([]);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [ignoreSubmitButtonDisabled, setIgnoreSubmitButtonDisabled] = useState(false);
    const [silentIncidentTurnOnModalButtonDisabled, setSilentIncidentTurnOnModalButtonDisabled] = useState(false);
    const [showSendReviewConfirmModal, setShowSendReviewConfirmModal] = useState(false);
    const [showCertificateModal, setShowCertificateModal] = useState(false);
    //const [chartData, setChartData] = useState([]);
    const [chartDataWithoutWhitelistedCount, setChartDataWithoutWhitelistedCount] = useState([]);
    const [chartDataWithWhitelistedCount, setChartDataWithWhitelistedCount] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [includeWhitelistedToggled, setIncludeWhitelistedToggled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [includeWhitelistedToggleDisabled, setIncludeWhitelistedToggleDisabled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [showTurnIncidentSilentModal, setShowTurnIncidentSilentModal] = useState(false);
    const [showTurnIncidentSilentConfirmModal, setShowTurnIncidentSilentConfirmModal] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("browserDataIncidentsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    //chart options for included and not including whitelisted incidents
    let chartWithoutWhitelistedCountOptions={
        autoSize: true,
        data: chartDataWithoutWhitelistedCount,
        title: {
            text: 'Identity Accesses Per Group',
            fontSize: 18,
        },
        subtitle: {
            text: 'Over the last 30 days',
            fontSize: 15,
        },
        series: [
            {
                type: 'column',
                xKey: 'zenGroupId',
                yKeys: [
                    'countWithoutWhitelisted',
                ],
                yNames: [
                    'Number of Accesses',
                ],
                fills: ['#e76a24'],
                strokes: ['#989898'],
                highlightStyle: {
                    item: {
                        fill: "#E8E8E8",
                        stroke: "#181818"
                    },
                },
                tooltip: { //for hovering over a column
                    renderer: function (params) {
                        let content = params.datum.zenGroupName + ": " + params.yValue
                        return {
                            title: params.yName,
                            content: content
                        };
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    rotation: 50,
                    formatter: function(params){
                        if(!params.axis.boundSeries[0].data[params.index].zenGroupName){
                            let group = findZenGroupById(params.value)
                            if(group && group.friendlyName){
                                //found group in session
                                params.axis.boundSeries[0].data[params.index].zenGroupName = group.friendlyName
                            }
                            else{
                                return "Group not found"
                            }
                        }
                        let countWithoutWhitelisted = params.axis.boundSeries[0].data[params.index].countWithoutWhitelisted
                        return params.axis.boundSeries[0].data[params.index].zenGroupName + " (" + countWithoutWhitelisted + ")"
                    },
                    fontSize: 11
                },
            },
            {
                type: 'number',
                position: 'left',
                //min: chartDataWithoutWhitelistedCount.length === 0 ? 0: undefined, //default min to 0 when chart data length is 0, else undefined leaves it to auto set the min according to the data given
                //max: chartDataWithoutWhitelistedCount.length === 0 ? 100: undefined, //default max to 100 when chart data length is 0, else undefined leaves it to auto set the max according to the data given
            },
        ],
        legend: {
            spacing: 40,
            position: 'top',
        },
    }
    let chartWithWhitelistedCountOptions={
        autoSize: true,
        data: chartDataWithWhitelistedCount,
        title: {
            text: 'Identity Accesses Per Group',
            fontSize: 18,
        },
        subtitle: {
            text: 'Over the last 30 days',
            fontSize: 15,
        },
        series: [
            {
                type: 'column',
                xKey: 'zenGroupId',
                yKeys: [
                    'countWithoutWhitelisted',
                    'whitelistedIncidentsCount'
                ],
                yNames: [
                    'Number of Accesses',
                    'Number of Whitelisted Accesses'
                ],
                fills: ['#e76a24','#4194a5'],
                strokes: ['#989898', '#989898'],
                highlightStyle: {
                    item: {
                        fill: "#E8E8E8",
                        stroke: "#181818"
                    },
                },
                tooltip: { //for hovering over a column
                    renderer: function (params) {
                        let content = params.datum.zenGroupName + ": " + params.yValue
                        return {
                            title: params.yName,
                            content: content
                        };
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    rotation: 50,
                    formatter: function(params){
                        if(!params.axis.boundSeries[0].data[params.index].zenGroupName){
                            let group = findZenGroupById(params.value)
                            if(group && group.friendlyName){
                                //found group in session
                                params.axis.boundSeries[0].data[params.index].zenGroupName = group.friendlyName
                            }
                            else{
                                return "Group not found"
                            }
                        }
                        let countWithoutWhitelisted = params.axis.boundSeries[0].data[params.index].countWithoutWhitelisted
                        let whitelistedIncidentsCount = params.axis.boundSeries[0].data[params.index].whitelistedIncidentsCount
                        let total = countWithoutWhitelisted + whitelistedIncidentsCount
                        return params.axis.boundSeries[0].data[params.index].zenGroupName + " (" + total + ")"

                    },
                    fontSize: 11
                },
            },
            {
                type: 'number',
                position: 'left',
                //min: chartDataWithWhitelistedCount.length === 0 ? 0: undefined, //default min to 0 when chart data length is 0, else undefined leaves it to auto set the min according to the data given
                //max: chartDataWithWhitelistedCount.length === 0 ? 100: undefined, //default max to 100 when chart data length is 0, else undefined leaves it to auto set the max according to the data given
            },
        ],
        legend: {
            spacing: 40,
            position: 'top',
        },
    }

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            async function populate() {
                setIsLoading(false);
            }
            await populate();
        })()
        return () => controller?.abort();
    }, []);

    //using the getGroups above with await since we need these values to populate group names in the chart
    /*useEffect(() => {
        getGroups().then(response => {
            //response is the list of zenGroups
            setZenGroups(response);
        }).catch(function (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error retrieving user's list of groups");
            }
        })
    }, []);*/

    useEffect(() => {
        setChartIsLoading(true)
        //the data returned from this call is for the initial chart and does not include a whitelisted incident count
        browserDataIncidentsPerGroupCountReactive(false).then(response => {
            setChartIsLoading(false)
            let incidentsPerGroupList = response.incidentsPerGroupList
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithoutWhitelistedCount(data)
            }
            else{
                setChartDataWithoutWhitelistedCount([])
            }
        }).catch(function (error) {
            setChartIsLoading(false)
        })
    }, [])

    useEffect(() => {
        //the data returned from this call is for when the user toggles to see incident count with whitelisted incidents included
        browserDataIncidentsPerGroupCountReactive(true).then(response => {
            let incidentsPerGroupList = response.incidentsPerGroupList
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithWhitelistedCount(data)
                setIncludeWhitelistedToggleDisabled(false)
            }
            else{
                setChartDataWithWhitelistedCount([])
            }
        }).catch(function (error) {
        })
    }, [])

    //commented out since multiple suggested paths can be returned not just one.
/*    useEffect(async () => {
        if (activeIncident && activeIncident.path && activeIncident.zenGroupId) {
            whitelistPathExists(activeIncident.path, activeIncident.zenGroupId, activeIncident.programArguments).then(result => {
                setIsLoading(false);
                if(!result.userPermitted){
                    setIgnoreButtonDisabled(true)
                }
                else{
                    //setIgnoreButtonDisabled(result.whitelistExists)
                    //just check for permission since things can get tricky with the new incident ignore modal for multiple similar paths
                    setIgnoreButtonDisabled(false)
                }
            }).catch(function (error) {
                setIsLoading(false);
                console.log(error)
                setIgnoreButtonDisabled(true) //if error, disable it
            })
        }
    }, [activeIncident]);*/

    const onIgnoreModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            setShowIgnoreConfirmModal(true)
        }
    };
    const onSilentIncidentCreateModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            setShowTurnIncidentSilentConfirmModal(true)
        }
    };

    const onWhitelistPath = async () => {
        try {
            if (clickedIconIncidentData && clickedIconIncidentData.zenGroupId && selectedPaths && selectedPaths.length > 0) {
                setIsLoading(true);
                let argumentsToSend = null;
                if(clickedIconIncidentData.programArgumentsEditedString){
                    argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
                }
                let response = await userAddWhitelistsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.zenGroupId, argumentsToSend)
                if(response.whitelistExistedAlreadyCount > 0){
                    if(selectedPaths.length === 1){
                        //user only tried to make 1 whitelist
                        NotificationManager.info(`This whitelist existed already and was not created again`);
                        setShowIgnoreConfirmModal(false)
                        setIsLoading(false);
                        return
                    }
                    else{
                        //user selected more than one path to make whitelists for
                        NotificationManager.info(`${response.whitelistExistedAlreadyCount} of the whitelists
                        existed already and 
                        ${response.whitelistExistedAlreadyCount > 1 ? "were" : "was"} not created`);
                        setShowIgnoreConfirmModal(false)
                        setIsLoading(false);
                        return
                    }
                }
                else{
                    NotificationManager.success("Whitelist(s) added successfully");
                }
                reset({})
                setShowIgnoreModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                setShowIgnoreConfirmModal(false)
                setIgnoreButtonDisabled(true)
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error adding whitelist(s)");
            }
        }
        setIsLoading(false);
        // setShowIgnoreModal(false)
        // setShowIgnoreConfirmModal(false)
    };

    const onConfirmCreateSilentIncidents =  () => {
        if(clickedIconIncidentData && clickedIconIncidentData.incidentId && selectedPaths && selectedPaths.length > 0){
            let argumentsToSend = null;
            if(clickedIconIncidentData.programArgumentsEditedString){
                argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
            }
            setIsLoading(true);
            createSilentIncidentsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.incidentId, argumentsToSend).then(response => {
                NotificationManager.success("Silent Response(s) successfully created/enabled, you may need to refresh the grid periodically to see updates for any affected accesses within the same group");
                setIsLoading(false)
                reset({})
                setClickedIconIncidentData()
                setShowTurnIncidentSilentModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                setShowTurnIncidentSilentConfirmModal(false)
                refreshGrid().then()
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    }

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <title>Identity Access</title>
                <script src="https://js.stripe.com/v3/"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Incident Details Modal*/}
            <Modal contentLabel="Access Details" isOpen={openResponseHistoryModal}
                   onRequestClose={() => {
                       setActiveIncident(null);
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setOpenResponseHistoryModal(false);
                       setClickedIconIncidentData()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-6xl max-w-6xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                {/*Cert Publisher Names Modal*/}
                <Modal contentLabel="Certificate Publisher Names" isOpen={showCertificateModal}
                       onRequestClose={() => {
                           setShowCertificateModal(false)
                       }} shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll">

                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Certificate Publisher Names</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setShowCertificateModal(false)
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        <div className="">
                            {clickedIconIncidentData && clickedIconIncidentData.certPublisherNames && clickedIconIncidentData.certPublisherNames.length > 0 && clickedIconIncidentData.certPublisherNames.map((certName, i) => {
                                return (
                                    <div key={i} className="mb-3 ml-3 flex flex-row">
                                        {`${certName}`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal>
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <h1 className="font-bold text-3xl">Access Details</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setActiveIncident(null);
                                setSuggestedPaths([])
                                setSelectedPaths([])
                                setOpenResponseHistoryModal(false);
                                setClickedIconIncidentData()
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8"/>
                    <div className="flex flex-1 flex-col p-8 w-full">
                        <DetailsGrid
                            headers={[
                                {field: "description", name: "Description", width: 65},
                                {field: "value", name: "Value"}
                            ]}
                            data={
                                clickedIconIncidentData
                                    ? processData(clickedIconIncidentData)
                                    : []
                            }
                        />
                    </div>
                    <div className="mx-8 mb-5">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    onClick={() => {
                                        setShowCertificateModal(true)
                                    }}
                                    disabled={!clickedIconIncidentData || !clickedIconIncidentData.certPublisherNames || !clickedIconIncidentData.certPublisherNames.length > 0}
                            >
                            View Certificate Publisher Names
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            {/*Ignore Modal*/}
            <Modal contentLabel="Ignore Access"
                   isOpen={showIgnoreModal}
                   onRequestClose={() => {
                       reset({})
                       setShowIgnoreModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                {/*Confirm Ignore Incident Modal*/}
                <ConfirmationModal
                    text="You are about to ignore this access in the future, would you like to continue?"
                    onConfirm={() => onWhitelistPath()}
                    onClose={() => {
                        setShowIgnoreConfirmModal(false)
                    }}
                    opened={showIgnoreConfirmModal}
                />
                {/*Confirm Ignore Incident Modal*/}
                <ConfirmationModal
                    text="Are you sure you want to submit this access for review?"
                    onConfirm={async () => {
                        if (clickedIconIncidentData && clickedIconIncidentData.incidentId) {
                            try {
                                setIsLoading(true)
                                await submitIncidentForReviewReactive(clickedIconIncidentData.incidentId)
                                NotificationManager.success("Successfully submitted this access for review")
                                reset({})
                                setShowIgnoreModal(false)
                                setSuggestedPaths([])
                                setSelectedPaths([])
                                setShowIgnoreConfirmModal(false)
                                setIgnoreButtonDisabled(true)
                                setShowSendReviewConfirmModal(false)
                                setClickedIconIncidentData()
                            } catch (error) {
                                if (error.message) {
                                    NotificationManager.error(error.message);
                                } else {
                                    NotificationManager.error("Error submitting this access for review")
                                }
                            }
                            setIsLoading(false)
                        }
                    }}
                    onClose={() => {
                        setShowSendReviewConfirmModal(false)
                    }}
                    opened={showSendReviewConfirmModal}
                />
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4"
                      onSubmit={handleSubmit(onIgnoreModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Whitelist</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({})
                                    setShowIgnoreModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <div><label>Access
                                Name:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.incidentDisplayName) ? clickedIconIncidentData.incidentDisplayName : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Executable
                                Path:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.path) ? clickedIconIncidentData.path : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Program
                                Arguments:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString) ? clickedIconIncidentData.programArgumentsEditedString : ""}`}
                            </div>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                            <label>Suggested executable paths. All selected paths will be whitelisted:</label>
                                {showNoSuggestedPathsFoundIcon &&
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={"No suggested executable paths were found, you may submit this access for review if desired"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if (selectedPathsArray.includes(path)) {
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                } else {
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3 mb-5">
                            <label>Not seeing what you need?</label>
                            <ThemeProvider theme = {roundButtonTheme}>
                                <Button variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            setShowSendReviewConfirmModal(true)
                                        }}
                                        type="button">
                                    Submit Access for Review
                                </Button>
                            </ThemeProvider>
                        </div>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={ignoreSubmitButtonDisabled}
                            >
                            Submit
                            </Button>
                        </ThemeProvider>
                    </div>

                </form>
            </Modal>
            {/*Turn Incident to Silent Modal*/}
            <Modal contentLabel="Silent Response"
                   isOpen={showTurnIncidentSilentModal}
                   onRequestClose={() => {
                       reset({})
                       setShowTurnIncidentSilentModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                {/*Confirm Silence Incident Modal*/}
                <ConfirmationModal
                    text="You are about to create/enable a silent response for each selected path and access program arguments (if present), would you like to continue?"
                    onConfirm={() => {
                        onConfirmCreateSilentIncidents()
                    }}
                    onClose={() => {
                        setShowTurnIncidentSilentConfirmModal(false)
                    }}
                    opened={showTurnIncidentSilentConfirmModal}
                />
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4"
                      onSubmit={handleSubmit(onSilentIncidentCreateModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Silence Access</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({})
                                    setShowTurnIncidentSilentModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <div><label>Access
                                Name:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.incidentDisplayName) ? clickedIconIncidentData.incidentDisplayName : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Executable
                                Path:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.path) ? clickedIconIncidentData.path : ""}`}
                            </div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Original Program
                                Arguments:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString) ? clickedIconIncidentData.programArgumentsEditedString : ""}`}
                            </div>
                        </div>
                        <hr className="mt-3 h-0.5"/>
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                                <label>The output below shows all currently disabled silent response paths in this response's group. A silent response will be
                                    created/enabled for each selected path and response program arguments (if present). As a reminder, ".*" is a wildcard:
                                </label>
                                {showNoSuggestedSilentIncidentPathsFoundIcon &&
                                    <div className="mt-12">
                                        <MuiIconWithTooltip
                                            icon={
                                                <FontAwesomeIcon
                                                    className="ml-1 object-contain"
                                                    icon="fa-light fa-circle-info"
                                                    size="lg"
                                                />
                                            }
                                            tooltipTitle={`No suggested executable paths were found. You may click the "Take me to Silent Responses Page" button that will redirect you to the
                                        Silent Responses page. This will also automatically supply the create new Silent Response form with the response's group, path, and
                                        program arguments (if present).`}
                                            tooltipPlacement={"bottom-start"}
                                        />
                                    </div>
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if (selectedPathsArray.includes(path)) {
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                } else {
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3 mb-5">
                            <label>Not seeing what you need?</label>
                            <div className="mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"Clicking this button will redirect you to the Silent Responses page and automatically supply the create new Silent\n" +
                                        "                                                    Response form with the response's group, path, and program arguments (if present)."}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <Link to={{
                                pathname: "/private/silentResponses"
                            }} state={{
                                showCreateModal: true,
                                path: clickedIconIncidentData ? clickedIconIncidentData.path.trim() : null,
                                zenGroupId: clickedIconIncidentData ? clickedIconIncidentData.zenGroupId : null,
                                programArguments: clickedIconIncidentData ? clickedIconIncidentData.programArgumentsEditedString.trim() : null
                            }} className="mr-2">
                                <ThemeProvider theme = {roundButtonTheme}>
                                    <Button variant={"contained"}
                                            color={"secondary"}>
                                    Take me to Silent Responses Page
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>

                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    disabled={silentIncidentTurnOnModalButtonDisabled}
                            >
                            Submit
                            </Button>
                        </ThemeProvider>
                    </div>

                </form>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden"/>
                <div className="flex flex-1 flex-col">
                    <div className="flex flex-col ml-10 mr-10 mt-8 flex-nowrap gap-y-3">
                        {privatePageHeaderHelper("Identity Access")}
                        <hr className=" bg-black h-0.5"/>
                        <div className="flex flex-col gap-y-3">
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme = {switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={chartToggled}
                                            name="incidentsPerGroupToggle"
                                            onChange={e => setChartToggled(e.target.checked)}
                                        />
                                    } label ={chartToggled ? "Showing Accesses Per Group Chart" : "Hiding Accesses Per Group Chart"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`${chartToggled ? 'block' : 'hidden'} flex flex-row items-center`}>
                                {/*      <Toggle
                                        disabled={includeWhitelistedToggleDisabled}
                                        checked={includeWhitelistedToggled}
                                        name="includeWhitelistedIncidentsToggle"
                                        onToggle={e => setIncludeWhitelistedToggled(e.target.checked)}
                                    />*/}
                                {/*                  <label>
                                        {includeWhitelistedToggled ? "Include Whitelisted Incidents Count" : "Hide Whitelisted Incidents Count"}
                                    </label>*/}
                            </div>
                        </div>
                        {/*Incidents Chart*/}
                        <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                            <AgChartsReact options={
                                !includeWhitelistedToggled ? chartWithoutWhitelistedCountOptions : chartWithWhitelistedCountOptions
                            }/>
                            <BackDropChartLoadingOverlay opened={chartIsLoading}/>
                        </div>
                        <hr className="bg-black h-0.5"/>
                        <div className="flex flex-row justify-between gap-x-2 gap-y-2">
                            <div className={"self-end flex flex-col gap-y-3"}>
                                <GridColumnFilterStateSaving
                                    useFilterStateSettingToggled={useFilterStateSettingToggled}
                                    setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                    toggleUpdateUseFilterState={toggleUpdateUseFilterState}
                                    useColumnStateSettingToggled={useColumnStateSettingToggled}
                                    setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                    toggleUpdateUseColumnState={toggleUpdateUseColumnState}/>
                            </div>

                            {/*<div className="flex flex-row flex-wrap gap-x-3 gap-y-4">
                                    <button disabled={!activeIncident}
                                            className={`focus:outline-none p-3 pl-5 pr-5 rounded-md ml-10 mt-4 text-white
                                ${(!activeIncident) ? "notActiveButton" : "buttons"}`}
                                            onClick={() => {
                                                setOpenResponseHistoryModal(true)
                                            }}
                                    >
                                        Show Details
                                    </button>
                                    <button disabled={!activeIncident || !activeIncident.certPublisherNames || !activeIncident.certPublisherNames.length > 0}
                                            className={`focus:outline-none p-3 pl-5 pr-5 rounded-md ml-10 mr-10 mt-4 text-white
                                ${(!activeIncident || !activeIncident.certPublisherNames || !activeIncident.certPublisherNames.length > 0) ? "notActiveButton" : "buttons"}`}
                                            onClick={() => {
                                                setShowCertificateModal(true)
                                            }}
                                    >
                                        View Certificate Publisher Names
                                    </button>
                                </div>*/}
                            <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                                <ClickToShowButtonsExpandingLeft
                                    buttonsText={"Columns"}
                                    tooltipTitle={"Column States"}
                                    buttonsDiv={
                                        <ToggleButtonGroup
                                            value={"med"}
                                            exclusive
                                            size={"small"}
                                            onChange={(event, newAlignment) => {
                                                //setAlignment(newAlignment)
                                            }}
                                        >
                                            <ToggleButton value={"min"}>
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"med"}>
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"max"}>
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"custom"}>
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                                </Tooltip>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    }
                                />
                                <ClearRefresh gridApi={gridApi} gridColumnApi={gridColumnApi}
                                              refreshGridFunction={refreshGrid}/>
                            </div>

                        </div>
                        <div className="mb-4" id="gridRoot">
                            {getGrid()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <NotificationContainer/>
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'browserDataIncidentsGridFilterState', updateBrowserDataIncidentsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridUseColumnStateReactive);
    }

    function initializeWhitelistModalForIconClick(incidentData){
        setSelectedPaths([])
        setClickedIconIncidentData(incidentData)
        setIgnoreSubmitButtonDisabled(false)
        setShowNoSuggestedPathsFoundIcon(false)
        //load suggested paths and programArgs
        findSuggestedPathsReactive(incidentData?.path).then(response => {
            if(response.suggestedPaths && response.suggestedPaths.length > 0){
                setSuggestedPaths(response.suggestedPaths)
            }
            else{
                setSuggestedPaths([])
                setIgnoreSubmitButtonDisabled(true)
                setShowNoSuggestedPathsFoundIcon(true)
            }
        }).catch(function (error) {
            setSuggestedPaths([])
            setIgnoreSubmitButtonDisabled(true)
            setShowNoSuggestedPathsFoundIcon(true)
        })
        setShowIgnoreModal(true)
    }

    function initializeDetailsModalForIconClick(incidentData){
        setClickedIconIncidentData(incidentData)
        setOpenResponseHistoryModal(true)
    }

    function initializeTurnSilentSettingToOffForIconClick(incidentData){
        setClickedIconIncidentData(incidentData)
        if(incidentData && incidentData.incidentId){
            userSetIncidentAsNotSilentReactive(incidentData.incidentId).then(response => {
                NotificationManager.success("Successfully queued update, you may need to refresh the grid periodically to see updates for any affected accesses within the same group")
                setClickedIconIncidentData()
                refreshGrid().then()
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error completing this request.");
                }
            })
        }
    }

    function initializeTurnSilentSettingToOnForIconClick(incidentData){
        //mirroring this program arguments replacement, don't think we need to do this though
        let data = incidentData
        let programArgumentsString = ""
        if(data) {
            programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
        }

        data.programArgumentsEditedString = programArgumentsString
        setClickedIconIncidentData(data)
        setShowNoSuggestedSilentIncidentPathsFoundIcon(false)
        setSilentIncidentTurnOnModalButtonDisabled(false)
        setSelectedPaths([])
        findSuggestedSilentIncidentPathsReactive(incidentData.path, incidentData.incidentId).then(suggestedPaths => {
            if(suggestedPaths && suggestedPaths.length > 0){
                setSuggestedPaths(suggestedPaths)
            }
            else{
                setSuggestedPaths([])
                setSilentIncidentTurnOnModalButtonDisabled(true)
                setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
            }
        }).catch(function (error) {
            setSuggestedPaths([])
            setSilentIncidentTurnOnModalButtonDisabled(true)
            setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
        })
        setShowTurnIncidentSilentModal(true)
    }

    function getGrid(){
        return (
            <Grid
                onClickRow={(data) => {
                    setActiveIncident(data[0])
                }}
                headers={[
                    defaultZenGroupColumnInitWithOptions(true, true, true),
                    defaultIncidentNameColumnInitWithOptions(true, false, initializeWhitelistModalForIconClick,
                        initializeDetailsModalForIconClick, initializeTurnSilentSettingToOnForIconClick, initializeTurnSilentSettingToOffForIconClick, "Access"),
                    {
                        //moved to showing silent icon in incident name col, so hide this column but keep it defined so
                        // .setDataValue() for this silent field does not throw error if this silent value is updated in refresh function
                        field: "silent", name: "Silent", width : 150, sortable: true,
                        hide: true, suppressColumnsToolPanel: true,
                        filter: 'agSetColumnFilter',
                        filterParamsInHeader: {
                            buttons: ["reset", "apply", "cancel"],
                            valueFormatter: (params) => {
                                if(params.value!=="(Select All)"){
                                    if(params.value === "true"){
                                        return "On";
                                    }else{
                                        return "Off";
                                    }
                                }
                                else{
                                    return params.value;
                                }
                            },
                            values: ['true', 'false'],
                            suppressSorting: true,
                        },
                        valueFormatter: function(params){
                            if(params.value === true){
                                return "On"
                            }
                            else{
                                return "Off"
                            }
                        },
                        cellRenderer: function(params){
                            let silentIconDiv = ""
                            if(params.valueFormatted === "On" && params.node.data.whitelistAppliedId == null){
                                silentIconDiv =
                                    <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-duotone fa-volume-slash"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={`Click to change this setting to Off`}
                                        tooltipPlacement={"bottom-start"}
                                        onClick={(event) => {
                                            setClickedIconIncidentData(params.node.data)
                                            let incidentData = params.node.data
                                            if(incidentData && incidentData.incidentId){
                                                userSetIncidentAsNotSilentReactive(incidentData.incidentId).then(response => {
                                                    NotificationManager.success("Successfully queued update, you may need to refresh the grid periodically to see updates for any affected accesses within the same group")
                                                    setClickedIconIncidentData()
                                                    refreshGrid().then()
                                                }).catch(function (error) {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error completing this request.");
                                                    }
                                                })
                                            }
                                        }}
                                    />
                            }
                            else if(params.valueFormatted === "Off" && params.node.data.whitelistAppliedId == null){
                                silentIconDiv =
                                    <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-duotone fa-volume"
                                                size="lg"
                                            />
                                        }
                                        tooltipPlacement={"bottom-start"}
                                        tooltipTitle={"Click to change this setting to On"}
                                        onClick={(event) => {
                                            //mirroring this program arguments replacement, don't think we need to do this though
                                            let data = params.node.data
                                            let programArgumentsString = ""
                                            if(data) {
                                                programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
                                            }

                                            data.programArgumentsEditedString = programArgumentsString
                                            setClickedIconIncidentData(params.node.data)
                                            setShowNoSuggestedSilentIncidentPathsFoundIcon(false)
                                            setSilentIncidentTurnOnModalButtonDisabled(false)
                                            setSelectedPaths([])
                                            findSuggestedSilentIncidentPathsReactive(params.node.data.path, params.node.data.incidentId).then(suggestedPaths => {
                                                if(suggestedPaths && suggestedPaths.length > 0){
                                                    setSuggestedPaths(suggestedPaths)
                                                }
                                                else{
                                                    setSuggestedPaths([])
                                                    setSilentIncidentTurnOnModalButtonDisabled(true)
                                                    setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
                                                }
                                            }).catch(function (error) {
                                                setSuggestedPaths([])
                                                setSilentIncidentTurnOnModalButtonDisabled(true)
                                                setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
                                            })
                                            setShowTurnIncidentSilentModal(true)
                                        }}
                                    />
                            }
                            return(
                                <div className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                                    {silentIconDiv}
                                    {params.valueFormatted}
                                </div>
                            )
                        },
                    },
                    { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},
                    defaultAgentNameColumnInitWithOptions(true),
                    { field: "status", name: "Status", width: 275,
                        filter: 'agSetColumnFilter',
                        filterParamsInHeader: {
                            buttons: ["reset", "apply", "cancel"],
                            values: ['AUTOMATICALLY_BLOCKED', 'AUTOMATICALLY_HIDDEN', 'AUTOMATICALLY_ISOLATED', 'AUTOMATICALLY_KILLED', 'AUTOMATICALLY_SAFE_KILLED', 'AUTOMATICALLY_SAFE_SUSPENDED', 'AUTOMATICALLY_SUSPENDED',
                                'DISMISSED', 'ISOLATED', 'ISOLATE_STARTED', 'KILLED', 'KILL_STARTED','SAFE_KILLED', 'SAFE_KILL_STARTED', 'SAFE_SUSPENDED',
                                'SAFE_SUSPEND_STARTED', 'SUSPENDED', 'SUSPEND_STARTED', 'WOULD_SUSPEND', 'WOULD_BLOCK', 'WOULD_HIDE', 'WHITELISTED'],
                            suppressSorting: false,
                            showTooltips: true
                        },
                        sortable: true
                    },
                    /*{ field: "whitelistAppliedData", name: "Whitelist Applied", width: 250,
                    sortable: true,
                    tooltipField: "whitelistAppliedId",
                    tooltipComponent: 'customTooltip',
                    valueFormatter: function(params){
                        if(params.node.data.whitelistAppliedId){
                            //if row has 'whitelistAppliedId' field
                            let whitelistId = params.node.data.whitelistAppliedId
                            //we should show 'Incident Ignored' since it had the whitelistAppliedId, doesn't need to be in the callback below api call
                            params.node.setDataValue("whitelistAppliedData", "Incident Ignored");
                            findBySingleWhitelistAppliedId(whitelistId).then(function(response){
                                if(response.whitelistName !== null){
                                    params.node.setDataValue("whitelistName", response.whitelistName)
                                }
                                else{
                                    //spinning circle in whitelistName col will keep showing if whitelistName data is never set, so have to account for
                                    // if response.whitelistName is null
                                    params.node.setDataValue("whitelistName", " ")
                                }
                                if(response.whitelist !== null){
                                    params.node.data.tooltipData = response.whitelist
                                }
                            }).catch(function (error) {
                                //spinning circle in whitelistName col will keep showing if whitelistName data is never set, so have to account for
                                // if there is an error in the api call and whitelistName
                                params.node.setDataValue("whitelistName", " ")
                            })
                            
                            if (params.node.data.whitelistAppliedData){
                                return params.node.data.whitelistAppliedData
                            } 
                             return params.node.data.whitelistAppliedId
                            }

                    }, 

                    cellRenderer: function(params){
                        let spinnerDiv = ""
                      if(params.node.data.whitelistAppliedId === params.valueFormatted ){
                            spinnerDiv = <FontAwesomeIcon
                                className="contain fa-pulse"
                                icon="fa-light fa-spinner"
                                size="lg"
                                name="whiteListAppliedIdLoading"
                            />
                        }else{
                            spinnerDiv = ""
                        }
                            return(
                                <div id ="fortooltip" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                                    {spinnerDiv}
                                    {params.valueFormatted}
                                </div>
                            )
                    },
                 },
                {
                    field: "whitelistName", name: "Whitelist Name", width : 330, sortable: true,
                    valueFormatter: function(params){
                        if(params.node.data.whitelistAppliedId){
                            if(params.node.data.whitelistName){
                                return params.node.data.whitelistName
                            }
                            return params.node.data.whitelistAppliedId
                        }
                    },
                    cellRenderer: function(params){
                        let spinnerDiv = ""
                      if(params.node.data.whitelistAppliedId === params.valueFormatted ){
                            spinnerDiv = <FontAwesomeIcon
                                className="contain fa-pulse"
                                icon="fa-light fa-spinner"
                                size="lg"
                                name="whiteListNameLoading"
                            />
                        }else{
                            spinnerDiv = ""
                        }
                            return(
                                <div id ="fortooltip" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                                    {spinnerDiv}
                                    {params.valueFormatted}
                                </div>
                            )
                    },
               },*/
                    { field: "accessedFilePath", name: "Accessed File Path", width: 500,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true
                    },
                    { field: "path", name: "Accessing Program Path", width: 500,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true
                    },
                    { field: "programArguments", name: "Accessing Program Arguments", width: 700,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        valueFormatter:
                            function(params){
                                if(params.node.data) {
                                    return removePathFromStartOfProgramArgumentsHelper(params.node.data.path, params.node.data.programArguments)
                                }
                                else{
                                    return ""
                                }
                            },
                    sortable: true
                    },
                    { field: "created", name: "Created", width: 280,
                        filter: 'agDateColumnFilter',
                        filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
                        sortable: true,
                        valueFormatter: dateValueFormatter
                    },
                    { field: "hasUntrustedRemoteThreads", name: "Untrusted Remote Threads", width: 325,
                        /*filter: 'agSetColumnFilter',
                        filterParamsInHeader: {
                            cellRenderer: (params) => {
                                if(params.value!=="(Select All)"){
                                    switch(params.value) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }
                                else{
                                    return params.value;
                                }
                            },
                            values: ['FALSE', 'TRUE', 'UNKNOWN'],
                            suppressSorting: true,
                        },*/
                        valueFormatter:
                            function (params) {
                                if(params.node.data.hasUntrustedRemoteThreads){
                                    switch(params.node.data.hasUntrustedRemoteThreads) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }else{
                                    return ""
                                }
                            },
                        sortable: true
                    },
                    { field: "hasModifiedMem", name: "Modified Memory", width: 230,
                        /*filter: 'agSetColumnFilter',
                        filterParamsInHeader: {
                            cellRenderer: (params) => {
                                if(params.value!=="(Select All)"){
                                    switch(params.value) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }
                                else{
                                    return params.value;
                                }
                            },
                            values: ['FALSE', 'TRUE', 'UNKNOWN'],
                            suppressSorting: true,
                        },*/
                        valueFormatter:
                            function (params) {
                                if(params.node.data.hasModifiedMem){
                                    switch(params.node.data.hasModifiedMem) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }else{
                                    return ""
                                }
                            },
                        sortable: true
                    },
                    { field: "hasNoTrustedCert", name: "No Trusted Cert", width: 230,
                        /*filter: 'agSetColumnFilter',
                        filterParamsInHeader: {
                            cellRenderer: (params) => {
                                if(params.value!=="(Select All)"){
                                    switch(params.value) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }
                                else{
                                    return params.value;
                                }
                            },
                            values: ['FALSE', 'TRUE', 'UNKNOWN'],
                            suppressSorting: true,
                        },*/
                        valueFormatter:
                            function (params) {
                                if(params.node.data.hasNoTrustedCert){
                                    switch(params.node.data.hasNoTrustedCert) {
                                        case "UNKNOWN":
                                            return "Unknown"
                                        case "TRUE":
                                            return "True"
                                        case "FALSE":
                                            return "False"
                                        default:
                                            return "";
                                    }
                                }else{
                                    return ""
                                }
                            },
                        sortable: true
                    },
                    { field: "certPublisherNames", name: "Cert Publisher Names List", hide: true, sortable: true, width: 500, suppressColumnsToolPanel: true,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains'],
                            suppressAndOrCondition: true,
                        }
                        },
                    { field: "certTrustErr", name: "Certificate Status", width: 225,
                        /*filter: 'agNumberColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                            suppressAndOrCondition: true,
                        },*/
                        sortable: true
                    },
                    { field: "agentVersionFormatted", name: "Agent Version", width: 225,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true,
                        valueFormatter: agentVersionFormatter,
                        cellRenderer: function(params){
                            let spinnerDiv = ""
                          if(params.node.data.agentId === params.valueFormatted ){
                                spinnerDiv = <FontAwesomeIcon
                                    className="contain fa-pulse"
                                    icon="fa-light fa-spinner"
                                    size="lg"
                                    name="agentVersionLoading"
                                />
                            }else{
                                spinnerDiv = ""
                            }
                                return(
                                    <div id ="fortooltip" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                                        {spinnerDiv}
                                        {params.valueFormatted}
                                    </div>
                                )
                        },


                    },
                    { field: "currentLatestAgentVersion", name: "Latest Agent Version", width: 275,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true,
                    },
                    { field: "machineName", name: "Machine Name", width: 225,
                        filter: 'agTextColumnFilter',
                        filterParamsInHeader: {
                           suppressSorting: true,
                            buttons: ["reset", "apply"],

                            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                            suppressAndOrCondition: true,
                        },
                        cellRenderer:function(params){
                            const machineNameOnRender= params.node.data.machineName
                            let agentLinkDiv = ""
                            //machineNameForLocationLink is a made up field in the agentNameValueFormatters in agentNameGridHelper.js file.
                            //The incident machine name has the '/' in front of it, which the agent's machine names don't, so use the dummy variable 'machineNameForLocationLink'
                            // for the cross link to agents page
                            if(params.data.agentId && params.node.data.agentDisplayName && machineNameOnRender){
                                agentLinkDiv =
                                    <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                                        agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                                        <MuiIconButtonWithTooltip
                                            icon={
                                                <FontAwesomeIcon
                                                    className="object-contain"
                                                    icon="fa-duotone fa-user-gear"
                                                    size="xs"
                                                />
                                            }
                                            tooltipTitle={"Click to Manage This Agent"}
                                            tooltipPlacement={"bottom-start"}
                                        />
                                    </Link>
                            }
                            return(
                                <div className={"flex flex-nowrap items-center gap-x-2"}>
                                    {agentLinkDiv}
                                    {machineNameOnRender}
                                </div>
                            )
                        },
                       
                        sortable: true,
                        tooltipField:"One",
                        

                    },
                    { field: "collected", name: "Collected", width: 280,
                        filter: 'agDateColumnFilter',
                        filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
                        sortable: true,
                        valueFormatter: dateValueFormatter
                    },
                    {
                        //field that is set by us for getting either whitelist name or silent incident/response name(s)
                        field: "hidingRule", name: "Hiding Rule", width : 430, sortable: false,
                        valueFormatter: function(params){
                            if(params.node.data.hidingRule){
                                return params.node.data.hidingRule
                            }
                            if(params.node.data.whitelistAppliedId){
                                //call for whitelist name that is associated with whitelistAppliedId
                                let whitelistId = params.node.data.whitelistAppliedId
                                findBySingleWhitelistAppliedIdReactive(whitelistId).then(function(response){
                                    if(response.whitelistName !== null){
                                        params.node.setDataValue("hidingRule", response.whitelistName)
                                    }
                                    else{
                                        params.node.setDataValue("hidingRule", "(The whitelist applied at the time of access was deleted)")
                                    }
                                    /*if(response.whitelist !== null){
                                        params.node.data.tooltipData = response.whitelist
                                    }*/
                                }).catch(function (error) {
                                    params.node.setDataValue("hidingRule", " ")
                                })
                                params.node.data.hidingRule = whitelistId
                            }
                            else{
                                //else if incident was not whitelisted then check the silent field
                                if(params.node.data.silent){
                                    let incidentId = params.node.data.incidentId
                                    findSilentIncidentNamesForSingleIncidentIdReactive(incidentId).then(function(silentIncidentsList){
                                        let listString = " "
                                        let namesSet = new Set();
                                        if(silentIncidentsList && silentIncidentsList.length > 0){
                                            silentIncidentsList.forEach(silentIncident => {
                                                if(silentIncident.silentIncidentDisplayName){
                                                    namesSet.add(silentIncident.silentIncidentDisplayName)
                                                }
                                            })
                                            //convert set to array and join values together into one string to show in cell
                                            listString = Array.from(namesSet).join(' | ')
                                            //listString = Array.from(namesSet).join(', ')
                                        }
                                        params.node.setDataValue("hidingRule", listString)
                                    }).catch(function (error) {
                                        params.node.setDataValue("hidingRule", " ")
                                    })
                                    params.node.data.hidingRule = incidentId
                                }
                                else{
                                    //don't show anything for incidents that aren't silent, but need to set dataValue so the spinner will stop in the cell renderer
                                    params.node.setDataValue("hidingRule", " ")
                                }
                            }
                            return null
                        },
                        cellRenderer: function(params){
                            let spinnerDiv = ""
                            if(params.node.data.hidingRule === null || params.node.data.hidingRule === undefined || params.node.data.hidingRule === params.node.data.whitelistAppliedId ||
                                params.node.data.hidingRule === params.node.data.incidentId){
                                spinnerDiv =
                                    <div>
                                        <FontAwesomeIcon
                                            className="contain fa-pulse mr-1"
                                            icon="fa-light fa-spinner"
                                            size="lg"
                                            name="hidingRuleSpinner"
                                        />
                                        Loading
                                    </div>
                            }
                            return(
                                <div className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                                    {spinnerDiv}
                                    {params.node.data.hidingRule}
                                </div>
                            )
                        },
                    },
                    { field: "accessLength", name: "Access Length", width: 500,
                        filter: 'agNumberColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],
                            
                            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true
                    },
                    { field: "accessOffset", name: "Access Offset", width: 500,
                        filter: 'agNumberColumnFilter',
                        filterParamsInHeader: {
                            suppressSorting: true,
                            buttons: ["reset", "apply"],
                            
                            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                            suppressAndOrCondition: true,
                        },
                        sortable: true
                    }
                ]}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
            />
        );
    }

    async function refreshGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "incidentId", "id", findByBrowserDataIncidentIdListReactive,
            keysToRefresh, "agentId",null, true)
        gridApi && gridApi.refreshCells({columns: ["hidingRule"], suppressFlash: true, force: true})
    }
    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
        setOpenResponseHistoryModal(false)
        setActiveIncident(null);
        setSuggestedPaths([])
        setSelectedPaths([])
        setIgnoreButtonDisabled(true)
        setIsLoading(false);
        setShowIgnoreModal(false)
        setShowIgnoreConfirmModal(false)
    }
    */

}

class Grid extends Component {
    constructor(props, onClickRow, filterVals, headers) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        //params.api.sizeColumnsToFit();
    };
    onCellValueChanged = (event) => {
        //console.log("cell change old value: "+event.oldValue+" and new value "+event.newValue);
    }
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateBrowserDataIncidentsGridColumnStateReactive)
    }
    onGridReady = async (gridReadyParams) => {
        gridReadyParams.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        let useFilterState = getUseFilterStateInSession('browserDataIncidentsGridFilterState')
        if(useFilterState){
            onGridReadyHelper(gridReadyParams, "browserDataIncidentsGridFilterState");
        }
        //by default when users go to incidents page, we don't want to show whitelisted incidents or silent responses even if they have the apply saved filters toggled
        /*let shouldRunOnFilterChanged = false
        //shouldRunOnFilterChanged is protecting if for some reason that the statusFilter and silentFilter are both null, we don't want to trigger the incident list query
        // again if we don't have to by using the gridReadyParams.api.onFilterChanged()
        var statusFilterParent = gridReadyParams.api.getFilterInstance('status'); //multi filter, so we need to grab child instances
        if(statusFilterParent){
            //the indexes are in the same order as they are in the column def for status
            let statusFilter = statusFilterParent.getChildFilterInstance(0)
            if(statusFilter && statusFilter.valueModel && statusFilter.valueModel.selectedValues){
                let filteredStatusValues = statusFilter.valueModel.selectedValues
                let filterValuesToSupply = []
                //only remove WHITELISTED from the selected values, so if there is a saved filter present for status, the only thing that changes is removing the WHITELISTED status from the selected vals
                filteredStatusValues.forEach(value => {
                    if(value !== "WHITELISTED"){
                        filterValuesToSupply.push(value)
                    }
                })
                statusFilter.setModel({
                    type: 'set',
                    values: filterValuesToSupply
                });
                shouldRunOnFilterChanged = true
            }
            //the indexes are in the same order as they are in the column def for status
            let silentFilter = statusFilterParent.getChildFilterInstance(1)
            if(silentFilter){
                silentFilter.setModel({
                        type: 'set',
                        values: ["false"]
                    }
                )
                shouldRunOnFilterChanged = true
            }
        }*/
        //old way for default filters, commenting out for now
        /*if(statusFilter && statusFilter.valueModel && statusFilter.valueModel.selectedValues){
            let filteredStatusValues = statusFilter.valueModel.selectedValues
            let filterValuesToSupply = []
            //only remove WHITELISTED from the selected values, so if there is a saved filter present for status, the only thing that changes is removing the WHITELISTED status from the selected vals
            filteredStatusValues.forEach(value => {
                if(value !== "WHITELISTED"){
                    filterValuesToSupply.push(value)
                }
            })
            statusFilter.setModel({
                type: 'set',
                values: filterValuesToSupply
            });
            shouldRunOnFilterChanged = true
        }*/
        /*var silentFilter = gridReadyParams.api.getFilterInstance('silent');
        if(silentFilter){
            silentFilter.setModel({
                    type: 'set',
                    values: ["false"]
                }
            )
            shouldRunOnFilterChanged = true
        }*/
        /*if(shouldRunOnFilterChanged){
            //apply default filters for status and silent columns
            gridReadyParams.api.onFilterChanged()
        }*/
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);
        //check if we want to apply saved column state

        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        var dataSource = {
            getRows: async function (params) {
                try{
                    let start = params.request.startRow;
                    let end = params.request.endRow;
                    let count = end - start;
                    let page = Math.floor(start / count)
                    let filters = masterFilterHelper(params.request.filterModel)
                    let sortModel = params.request.sortModel;
                    let sortModelForRequest = null;
                    if(sortModel && sortModel.length > 0){
                        try{ //extra precaution for array access on the sort model so it does not break
                            if(sortModel[0].colId && sortModel[0].sort){
                                sortModelForRequest = sortModel[0]
                            }
                        }
                        catch(error){
                            console.log("Error generating sort request")
                        }
                    }
                    var data = await getBrowserDataIncidentsLazy(count, page, sortModelForRequest, filters);
                    let incidents = data.incidents
                    let finalPage = data.finalPage
                    let lastIdx = undefined
                    if (finalPage) {
                        lastIdx = (count * page) + incidents.length
                    }

                    params.api.deselectAll();

                    const success = (incidents.length !== 0);
                    if (success) {
                        params.api.hideOverlay()
                        params.successCallback(incidents, lastIdx);
                        // var allColumnIds = [];
                        // params.columnApi.getAllColumns().forEach(function (column) {
                        //     allColumnIds.push(column.colId);
                        // });
                        // params.columnApi.autoSizeColumns(allColumnIds);
                        //getZenGroupNameAndAgentInformationOnlyForInitialRender(incidents, gridReadyParams.api, gridReadyParams, "agentId", null)
                    } else {
                        params.api.showNoRowsOverlay();
                        params.successCallback(incidents, lastIdx); //to stop loading circle from spinning and make it disappear, nothing in incidents.
                        params.failCallback();
                    }
                }
                catch(error){
                    //console.log(error);
                    params.api.showNoRowsOverlay();
                    params.failCallback();
                }
            }
        }
        gridReadyParams.api.setServerSideDatasource(dataSource);
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="incidentGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MultiFilterModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        serverSideInfiniteScroll={true}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={browserDataIncidentsPageCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        tooltipShowDelay={0}
                        tooltipMouseTrack={true }
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'browserDataIncidentsGridFilterState', updateBrowserDataIncidentsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                    >
                        {this.props.headers.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate,
                                    cellRenderer, cellRendererSelector, cellEditorType, hide,
                                    sortable, minWidth, width, tooltipField, tooltipComponent, valueFormatter,
                                    suppressColumnsToolPanel, wrapText, cellEditorSelector, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight={false}
                                    wrapText={wrapText}
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    tooltipField={tooltipField}
                                    tooltipComponent={tooltipComponent}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    enableCellChangeFlash={true}
                                    cellEditorSelector={cellEditorSelector}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class DetailsGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit()
    }


    render() {
        return (
            <div className="mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule]}
                        suppressMultiSort={true}
                        domLayout="autoHeight"
                        headerHeight={50}
                        enableBrowserTooltips={true}
                        rowData={this.props.data}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        onFilterChanged={(params) => {
                            params.api.deselectAll();
                        }}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    >
                        {this.props.headers.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer, cellRendererSelector, cellEditorType, hide, sortable, minWidth, width, valueFormatter},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight={false}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
