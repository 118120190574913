import axios from "../../utils/axiosInstance";

export const tasksListReactive = async (count, pageCount, filters, sortModel) => {
    const response = await axios.post("/tasksListReactive", {
        page: pageCount,
        count: count,
        filters: filters,
        sortModel: sortModel,
    });
    return response.data;
};
export const findByTaskIdListReactive = async (ids) => {
    const response = await axios.post("/findByTaskIdListReactive",{
        ids
    })
    return response.data
}

export const getTasksGridFilterStateReactive = async () => {
    const response =  await axios.post("/getTasksGridFilterStateReactive", {});
    return response.data;
};

export const updateTasksGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateTasksGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateTasksGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateTasksGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getTasksGridColumnStateReactive = async () => {
    const response =  await axios.post("/getTasksGridColumnStateReactive", {});
    return response.data;
};
export const updateTasksGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateTasksGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateTasksGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateTasksGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};