import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {NotificationContainer} from "react-notifications";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {useForm} from "react-hook-form";
import {Link, NavLink} from "react-router-dom";
import {Auth} from "aws-amplify";
import {ErrorTag} from "../../components/errorDiv"
import {
    AcceptEulaModal,
    GroupCollectAgentTelemetrySetupModal,
    MFAModal,
    NewPasswordModal,
    UserInfoModal,
    UserTimeZoneSetupModal
} from "../../components/LoginModals";
import {checkRefreshToken, handleLogout} from "../../utils/axiosInstance";
import {
    userAcceptEulaReactive,
    userEulaAcceptedCheckReactive,
    userInfoCheckReactive,
    userTimeZoneCheckReactive,
    checkUserLastPasswordResetDateReactive,
    createAgentReceiveOauthTokenTaskReactive,
    groupAgentTelemetryInitialSetupCheckReactive,
} from "../api/loginApi";
import {retrieveCheckoutSessionCustomerReactive} from "../api/stripeApi";
import {
    PartnerOnBoardingHomeModal,
    PartnerOnBoardingSetupSalesNotificationModal,
    PartnerOnBoardingSetupSecurityNotificationModal
} from "../../components/partnerOnBoarding";
import {partnerOnBoardingCheckReactive} from "../api/partnerOnBoardingApi";
import {InformationModal} from "../../components/informationOnlyModal";
import {groupListReactive} from "../api/groupsApi";
import {
    getAgentsGridFilterAndColumnStateAndStoreInSession,
    getAndStoreAllFilterStatesInSession,
    getAndStoreAllGridColumnStatesInSession
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/encryptDecryptHelper";
import {getAndStoreAllRoleNamesOfCurrentUserInSession} from "../../utils/roleNamesOfCurrentUserHelper";
import {getAndStoreAgentNamesListInSession} from "../../utils/agentNameGridHelper";
import {getAndStoreLicensesListWithAgentIdReactiveWithSSE} from "../../utils/licenseNameGridHelper";
import {getAndStoreAllClientSideDataInSession} from "../../utils/clientSideDataSessionStorageHelper";
import CCLogo from "../../components/CCLogo";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {Button, ThemeProvider} from "@mui/material";
import {roundButtonTheme} from "../../utils/muiStyling";
import Modal from "react-modal";

let login;
//Modal.setAppElement('#root')

export default function Login(){
    const [isLoading, setIsLoading] = useState(false);
    const [loggedUser, setLoggedUser] = useState({});
    const [loginError, setLoginError] = useState();
    const [mfaSetup, setMFASetup] = useState();
    const [showNewPassword, setShowNewPassword] = useState();
    const [showMFA, setShowMFA] = useState(false);
    const [showAcceptEula, setShowAcceptEula] = useState();
    const [showUserInfo, setShowUserInfo] = useState();
    const [showUserTimeZoneModal, setShowUserTimeZoneModal] = useState(false);
    const [showPartnerOnBoardingHomeModal, setShowPartnerOnBoardingHomeModal] = useState();
    const [salesNotificationButtonDisabled, setSalesNotificationButtonDisabled] = useState(false);
    const [securityNotificationButtonDisabled, setSecurityNotificationButtonDisabled] = useState(false);
    const [showResetPasswordNeededModal, setShowResetPasswordNeededModal] = useState(false);
    const [salesNotificationSetupModalOpened, setSalesNotificationSetupModalOpened] = useState();
    const [salesNotificationGroups, setSalesNotificationGroups] = useState([]);
    const [securityNotificationSetupModalOpened, setSecurityNotificationSetupModalOpened] = useState();
    const [securityNotificationGroups, setSecurityNotificationGroups] = useState([]);
    const [onBoardingSuccessModalOpened, setOnBoardingSuccessModalOpened] = useState(false);
    const [showGroupCollectAgentTelemetrySetupModal, setShowGroupCollectAgentTelemetrySetupModal] = useState(false);
    const [collectAgentTelemetrySetupGroups, setCollectAgentTelemetrySetupGroups] = useState([]);
    const { register, handleSubmit} = useForm();

    React.useEffect( () => {
        let controller = new AbortController();
        (async () => {
            //check if there is a session id in the url in order to display success message to user
            const params = new URLSearchParams(window.location.search)
            let session_id = params.get('session_id')
            if(session_id){
                try{
                    const data = await retrieveCheckoutSessionCustomerReactive(session_id)
                    if(data.customerEmail){
                        NotificationManager.success(
                            `Thank you for your order, ${data.customerEmail}! Please login to view your new licenses.`
                        );
                    }
                }
                catch(error){
                    console.log(error)
                }
            }
            //check if user is logged in, if they are then send them to agents page
            let refreshToken = decryptAndGetSessionVariable("refresh_token")
            if(refreshToken !== null){
                let path = window.location.pathname
                if(/^\/login/.test(path)){ //check if we are on the login page
                    window.location.href='./private/agents'; //take user to agents page
                }
            }
        })()
        return () => controller?.abort();
    }, []);

    const loginSubmit = async (data) => {
        try {
            setIsLoading(true);
            data.username = data.username.trim().toLowerCase()
            //TODO: make this toLowerCase() as well?
            login = await Auth.signIn(data.username, data.password);
            setLoggedUser(login);
            sessionStorage.setItem("username", login.username)
            if (login.challengeName === "NEW_PASSWORD_REQUIRED") {
                setShowNewPassword(true);
            }
            if (login.challengeName === "MFA_SETUP") {
                setMFASetup(true);
                setShowMFA(true);
            }
            if (login.challengeName === "SOFTWARE_TOKEN_MFA") {
                setShowMFA(true);
                setMFASetup(false);
            }
            setLoginError();
        } catch (error) {
            setLoginError(error.message);
        }
        setIsLoading(false);
    }


    return(
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Cyber Crucible-Login</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*New password modal*/}
            {showNewPassword && (
                <NewPasswordModal
                    user={loggedUser}
                    opened={showNewPassword}
                    onClose={() => setShowNewPassword(false)}
                    onSuccess={() => {
                        NotificationManager.success(
                            `You have successfully made a new password! You will have to login again with your new password in order to continue.`
                        );
                        setShowNewPassword(false)
                        //TODO: encrypt this session variable?
                        sessionStorage.setItem("usernameFromResetPassword", loggedUser.username)
                    }}
                />
            )}
            {/*MFA Modal*/}
            {showMFA && (
                <MFAModal
                    user={loggedUser}
                    opened={showMFA}
                    isSetup={mfaSetup}
                    setIsLoadingMethod={setIsLoading}
                    onClose={() => setShowMFA(false)}
                    onSuccess={async () => {
                        if(login){
                            sessionStorage.removeItem("refresh_token")
                            //sessionStorage.setItem("username", login.username)
                            encryptAndStoreSessionVariable("refresh_token", login.signInUserSession.refreshToken.token)
                            checkRefreshToken();
                            let usernameFromResetPassword = sessionStorage.getItem("usernameFromResetPassword")
                            let currentLoginUsername = login.username
                            try{
                                //check usernameFromResetPassword session variable to see if we are coming reset password page or the NEW_PASSWORD_REQUIRED challenge
                                let updateUserLastPasswordResetDateTime = false
                                if(usernameFromResetPassword !== null && usernameFromResetPassword !== undefined && usernameFromResetPassword.toLowerCase() === currentLoginUsername.toLowerCase()){
                                    updateUserLastPasswordResetDateTime = true
                                }
                                let response = await checkUserLastPasswordResetDateReactive(updateUserLastPasswordResetDateTime);
                                if(response && response.passwordResetNeeded){
                                    //user needs to reset password
                                    //remove usernameFromResetPassword storage variable
                                    sessionStorage.removeItem("usernameFromResetPassword")
                                    setIsLoading(false)
                                    setShowResetPasswordNeededModal(true)
                                    try{
                                        //log user out since they need to reset their password and login again after
                                        await Auth.signOut();
                                        sessionStorage.clear();
                                    } catch (e) {}
                                    //need to return to stop code below from running since they have to reset their password
                                    return
                                }
                            } catch (e) {}
                            //remove usernameFromResetPassword storage variable
                            sessionStorage.removeItem("usernameFromResetPassword")
                            //We need to get training/demo settings first before running any queries so that they send the right trainingMode/demoMode fields in the requests, use await since the other
                            // queries rely on these settings
                            let userInfoNeedsUpdated = false
                            let trainingGroupsVisible = false
                            let demoGroupsVisible = false
                            try{
                                let userInfoResults = await userInfoCheckReactive();
                                userInfoNeedsUpdated = userInfoResults.userInfoNeedsUpdated
                                trainingGroupsVisible = userInfoResults.trainingGroupsVisible
                                demoGroupsVisible = userInfoResults.demoGroupsVisible
                                encryptAndStoreSessionVariable("trainingGroupsVisible", JSON.stringify(trainingGroupsVisible))
                                encryptAndStoreSessionVariable("demoGroupsVisible", JSON.stringify(demoGroupsVisible))
                            } catch (e) {}

                            //async calls for filter and column states for user, make calls for agent grid filter and column states first since users go to this page first after login
                            if(!trainingGroupsVisible && !demoGroupsVisible){
                                //only if user is not in training and demo mode get the filter/column states or else user will see production filters for training or demo mode
                                getAgentsGridFilterAndColumnStateAndStoreInSession()
                            }
                            //getAndStoreLicensesListWithAgentIdReactive()
                            getAndStoreLicensesListWithAgentIdReactiveWithSSE()

                            //Since many components and renderers for grids depend on groups, we should wait for getGroups to finish so we have the zenGroupSessionStorage populated before going to landing page after login
                            try{

                                let result = await groupListReactive()
                                encryptAndStoreSessionVariable("zenGroupSessionStorage", JSON.stringify(result))
                            } catch (e) {console.error(e)}
                            //Since we are not relying on this list for some values in agents and groups, we should wait until this api call finishes before moving on
                            try{
                                let data = await distinctAgentVersionsReactive()
                                //store list in descending order by version number
                                data?.sort()?.reverse()
                                data.unshift("Do Not Update")
                                encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                            } catch (e){}
                            getAndStoreAgentNamesListInSession() //agentsList
                            getAndStoreAllClientSideDataInSession()
                            if(!trainingGroupsVisible && !demoGroupsVisible){
                                //only if user is not in training and demo mode get the filter/column states or else user will see production filters for training or demo mode
                                getAndStoreAllFilterStatesInSession(false);
                                getAndStoreAllGridColumnStatesInSession(false);
                            }
                            //  async calls to populate role name list for current user
                            getAndStoreAllRoleNamesOfCurrentUserInSession()
                            try{
                                createAgentReceiveOauthTokenTaskReactive(login.signInUserSession.refreshToken.token) //don't wait for return
                            }
                            catch(error){} //keep moving forward if createAgentReceiveOauthTokenTask() throws error
                            try{
                                const eulaCheck = await userEulaAcceptedCheckReactive();
                                if(eulaCheck.userAcceptedEULA === false){
                                    setIsLoading(false);
                                    NotificationManager.info("Please accept our End User License Agreement before continuing.");
                                    setShowAcceptEula(true);
                                    setShowMFA(false)
                                }
                                else if(eulaCheck.userAcceptedEULA === true){
                                    try{
                                        if(userInfoNeedsUpdated === true){
                                            setIsLoading(false);
                                            setShowMFA(false);
                                            setShowUserInfo(true);
                                        }
                                        else{
                                            try{
                                                const partnerOnBoardingCheckResponse = await partnerOnBoardingCheckReactive();
                                                if(partnerOnBoardingCheckResponse.salesNotificationSetupNeeded || partnerOnBoardingCheckResponse.securityNotificationSetupNeeded){
                                                    setSalesNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSalesNotifications)
                                                    setSecurityNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSecurityNotifications)
                                                    setSalesNotificationButtonDisabled(!partnerOnBoardingCheckResponse.salesNotificationSetupNeeded)
                                                    setSecurityNotificationButtonDisabled(!partnerOnBoardingCheckResponse.securityNotificationSetupNeeded)
                                                    setShowPartnerOnBoardingHomeModal(true)
                                                    setIsLoading(false)
                                                }
                                                else{
                                                    //all good
                                                    const userTimeZoneResponse = await userTimeZoneCheckReactive()
                                                    if(userTimeZoneResponse){
                                                        encryptAndStoreSessionVariable("timezone", userTimeZoneResponse)
                                                        const agentTelemetrySetupResponse = await groupAgentTelemetryInitialSetupCheckReactive()
                                                        if(agentTelemetrySetupResponse.setupNeeded){
                                                            setIsLoading(false)
                                                            setShowMFA(false)
                                                            setCollectAgentTelemetrySetupGroups(agentTelemetrySetupResponse.groupsToInitialize)
                                                            setShowGroupCollectAgentTelemetrySetupModal(true)
                                                        }
                                                        else{
                                                            document.getElementById("agentsLink").click();
                                                        }
                                                    }
                                                    else{
                                                        setIsLoading(false)
                                                        setShowMFA(false)
                                                        setShowUserTimeZoneModal(true)
                                                    }
                                                }
                                            }
                                            catch(error){
                                                setIsLoading(false);
                                                document.getElementById("agentsLink").click();
                                                //redirect to agents page since this user info is not essential
                                            }
                                            setIsLoading(false);
                                        }
                                    }
                                    catch(error){
                                        setIsLoading(false);
                                        //TODO: find better way to redirect users
                                        document.getElementById("agentsLink").click();
                                    }
                                }
                            }
                            catch (error){
                                setIsLoading(false);
                                console.log(error)
                                NotificationManager.error("Unexpected error, please try logging in again.");
                                await Auth.signOut();
                                handleLogout()
                            }
                        }
                        setIsLoading(false);
                    }}
                />
            )}
            {/*Accept Eula Modal*/}
            {showAcceptEula && (
                <AcceptEulaModal
                    opened={showAcceptEula}
                    onClose={() => {
                        setShowAcceptEula(false)
                    }}
                    onAccept={async () => {
                        try{
                            setIsLoading(true)
                            await userAcceptEulaReactive();
                            try{
                                const userInfoCheckStatus = await userInfoCheckReactive();
                                if(userInfoCheckStatus.userInfoNeedsUpdated === true){
                                    setShowMFA(false);
                                    setShowAcceptEula(false)
                                    setShowUserInfo(true);
                                }
                                else{
                                    try{
                                        const partnerOnBoardingCheckResponse = await partnerOnBoardingCheckReactive();
                                        if(partnerOnBoardingCheckResponse.salesNotificationSetupNeeded || partnerOnBoardingCheckResponse.securityNotificationSetupNeeded){
                                            setShowMFA(false);
                                            setShowAcceptEula(false);
                                            setSalesNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSalesNotifications)
                                            setSecurityNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSecurityNotifications)
                                            setSalesNotificationButtonDisabled(!partnerOnBoardingCheckResponse.salesNotificationSetupNeeded)
                                            setSecurityNotificationButtonDisabled(!partnerOnBoardingCheckResponse.securityNotificationSetupNeeded)
                                            setShowPartnerOnBoardingHomeModal(true)
                                            setIsLoading(false)
                                        }
                                        else{
                                            //all good
                                            const userTimeZoneResponse = await userTimeZoneCheckReactive()
                                            if(userTimeZoneResponse){
                                                encryptAndStoreSessionVariable("timezone", userTimeZoneResponse)
                                                const agentTelemetrySetupResponse = await groupAgentTelemetryInitialSetupCheckReactive()
                                                if(agentTelemetrySetupResponse.setupNeeded){
                                                    setIsLoading(false)
                                                    setShowMFA(false)
                                                    setShowAcceptEula(false)
                                                    setCollectAgentTelemetrySetupGroups(agentTelemetrySetupResponse.groupsToInitialize)
                                                    setShowGroupCollectAgentTelemetrySetupModal(true)
                                                }
                                                else{
                                                    document.getElementById("agentsLink").click();
                                                }
                                            }
                                            else{
                                                setIsLoading(false)
                                                setShowAcceptEula(false);
                                                setShowMFA(false);
                                                setShowUserTimeZoneModal(true)
                                            }
                                        }
                                    }
                                    catch(error){
                                        setIsLoading(false);
                                        document.getElementById("agentsLink").click();
                                        //redirect to agents page since this user info is not essential
                                    }
                                    setIsLoading(false);
                                }
                            }
                            catch(error){
                                //redirect to agents page since this user info is not essential
                                //TODO: find better way to redirect users
                                document.getElementById("agentsLink").click();
                            }
                        }
                        catch(error){
                            NotificationManager.error(
                                'Unexpected error accepting our EULA.'
                            );
                        }
                        setIsLoading(false)
                    }}
                />
            )}
            {showUserInfo && (
                <UserInfoModal
                    awsUser={login}
                    opened={showUserInfo}
                    onClose={() => setShowUserInfo(false)}
                    onSuccess={async () => {
                        try{
                            setIsLoading(true)
                            setShowUserInfo(false);
                            const partnerOnBoardingCheckResponse = await partnerOnBoardingCheckReactive();
                            if(partnerOnBoardingCheckResponse.salesNotificationSetupNeeded || partnerOnBoardingCheckResponse.securityNotificationSetupNeeded){
                                setSalesNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSalesNotifications)
                                setSecurityNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSecurityNotifications)
                                setSalesNotificationButtonDisabled(!partnerOnBoardingCheckResponse.salesNotificationSetupNeeded)
                                setSecurityNotificationButtonDisabled(!partnerOnBoardingCheckResponse.securityNotificationSetupNeeded)
                                setShowPartnerOnBoardingHomeModal(true)
                                setIsLoading(false)
                            }
                            else{
                                //all good
                                const userTimeZoneResponse = await userTimeZoneCheckReactive()
                                if(userTimeZoneResponse){
                                    encryptAndStoreSessionVariable("timezone", userTimeZoneResponse)
                                    const agentTelemetrySetupResponse = await groupAgentTelemetryInitialSetupCheckReactive()
                                    if(agentTelemetrySetupResponse.setupNeeded){
                                        setIsLoading(false)
                                        setShowMFA(false)
                                        setCollectAgentTelemetrySetupGroups(agentTelemetrySetupResponse.groupsToInitialize)
                                        setShowGroupCollectAgentTelemetrySetupModal(true)
                                    }
                                    else{
                                        document.getElementById("agentsLink").click();
                                    }
                                }
                                else{
                                    setIsLoading(false)
                                    setShowUserTimeZoneModal(true)
                                }
                            }
                        }
                        catch(error){
                            setIsLoading(false);
                            document.getElementById("agentsLink").click();
                            //redirect to agents page since this user info is not essential
                        }
                        setIsLoading(false);
                    }}
                    onError={() => {
                        NotificationManager.error(
                            `There was an error updating your account.`
                        );
                        setShowUserInfo(false);
                        //TODO: find better way to redirect users
                        document.getElementById("agentsLink").click();
                    }}
                />
            )}
            {showUserTimeZoneModal && (
                <UserTimeZoneSetupModal
                    opened={showUserTimeZoneModal}
                    onClose={() => setShowUserTimeZoneModal(false)}
                    onSuccess={async () => {
                        setIsLoading(true)
                        try{
                            const agentTelemetrySetupResponse = await groupAgentTelemetryInitialSetupCheckReactive()
                            if(agentTelemetrySetupResponse.setupNeeded){
                                setIsLoading(false)
                                setShowMFA(false)
                                setShowUserTimeZoneModal(false)
                                setCollectAgentTelemetrySetupGroups(agentTelemetrySetupResponse.groupsToInitialize)
                                setShowGroupCollectAgentTelemetrySetupModal(true)
                            }
                            else{
                                document.getElementById("agentsLink").click();
                            }
                        }
                        catch (error){
                            document.getElementById("agentsLink").click();
                        }
                    }}
                    setIsLoading={setIsLoading}
                />
            )}
            {showGroupCollectAgentTelemetrySetupModal && (
                <GroupCollectAgentTelemetrySetupModal
                    opened={showGroupCollectAgentTelemetrySetupModal}
                    onClose={() => setShowGroupCollectAgentTelemetrySetupModal(false)}
                    onSuccess={async () => {
                        document.getElementById("agentsLink").click();
                    }}
                    onError={() => {
                        NotificationManager.error(
                            `Unexpected error updating these groups.`
                        );
                        document.getElementById("agentsLink").click();
                    }}
                    setIsLoading={setIsLoading}
                    groupsToInitialize={collectAgentTelemetrySetupGroups}
                />
            )}
            {/*Partner On Boarding Modals*/}
            {showPartnerOnBoardingHomeModal && (
                <PartnerOnBoardingHomeModal
                    opened={showPartnerOnBoardingHomeModal}
                    onClose={() => setShowPartnerOnBoardingHomeModal(false)}
                    salesNotificationButtonDisabled={salesNotificationButtonDisabled}
                    securityNotificationButtonDisabled={securityNotificationButtonDisabled}
                    setSalesNotificationSetupModalOpened={setSalesNotificationSetupModalOpened}
                    setSecurityNotificationSetupModalOpened={setSecurityNotificationSetupModalOpened}
                />
            )}
            {salesNotificationSetupModalOpened && (
                <PartnerOnBoardingSetupSalesNotificationModal
                    opened={salesNotificationSetupModalOpened}
                    setOpened={setSalesNotificationSetupModalOpened}
                    setIsLoading={setIsLoading}
                    onSuccess={async (zenGroupId) => {
                        try{
                            //remove zenGroupId from instead of calling to api again to avoid the primary vs secondary lag where notification wouldn't be inserted in secondary yet.
                            let newSalesGroupsList = salesNotificationGroups.filter(function (value, index, arr) {
                                return value.id !== zenGroupId;
                            })
                            if(newSalesGroupsList.length > 0 || securityNotificationGroups.length > 0){
                                //not finished yet
                                setSalesNotificationGroups(newSalesGroupsList)
                                setSecurityNotificationGroups(securityNotificationGroups)
                                setSalesNotificationButtonDisabled(newSalesGroupsList.length === 0)
                                setSecurityNotificationButtonDisabled(securityNotificationGroups.length === 0)
                                setShowPartnerOnBoardingHomeModal(true)
                                setIsLoading(false)
                            }
                            else{
                                //else finished
                                setSalesNotificationButtonDisabled(true)
                                setSecurityNotificationButtonDisabled(true)
                                setOnBoardingSuccessModalOpened(true)
                                setIsLoading(false)
                            }

                            /*setIsLoading(true)
                            const partnerOnBoardingCheckResponse = await partnerOnBoardingCheckReactive();
                            if(partnerOnBoardingCheckResponse.salesNotificationSetupNeeded || partnerOnBoardingCheckResponse.securityNotificationSetupNeeded){
                                setSalesNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSalesNotifications)
                                setSecurityNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSecurityNotifications)
                                setSalesNotificationButtonDisabled(!partnerOnBoardingCheckResponse.salesNotificationSetupNeeded)
                                setSecurityNotificationButtonDisabled(!partnerOnBoardingCheckResponse.securityNotificationSetupNeeded)
                                setShowPartnerOnBoardingHomeModal(true)
                                setIsLoading(false)
                            }
                            else{
                                //all good
                                setSalesNotificationButtonDisabled(true)
                                setSecurityNotificationButtonDisabled(true)
                                setOnBoardingSuccessModalOpened(true)
                                setIsLoading(false)
                            }*/
                        }
                        catch(error){
                            console.log(error)
                        }
                    }}
                    zenGroups={salesNotificationGroups}

                />
            )}
            {securityNotificationSetupModalOpened && (
                <PartnerOnBoardingSetupSecurityNotificationModal
                    opened={securityNotificationSetupModalOpened}
                    setOpened={setSecurityNotificationSetupModalOpened}
                    setIsLoading={setIsLoading}
                    onSuccess={async (zenGroupId) => {
                        try{
                            //remove zenGroupId from instead of calling to api again to avoid the primary vs secondary lag where notification wouldn't be inserted in secondary yet.
                            let newSecurityNotificationGroups = securityNotificationGroups.filter(function (value, index, arr) {
                                return value.id !== zenGroupId;
                            })
                            if(newSecurityNotificationGroups.length > 0 || salesNotificationGroups.length > 0){
                                //not finished yet
                                setSalesNotificationGroups(salesNotificationGroups)
                                setSecurityNotificationGroups(newSecurityNotificationGroups)
                                setSalesNotificationButtonDisabled(salesNotificationGroups.length === 0)
                                setSecurityNotificationButtonDisabled(newSecurityNotificationGroups.length === 0)
                                setShowPartnerOnBoardingHomeModal(true)
                                setIsLoading(false)
                            }
                            else{
                                //else finished
                                setSalesNotificationButtonDisabled(true)
                                setSecurityNotificationButtonDisabled(true)
                                setOnBoardingSuccessModalOpened(true)
                                setIsLoading(false)
                            }
                            /*setIsLoading(true)
                            const partnerOnBoardingCheckResponse = await partnerOnBoardingCheckReactive();
                            if(partnerOnBoardingCheckResponse.salesNotificationSetupNeeded || partnerOnBoardingCheckResponse.securityNotificationSetupNeeded){
                                setSalesNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSalesNotifications)
                                setSecurityNotificationGroups(partnerOnBoardingCheckResponse.channelGroupsThatNeedToSetupSecurityNotifications)
                                setSalesNotificationButtonDisabled(!partnerOnBoardingCheckResponse.salesNotificationSetupNeeded)
                                setSecurityNotificationButtonDisabled(!partnerOnBoardingCheckResponse.securityNotificationSetupNeeded)
                                setShowPartnerOnBoardingHomeModal(true)
                                setIsLoading(false)
                            }
                            else{
                                //all good
                                setSalesNotificationButtonDisabled(true)
                                setSecurityNotificationButtonDisabled(true)
                                setOnBoardingSuccessModalOpened(true)
                                setIsLoading(false)
                            }*/
                        }
                        catch(error){
                            console.log(error)
                        }
                    }}
                    zenGroups={securityNotificationGroups}
                />
            )}
            {onBoardingSuccessModalOpened && (
                <InformationModal
                    opened={onBoardingSuccessModalOpened}
                    onClose={async () => {
                        try{
                            setIsLoading(true)
                            const userTimeZoneResponse = await userTimeZoneCheckReactive()
                            if(userTimeZoneResponse){
                                encryptAndStoreSessionVariable("timezone", userTimeZoneResponse)
                                const agentTelemetrySetupResponse = await groupAgentTelemetryInitialSetupCheckReactive()
                                if(agentTelemetrySetupResponse.setupNeeded){
                                    setIsLoading(false)
                                    setShowMFA(false)
                                    setOnBoardingSuccessModalOpened(false)
                                    setCollectAgentTelemetrySetupGroups(agentTelemetrySetupResponse.groupsToInitialize)
                                    setShowGroupCollectAgentTelemetrySetupModal(true)
                                }
                                else{
                                    document.getElementById("agentsLink").click();
                                }
                            }
                            else{
                                setIsLoading(false)
                                setShowUserTimeZoneModal(true)
                            }
                        }
                        catch(error){
                            document.getElementById("agentsLink").click();
                        }
                    }}
                    text="You have successfully completed your channel partner on boarding process!"
                    contentLabel={"Successfully Completed Channel Partner On Boarding"}
                />
            )}
            {showResetPasswordNeededModal && (
                <Modal contentLabel={"User password reset needed modal"} isOpen={showResetPasswordNeededModal}
                       onRequestClose={() => {
                           setShowResetPasswordNeededModal(false)
                       }}
                       shouldCloseOnOverlayClick={false}
                       shouldCloseOnEsc={false}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-col p-5 pl-8 pr-8">
                        <div className="flex-1 mb-6 text-xl">
                            <small>
                                The password for your account needs reset. A verification code to reset your password on the next page was sent to your email address.
                                You need to immediately login within the same session after resetting your password to complete the process.
                            </small>
                        </div>
                        <div className="flex flex-row flex-wrap">
                            <Link to={{pathname:"../resetPassword"}} state={{showFullForm: true}} className="">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"} color={"primary"}>
                                        Reset Password
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>
                    </div>
                </Modal>
            )}
            <form onSubmit={handleSubmit(loginSubmit)}>
                <div className="flex flex-col justify-start gap-y-10 items-center flex-1 mx-6">
                    {/*Image div*/}
                    <CCLogo className={"max-w-md mx-1"} />
                    {/*Login Form Div*/}
                    <div className="flex flex-1 flex-col text-left items-start content-center gap-y-5">
                        <div className="text-3xl">Sign Into Your Account</div>
                        <div className="w-full">
                            <small className="">Email</small>
                            <input
                                autoFocus={true}
                                type="text"
                                required
                                name="username"
                                {...register("username")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full xl:max-w-lg lg:max-w-lg ">
                            <small className="">Password</small>
                            <input
                                type="password"
                                required
                                name="password"
                                {...register("password")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                            {loginError && (
                                <div className="width-fit-content mt-5">
                                    <ErrorTag text={loginError} />
                                </div>
                            )}
                        </div>
                        <div className="w-full xl:max-w-lg lg:max-w-lg flex flex-row flex-wrap justify-center">
                            <NavLink className="text-theme-teal hover:text-theme" to="/resetPassword">
                                Reset Your Password Here
                            </NavLink>
                        </div>
                        <div className="w-full xl:max-w-lg lg:max-w-lg text-center">
                            <ThemeProvider theme={roundButtonTheme}>
                                <Button
                                    color={"primary"}
                                    variant={"contained"}
                                    style={{ fontSize: 20, textTransform: "none", padding: "10px 30px" }}
                                    type="submit">
                                    Sign In
                                </Button>
                            </ThemeProvider>
                        </div>
                        <div className="w-full xl:max-w-lg lg:max-w-lg mt-10 mb-10 text-center">
                            <p>Don't have an account with us?
                                <NavLink className="ml-3 text-theme-teal hover:text-theme" to="/createAccount">
                                    Sign Up
                                </NavLink></p>
                        </div>

                    </div>
                </div>
            </form>
            <div className="hidden">
                <Link id="agentsLink" to="/private/agents"/>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    )
}
