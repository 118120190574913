import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivacyPolicy from "./pages/static/privacy-policy";
import Terms from "./pages/static/terms-service";
//import React from "react";
import Agents from "./pages/private/agents";
import Callback from "./pages/private/callback";
import Licenses from "./pages/private/licenses";
import Whitelists from "./pages/private/whitelists";
import Decryptors from "./pages/private/decryptors";
import ProcessInjections from "./pages/private/processInjections";
import Settings from "./pages/private/settings";
import Deals from "./pages/private/deals";
import Notifications from "./pages/private/notifications";

import Groups from "./pages/private/groups";
import Schedules from "./pages/private/schedules";
import Roles from "./pages/private/roles";
import Incidents from "./pages/private/incidents";
import Login from "./pages/static/login";
import Register from "./pages/static/register";
import ResetPassword from "./pages/static/resetPassword";
import {Amplify} from "aws-amplify";
import ReactGA from 'react-ga';
import Sitemap from "./components/sitemap";
import SalesNotifications from "./pages/private/salesNotifications";
import Tasks from "./pages/private/tasks";
import RestIntegration from "./pages/private/restIntegration";
import SessionExpiration from "./pages/private/sessionExpiration";
import ProcessCreations from "./pages/private/processCreations";
import SilentResponses from "./pages/private/silentResponses";
import Collectipede from "./pages/private/collectipede";
import BrowserDataIncidents from "./pages/private/browserDataIncidents";
import AgentReleaseEmailSubmissions from "./pages/private/agentReleaseEmailSubmissions";
import TrainingLicenses from "./pages/private/trainingLicenses";
import React from "react";
import {AmplifyAuthStorage} from "./utils/customAwsStorage";
import { library } from '@fortawesome/fontawesome-svg-core'
import {faInfoCircle, faSpinner, faBars, faUpload, faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import {faColumns, faFilterSlash, faRotate, faTrashCan, faUserCog, faWarehouse,
    faMagnifyingGlassArrowRight, faSyringe, faVolume, faVolumeSlash, faPenToSquare, faUserPlus, faSortAlphaUp, faSortAlphaDownAlt,
    faFilter, faEdit, faShieldSlash, faShieldHalved, faBinoculars, faUserCircle, faDialMin, faDialMed, faDialMax} from "@fortawesome/pro-duotone-svg-icons";
import {faCaretDown, faCaretUp, faDownload} from "@fortawesome/pro-solid-svg-icons";

library.add(faInfoCircle, faFilterSlash, faColumns, faRotate, faSpinner, faBars, faTrashCan,
    faUserCog, faWarehouse, faUpload, faChevronLeft, faMagnifyingGlassArrowRight, faSyringe,
    faVolume, faVolumeSlash, faPenToSquare, faUserPlus, faCaretUp, faCaretDown, faSortAlphaUp, faSortAlphaDownAlt,
    faFilter, faEdit, faShieldSlash, faShieldHalved, faBinoculars, faUserCircle, faDownload,
    faDialMin, faDialMed, faDialMax
    );

const trackingCode = process.env.REACT_APP_GA_ID
ReactGA.initialize(trackingCode); //pageviews are updated in header.js


Amplify.configure({
    Auth: {
        region: "us-west-2",
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
        storage: AmplifyAuthStorage
    }
});

function App() {
    return (
        <Routes>
            <Route path="/private/trainingLicenses" element={<TrainingLicenses/>}/>
            <Route path="/private/agentReleaseEmailSubmissions" element={<AgentReleaseEmailSubmissions/>}/>
            <Route path="/private/collectipede" element={<Collectipede/>}/>
            <Route path="/private/silentResponses" element={<SilentResponses/>}/>
            <Route path="/private/processCreations" element={<ProcessCreations/>}/>
            <Route path="/private/sessionDurations" element={<SessionExpiration/>}/>
            <Route path="/private/restIntegration" element={<RestIntegration/>}/>
            <Route path="/private/incidents" element={<Incidents/>}/>
            <Route path="/private/browserDataIncidents" element={<BrowserDataIncidents/>}/>
            <Route path="/private/roles" element={<Roles/>}/>
            <Route path="/private/scheduling" element={<Schedules/>}/>
            <Route path="/private/groups" element={<Groups/>}/>
            <Route path="/private/notifications" element={<Notifications/>}/>
            <Route path="/private/salesNotifications" element={<SalesNotifications/>}/>
            <Route path="/private/partnerRegisterDeal" element={<Deals/>}/>
            <Route path="/private/settings" element={<Settings/>}/>
            <Route path="/private/processInjections" element={<ProcessInjections/>}/>
            <Route path="/private/tasks" element={<Tasks/>}/>
            <Route path="/private/decryptors" element={<Decryptors/>}/>
            <Route path="/private/whitelists" element={<Whitelists/>}/>
            <Route path="/private/licenses" element={<Licenses/>}/>
            <Route path="/private/agents" element={<Agents/>}/>
            <Route path="/sitemap" element={<Sitemap/>}/>
            <Route path="/callback" element={<Callback/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/createAccount" element={<Register/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/" element={<Navigate to="/login" replace/>}/>
        </Routes>
    );
}
export default App;
