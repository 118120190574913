import React, {Component, createRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MuiIconButtonWithTooltip} from "../components/muiComponents";


//NOTE: You need to register this Component with each AgGridReact's components in order to use it
export default class CustomNameCellEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value //used to return the cell value in the getValue() function
        };
    }

    //focus on the input element of the cell
    afterGuiAttached(){
        if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
    }

    //Final value to send to the grid, on completion of editing
    getValue() {
        return this.state.value;
    }

    //Gets called once before cell editing starts
    isCancelBeforeStart() {
        //returning true would cancel the cell editing.
        //TODO: We could check the permission levels for editing name or other fields here. Ideally we already have permission level so we don't have to make another api call, maybe
        // put in the valueFormatter or something where it already performs an api call for the name.
        return false;
    }

    // Gets called once when editing is finished.
    isCancelAfterEnd() {
        //returning true means the result of the edit is ignored and changed back to original value
        //The applyButtonPressed is just to make sure that the only way the update will trigger is if they click the Apply button.
        // This probably is unnecessary since we are calling stopPropagation() on the enter key down
        return !this.state.applyButtonPressed
    }

    //ignore the enter button press so the cell editing does not stop on enter button click
    onKeyPress(event) {
        if (event.keyCode === 13) { // enter = 13
            event.stopPropagation()
        }
    }

    render() {
        return (
            <div
                className="flex flex-row gap-x-1 align-start w-full justify-between p-1"
            >
                <input ref={this.inputRef}
                       value={this.state.value}
                       onChange={event => this.setState({value: event.target.value})}
                       onKeyDown={this.onKeyPress}
                       className="w-full px-1 focus:outline-none rounded h-full mt-1 mb-1"
                />
                <button
                    className="focus:outline-none px-1 bg-success text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing()
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <button
                    className="focus:outline-none px-1 bg-danger text-white rounded whitespace-normal md:whitespace-no-wrap outline-none"
                    onClick={() => {
                        this.setState({applyButtonPressed: true}, () => {
                            this.props.api.stopEditing(true)
                        })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>
        );
    }
}

export function editNameIconOnlyCellRenderer(params, titleText, colKey, alwaysShowIconDiv=false){
    let iconDiv = ""
    if(params.value){
        iconDiv = getEditIconComponent(params, titleText, colKey)
    }
    else if(alwaysShowIconDiv){
        //if alwaysShowIconDiv is passed a true value, then we want to always show the icon div even if there is not a value, so this would return a cell with the icon div but no value after,
        // instead of not showing the div and just a blank cell
        iconDiv = getEditIconComponent(params, titleText, colKey)
    }

    return(
        <div className={"flex flex-nowrap justify-start gap-x-2 items-center"}>
            {iconDiv}
            <div className="flex">
                {params.value}
            </div>
        </div>
    )
}

export function getEditIconComponent(params, titleText, colKey){
    return (
        <MuiIconButtonWithTooltip
            icon={
                <FontAwesomeIcon
                    className="object-contain"
                    icon="fa-duotone fa-pen-to-square"
                    size="xs"
                />
            }
            onClick={() => {
                //manually start editing the cell
                if(params.api && params.node && colKey){
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: colKey,
                        charPress: "editNameIconClicked"
                    })
                }
                //The charPress: "editNameIconClicked" was intended for use of distinguishing which cell editor to use for default group col
                // since we now allow them to be able to change a group name and object's group in one cell, otherwise it is ignored for other columns that use this function
            }}
            tooltipTitle={titleText}
            tooltipPlacement={"bottom-start"}
        />
    )
}