import axios from "../../utils/axiosInstance";

export const getUserAccountExecutiveSummarySettingsReactive = async () => {
    const response = await axios.post("/getUserAccountExecutiveSummarySettingsReactive", {});
    return response.data;
};

export const updateUserAccountWeeklyExecutiveSummaryDisabledSettingReactive = async (disabled) => {
    const response = await axios.post("/updateUserAccountWeeklyExecutiveSummaryDisabledSettingReactive", {
        disabled: disabled
    });
    return response.data;
};

export const updateUserAccountMonthlyExecutiveSummaryDisabledSettingReactive = async (disabled) => {
    const response = await axios.post("/updateUserAccountMonthlyExecutiveSummaryDisabledSettingReactive", {
        disabled: disabled
    });
    return response.data;
};

export const updateUserAccountExecutiveSummaryFormatReactive = async (reportFormat) => {
    const response = await axios.post("/updateUserAccountExecutiveSummaryFormatReactive", {
        reportFormat: reportFormat
    });
    return response.data;
};