import axios from "../../utils/axiosInstance";


export const getBrowserDataIncidentFriendlyNamesReactive = async (incidentIds) => {
    const response = await axios.post("/getBrowserDataIncidentFriendlyNamesReactive",{
        incidentIds
    })
    return response.data;
}

export const changeBrowserDataIncidentNameReactive = async (newName, groupId, incidentId) => {
    const response = await axios.post("/changeBrowserDataIncidentNameReactive", {
        "newName": newName,
        "zenGroupId": groupId,
        "id": incidentId
    });
    return response;
};

//The browserDataIncidentList endpoint is reactive and accounts for pageable in query, there is a browserDataIncidentListReactive endpoint that does not account for pageable which should not be used yet
export const getBrowserDataIncidentsLazy = async (count, pageCount, sortModel, filters) => {
    const response = await axios.post("/browserDataIncidentList", {
        page: pageCount,
        count: count,
        filters: filters,
        sortModel: sortModel,
    });
    return response.data;
};

export const browserDataWhitelistPathExistsReactive = async (path, zenGroupId, programArguments) => {
    const response = await axios.post("/browserDataWhitelistPathExistsReactive", {
        path: path,
        zenGroupId: zenGroupId,
        programArguments: programArguments
    });
    return response.data;
};

export const findByBrowserDataIncidentIdListReactive = async (ids) => {
    const response = await axios.post("/findByBrowserDataIncidentIdListReactive",{
        ids
    })
    return response.data
}

export const browserDataIncidentsPerGroupCountReactive = async (includeWhitelistedIncidents) => {
    const response = await axios.post("/browserDataIncidentsPerGroupCountReactive",{
        includeWhitelistedIncidents: includeWhitelistedIncidents
    })
    return response.data
}

export const getBrowserDataIncidentsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getBrowserDataIncidentsGridFilterStateReactive", {});
    return response.data;
};

export const updateBrowserDataIncidentsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateBrowserDataIncidentsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateBrowserDataIncidentsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateBrowserDataIncidentsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};
export const findSilentIncidentNamesForSingleBrowserDataIncidentId = async (incidentId) => {
    const response = await axios.post("/findSilentIncidentNamesForSingleBrowserDataIncidentIdReactive", {
        incidentId: incidentId
    });
    return response.data;
};

export const getBrowserDataIncidentsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getBrowserDataIncidentsGridColumnStateReactive", {});
    return response.data;
};
export const updateBrowserDataIncidentsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateBrowserDataIncidentsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateBrowserDataIncidentsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateBrowserDataIncidentsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};