import React from "react";

/*
            width="422.875"
            height="137.401"
 */
function CCLogo({className=""}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            viewBox="0 0 317.157 103.051"
            className={`${className}`}
            style={{ }}
        >
            <g
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                transform="translate(-57.421 -82.477)"
            >
                <path
                    fill="#e66823"
                    d="M103.258 168.773a13.446 13.446 0 01-1.285-.097c-12.153-.782-21.778-10.86-21.778-23.211 0-5.387 1.848-10.336 4.922-14.281.086-.122.176-.235.266-.352.058-.074.11-.152.172-.223 1.265-1.632 2.754-2.976 4.437-4.101a23.082 23.082 0 019.2-3.918c4.796-.883 6.503-.656 10.663-1.524 13.965-2.922 24.012-38.59 24.012-38.59-9.047 18.395-22.347 20.872-38.808 21.567-19.551.824-22.114 23.648-22.114 23.648-2.683-4.273-1.23-10.187-1.23-10.187-19.356 17.742-2.192 41.172-2.192 41.172-7.37-1.313-12.066-12.153-12.066-12.153-1.004 24.961 19.445 39.004 38.191 39.004 37.293 0 38.22-38.222 38.22-38.222s-9.083 21.879-30.61 21.468"
                ></path>
                <path
                    fill="#e66823"
                    d="M94.457 130.004v.062c0 .352-.05.704-.234.985-.184.285-.5.484-.97.484-.25 0-.448-.066-.616-.183.183-.149.367-.282.55-.434.036 0 .051.02.067.02a.436.436 0 00.418-.25c.016-.036.016-.086.016-.137.269-.184.5-.364.769-.547"
                ></path>
                <path
                    fill="#e66823"
                    d="M96.54 128.836v2.633h-.665v-1.817h-.668v-.082c.434-.254.883-.52 1.332-.734"
                ></path>
                <path
                    fill="#e66823"
                    d="M99.059 130.684a.43.43 0 01-.418.25.406.406 0 01-.399-.25c-.082-.164-.117-.383-.117-.614 0-.218.035-.453.117-.617.067-.152.2-.25.399-.25a.43.43 0 01.418.25c.082.164.101.399.101.617 0 .23-.02.45-.101.614m.55-1.598c-.199-.285-.5-.484-.968-.484-.45 0-.766.199-.95.484-.183.281-.25.633-.25.984 0 .348.067.7.25.98.184.286.5.485.95.485.468 0 .77-.2.968-.484.184-.281.235-.633.235-.98 0-.352-.051-.704-.235-.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M101.258 131.469h.68v-2.805h-.586c-.047.285-.344.524-.766.527v.454h.672zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M104.445 130.684a.43.43 0 01-.418.25.406.406 0 01-.398-.25c-.082-.164-.117-.383-.117-.614 0-.218.035-.453.117-.617.066-.152.2-.25.398-.25a.43.43 0 01.418.25c.082.164.102.399.102.617 0 .23-.02.45-.102.614m.551-1.598c-.183-.285-.5-.484-.969-.484-.449 0-.765.199-.949.484-.183.281-.25.633-.25.984 0 .348.067.7.25.98.184.286.5.485.95.485.468 0 .785-.2.968-.484.184-.281.234-.633.234-.98 0-.352-.05-.704-.234-.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M106.781 129.453c-.082.164-.117.399-.117.617 0 .23.035.45.117.618.031.066.082.113.133.164v.617c-.3-.067-.535-.2-.684-.418-.183-.281-.25-.633-.25-.98 0-.352.067-.704.25-.985.149-.234.415-.383.75-.434l-.015.618a.305.305 0 00-.184.183"
                ></path>
                <path
                    fill="#e66823"
                    d="M90.785 134.555c0 .351-.047.699-.25 1-.183.27-.484.468-.95.468-.35 0-.6-.132-.784-.316.117-.203.234-.387.367-.57v.035a.46.46 0 00.418.25c.215 0 .332-.102.418-.25.082-.168.098-.383.098-.617 0-.2-.032-.383-.098-.535.148-.184.316-.348.465-.516.02.016.05.031.066.066.203.286.25.653.25.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M92.195 135.96h.68v-2.808h-.586c-.047.285-.344.524-.766.528v.457h.672zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M95.39 135.172c-.085.148-.199.25-.417.25a.46.46 0 01-.418-.25 1.72 1.72 0 01-.098-.617c0-.215.031-.434.098-.602.086-.164.218-.265.418-.265.218 0 .332.101.418.265.082.168.097.387.097.602 0 .234-.015.449-.097.617m.53-1.602c-.183-.285-.48-.484-.948-.484-.45 0-.766.2-.95.484-.203.285-.25.653-.25.985 0 .351.047.699.25 1 .184.265.5.468.95.468.468 0 .765-.203.949-.468.203-.301.25-.649.25-1 0-.332-.047-.7-.25-.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M98.543 135.172c-.086.148-.203.25-.418.25a.46.46 0 01-.418-.25 1.72 1.72 0 01-.098-.617c0-.215.032-.434.098-.602.086-.164.219-.265.418-.265.215 0 .332.101.418.265.082.168.098.387.098.602 0 .234-.016.449-.098.617m.531-1.602c-.183-.285-.5-.484-.949-.484-.45 0-.766.2-.95.484-.202.285-.253.653-.253.985 0 .351.05.699.254 1 .133.199.383.351.683.418.45-.317.914-.602 1.399-.852.05-.184.066-.367.066-.566 0-.332-.047-.7-.25-.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M101.41 133.152v1.137c-.234.066-.45.117-.683.2v-.352h-.668v-.45c.418-.015.718-.25.77-.535zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M104.527 133.719c-.25.02-.484.05-.734.086a.37.37 0 00-.281-.118.385.385 0 00-.352.2c-.265.035-.531.101-.8.152.05-.168.1-.32.202-.469.184-.285.5-.484.95-.484.468 0 .765.2.949.484.035.051.05.102.066.149"
                ></path>
                <path
                    fill="#e66823"
                    d="M106.863 133.137l-.015.55-.301-.015c-.3 0-.586 0-.883.015.016-.05.016-.082.047-.117.184-.28.5-.484.953-.484.082 0 .133.035.2.05"
                ></path>
                <path
                    fill="#e66823"
                    d="M88.086 137.64v2.817h-.684v-1.832h-.183c.133-.336.316-.652.484-.984zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M89.637 140.453h.68v-2.808h-.587c-.046.289-.343.527-.765.53v.454h.672zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M92.82 139.656a.401.401 0 01-.398.27c-.219 0-.336-.102-.418-.27-.082-.164-.098-.383-.098-.597 0-.235.016-.45.098-.618a.43.43 0 01.418-.25c.2 0 .332.098.398.25.086.168.118.383.118.618 0 .214-.032.433-.118.597m.551-1.597c-.183-.286-.5-.485-.95-.485-.468 0-.765.2-.952.485-.2.296-.25.648-.25 1 0 .332.05.683.25.984.187.281.484.48.953.48.45 0 .766-.199.95-.48.198-.3.25-.652.25-.984 0-.352-.052-.704-.25-1"
                ></path>
                <path
                    fill="#e66823"
                    d="M95.871 137.656c-.2.2-.383.403-.547.602a.34.34 0 00-.168.183v.016c-.285.332-.515.684-.75 1.035-.015-.152-.035-.285-.035-.433 0-.352.05-.704.234-1 .2-.286.5-.485.97-.485.116 0 .198.047.296.082"
                ></path>
                <path
                    fill="#e66823"
                    d="M86.336 142.145v2.8h-.57c.05-.933.183-1.867.382-2.8zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M88.836 144.16c-.066.152-.2.25-.402.25a.428.428 0 01-.414-.25c-.086-.168-.102-.398-.102-.617 0-.234.016-.45.102-.617.082-.149.199-.25.414-.25.203 0 .336.101.402.25.082.168.117.383.117.617 0 .219-.035.45-.117.617m.55-1.601c-.183-.282-.5-.48-.952-.48-.45 0-.766.198-.95.48-.199.285-.25.636-.25.984 0 .352.051.7.25.984.184.285.5.485.95.485.453 0 .77-.2.953-.485.199-.285.25-.632.25-.984 0-.348-.051-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M91.988 144.16c-.066.152-.199.25-.402.25a.428.428 0 01-.414-.25c-.086-.168-.102-.398-.102-.617 0-.234.016-.45.102-.617.082-.149.2-.25.414-.25.203 0 .336.101.402.25.082.168.117.383.117.617 0 .219-.035.45-.117.617m.551-1.601c-.184-.282-.5-.48-.953-.48-.465 0-.766.198-.95.48-.198.285-.25.636-.25.984 0 .352.051.7.25.984.184.285.485.485.95.485.453 0 .77-.2.953-.485.2-.285.25-.632.25-.984 0-.348-.05-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M116.152 143.543c0 .352-.05.703-.234.984a.886.886 0 01-.3.266c.05-.617.085-1.281.085-1.965v-.32h.164l.05.05c.185.286.235.637.235.985"
                ></path>
                <path
                    fill="#e66823"
                    d="M118.238 142.559v2.386h-.668v-1.82h-.668v-.465c.153 0 .286-.05.403-.117zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M120.758 144.16c-.086.152-.203.25-.418.25-.2 0-.336-.098-.402-.25-.082-.168-.118-.398-.118-.617 0-.234.036-.45.118-.617a.417.417 0 01.402-.25c.215 0 .332.101.418.25.082.168.113.383.113.617 0 .219-.031.45-.113.617m.582-1.535l-1.969-.031c-.168.281-.234.617-.234.949 0 .352.066.7.25.984.183.286.5.485.953.485.465 0 .781-.2.965-.485.183-.285.234-.632.234-.984 0-.316-.05-.633-.2-.918"
                ></path>
                <path
                    fill="#e66823"
                    d="M122.605 142.66v.3a37.068 37.068 0 01-.082 1.532c-.167-.281-.234-.617-.234-.949 0-.316.066-.617.219-.883zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M87.75 148.648c-.066.149-.184.266-.398.266-.22 0-.336-.117-.418-.266a1.735 1.735 0 01-.098-.617c0-.219.031-.433.098-.601.082-.168.199-.266.418-.266.214 0 .332.098.398.266.086.168.117.382.117.601 0 .23-.031.45-.117.617m.55-1.601c-.183-.285-.5-.485-.948-.485-.45 0-.766.2-.95.485-.203.3-.25.648-.25.984 0 .348.047.7.25 1 .184.266.5.485.95.485.449 0 .765-.22.949-.485.199-.3.25-.652.25-1 0-.336-.051-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M90.637 146.629h-.586c-.047.285-.344.523-.766.527v.457h.672v1.825h.68zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M93.422 149.266c-.168.132-.383.25-.684.25a1.12 1.12 0 01-.949-.485c-.2-.3-.254-.652-.254-1 0-.336.055-.683.254-.984.184-.285.5-.484.95-.484.015 0 .034.015.05.015.016.219.05.418.082.633-.031-.016-.082-.047-.133-.047-.218 0-.336.098-.418.266a1.69 1.69 0 00-.097.601c0 .23.03.45.097.617.082.149.2.266.418.266.215 0 .332-.117.399-.266.02-.035.02-.085.035-.117.066.25.168.5.25.735"
                ></path>
                <path
                    fill="#e66823"
                    d="M115.336 146.98v2.45h-.684v-.098c.051-.117.118-.219.149-.336.234-.582.402-1.266.535-2.016"
                ></path>
                <path
                    fill="#e66823"
                    d="M117.855 148.648c-.085.149-.203.266-.418.266-.199 0-.335-.117-.417-.266a1.735 1.735 0 01-.098-.617c0-.219.031-.433.098-.601a.438.438 0 01.418-.266c.214 0 .332.098.417.266.082.168.098.382.098.601 0 .23-.015.45-.098.617m.532-1.601c-.184-.285-.5-.485-.95-.485-.449 0-.769.2-.953.485-.199.3-.25.648-.25.984 0 .348.051.7.25 1 .184.266.504.485.954.485.449 0 .765-.22.949-.485.203-.3.25-.652.25-1 0-.336-.047-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M120.988 148.648c-.066.149-.183.266-.398.266-.219 0-.336-.117-.418-.266a1.735 1.735 0 01-.098-.617c0-.219.031-.433.098-.601.082-.168.2-.266.418-.266.215 0 .332.098.398.266.086.168.117.382.117.601 0 .23-.03.45-.117.617m.551-1.601c-.184-.285-.5-.485-.95-.485-.452 0-.765.2-.952.485-.2.3-.25.648-.25.984 0 .348.05.7.25 1 .187.266.5.485.953.485.45 0 .765-.22.95-.485.198-.3.25-.652.25-1 0-.336-.052-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M100.41 127.418v.45h-.668v-.266c.219-.067.45-.133.668-.184"
                ></path>
                <path
                    fill="#e66823"
                    d="M103.68 126.883c-.036.199-.086.383-.203.566-.184.285-.5.485-.965.485-.453 0-.77-.2-.953-.485-.047-.082-.067-.183-.098-.265.215-.051.433-.082.648-.118a.43.43 0 00.403.266c.215 0 .332-.117.418-.266.015-.03 0-.082.015-.117.25-.015.485-.05.735-.066"
                ></path>
                <path
                    fill="#e66823"
                    d="M106.832 126.867a1.46 1.46 0 01-.203.582c-.2.285-.5.485-.965.485-.453 0-.77-.2-.953-.485a1.325 1.325 0 01-.2-.617c.235 0 .47-.031.7-.031.02.098.02.2.05.265.067.149.2.266.403.266.215 0 .332-.117.418-.266.031-.066.016-.148.031-.234.2.02.418.02.617.035zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M88.953 152.7c-.066.167-.2.265-.402.265-.215 0-.332-.098-.414-.266-.086-.168-.102-.383-.102-.601 0-.23.016-.45.102-.618.082-.148.199-.25.414-.25.203 0 .336.102.402.25.082.168.117.387.117.618 0 .218-.035.433-.117.601m.55-1.601c-.183-.282-.5-.465-.952-.465-.465 0-.766.183-.965.465-.184.3-.234.652-.234 1 0 .351.05.683.234.984.2.285.5.484.965.484.453 0 .77-.199.953-.484.2-.3.25-.633.25-.984 0-.348-.05-.7-.25-1"
                ></path>
                <path
                    fill="#e66823"
                    d="M92.105 152.7c-.066.167-.203.265-.418.265-.199 0-.316-.098-.402-.266-.082-.168-.098-.383-.098-.601 0-.23.016-.45.098-.618.086-.148.203-.25.403-.25.214 0 .351.102.417.25.082.168.118.387.118.618 0 .218-.036.433-.118.601m.551-1.601c-.183-.282-.504-.465-.969-.465-.449 0-.75.183-.949.465-.183.3-.234.652-.234 1 0 .351.05.683.234.984.2.285.5.484.95.484.464 0 .785-.199.968-.484.2-.3.25-.633.25-.984 0-.348-.05-.7-.25-1"
                ></path>
                <path
                    fill="#e66823"
                    d="M94.988 152v1.5h-.683v-1.836h-.668v-.45a.771.771 0 00.601-.28c.235.347.469.714.75 1.066"
                ></path>
                <path
                    fill="#e66823"
                    d="M114.57 152.7c-.086.167-.203.265-.418.265a.406.406 0 01-.402-.266c-.082-.168-.098-.383-.098-.601 0-.23.016-.45.098-.618a.42.42 0 01.402-.25c.215 0 .332.102.418.25.082.168.114.387.114.618 0 .218-.032.433-.114.601m.547-1.601c-.183-.282-.5-.465-.965-.465-.066 0-.101.015-.168.031-.3.52-.632 1.02-1.015 1.5 0 .32.047.652.23.918.184.285.504.484.953.484.465 0 .782-.199.965-.484.184-.3.25-.633.25-.984 0-.348-.066-.7-.25-1"
                ></path>
                <path
                    fill="#e66823"
                    d="M116.094 151.215v.457h.672v1.824h.68v-2.808h-.587c-.047.285-.343.523-.765.527"
                ></path>
                <path
                    fill="#e66823"
                    d="M119.957 152.7c-.086.167-.203.265-.418.265a.406.406 0 01-.402-.266c-.082-.168-.098-.383-.098-.601 0-.23.016-.45.098-.618a.42.42 0 01.402-.25c.215 0 .332.102.418.25.082.168.113.387.113.618 0 .218-.03.433-.113.601m.547-1.601c-.184-.282-.5-.465-.965-.465-.45 0-.75.183-.95.465-.187.3-.234.652-.234 1 0 .351.047.683.235.984.2.285.5.484.95.484.433 0 .733-.183.917-.433.082-.168.148-.332.23-.485a2.95 2.95 0 00.07-.55c0-.348-.07-.7-.253-1"
                ></path>
                <path
                    fill="#e66823"
                    d="M90.453 156.586c0 .25-.05.5-.148.734-.184-.203-.387-.418-.551-.636 0-.032.016-.067.016-.098 0-.234-.036-.45-.118-.617-.066-.153-.183-.25-.398-.25a.441.441 0 00-.219.05c-.117-.167-.234-.351-.351-.519.152-.082.336-.133.57-.133.45 0 .766.2.95.485.198.285.25.632.25.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M91.188 155.707v.453h.671v1.828h.68v-2.808h-.586c-.047.285-.344.523-.766.527"
                ></path>
                <path
                    fill="#e66823"
                    d="M95.04 157.203c-.067.149-.185.25-.4.25a.436.436 0 01-.417-.25 1.794 1.794 0 01-.102-.617c0-.234.035-.45.102-.617a.43.43 0 01.418-.25c.214 0 .332.097.398.25.082.168.117.383.117.617 0 .215-.035.45-.117.617m.55-1.601c-.183-.286-.5-.485-.948-.485-.454 0-.77.2-.954.485-.199.285-.25.632-.25.984 0 .348.051.7.25.984.184.282.5.485.954.485.449 0 .765-.203.949-.485.2-.285.25-.636.25-.984 0-.352-.05-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M98.191 157.203a.412.412 0 01-.398.25.436.436 0 01-.418-.25c-.082-.168-.102-.402-.102-.617 0-.234.02-.45.102-.617a.43.43 0 01.418-.25c.2 0 .332.097.398.25.082.168.118.383.118.617 0 .215-.036.45-.118.617m.551-1.601c-.183-.286-.5-.485-.949-.485-.469 0-.77.2-.953.485-.2.285-.25.632-.25.984 0 .348.05.7.25.984.183.282.484.485.953.485.45 0 .766-.203.95-.485.198-.285.25-.636.25-.984 0-.352-.052-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M101.078 156.3v1.688h-.683v-1.82h-.668v-.367c.433.199.882.367 1.351.5"
                ></path>
                <path
                    fill="#e66823"
                    d="M104.363 156.719c-.02.3-.066.601-.234.851-.184.282-.5.485-.95.485-.452 0-.769-.203-.952-.485-.2-.285-.25-.632-.25-.984v-.066c.234.03.468.097.703.132 0 .2.015.399.082.551.082.149.199.25.418.25.199 0 .332-.101.398-.25.066-.133.082-.316.102-.484zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M107.531 156.586c0 .351-.05.7-.25.984-.183.282-.5.485-.953.485-.465 0-.766-.203-.95-.485-.183-.27-.233-.601-.25-.918l.685-.101v.035c0 .215.015.45.101.617.082.149.2.25.414.25a.413.413 0 00.402-.25c.082-.168.118-.402.118-.617 0-.102-.036-.168-.051-.25.015 0 .05-.02.066-.02.215-.062.418-.148.617-.214.036.152.051.316.051.484"
                ></path>
                <path
                    fill="#e66823"
                    d="M109.613 155.184v2.804h-.683v-1.82h-.664v-.352c.097-.046.183-.082.28-.148a.393.393 0 00.184-.082c.27-.117.52-.25.786-.402zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M112.117 157.203a.413.413 0 01-.402.25c-.215 0-.332-.101-.414-.25-.086-.168-.102-.402-.102-.617 0-.234.016-.45.102-.617.082-.153.199-.25.414-.25.203 0 .336.097.402.25.082.168.117.383.117.617 0 .215-.035.45-.117.617m.551-1.601c-.184-.286-.5-.485-.953-.485-.465 0-.766.2-.95.485-.199.285-.25.632-.25.984 0 .348.051.7.25.984.184.282.485.485.95.485.453 0 .77-.203.953-.485.2-.285.25-.636.25-.984 0-.352-.05-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M115.27 157.203a.413.413 0 01-.403.25c-.215 0-.332-.101-.414-.25-.086-.168-.101-.402-.101-.617 0-.234.015-.45.101-.617.082-.153.2-.25.414-.25.203 0 .336.097.403.25.082.168.117.383.117.617 0 .215-.035.45-.117.617m.55-1.601c-.183-.286-.5-.485-.953-.485-.465 0-.765.2-.949.485-.2.285-.25.632-.25.984 0 .348.05.7.25.984.184.282.484.485.95.485.452 0 .769-.203.952-.485.2-.285.25-.636.25-.984 0-.352-.05-.7-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M118.156 155.184v1.367c-.219.25-.453.484-.687.734v-1.117h-.664v-.465c.414 0 .715-.234.765-.52zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M95.79 161.07c0 .22-.032.418-.083.618a9.467 9.467 0 01-.633-.368c0-.082.031-.148.031-.25 0-.215-.015-.433-.101-.597-.082-.168-.2-.27-.414-.27-.2 0-.336.102-.402.27-.032.066-.032.164-.051.265-.2-.133-.383-.285-.582-.433.035-.067.05-.149.082-.22.183-.28.5-.483.953-.483.465 0 .781.203.965.484.183.3.234.652.234.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M98.156 161.688c-.082.152-.199.269-.414.269a.427.427 0 01-.402-.27c-.082-.164-.117-.382-.117-.617 0-.215.035-.433.117-.597a.406.406 0 01.402-.27c.215 0 .332.102.414.27.086.164.102.382.102.597 0 .235-.016.453-.102.618m.551-1.602c-.2-.281-.5-.484-.965-.484-.449 0-.77.203-.953.484-.2.3-.25.652-.25.984 0 .352.05.703.25 1.004.102.133.219.25.367.332.02 0 .02 0 .035.016.082.035.164.066.25.101.102.016.2.032.301.032.465 0 .766-.215.965-.48.184-.302.234-.653.234-1.005 0-.332-.05-.683-.234-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M101.309 161.688c-.082.152-.2.269-.414.269-.204 0-.336-.117-.418-.27a1.701 1.701 0 01-.102-.617c0-.215.035-.433.102-.597.082-.168.214-.27.418-.27.214 0 .332.102.414.27.085.164.101.382.101.597 0 .235-.015.453-.101.618m.535-1.602c-.184-.281-.485-.484-.95-.484-.453 0-.769.203-.953.484-.199.3-.25.652-.25.984 0 .352.051.703.25 1.004.184.266.5.48.954.48.464 0 .765-.214.949-.48.199-.3.25-.652.25-1.004 0-.332-.051-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M102.832 160.2v.456h.672v1.824h.68v-2.808h-.586c-.047.285-.344.523-.766.527"
                ></path>
                <path
                    fill="#e66823"
                    d="M106.695 161.688c-.082.152-.199.269-.414.269-.203 0-.336-.117-.418-.27a1.701 1.701 0 01-.101-.617c0-.215.035-.433.101-.597.082-.168.215-.27.418-.27.215 0 .332.102.414.27.086.164.102.382.102.597 0 .235-.016.453-.102.618m.535-1.602c-.183-.281-.484-.484-.949-.484-.453 0-.77.203-.953.484-.2.3-.25.652-.25.984 0 .352.05.703.25 1.004.184.266.5.48.953.48.465 0 .766-.214.95-.48.199-.3.25-.652.25-1.004 0-.332-.051-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M109.848 161.688c-.082.152-.2.269-.418.269-.2 0-.332-.117-.414-.27a1.701 1.701 0 01-.102-.617c0-.215.035-.433.102-.597.082-.168.214-.27.414-.27.218 0 .336.102.418.27.082.164.101.382.101.597 0 .235-.02.453-.101.618m.535-1.602c-.184-.281-.5-.484-.953-.484-.45 0-.766.203-.95.484-.199.3-.25.652-.25.984 0 .352.051.703.25 1.004.184.266.5.48.95.48.453 0 .77-.214.953-.48.199-.3.25-.652.25-1.004 0-.332-.051-.683-.25-.984"
                ></path>
                <path
                    fill="#e66823"
                    d="M112.719 159.672v1.582c-.219.133-.453.285-.684.402v-1h-.668v-.453c.418-.015.715-.25.766-.531zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M115.004 159.652c-.453.352-.887.703-1.336 1 .031-.199.082-.398.2-.566.183-.281.5-.48.952-.48.067 0 .118.03.184.046"
                ></path>
                <path
                    fill="#e66823"
                    d="M93.172 148.531a11.48 11.48 0 01-.3-1.316"
                ></path>
                <path
                    fill="#e66823"
                    d="M120.688 152.648c-.047.153-.098.301-.184.434l-.047.05c.082-.167.148-.331.23-.484"
                ></path>
                <path
                    fill="#e66823"
                    d="M108.266 155.703c.097 0 .199-.02.28-.035-.097.066-.183.101-.28.148zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M109.516 155.184a7.851 7.851 0 01-.786.402c.02-.02.036-.02.051-.035.067-.051.133-.098.168-.168.051-.067.067-.133.082-.2zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M97.188 162.418a.838.838 0 00-.024-.012c.008.008.016.008.024.012"
                ></path>
                <path
                    fill="#3f91a3"
                    d="M149.914 147.488c0 4.133 2.59 6.348 5.516 6.348 3.382 0 4.62-2.293 5.035-3.719l3.117 1.352c-.867 2.59-3.457 5.633-8.152 5.633-5.254 0-9.121-4.13-9.121-9.614 0-5.633 3.941-9.574 9.086-9.574 4.808 0 7.285 3.004 8.035 5.746l-3.192 1.352c-.449-1.766-1.765-3.832-4.843-3.832-2.856 0-5.48 2.101-5.48 6.308"
                ></path>
                <path
                    fill="#3f91a3"
                    d="M166.988 164.086l4.504-9.54-7.77-16.07h4.051l5.633 12.465 5.41-12.464h3.793l-11.754 25.609zm0 0"
                ></path>
                <path
                    fill="#3f91a3"
                    d="M193.586 141.066c-3.117 0-5.371 2.442-5.371 6.348 0 3.906 2.254 6.457 5.37 6.457 3.227 0 5.33-2.55 5.33-6.457 0-3.906-2.063-6.348-5.33-6.348m-8.827 15.473v-27.187h3.492v11.68c.867-1.618 2.969-3.083 5.973-3.083 5.48 0 8.3 4.207 8.3 9.465 0 5.367-3.043 9.613-8.414 9.613-2.668 0-4.73-1.164-5.859-3.117v2.63zm0 0"
                ></path>
                <path
                    fill="#3f91a3"
                    d="M218.219 145.648c-.074-2.59-1.766-4.617-4.957-4.617-2.969 0-4.77 2.29-4.918 4.617zm3.566 5.747c-1.012 3.19-3.906 5.707-8.11 5.707-4.843 0-9.085-3.532-9.085-9.649 0-5.672 4.09-9.539 8.637-9.539 5.554 0 8.671 3.828 8.671 9.461 0 .453-.035.902-.074 1.129H208.23c.075 3.226 2.403 5.48 5.446 5.48 2.93 0 4.394-1.613 5.066-3.64zm0 0"
                ></path>
                <path
                    fill="#3f91a3"
                    d="M235.176 142.043a11.07 11.07 0 00-1.535-.113c-2.97 0-4.957 1.578-4.957 5.558v9.051h-3.532v-18.062h3.457v3.152c1.313-2.777 3.563-3.453 5.407-3.453.484 0 .976.074 1.16.113zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M246.164 147.227c0 2.964 1.992 4.617 4.36 4.617 2.214 0 3.453-1.39 3.867-2.778l5.03 1.54c-.823 3.265-3.905 6.496-8.898 6.496-5.558 0-10.027-4.094-10.027-9.875 0-5.82 4.36-9.914 9.84-9.914 5.144 0 8.148 3.152 8.973 6.496l-5.106 1.539c-.414-1.426-1.539-2.778-3.758-2.778-2.363 0-4.28 1.653-4.28 4.657"
                ></path>
                <path
                    fill="#e66823"
                    d="M273.484 143.508a7.855 7.855 0 00-1.804-.188c-2.29 0-4.356 1.352-4.356 5.07v8.15h-5.707v-18.665h5.52v2.516c.976-2.102 3.34-2.703 4.843-2.703.563 0 1.125.074 1.504.187zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M287.227 154.66c-.977 1.617-3.118 2.293-4.993 2.293-4.546 0-7.101-3.305-7.101-7.285v-11.793h5.707v10.59c0 1.8.98 3.23 3.004 3.23 1.918 0 3.117-1.316 3.117-3.191v-10.629h5.71v15.32c0 1.692.15 3.043.188 3.344h-5.484c-.074-.34-.148-1.39-.148-1.879"
                ></path>
                <path
                    fill="#e66823"
                    d="M300.723 147.227c0 2.964 1.988 4.617 4.355 4.617 2.215 0 3.457-1.39 3.871-2.778l5.031 1.54c-.828 3.265-3.906 6.496-8.902 6.496-5.555 0-10.023-4.094-10.023-9.875 0-5.82 4.355-9.914 9.84-9.914 5.14 0 8.144 3.152 8.972 6.496l-5.11 1.539c-.413-1.426-1.538-2.778-3.753-2.778-2.363 0-4.281 1.653-4.281 4.657"
                ></path>
                <path
                    fill="#e66823"
                    d="M316.188 137.875h5.707v18.664h-5.708zm2.816-9.05c1.879 0 3.383 1.503 3.383 3.343s-1.504 3.344-3.383 3.344c-1.8 0-3.305-1.504-3.305-3.344 0-1.84 1.504-3.344 3.305-3.344"
                ></path>
                <path
                    fill="#e66823"
                    d="M335.258 142.57c-2.215 0-4.242 1.465-4.242 4.618 0 3.082 2.027 4.656 4.242 4.656 2.219 0 4.207-1.54 4.207-4.656 0-3.153-1.988-4.618-4.207-4.618m-9.762 13.97v-27.188h5.598v10.289c.785-1.164 2.851-2.215 5.402-2.215 5.41 0 8.676 4.129 8.676 9.726 0 5.707-3.68 9.801-8.899 9.801-2.476 0-4.394-1.09-5.257-2.52v2.106zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M347.723 129.352h5.707v27.187h-5.707zm0 0"
                ></path>
                <path
                    fill="#e66823"
                    d="M369.059 144.973c-.075-1.426-1.051-3.153-3.758-3.153-2.403 0-3.602 1.762-3.719 3.153zm5.18 6.195c-.903 3.23-3.868 5.934-8.598 5.934-5.145 0-9.727-3.68-9.727-9.95 0-6.011 4.469-9.84 9.313-9.84 5.78 0 9.351 3.567 9.351 9.575 0 .789-.078 1.652-.113 1.765h-12.992c.109 2.102 2.027 3.606 4.242 3.606 2.066 0 3.23-.977 3.758-2.442zm0 0"
                ></path>
            </g>
        </svg>
    );
}

export default CCLogo;