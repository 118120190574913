import React, {Component, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import LockIcon from '@mui/icons-material/Lock';
import {
    addToGroupReactive,
    changeGroupCollectAgentTelemetrySettingReactive,
    changeZenGroupAssignedDistributionGroupIdReactive,
    changeZenGroupGroupNameReactive,
    changeZenGroupOrganizationNameReactive,
    createNewGroupReactive,
    createUserFromGMReactive,
    deleteSSLCertFromGroupReactive,
    editGroupAutoUpdateAgentsSettingReactive,
    editGroupLatestAgentVersionApprovedSettingReactive,
    editGroupSSLPinningModeReactive,
    editGroupWhcpReactive,
    getGroupManagementListReactive,
    getUsersInGroupListReactive,
    removeFromGroupReactive,
    singleRemoveZenGroupAssignedDistributionGroupIdReactive,
    updateGroupsAssignedDistributionGroupIdReactive,
    updateGroupsGridColumnStateReactive,
    updateGroupsGridFilterModelReactive,
    updateGroupsGridUseColumnStateReactive,
    updateGroupsGridUseFilterStateReactive,
    updateUserRolesReactive,
    uploadSSLCertReactive, editGroupPasswordExpirationPolicyReactive
} from "../api/groupsApi";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {ConfirmationModal} from "../../components/confirmationModal";
import SidebarMenu from "../../components/sideBarComponent";
import * as EmailValidator from "email-validator";
import Dropdown from "react-dropdown";
import {useLocation} from "react-router";
import {
    assignedDistributionGroupCellRendererForGroupsPage,
    assignedDistributionGroupValueFormatterForGroupsPage,
    distributionGroupEnableToggled,
    zenGroupFilterParamsValues
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    getDistributionGroups,
    getDistributionZenGroupFriendlyNames,
    getZenGroupSessionStorageOrDefault,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer, getEditIconComponent} from "../../utils/customCellEditor";
import {Link} from "react-router-dom";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/encryptDecryptHelper";
import {updateObjectInSessionStorage} from "../../utils/clientSideDataSessionStorageHelper";
import {getGroupAndUserInfoReactive} from "../api/rolesApi";
import {checkPermission} from "../../utils/permissionCheckHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, cellButtonTheme, iconButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Box, Button, FormControlLabel, IconButton, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox,
    MuiIconWithTooltip,
    MuiTextWithTooltip
} from "../../components/muiComponents";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

let gridColumnStateSessionVariableName = "groupsGridColumnState"
let doNotUpdateKeywordForApprovedVersion = "Do Not Update"


//no mandatory yet
const sslPinningModeOptions = [
    {
        value: "OFF",
        label: "Off",
    },
    {
        value: "ATTEMPT",
        label: "Attempt",
    },
]
const passwordPolicyFilterAndEditableOptionsMap = [
    {
        value: 90,
        label: "90 Days",
    },
    {
        value: 180,
        label: "180 Days",
    },
    {
        value: 365,
        label: "365 Days",
    },
    {
        value: null,
        label: "Never",
    },
]
const passwordPolicyLabelList = ["90 Days", "180 Days", "365 Days", "Never"]
export default function Groups() {
    const { register, watch, reset, handleSubmit } = useForm();
    const {
        handleSubmit: handleUser
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    //const [zenGroups, setZenGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [availableRoles, setAvailableRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [zenGroup, setZenGroup] = useState();
    const [showRolesModal, setShowRolesModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showUpdateRolesConfirmation, setShowUpdateRolesConfirmation] = useState(false);
    const [showManageGroupModal, setShowManageGroupModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showAddConfirmation, setShowAddConfirmation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showCreateGroupConfirmation, setShowCreateGroupConfirmation] = useState(false);
    const [showChannelOptions, setShowChannelOptions] = useState(false);
    const [internalPartnerUse, setInternalPartnerUse] = useState(true);
    const [distributionGroupChecked, setDistributionGroupChecked] = useState(true);
    const [whcpToggleChecked, setWhcpToggleChecked] = useState(true);
    const [autoAssignLicensesChecked, setAutoAssignLicensesChecked] = useState(false);
    const [newGroupCollectAgentTelemetryChecked, setNewGroupCollectAgentTelemetryChecked] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [distributionGroups, setDistributionGroups] = useState(getDistributionGroups(true));
    const [distributionGroupSelected, setDistributionGroupSelected] = useState();
    const [showReassignGroupsModal, setShowReassignGroupsModal] = useState(false);
    const [distributionGroupsForReassignModal, setDistributionGroupsForReassignModal] = useState([]);
    const [reassignGroupsModalGroupSelected, setReassignGroupsModalGroupSelected] = useState();
    const [groupsToReassign, setGroupsToReassign] = useState([]);
    const [selectedGroupsToReassign, setSelectedGroupsToReassign] = useState([]);
    const [zenGroupIdToUpdateDistributionGroup, setZenGroupIdToUpdateDistributionGroup] = useState();
    // eslint-disable-next-line no-unused-vars
    const [showRemoveAssignedDistributionGroupIdModal, setShowRemoveAssignedDistributionGroupIdModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupIdToRemoveDistributionGroupId, setZenGroupIdToRemoveDistributionGroupId] = useState();
    const [newGroupAutoUpdateToggled, setNewGroupAutoUpdateToggled] = useState(true);
    const [copyFromGroupToggled, setCopyFromGroupToggled] = useState(false);
    const [copyFromGroupSelected, setCopyFromGroupSelected] = useState();
    const [newGroupLatestApprovedAgentVersionSelected, setNewGroupLatestApprovedAgentVersionSelected] = useState();
    const [copyWhitelistsFromGroupToggled, setCopyWhitelistsFromGroupToggled] = useState(false);
    const [copyWhitelistsFromGroupSelected, setCopyWhitelistsFromGroupSelected] = useState();
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState();
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("groupsGridFilterState"));
    const whcpWikipediaLink =<p> <a href ="https://en.wikipedia.org/wiki/WHQL_Testing" rel="noreferrer" target="_blank">WHCP Wikipedia Page</a></p>
    const whcpMicrosoftLink = <p> <a href ="https://docs.microsoft.com/en-us/windows-hardware/drivers/install/whql-release-signature" rel="noreferrer" target="_blank">WHCP Microsoft Page</a></p>
    const zenGroupIdLocation = useLocation();
    const [showSSLModal, setShowSSLModal] = useState(false);
    const [newGroupSSLPinningModeSelected, setNewGroupSSLPinningModeSelected] = useState("OFF"); //OFF by default
    const [selectedGroupForSSLCertModal, setSelectedGroupForSSLCertModal] = useState();
    const [passwordExpirationPolicyForNewGroup, setPasswordExpirationPolicyForNewGroup] = useState("Never"); //Never by default
    const [sslCertGridRowData, setSSLCertGridRowData] = useState([]);
    const [sslCertGridSelectedRow, setSSLCertGridSelectedRow] = useState();
    const [showDeleteCertConfirmModal, setShowDeleteCertConfirmModal] = useState(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState(JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList")));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "friendlyName", name: "Group", width: 400,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                let isADistributionGroup = params.node.data.distributionGroup
                return (
                    <div id ="groupFriendlyNameCell" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                        <div className='mb-1'>
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="object-contain"
                                    icon="fa-duotone fa-user-gear"
                                    size="xs"
                                />
                            }
                            onClick={async () => {
                                setShowManageGroupModal(true)
                                if(params.node.data && params.node.data.zenGroupId){
                                    setZenGroup(params.node.data) //just in case we should setZenGroup here too
                                    try{
                                        let usersList = await getUsersInGroupListReactive(params.node.data.zenGroupId)
                                        if(usersList){
                                            setUsers(usersList)
                                        }
                                        else{
                                            setUsers([])
                                        }
                                    }
                                    catch (error){
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error retrieving the list of users in this group");
                                        }
                                        console.log(error)
                                        setUsers([])
                                    }
                                }
                                else{
                                    setUsers([]);
                                }
                            }}
                            tooltipTitle={"Click to manage this group"}
                            tooltipPlacement={"bottom-start"}
                        />
                        </div>
                        {getEditIconComponent(params, "Click to Edit this Group's Name", "friendlyName")}
                        { isADistributionGroup &&
                            <div className='mb-3'>
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-duotone fa-warehouse"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"This group is a Distribution Group. It can automatically supply licenses to linked child groups as necessary."}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        }
                        <MuiTextWithTooltip
                            text={params.value}
                            tooltipTitle={"Double click to change group name"}
                            tooltipPlacement={"right"}
                        >
                        </MuiTextWithTooltip>
                    </div>
                )
            },
            onUpdate: async (event) => {
                let newName = event.newValue;
                if(newName && newName.trim().length > 0){
                    if(!event.data.zenGroupId){
                        event.data.friendlyName = event.oldValue
                        event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                        return;
                    }
                    try{
                        let response = await changeZenGroupGroupNameReactive(newName.trim(), event.data.zenGroupId)
                        if(response){
                            NotificationManager.success("Successfully changed this group's name")
                            updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                event.data.zenGroupId, "friendlyName", newName.trim())
                        }
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error changing group name")
                        }
                        event.data.friendlyName = event.oldValue
                        event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                    }
                    //resetGrid()
                }
                else{
                    NotificationManager.error("Invalid Group Name")
                    event.data.friendlyName = event.oldValue
                    event.api.refreshCells({columns: ["friendlyName"], suppressFlash: true, rowNodes: [event.node]})
                }
            },
        },
        { field: "organizationName", name: "Organization Name", sortable: true,
            width: 325,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Group's Organization Name", "organizationName", true)
            },
            onUpdate: async (event) => {
                let newName = event.newValue;
                if(newName && newName.trim().length > 0){
                    if(!event.data.zenGroupId){
                        event.data.organizationName = event.oldValue
                        event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                        return;
                    }
                    try{
                        let response = await changeZenGroupOrganizationNameReactive(newName.trim(), event.data.zenGroupId)
                        if(response){
                            NotificationManager.success("Successfully changed this group's organization name")
                            updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                event.data.zenGroupId, "organizationName", newName.trim())
                        }
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error changing group's organization name")
                        }
                        event.data.organizationName = event.oldValue
                        event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                    }
                    //resetGrid()
                }
                else{
                    NotificationManager.error("Invalid Name")
                    //resetGrid()
                    event.data.organizationName = event.oldValue
                    event.api.refreshCells({columns: ["organizationName"], suppressFlash: true, rowNodes: [event.node]})
                }
            },
        },
        { field: "channelPartner", name: "Channel Partner",
            width: 290,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner', 'Direct Customer'],
                suppressSorting: false,
            },
            keyCreator: (params) => {
                if(params.node.data.channelPartner){
                    return "Channel Partner";
                }else{
                    return "Direct Customer";
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.channelPartner){
                    return "Channel Partner";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartner}
                                    name={`cellToggleChannelPartner${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            sortable: true
        },
        { field: "channelPartnerCustomer", name: "Channel Partner Customer",
            width: 325,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner Customer', 'Direct Customer'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.channelPartnerCustomer){
                    return "Channel Partner Customer";
                }else{
                    return "Direct Customer";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.channelPartnerCustomer){
                    return "Channel Partner Customer";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartnerCustomer}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                    name={`cellToggleChannelPartnerCustomer${params.node.data.zenGroupId}`}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
        { field: "distributionGroup", name: "Distribution Group",
            width: 300,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Distributor', 'Consumer'],
                suppressSorting: false,
            },
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                checked={params.node.data.distributionGroup}
                                name={`cellToggleDistributionGroup${params.node.data.zenGroupId}`}
                                onChange={(changeEvent) =>{
                                    setSelectedGroupsToReassign([])
                                    params.node.setDataValue("distributionGroup",!params.node.data.distributionGroup);
                                    distributionGroupEnableToggled(params,changeEvent,
                                        setZenGroupIdToUpdateDistributionGroup, setDistributionGroupsForReassignModal,
                                        setGroupsToReassign,setShowReassignGroupsModal).then(() =>{
                                    })

                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            keyCreator: (params) => {
                if(params.node.data.distributionGroup){
                    return "Distributor";
                }else{
                    return "Consumer";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.distributionGroup){
                    return "Distributor";
                }else{
                    return "Consumer";
                }
            },
        },
        { field: "sslPinningMode", name: "SSL Pinning Mode",
            width: 325,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: sslPinningModeOptions.map(
                    ({ value, label}) => label
                ),
                suppressSorting: false
            },
            cellRenderer: function (params) {
                let valueToShow = convertSSLPinningModeToFormattedValue(params.node.data.sslPinningMode)
                return (
                    <button id ="sslCell" className={"flex flex-nowrap items-center justify-between gap-x-2"}>
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="object-contain"
                                    icon="fa-duotone fa-user-gear"
                                    size="xs"
                                />
                            }
                            onClick={() => {
                                reset({sslFile: null})
                                setShowDeleteCertConfirmModal(false)
                                setShowSSLModal(true)
                                setSelectedGroupForSSLCertModal(params.node.data)
                                setSSLCertGridSelectedRow() //reset
                                if(params.node.data.sslPublicKeys){
                                    setSSLCertGridRowData(params.node.data.sslPublicKeys)
                                }
                                else{
                                    setSSLCertGridRowData([])
                                }
                            }}
                            tooltipTitle={"Click to manage SSL Certs"}
                            tooltipPlacement={"bottom-start"}
                        />
                        <MuiTextWithTooltip
                            text={valueToShow}
                            tooltipTitle={"Double click to edit"}
                            tooltipPlacement={"right"}
                        >
                        </MuiTextWithTooltip>
                    </button>
                )
            },
            keyCreator: function (params) {
                return convertSSLPinningModeToFormattedValue(params.node.data.sslPinningMode)
            },
            valueGetter: function (params) {
                return convertSSLPinningModeToFormattedValue(params.node.data.sslPinningMode)
            },
            sortable: true,
            cellEditorType: "agSelectCellEditor",
            editable: true,
            editableOptions: sslPinningModeOptions.map(
                ({ value, label}) => label
            ),
            onUpdate: async (value) => {
                try{
                    let zenGroupId = value.data.zenGroupId
                    let newValue = value.newValue
                    if(zenGroupId && newValue){
                        setIsLoading(true)
                        let modeToSend = sslPinningModeOptions.find(({value, label}) => label === newValue)
                        await editGroupSSLPinningModeReactive(zenGroupId, modeToSend.value)
                        NotificationManager.success("Successfully updated this group")
                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                            value.data.zenGroupId, "sslPinningMode", newValue)
                    }
                    else{
                        value.data.sslPinningMode = value.oldValue
                        value.api.refreshCells({suppressFlash: true})
                    }
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    value.data.sslPinningMode = value.oldValue
                    value.api.refreshCells({suppressFlash: true})
                }
                setIsLoading(false)
            },
        },
        { field: "assignedDistributionGroupName", name: "Source Distribution Group",
            width: 360,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: function(params){
                    params.success(getDistributionZenGroupFriendlyNames())
                },
                suppressSorting: true,
            },
            sortable: true,
            cellEditorPopup:true,
            cellEditorType: "agRichSelectCellEditor",
            cellEditorParams: (params) =>{
                return {values: distributionGroups.map(({id, friendlyName}) => friendlyName || id)};
            },
            editable: true,
            valueGetter: assignedDistributionGroupValueFormatterForGroupsPage,
            cellRenderer: assignedDistributionGroupCellRendererForGroupsPage,
            onUpdate: async (value) => {
                try{
                    let zenGroupId = value.data.zenGroupId
                    let newGroup = value.newValue
                    if(zenGroupId && newGroup){
                        if(newGroup === "(Double Click to Assign)"){
                            value.data.assignedDistributionGroupName = value.oldValue
                            value.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [value.node]})
                            //resetGrid()
                            return;
                        }
                        const newAssignedDistributionGroupId = getDistributionGroups().find(
                            (zg) => zg.friendlyName === newGroup || zg.id === newGroup
                        );
                        if(!newAssignedDistributionGroupId){
                            value.data.assignedDistributionGroupName = value.oldValue
                            value.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [value.node]})
                            //resetGrid()
                            return;
                        }
                        if(zenGroupId === newAssignedDistributionGroupId.id){
                            NotificationManager.error("You may not set a group's assigned distribution group to itself.")
                            value.data.assignedDistributionGroupName = value.oldValue
                            value.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [value.node]})
                            //resetGrid()
                            return;
                        }
                        setIsLoading(true)
                        await changeZenGroupAssignedDistributionGroupIdReactive(zenGroupId, newAssignedDistributionGroupId.id)
                        NotificationManager.success("Successfully updated this group")
                        value.node.data.assignedDistributionGroupId = newAssignedDistributionGroupId.id
                        value.node.data.assignedDistributionGroupName = newGroup
                        //new value does not display on cell without calling the refresh cells, this will only refresh this
                        // row's assignedDistributionGroupName/assignedDistributionGroupId cells
                        value.api.refreshCells({columns: ["assignedDistributionGroupName", "assignedDistributionGroupId"], suppressFlash: true, rowNodes: [value.node]})
                        //name
                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                            zenGroupId, "assignedDistributionGroupName", newGroup)
                        //id
                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                            zenGroupId, "assignedDistributionGroupId", newAssignedDistributionGroupId.id)
                    }
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Unexpected error updating this group")
                    }
                    value.data.assignedDistributionGroupName = value.oldValue
                    value.api.refreshCells({columns: ["assignedDistributionGroupName"], suppressFlash: true, rowNodes: [value.node]})
                }
                //resetGrid()
                setIsLoading(false)
            },

        },
        { field: "assignedDistributionGroupId", name: "Remove Assigned Distribution Group",
            width: 400,
            sortable: true,
            cellRenderer:
                function (params) {
                    if(params.node.data.assignedDistributionGroupId){
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        onClick={async () => {
                                            if(params.node.data.zenGroupId){
                                                setZenGroupIdToRemoveDistributionGroupId(params.node.data.zenGroupId)
                                                setIsLoading(true)
                                                try{
                                                    await singleRemoveZenGroupAssignedDistributionGroupIdReactive(params.node.data.zenGroupId)
                                                    NotificationManager.success("Successfully updated this group")
                                                    params.node.data.assignedDistributionGroupId = null
                                                    params.node.data.assignedDistributionGroupName = null
                                                    //need to refresh these two cells in row due to cells not updating with new null value
                                                    params.api.refreshCells({columns: ["assignedDistributionGroupName", "assignedDistributionGroupId"], suppressFlash: true, rowNodes: [params.node]})
                                                    //name
                                                    updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                        params.node.data.zenGroupId, "assignedDistributionGroupName", null)
                                                    //id
                                                    updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                        params.node.data.zenGroupId, "assignedDistributionGroupId", null)
                                                }
                                                catch(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Error updating this group")
                                                    }
                                                    //reset to old value
                                                    params.data.assignedDistributionGroupId = params.value
                                                }
                                                setIsLoading(false)
                                                //resetGrid()
                                            }
                                        }}
                                >Remove Association
                                </Button>
                            </ThemeProvider>
                        )
                    }
                    return null
                }
        },
        { field: "autoUpdate", name: "Always Install/Update Agents in Group with Latest Version",
            width: 520,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ["On", "Off"],
                suppressSorting: true,
            },
            sortable: true,
            keyCreator: (params) => {
                if(params.node.data.autoUpdate){
                    return "On"
                }
                else{
                    return "Off"
                }
            },
            valueGetter: (params) => {
                if(params.node.data.autoUpdate){
                    return "On"
                }
                else{
                    return "Off"
                }
            },
            tooltipValueGetter: (params) => {
                return "This setting controls if agents in this group should always update/install with the latest agent version regardless" +
                    " of the latest agent version approved in this group"
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.autoUpdate}
                                    name={`cellToggleAutoUpdate${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("autoUpdate", params.node.data.autoUpdate);
                                        }
                                        editGroupAutoUpdateAgentsSettingReactive(params.node.data.zenGroupId, !params.node.data.autoUpdate).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                params.node.data.zenGroupId, "autoUpdate", !params.node.data.autoUpdate)
                                            //manually change the toggle on success
                                            params.node.setDataValue("autoUpdate", !params.node.data.autoUpdate);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("autoUpdate", params.node.data.autoUpdate);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "latestAgentVersionApproved", name: "Latest Agent Version Approved for Updates/Installs", sortable: true,
            width: 500,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: false,
                suppressSorting: true, //we want values to be in descending order which we do before storing in session, so suppress ag grid sorting this list in asc order to show most recent versions first
                values: function(params){
                    try{
                        //should not use the distinctAgentVersionsList hook here to provide filter values because after testing the filter does not get the updated list when using the
                        // setDistinctAgentVersionsList in the useEffect above, so just do some processing here to provide the filter values
                        let agentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                        if(agentVersionsList === null){
                            distinctAgentVersionsReactive().then(data => {
                                //store list in descending order by version number
                                data?.sort()?.reverse()
                                data.unshift(doNotUpdateKeywordForApprovedVersion)
                                params.success(data)
                                encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                            }).catch(error => {})
                        }
                        params.success(agentVersionsList)
                    }catch (e) {
                        params.success([])
                    }
                },
            },
            tooltipValueGetter: (params) => {
                return "If the always update/install agents with the latest version setting is turned off, this setting controls what version agents in this group" +
                    " are allowed to update to and install with. This setting will not downgrade agents."
            },
            editable: function(params){
                return params.node.data.autoUpdate === false;
            },
            valueGetter: function (params) {
                if(params.node.data.autoUpdate === true && distinctAgentVersionsList?.length >= 2 ){
                    try{
                        return distinctAgentVersionsList[1]
                    } catch (e) {
                        //default to value in row data if error accessing list
                        return params.node.data.latestAgentVersionApproved
                    }
                }
                else{
                    return params.node.data.latestAgentVersionApproved
                }
            },
            cellEditorType: "agSelectCellEditor",
            editableOptions: distinctAgentVersionsList,
            onUpdate: async (params) => {
                let zenGroupId = params.data.zenGroupId
                let newValue = params.newValue
                let oldValue = params.oldValue
                if(zenGroupId && newValue && newValue !== oldValue){
                    editGroupLatestAgentVersionApprovedSettingReactive(zenGroupId, newValue).then(response => {
                        NotificationManager.success("Successfully updated this group")
                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                            params.data.zenGroupId, "latestAgentVersionApproved", newValue)
                    }).catch((error) => {
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Unexpected error updating this group")
                        }
                        params.data.latestAgentVersionApproved = oldValue
                        params.api.refreshCells({suppressFlash: true, rowNodes: [params.node]})
                    })
                }
                else{
                    params.data.latestAgentVersionApproved = oldValue
                    params.api.refreshCells({suppressFlash: true, rowNodes: [params.node]})
                }
            },
        },
        { field: "whcpOnly", name: "Use WHCP Verified Drivers", filter: 'agSetColumnFilter',
            width: 325,
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Require Validation', 'Do Not Require Validation'],
                suppressSorting: true
            },
            keyCreator: (params) => {
                if(params.node.data.whcpOnly){
                    return "Require Validation"
                }
                else{
                    return "Do Not Require Validation"
                }
            },
            valueGetter: (params) => {
                if(params.node.data.whcpOnly){
                    return "Require Validation"
                }
                else{
                    return "Do Not Require Validation"
                }
            },
            //headerComponentFramework:customerHeaderforUseWHCPDrivers,
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.whcpOnly}
                                    name={`cellToggleWHCPOnly${params.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("whcpOnly", params.node.data.whcpOnly);
                                        }
                                        editGroupWhcpReactive(params.node.data.zenGroupId, !params.node.data.whcpOnly).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                params.node.data.zenGroupId, "whcpOnly", !params.node.data.whcpOnly)
                                            //manually change the toggle on success
                                            params.node.setDataValue("whcpOnly", !params.node.data.whcpOnly);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("whcpOnly", params.node.data.whcpOnly);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "collectAgentTelemetry", name: "Collect Agent Telemetry",
            width: 325,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Collect', 'Do Not Collect'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.collectAgentTelemetry){
                    return "Collect"
                }
                else{
                    return "Do Not Collect"
                }
            },
            valueGetter: (params) => {
                if(params.node.data.collectAgentTelemetry){
                    return "Collect"
                }
                else{
                    return "Do Not Collect"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.collectAgentTelemetry}
                                    name={`cellToggle${params.node.data.zenGroupId}`}
                                    onChange={(changeEvent) => {
                                        if(!params.node.data.zenGroupId){
                                            NotificationManager.error(`Unexpected error making this request`);
                                            //reset toggle
                                            params.node.setDataValue("collectAgentTelemetry", params.node.data.collectAgentTelemetry);
                                        }
                                        changeGroupCollectAgentTelemetrySettingReactive(params.node.data.zenGroupId, !params.node.data.collectAgentTelemetry).then(response => {
                                            NotificationManager.success(`Successfully updated this setting`);
                                            updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                params.node.data.zenGroupId, "collectAgentTelemetry", !params.node.data.collectAgentTelemetry)
                                            //manually change the toggle on success
                                            params.node.setDataValue("collectAgentTelemetry", !params.node.data.collectAgentTelemetry);
                                        }).catch(function(error){
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error making this request`);
                                            }
                                            //reset toggle
                                            params.node.setDataValue("collectAgentTelemetry", params.node.data.collectAgentTelemetry);
                                        })
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "passwordExpirationPolicyInDays", name: "User Password Expiration Policy",
            width: 350,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: passwordPolicyFilterAndEditableOptionsMap.map(
                    ({ value, label}) => label
                ),
                suppressSorting: true,
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                let sortValueA = getPasswordExpirationValueForCellSort(nodeA.data.passwordExpirationPolicyInDays)
                let sortValueB = getPasswordExpirationValueForCellSort(nodeB.data.passwordExpirationPolicyInDays)

                //if the passwordExpirationPolicyInDays is null that means never, so assign sortValueA and sortValueB a large value to use so that sort will return as expected on grid for never
                if(sortValueA === null || sortValueA === undefined){
                    sortValueA = 7300 //20 years in days, does not matter what value just needs to be greater than the highest allowable number of days for this field
                }
                if(sortValueB === null || sortValueB === undefined){
                    sortValueB = 7300 //20 years in days, does not matter what value just needs to be greater than the highest allowable number of days for this field
                }
                return (sortValueA === sortValueB) ? 0 : (sortValueA > sortValueB) ? 1 : -1;
            },
            keyCreator: (params) => {
                return getPasswordExpirationToDisplayInCell(params.node.data.passwordExpirationPolicyInDays)
            },
            valueGetter: (params) => {
                return getPasswordExpirationToDisplayInCell(params.node.data.passwordExpirationPolicyInDays)
            },
            cellEditorType: "agRichSelectCellEditor",
            editable: true,
            cellEditorPopup:true,
            editableOptions: passwordPolicyFilterAndEditableOptionsMap.map(
                ({ value, label}) => label
            ),
            sortable: true,
            onUpdate: (params) => {
                let zenGroupId = params.data.zenGroupId
                let newValue = params.newValue
                if(zenGroupId && newValue){
                    setIsLoading(true)
                    let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === newValue)
                    editGroupPasswordExpirationPolicyReactive(zenGroupId, valueToSend.value).then(result => {
                        NotificationManager.success("Successfully updated this group")
                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                            params.data.zenGroupId, "passwordExpirationPolicyInDays", newValue)
                        setIsLoading(false)
                    }).catch(error => {
                        setIsLoading(false)
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Unexpected error updating this group")
                        }
                        params.data.passwordExpirationPolicyInDays = params.oldValue
                        params.api.refreshCells({columns: ["passwordExpirationPolicyInDays"], suppressFlash: true, rowNodes: [params.node]})
                    })
                }
                else{
                    params.data.passwordExpirationPolicyInDays = params.oldValue
                    params.api.refreshCells({columns: ["passwordExpirationPolicyInDays"], suppressFlash: true, rowNodes: [params.node]})
                }
            },
        },
    ])

    useEffect(()  => {
        async function openManageGroupModal () {
            let zenGroupClickId = null
            if(zenGroupIdLocation && zenGroupIdLocation.state){
                zenGroupClickId = zenGroupIdLocation.state.zenGroupClickId
            }
            if (zenGroupClickId  !== null && zenGroupClickId !== undefined){
                let zenGroupList = getZenGroupSessionStorageOrDefault()
                let zenGroupMatched = null
                for (const currentZenGroup of zenGroupList) {
                    if (currentZenGroup.id === zenGroupClickId) {
                        zenGroupMatched = currentZenGroup
                        //the groupManagementListReactive endpoint used to supply this page's grid data has the key of zenGroupId for the id, and the groups from getZenGroupSessionStorageOrDefault
                        // have "id" for the key. So to keep standard on this page and the zenGroup hook, make a "zenGroupId" key/value in the zenGroupMatched variable so nothing breaks
                        zenGroupMatched["zenGroupId"] = zenGroupMatched.id
                        break
                    }
                }
                //if zenGroupMatched is not null, then proceed
                if(zenGroupMatched){
                    setZenGroup(zenGroupMatched)
                    setShowManageGroupModal(true)
                    try{
                        let usersList = await getUsersInGroupListReactive(zenGroupClickId)
                        if(usersList){
                            setUsers(usersList)
                        }
                        else{
                            setUsers([])
                        }
                    }
                    catch (error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error retrieving the list of users in this group");
                        }
                        console.log(error)
                        setUsers([])
                    }
                }
            }else {
                // do nothing since the click is not from an any icon
            }
        }
        openManageGroupModal();
    },[zenGroupIdLocation])

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            let isMemberOfChannelPartnerGroup = false;
            zenGroupSessionStorage.forEach(zenGroupFromSessionStorage => {
                if (zenGroupFromSessionStorage.channelPartner === true) {
                    isMemberOfChannelPartnerGroup = true;
                }
            })
            if(isMemberOfChannelPartnerGroup){
                setShowChannelOptions(true)
            }
            else{
                setShowChannelOptions(false)
            }
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        //only runs if distinctAgentVersionsList is null from JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
        let controller = new AbortController();
        (async () => {
            if(distinctAgentVersionsList === null){
                try{
                    let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                    if(sessionAgentVersionsList === null){
                        distinctAgentVersionsReactive().then(data => {
                            //store list in descending order by version number
                            data?.sort()?.reverse()
                            data.unshift(doNotUpdateKeywordForApprovedVersion)
                            setDistinctAgentVersionsList(data)
                            columnDefs?.forEach((col) => {
                                if(col && col.field === "latestAgentVersionApproved"){
                                    col.editableOptions = {values: data}
                                    //have to reset the valueGetter with the data list of distinct agent versions since col defs are in hook they won't get the updated hook from setDistinctAgentVersionsList
                                    col.valueGetter = function(params) {
                                        if(params.node.data.autoUpdate === true && data?.length >= 2 ){
                                            try{
                                                return data[1]
                                            } catch (e) {
                                                //default to value in row data if error accessing list
                                                return params.node.data.latestAgentVersionApproved
                                            }
                                        }
                                        else{
                                            return params.node.data.latestAgentVersionApproved
                                        }
                                    }
                                }
                            })
                            encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                        }).catch(error => {})
                    }
                    else{
                        setDistinctAgentVersionsList(sessionAgentVersionsList)
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "latestAgentVersionApproved"){
                                col.editableOptions = {values: sessionAgentVersionsList}
                                //have to reset the valueGetter with the sessionAgentVersionsList list of distinct agent versions since col defs are in hook they won't get the updated hook from setDistinctAgentVersionsList
                                col.valueGetter = function(params) {
                                    if(params.node.data.autoUpdate === true && sessionAgentVersionsList?.length >= 2 ){
                                        try{
                                            return sessionAgentVersionsList[1]
                                        } catch (e) {
                                            //default to value in row data if error accessing list
                                            return params.node.data.latestAgentVersionApproved
                                        }
                                    }
                                    else{
                                        return params.node.data.latestAgentVersionApproved
                                    }
                                }
                            }
                        })
                    }
                } catch (e) {}
            }
        })()
        return () => controller?.abort();
    }, [])
    const resetFormFields = () => {
        reset({
            newGroup:"",
            newUser:""
        })
    }

    const onCreateGroup = async () => {
        try {
            const newGroup = watch("newGroup");
            if (newGroup) {
                if(newGroup.trim().length > 0 && newGroupSSLPinningModeSelected){
                    if(autoAssignLicensesChecked){ //only pass 'distributionGroupSelected' if 'autoAssignLicensesChecked' is toggled
                        if(!distributionGroupSelected){
                            NotificationManager.info("Please select a distribution group before continuing.");
                            return;
                        }
                        if(copyFromGroupToggled && !copyFromGroupSelected){
                            NotificationManager.info("Please select a group to add all users and permissions from before continuing.");
                            return;
                        }
                        if(copyWhitelistsFromGroupToggled && !copyWhitelistsFromGroupSelected){
                            NotificationManager.info("Please select a group to add all whitelists from before continuing.");
                            return;
                        }
                        if(!newGroupAutoUpdateToggled && !newGroupLatestApprovedAgentVersionSelected){
                            NotificationManager.info("Please select the latest agent version approved for agents in this group to update to automatically.");
                            return;
                        }
                        let latestApprovedAgentVersionToSend = null
                        if(!newGroupAutoUpdateToggled){
                            latestApprovedAgentVersionToSend = newGroupLatestApprovedAgentVersionSelected
                        }
                        let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === passwordExpirationPolicyForNewGroup)

                        setIsLoading(true)
                        await createNewGroupReactive(newGroup.trim(), internalPartnerUse, distributionGroupChecked, distributionGroupSelected, copyFromGroupToggled ? copyFromGroupSelected : null,
                            copyWhitelistsFromGroupToggled ? copyWhitelistsFromGroupSelected : null,
                            newGroupCollectAgentTelemetryChecked, whcpToggleChecked, newGroupSSLPinningModeSelected, latestApprovedAgentVersionToSend, valueToSend.value)
                        }
                    else{
                        if(copyFromGroupToggled && !copyFromGroupSelected){
                            NotificationManager.info("Please select a group to add all users and permissions from before continuing.");
                            return;
                        }
                        if(copyWhitelistsFromGroupToggled && !copyWhitelistsFromGroupSelected){
                            NotificationManager.info("Please select a group to add all whitelists from before continuing.");
                            return;
                        }
                        if(!newGroupAutoUpdateToggled && !newGroupLatestApprovedAgentVersionSelected){
                            NotificationManager.info("Please select the latest agent version approved for agents in this group to update to automatically.");
                            return;
                        }
                        let latestApprovedAgentVersionToSend = null
                        if(!newGroupAutoUpdateToggled){
                            latestApprovedAgentVersionToSend = newGroupLatestApprovedAgentVersionSelected
                        }
                        let valueToSend = passwordPolicyFilterAndEditableOptionsMap.find(({value, label}) => label === passwordExpirationPolicyForNewGroup)

                        setIsLoading(true)
                        await createNewGroupReactive(newGroup.trim(), internalPartnerUse, distributionGroupChecked, null, copyFromGroupToggled ? copyFromGroupSelected : null,
                            copyWhitelistsFromGroupToggled ? copyWhitelistsFromGroupSelected : null, newGroupCollectAgentTelemetryChecked,whcpToggleChecked, newGroupSSLPinningModeSelected, latestApprovedAgentVersionToSend,
                            valueToSend.value)
                    }
                    NotificationManager.success("Group created successfully!");
                    resetGrid()
                    resetCreateGroupModalFields()
                }
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error creating this group, please try again.");
            }
        }
        setZenGroup(null);
        setIsLoading(false);
        setShowCreateGroupConfirmation(false);
    };

    const onUnexistingUser = async () => {
        try {
            const newUser = watch("newUser");
            if (newUser && zenGroup && zenGroup.zenGroupId) {
                setIsLoading(true);
                const response = await createUserFromGMReactive(newUser.trim(), zenGroup.zenGroupId);
                if(response.userExistsAlready === false){
                    //success
                    await resetUsersData()
                    setUser(null)
                    NotificationManager.success("Successfully created this user");
                    resetFormFields()
                    //need to update the roles grid roleGridData sessionStorage variable for the new user created then added to the group
                    getGroupAndUserInfoReactive().then(response => {
                        encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                    }).catch(error => {})
                }
                else if(response.userExistsAlready === true){
                    //user exists already
                    NotificationManager.error("Error, this user exists already");
                }
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error creating this user");
            }
        }
        setIsLoading(false);
    };

    const onAddUser = async () => {
        try {
            const newUser = watch("newUser");
            if (newUser && newUser.trim().length > 0 && zenGroup && zenGroup.zenGroupId) {
                let email = newUser.trim();
                if(!EmailValidator.validate(email)){
                    NotificationManager.error("Please enter a valid email address")
                    return;
                }
                setIsLoading(true);
                const response = await addToGroupReactive(email, zenGroup.zenGroupId);
                if(response.userToAddExists === true){
                    await resetUsersData()
                    NotificationManager.success(`Successfully added ${email.toLowerCase()} to this group`);
                    resetFormFields()
                    setUser(null)
                    //need to update the roles grid roleGridData session storage variable for new user added to group
                    getGroupAndUserInfoReactive().then(response => {
                        encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                    }).catch(error => {})
                }
                else if(response.userToAddExists === false){
                    await onUnexistingUser()
                }
            }
        } catch (error) {
            if (error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error adding user to group");
            }
        }
        setIsLoading(false);
        setShowAddConfirmation(false);
    };

    const onRemoveUser = async () => {
        try {
            if (user) {
                setIsLoading(true);
                await removeFromGroupReactive(
                    user.username,
                    zenGroup.zenGroupId
                );
                await resetUsersData()
                NotificationManager.success(`Successfully removed ${user.username} from this group`);
                //need to update the roles grid roleGridData session storage variable for user removed from group
                getGroupAndUserInfoReactive().then(response => {
                    encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
                }).catch(error => {})
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error removing user from this group");
            }
        }
        setIsLoading(false);
        setUser(null);
        setShowRemoveConfirmation(false);
    };

    const onUpdateRoles = async () => {
        try {
            if (user && zenGroup && zenGroup.zenGroupId) {
                setIsLoading(true);
                await updateUserRolesReactive(
                    user.username,
                    zenGroup.zenGroupId,
                    userRoles
                );
                setShowRolesModal(false);
                //setUser(null);
                NotificationManager.success(`Successfully updated ${user.username}'s assigned roles`);
                resetFormFields()
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error updating user's assigned roles");
            }
        }
        setIsLoading(false);
        setShowUpdateRolesConfirmation(false);
    };

    const resetUsersData = async () => {
        if(zenGroup && zenGroup.zenGroupId){
            try{
                let usersList = await getUsersInGroupListReactive(zenGroup.zenGroupId)
                if(usersList){
                    setUsers(usersList)
                }
                else{
                    setUsers([])
                }
            }
            catch (error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving the list of users in this group");
                }
                console.log(error)
                setUsers([])
            }
        }
        else{
            setUsers([]);
        }
    }

    const onUploadSSLSubmit = (data) => {
        if(data && data.sslFile && data.sslFile.length > 0 && selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.zenGroupId){
            setIsLoading(true)
            uploadSSLCertReactive(data.sslFile[0], selectedGroupForSSLCertModal.zenGroupId).then(response => {
                NotificationManager.success("Successfully uploaded this cert")
                reset({sslFile: null})
                setSSLCertGridRowData(response.data.sslPublicKeys)
                selectedGroupForSSLCertModal.sslPublicKeys = response.data.sslPublicKeys
                updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                    selectedGroupForSSLCertModal.zenGroupId, "sslPublicKeys", response.data.sslPublicKeys)
                setSelectedGroupForSSLCertModal(selectedGroupForSSLCertModal)
                setSSLCertGridSelectedRow()
                setIsLoading(false)
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error uploading this certificate")
                }
                setIsLoading(false)
            })
        }
    };

    function resetCreateGroupModalFields(){
        setShowCreateGroupModal(false)
        resetFormFields()
        setDistributionGroupChecked(true)
        setInternalPartnerUse(true)
        setNewGroupCollectAgentTelemetryChecked(true)
        setAutoAssignLicensesChecked(false)
        setDistributionGroupSelected()
        setCopyFromGroupToggled(false)
        setCopyWhitelistsFromGroupToggled(false)
        setNewGroupAutoUpdateToggled(true)
        setCopyFromGroupSelected()
        setNewGroupLatestApprovedAgentVersionSelected()
        setCopyWhitelistsFromGroupSelected()
        setWhcpToggleChecked(true)
        setNewGroupSSLPinningModeSelected("OFF")
        setPasswordExpirationPolicyForNewGroup("Never")
    }

    function getPasswordExpirationToDisplayInCell(passwordExpirationPolicyInDays){
        if(passwordExpirationPolicyInDays){
            //need to check for the number and the strings in passwordPolicyFilterAndEditableOptions because these are the values from the cell editor
            switch(passwordExpirationPolicyInDays) {
                case 90: case "90 Days":
                    return '90 Days'
                case 180: case "180 Days":
                    return '180 Days'
                case 365: case "365 Days":
                    return '365 Days'
                case 'Never':
                    return 'Never'
                default:
                    return null;
            }
        }
        else{
            return 'Never'
        }
    }
    function getPasswordExpirationValueForCellSort(passwordExpirationPolicyInDays){
        if(passwordExpirationPolicyInDays){
            //need to check for the number and the strings in passwordPolicyFilterAndEditableOptions because these are the values from the cell editor
            switch(passwordExpirationPolicyInDays) {
                case 90: case "90 Days":
                    return 90
                case 180: case "180 Days":
                    return 180
                case 365: case "365 Days":
                    return 365
                case null: case 'Never':
                    return null
                default:
                    return null;
            }
        }
        else{
            return null;
        }
    }

    let newUser = watch("newUser");
    const newGroup = watch("newGroup");

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Groups</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create New Group Modal*/}
            <Modal contentLabel="Create New Group Modal"
                   isOpen={showCreateGroupModal}
                   onRequestClose={() => {
                       resetCreateGroupModalFields()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Create New Group</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetCreateGroupModalFields()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="flex flex-1 flex-col gap-y-5">
                        <label>Group Name</label>
                        <input
                            type="text"
                            name="newGroup"
                            {...register("newGroup")}
                            className="focus:outline-none h-10 p-2 w-full rounded-lg border border-black border-opacity-25 border-solid"
                            placeholder={'New Group Name'}
                        />
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={distributionGroupChecked}
                                        name="toggle3"
                                        onChange={e => setDistributionGroupChecked(e.target.checked)}
                                    />
                                } label={distributionGroupChecked ? "Distribution Group" : "No Distribution"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${showChannelOptions ? 'block' : 'hidden'} flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={internalPartnerUse}
                                        name="toggle4"
                                        onChange={e => setInternalPartnerUse(e.target.checked)}
                                    />
                                } label={internalPartnerUse ? "Internal Partner Use" : "Customer Use"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={newGroupCollectAgentTelemetryChecked}
                                        name="agentTelemetryToggleNewGroup"
                                        onChange={e => setNewGroupCollectAgentTelemetryChecked(e.target.checked)}
                                    />
                                } label={newGroupCollectAgentTelemetryChecked ? "Collect Telemetry For Agents in This Group" : "Do Not Collect Telemetry For Agents in This Group"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={autoAssignLicensesChecked}
                                        name="toggle5"
                                        onChange={e => setAutoAssignLicensesChecked(e.target.checked)}
                                    />
                                } label={autoAssignLicensesChecked ? "Auto-Allocate Licenses" : "Do Not Auto-Allocate Licenses"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${autoAssignLicensesChecked ? 'block' : 'hidden'}`}>
                            <label>Select distribution group to allocate from</label>
                            <Dropdown
                                options={distributionGroups.map(({id,friendlyName}) => ({
                                        value: id,
                                        label:friendlyName,
                                    })
                                )}
                                value={distributionGroupSelected}
                                onChange={({ value }) => {
                                    setDistributionGroupSelected(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={copyFromGroupToggled}
                                        name="toggleCopyFromGroup"
                                        onChange={e => setCopyFromGroupToggled(e.target.checked)}
                                    />
                                } label={copyFromGroupToggled ? "Add All Users and Permissions from Another Group" : "Do Not Add All Users and Permissions from Another Group"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${copyFromGroupToggled ? 'block' : 'hidden'}`}>
                            <label>Select which group to add users and permissions from</label>
                            <Dropdown
                                options={zenGroupSessionStorage.map(({ id, friendlyName }) => ({
                                    value: id,
                                    label: friendlyName || id,
                                }))}
                                value={copyFromGroupSelected}
                                onChange={({ value }) => {
                                    setCopyFromGroupSelected(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={copyWhitelistsFromGroupToggled}
                                        name="toggleCopyWhitelistsFromGroup"
                                        onChange={e => setCopyWhitelistsFromGroupToggled(e.target.checked)}
                                    />
                                } label={copyWhitelistsFromGroupToggled ? "Add All Whitelists from Another Group" : "Do Not Add All Whitelists from Another Group"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${copyWhitelistsFromGroupToggled ? 'block' : 'hidden'}`}>
                            <label>Select which group to add whitelists from</label>
                            <Dropdown
                                options={zenGroupSessionStorage.map(({ id, friendlyName }) => ({
                                    value: id,
                                    label: friendlyName || id,
                                }))}
                                value={copyWhitelistsFromGroupSelected}
                                onChange={({ value }) => {
                                    setCopyWhitelistsFromGroupSelected(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={`flex flex-row items-center`} >
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={newGroupAutoUpdateToggled}
                                        name="toggleAutoUpdateAgents"
                                        onChange={e => setNewGroupAutoUpdateToggled(e.target.checked)}
                                    />
                                } label={newGroupAutoUpdateToggled ? "Always Update Agents in Group to Newest Version" : "Do Not Update Agents in Group to Newest Version Automatically"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${newGroupAutoUpdateToggled ? 'hidden' : 'block'}`}>
                            <label>Select the latest agent version approved for agents in this group to update to automatically</label>
                            <Dropdown
                                options={distinctAgentVersionsList}
                                value={newGroupLatestApprovedAgentVersionSelected}
                                onChange={({ value }) => {
                                    setNewGroupLatestApprovedAgentVersionSelected(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={whcpToggleChecked}
                                        name="wchpDriversOnlyNewGroupToggle"
                                        onChange={e => setWhcpToggleChecked(e.target.checked)}
                                    />
                                } label={whcpToggleChecked ? `Use Only WHCP Verified Drivers` : "Do Not Use WHCP Verified Drivers"}/>
                            </ThemeProvider>
                            <div className="mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={<div>Learn More About the WHCP Verification Process Here: {whcpWikipediaLink} {whcpMicrosoftLink}</div>}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        </div>
                        <div className={``}>
                            <div className={`flex flex-row items-center`}>
                                <label>Select SSL Pinning Mode</label>
                                <div className="ml-1 mt-2">
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={`This option is for environments with SSL proxying`}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                </div>
                            </div>
                            <Dropdown
                                options={sslPinningModeOptions.map(({ value, label }) => ({
                                    value: value,
                                    label: label
                                }))}
                                value={newGroupSSLPinningModeSelected}
                                onChange={({ value }) => {
                                    setNewGroupSSLPinningModeSelected(value)
                                }}
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={``}>
                            <label>User Password Expiration Policy</label>
                            <Dropdown
                                options={passwordPolicyLabelList}
                                value={passwordExpirationPolicyForNewGroup}
                                onChange={({ value }) => {
                                    setPasswordExpirationPolicyForNewGroup(value)
                                }}
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                    onClick={() => {
                                        if(newGroup){
                                            if(newGroup.trim().length > 0){
                                                if(newGroupSSLPinningModeSelected){
                                                    onCreateGroup()
                                                }
                                                else{
                                                    NotificationManager.error("Please select the SSL Pinning Mode for new group");
                                                }
                                            }
                                            else{
                                                NotificationManager.error("Invalid Group Name");
                                            }
                                        }
                                    }}>
                                    Create
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            {/*Upload ssl modal*/}
            <Modal contentLabel="Manage SSL Cert for Group"
                   isOpen={showSSLModal}
                   onRequestClose={() => {
                       setShowSSLModal(false)
                       reset({sslFile: null})
                       setSelectedGroupForSSLCertModal(null)
                       setShowDeleteCertConfirmModal(false)
                       setSSLCertGridSelectedRow() //reset
                       setSSLCertGridRowData([])
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-7xl max-w-7xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <ConfirmationModal
                    text={`You are about to delete this certificate from the current group, would you like to continue?`}
                    onConfirm={() => {
                        if(sslCertGridSelectedRow && sslCertGridSelectedRow.sha256Base64 && selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.zenGroupId){
                            if(checkPermission(selectedGroupForSSLCertModal.zenGroupId, "groupManager", "deleteSSLCert")) {
                                if(sslCertGridSelectedRow.userRemovable) {
                                    setIsLoading(true)
                                    deleteSSLCertFromGroupReactive(selectedGroupForSSLCertModal.zenGroupId, sslCertGridSelectedRow.sha256Base64).then(result => {

                                        NotificationManager.success("Successfully deleted this certificate")

                                        if (selectedGroupForSSLCertModal.sslPublicKeys && selectedGroupForSSLCertModal.sslPublicKeys.includes(sslCertGridSelectedRow)) {
                                            selectedGroupForSSLCertModal.sslPublicKeys = selectedGroupForSSLCertModal.sslPublicKeys.filter(function (value, index, arr) {
                                                return value !== sslCertGridSelectedRow;
                                            })
                                        }
                                        setSSLCertGridRowData(selectedGroupForSSLCertModal.sslPublicKeys)

                                        updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                            selectedGroupForSSLCertModal.zenGroupId, "sslPublicKeys", selectedGroupForSSLCertModal.sslPublicKeys)
                                        setSelectedGroupForSSLCertModal(selectedGroupForSSLCertModal)
                                        setShowDeleteCertConfirmModal(false)
                                        setSSLCertGridSelectedRow()
                                        setIsLoading(false)
                                    }).catch(function (error) {
                                        if (error.message) {
                                            NotificationManager.error(error.message);
                                        } else {
                                            NotificationManager.error("Error deleting this certificate from group");
                                        }
                                        setIsLoading(false)
                                    })
                                }
                                else{
                                    NotificationManager.error("This certificate cannot be deleted");
                                }
                            }
                            else{
                                NotificationManager.error("You do not have Permission to delete this certificate from group");
                            }
                        }
                    }}
                    onClose={() => {
                        setShowDeleteCertConfirmModal(false);
                    }}
                    opened={showDeleteCertConfirmModal}
                />
                <div className={"flex flex-1 flex-col gap-y-3"}>
                    <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onUploadSSLSubmit)}>
                        <div className="flex flex-1 flex-col gap-y-3">
                            {/*Title with exit button*/}
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">Manage SSL Certificates</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        setShowSSLModal(false)
                                        reset({sslFile: null})
                                        setSelectedGroupForSSLCertModal(null)
                                        setShowDeleteCertConfirmModal(false)
                                        setSSLCertGridSelectedRow() //reset
                                        setSSLCertGridRowData([])
                                    }}
                                />
                            </div>
                            <hr className="mt-3 h-0.5" />
                            {/*Form content*/}
                            <div className="ml-1 mt-5">
                                <label className="font-bold text-2xl">Current Group: {selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.friendlyName}</label>
                            </div>
                            <div className="ml-1 mt-5 flex flex-col gap-y-3 w-min">
                                <label>Upload SSL Cert File:</label>
                                <input className={'ml-2 w-min'} type="file" name="sslFile"
                                       {...register("sslFile")}
                                       required={true}
                                       accept=".crt,.pem,.der"
                                />
                            </div>
                            <div className="ml-1 mt-3">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            color={"primary"}
                                            type={"submit"}
                                            startIcon={<FontAwesomeIcon
                                                className="object-contain text-black mr-2"
                                                icon="fa-light fa-upload"
                                                size="1x"
                                            /> }>
                                    Upload SSL Cert
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                    <hr className="h-0.5 ml-10 mr-4" />
                    <div className="flex flex-col w-full mb-5 gap-y-3">
                        <label className="font-bold text-2xl ml-10">Existing SSL Certs for Group</label>
                        <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3 ml-11">
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>}
                                            tooltipTitle={"Delete Cert"}
                                            tooltipPlacement={"top"}
                                            disabled={!sslCertGridSelectedRow}
                                            onClick={() => {
                                                if(sslCertGridSelectedRow && sslCertGridSelectedRow.sha256Base64 && selectedGroupForSSLCertModal && selectedGroupForSSLCertModal.zenGroupId){
                                                    if(checkPermission(selectedGroupForSSLCertModal.zenGroupId, "groupManager", "deleteSSLCert")) {
                                                        if(sslCertGridSelectedRow.userRemovable) {
                                                            setIsLoading(true)
                                                            deleteSSLCertFromGroupReactive(selectedGroupForSSLCertModal.zenGroupId, sslCertGridSelectedRow.sha256Base64).then(result => {

                                                                NotificationManager.success("Successfully deleted this certificate")

                                                                if (selectedGroupForSSLCertModal.sslPublicKeys && selectedGroupForSSLCertModal.sslPublicKeys.includes(sslCertGridSelectedRow)) {
                                                                    selectedGroupForSSLCertModal.sslPublicKeys = selectedGroupForSSLCertModal.sslPublicKeys.filter(function (value, index, arr) {
                                                                        return value.sha256Base64 !== sslCertGridSelectedRow.sha256Base64;
                                                                    })
                                                                }
                                                                setSSLCertGridRowData(selectedGroupForSSLCertModal.sslPublicKeys)

                                                                updateObjectInSessionStorage("groupManagementGridList", "zenGroupId",
                                                                    selectedGroupForSSLCertModal.zenGroupId, "sslPublicKeys", selectedGroupForSSLCertModal.sslPublicKeys)
                                                                setSelectedGroupForSSLCertModal(selectedGroupForSSLCertModal)
                                                                setShowDeleteCertConfirmModal(false)
                                                                setSSLCertGridSelectedRow()
                                                                setIsLoading(false)
                                                            }).catch(function (error) {
                                                                if (error.message) {
                                                                    NotificationManager.error(error.message);
                                                                } else {
                                                                    NotificationManager.error("Error deleting this certificate from group");
                                                                }
                                                                setIsLoading(false)
                                                            })
                                                        }
                                                        else{
                                                            NotificationManager.error("This certificate cannot be deleted");
                                                        }
                                                    }
                                                    else{
                                                        NotificationManager.error("You do not have permission to delete this certificate from this group");
                                                    }
                                                }
                                            }}
                                        >
                                        </MuiIconButtonWithTooltipAndBox>
                                    </div>
                                }
                            />
                        </div>
                        <div className="m-10 mt-2">
                            <div id="sslCertsGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                                <div className="ag-theme-alpine" style={{height: 400}}>
                                    <AgGridReact
                                        suppressContextMenu={true}
                                        suppressExcelExport={true}
                                        suppressCsvExport={true}
                                        onGridReady={(params) => {
                                            var allColumnIds = [];
                                            params.columnApi.getAllColumns().forEach(function (column) {
                                                allColumnIds.push(column.colId);
                                            });
                                            //params.columnApi.autoSizeColumns(allColumnIds);
                                        }}
                                        modules={[ClientSideRowModelModule,RichSelectModule,SetFilterModule,MenuModule,ColumnsToolPanelModule]}
                                        rowSelection={'single'}
                                        onFilterChanged={(params) => {
                                            params.api.deselectAll();
                                        }}
                                        suppressMultiSort={true}
                                        onSelectionChanged={(params) => {
                                            let selectedRow = params.api.getSelectedRows();
                                            if(selectedRow && selectedRow.length > 0){
                                                setSSLCertGridSelectedRow(selectedRow[0])
                                            }
                                            else{
                                                setSSLCertGridSelectedRow()
                                            }
                                        }}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                        rowData={sslCertGridRowData}
                                    >
                                        <AgGridColumn
                                            sortable={true}
                                            resizable
                                            field="uploaderUsername"
                                            filter='agTextColumnFilter'
                                            filterParams={{
                                                suppressSorting: true,
                                                buttons: ["reset", "apply"],
                                                
                                                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                                                suppressAndOrCondition: true,
                                            }}
                                            width={300}
                                        />
                                        <AgGridColumn
                                            sortable={true}
                                            resizable
                                            field="domainName"
                                            filter='agTextColumnFilter'
                                            filterParams={{
                                                suppressSorting: true,
                                                buttons: ["reset", "apply"],
                                                
                                                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                                                suppressAndOrCondition: true,
                                            }}
                                            width={400}
                                        />
                                        <AgGridColumn
                                            sortable={true}
                                            filter={'agDateColumnFilter'}
                                            filterParams={{
                                                suppressSorting: true,
                                                buttons: ["reset", "apply"],
                                                
                                                filterOptions: ['greaterThan', 'lessThan'],
                                                suppressAndOrCondition: true,
                                            }}
                                            resizable
                                            field="created"
                                            valueFormatter={dateValueFormatter}
                                            width={350}
                                        />
                                        <AgGridColumn
                                            sortable={true}
                                            filter={'agDateColumnFilter'}
                                            filterParams={{
                                                suppressSorting: true,
                                                buttons: ["reset", "apply"],
                                                
                                                filterOptions: ['greaterThan', 'lessThan'],
                                                suppressAndOrCondition: true,
                                            }}
                                            resizable
                                            valueFormatter={dateValueFormatter}
                                            field="expired"
                                            width={350}
                                        />
                                        <AgGridColumn
                                            sortable={true}
                                            filter={'agDateColumnFilter'}
                                            filterParams={{
                                                suppressSorting: true,
                                                buttons: ["reset", "apply"],
                                                
                                                filterOptions: ['greaterThan', 'lessThan'],
                                                suppressAndOrCondition: true,
                                            }}
                                            resizable
                                            field="userUploadedDate"
                                            valueFormatter={dateValueFormatter}
                                            width={350}
                                        />
                                    </AgGridReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*Manage Group Modal*/}
            <Modal contentLabel="Manage Group" isOpen={showManageGroupModal}
                   onRequestClose={() => {
                       setShowManageGroupModal(false)
                       setUser(null)
                       setUsers([])
                       resetFormFields()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <h1 className="font-bold text-3xl">{`Manage Group${(zenGroup && zenGroup.friendlyName) ? `: ${zenGroup.friendlyName}` : ""}`}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowManageGroupModal(false)
                                setUser(null)
                                setUsers([])
                                resetFormFields()
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8" />
                    <form className="flex flex-1 flex-col p-8 max-w-md" onSubmit={handleUser(() => onAddUser())}>
                        <div className="flex flex-1 flex-col">
                            <div className="ml-1">
                                <label>Add user</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    required
                                    name="newUser"
                                    {...register("newUser")}
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className="ml-1 mb-0 mt-3">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            type={"submit"}
                                            color={"primary"} >
                                            Add user
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </form>
                    <hr className="ml-10 mr-10 mb-3 bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3  ml-10 mr-10 mb-2">
                        <ClickToShowButtonsExpandingRight
                            buttonsText={"Automation"}
                            tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                            buttonsDiv={
                                <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                    <MuiIconButtonWithTooltipAndBox
                                        icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Remove user from group"}
                                        tooltipPlacement={"top"}
                                        disabled={!user}
                                        onClick={() => {
                                            onRemoveUser().then()
                                        }}/>
                                    <Tooltip title={<div className={"text-sm"}>Assign user roles, clicking this button will redirect you to the roles page and automatically filter the data by user and group where you can easily manage their roles</div>}
                                             slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             placement={"top"} followCursor={false} enterDelay={750} arrow>
                                        <Box sx={{boxShadow: 7, borderRadius: '28px'}}>
                                            <Link
                                                style={{pointerEvents: user ? '' : 'none'}}
                                                to={{pathname:"/private/roles"}} state={{zenGroupDisplayName: zenGroup && zenGroup.friendlyName, user: user}}>
                                                <IconButton
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "white",
                                                            cursor: "pointer"
                                                        },
                                                        color: "#505050",
                                                        background: "#F3f3f3",
                                                        "&:disabled": {
                                                            backgroundColor: "#Fbfbfb",
                                                            cursor: "default",
                                                            color: "#C1c1c1"
                                                        },
                                                        borderRadius: "28px"
                                                    }}
                                                    disabled={!user}
                                                    className={"self-center object-contain"}
                                                >
                                                    <LockIcon className={"cursor-pointer"}/>
                                                </IconButton>
                                            </Link>
                                        </Box>
                                    </Tooltip>
                                </div>
                            }
                        />
                    </div>
                    <UsersGrid
                        headers={[
                            { field: "username", name: "Users in group",
                                filter: 'agTextColumnFilter',
                                filterParamsInHeader: {
                                    suppressSorting: true,
                                    buttons: ["reset", "apply"],
                                    
                                    filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                                    suppressAndOrCondition: true,
                                },
                                sortable: true
                            },
                        ]}
                        data={users}
                        onClickRow={(row) => {
                            setUser(row[0]);
                        }}
                    />
                </div>

            </Modal>
            {/*Assign Roles Modal*/}
            <Modal contentLabel="Assign User Roles" isOpen={showRolesModal}
                   onRequestClose={() => {
                       setShowRolesModal(false);
                       //setUser(null);
                   }} shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 max-w-lg bg-white xl:w-1/3 lg:w-1/3
                       md:w-1/2 w-3/4 inset-y-10 mx-auto rounded-2xl overflow-x-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <ThemeProvider theme = {iconButtonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                startIcon={<FontAwesomeIcon
                                    size="1x"
                                    className="text-black-40 flex-1 text-2xl"
                                    icon="fa-light fa-chevron-left"
                                />}
                                onClick={() => {
                            setShowRolesModal(false);
                            setShowManageGroupModal(true);
                            //setUser(null);
                        }}>
                            <small className="flex-1 text-base text-black-40 ml-6">
                                Back
                            </small>
                        </Button>
                        </ThemeProvider>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowRolesModal(false);
                                setShowManageGroupModal(true);
                                //setUser(null);
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8" />
                    {/*Roles*/}
                    <label className="ml-8 mr-8 mt-3 text-xl">{`Assigned Roles for ${user?.username}`}</label>
                    {availableRoles && availableRoles.map((role) => {
                        return (
                            <div key={role} className="ml-10 mt-5 flex flex-row">
                                <input
                                    className="mr-2 checkbox"
                                    type="checkbox"
                                    checked={userRoles.includes(role)}
                                    onChange={(value) => {
                                        let selectedRolesArray = userRoles.slice(); //copy userRoles array into new array object. Using slice() with nothing passed in
                                        // returns all objects in the array from 0 to last element
                                        if(selectedRolesArray.includes(role)){ //if role is selected, de-select it (remove from userRoles)
                                            let index = selectedRolesArray.indexOf(role); //should always return number greater than -1 (not found) since we passed the if statement above
                                            selectedRolesArray.splice(index, 1); //delete role at specific index in array
                                            setUserRoles(selectedRolesArray);
                                        }
                                        else{ //select the role
                                            selectedRolesArray.push(role);
                                            setUserRoles(selectedRolesArray);
                                        }
                                    }}

                                />
                                <label>{role}</label>
                            </div>
                        );
                    })}


                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                onClick={() => onUpdateRoles()}>
                            Update
                    </Button>
                    </ThemeProvider>

                </div>
            </Modal>
            {/*Re-assign groups modal*/}
            <Modal contentLabel="Reassign Groups" isOpen={showReassignGroupsModal}
                   onRequestClose={() => {
                       setShowReassignGroupsModal(false);
                       resetGrid();
                   }} shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-xl lg:w-xl md:w-xl sm:w-xl xs:w-11/12 max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Reassign Groups</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowReassignGroupsModal(false);
                                resetGrid();
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="text-xl font-bold ml-1">
                        This form will repeat itself until all groups are reassigned, you do not have to reassign all groups to the same group.
                    </div>
                    <div className="ml-1">
                        <label>Select which distribution group you would like reassign the groups selected below to</label>
                        <Dropdown
                            options={distributionGroupsForReassignModal.map(({ id, friendlyName }) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={reassignGroupsModalGroupSelected}
                            onChange={({ value }) => {
                                setReassignGroupsModalGroupSelected(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    {groupsToReassign && groupsToReassign.map((group) => {
                        return (
                            <div key={group.zenGroupId} className="ml-1 flex flex-row items-center">
                                <input
                                    className="mr-2 checkbox"
                                    type="checkbox"
                                    checked={selectedGroupsToReassign.includes(group.zenGroupId)}
                                    onChange={(value) => {
                                        let selectedGroupsArray = selectedGroupsToReassign.slice();
                                        if(selectedGroupsArray.includes(group.zenGroupId)){
                                            let index = selectedGroupsArray.indexOf(group.zenGroupId);
                                            selectedGroupsArray.splice(index, 1);
                                            setSelectedGroupsToReassign(selectedGroupsArray);
                                        }
                                        else{
                                            selectedGroupsArray.push(group.zenGroupId);
                                            setSelectedGroupsToReassign(selectedGroupsArray);
                                        }
                                    }}

                                />
                                <label>{group.friendlyName}</label>
                            </div>
                        );
                    })}
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                              onClick={handleReassignSelectedGroups}
                        >
                        Reassign Groups
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col mr-10 ml-10 mt-8 flex-nowrap gap-y-3">
                    {privatePageHeaderHelper("Groups")}
                    <hr className="bg-black h-0.5" />
                    {/*Create New Group*/}
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Group"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setShowCreateGroupModal(true)
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={true} excelExportFunction={excelExport} />
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'groupsGridFilterState', updateGroupsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateGroupsGridUseColumnStateReactive);
    }

    async function handleReassignSelectedGroups(){
        if(zenGroupIdToUpdateDistributionGroup && reassignGroupsModalGroupSelected && selectedGroupsToReassign && selectedGroupsToReassign.length > 0){
            setIsLoading(true)
            try{
                let response = null
                if(reassignGroupsModalGroupSelected === "none"){ //custom added option to allow user to remove selected groups assignedDistributionGroupId field
                    response = await updateGroupsAssignedDistributionGroupIdReactive(null, selectedGroupsToReassign, zenGroupIdToUpdateDistributionGroup)
                }
                else{
                    response = await updateGroupsAssignedDistributionGroupIdReactive(reassignGroupsModalGroupSelected, selectedGroupsToReassign, zenGroupIdToUpdateDistributionGroup)
                }
                if(!response.needToReassignGroups){
                    NotificationManager.success("Successfully updated all groups that needed to be reassigned a distribution group")
                    //make sure all necessary fields are reset
                    resetGrid()
                }
                else{
                    //user needs to reassign existing groups to have a different assignedDistributionGroupId
                    if(response.zenGroupsThatNeedReassigned){
                        let distributionGroupsForReassign = getDistributionGroups(true)
                        if(distributionGroupsForReassign){
                            //good to go, we need to remove the value.data.zenGroupId group from the distributionGroupsForReassign list
                            let distributionGroupsList = []
                            distributionGroupsForReassign.forEach(distributionGroup => {
                                if(distributionGroup.id && distributionGroup.id !== zenGroupIdToUpdateDistributionGroup){
                                    distributionGroupsList.push(distributionGroup)
                                }
                            })
                            let noGroupOption = {"id":"none", "friendlyName":"Do Not Reassign a Value"}
                            distributionGroupsList.push(noGroupOption)
                            setDistributionGroupsForReassignModal(distributionGroupsList)
                            setGroupsToReassign(response.zenGroupsThatNeedReassigned)
                            setSelectedGroupsToReassign([])
                        }
                        else{
                            NotificationManager.error("Unexpected error updating groups");
                        }
                    }
                    else{
                        NotificationManager.error("Unexpected error updating groups");
                    }

                }
            }
            catch (error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error(`Unexpected error updating groups`);
                }
            }
            setIsLoading(false)
        }

    }

    function getGrid(){
        /*
        let needToAddNoGroupOption = true


        let cellDistributionGroupVals = [...distributionGroups]
        cellDistributionGroupVals.forEach(e => {
            if(e.friendlyName === "(Double Click to Assign)"){
                needToAddNoGroupOption = false
            }
        })
        if(needToAddNoGroupOption){
            let noGroupOption = {"id":"none", "friendlyName":"(Double Click to Assign)"}
            cellDistributionGroupVals.push(noGroupOption)
        }
        */
        return (
            <Grid
                columnDefs={columnDefs}
                onClickRow={(row) => {
                    setZenGroup(row[0]);
                }}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                excelExport={excelExport}
            />
        );
    }

    function resetGrid(){
        //gridApi && gridApi.onFilterChanged()
        gridApi && gridApi.deselectAll();
        getGroupManagementListReactive().then(data => {
            if(gridApi){
                //The ag grid gridApi.setRowData() clears all filters, so we need to get the filter model before, perform the setRowData, and then reapply the filter
                let filterModelBefore = gridApi.getFilterModel()
                gridApi.setRowData(data)
                gridApi.setFilterModel(filterModelBefore)
            }
            //we also can update the sessionStorage variable with new data:
            encryptAndStoreSessionVariable("groupManagementGridList", JSON.stringify(data))
        }).catch(function(error){})
        setIsLoading(false)
        setUsers([])
        setUser(null)
        setAvailableRoles([])
        setUserRoles([])
        setZenGroup(null)
        setShowRolesModal(false)
        setShowUpdateRolesConfirmation(false)
        setShowManageGroupModal(false)
        setShowRemoveConfirmation(false)
        setShowAddConfirmation(false)
        setShowCreateGroupConfirmation(false)
        setShowReassignGroupsModal(false)
        setDistributionGroupsForReassignModal([])
        setReassignGroupsModalGroupSelected()
        setGroupsToReassign([])
        setSelectedGroupsToReassign([])
        setZenGroupIdToUpdateDistributionGroup()
        setZenGroupIdToRemoveDistributionGroupId()
        setShowRemoveAssignedDistributionGroupIdModal(false)
        setInternalPartnerUse(true)
        setDistributionGroupChecked(true)
        setDistributionGroupSelected()
        setCopyFromGroupSelected()
        setNewGroupLatestApprovedAgentVersionSelected()
        setCopyWhitelistsFromGroupSelected()
        setNewGroupCollectAgentTelemetryChecked(true)
        setAutoAssignLicensesChecked(false)
        setCopyFromGroupToggled(false)
        setCopyWhitelistsFromGroupToggled(false)
        setNewGroupAutoUpdateToggled(true)
        setCopyWhitelistsFromGroupToggled(false)
        setWhcpToggleChecked(true)
        setNewGroupSSLPinningModeSelected("OFF")
        setPasswordExpirationPolicyForNewGroup("Never")
        setSelectedGroupForSSLCertModal()
        setSSLCertGridRowData([])
        setSSLCertGridSelectedRow()
        setShowDeleteCertConfirmModal(false)
        setShowCreateGroupModal(false)


        //TODO: should reset zenGroups and distributionGroups with new data
        resetFormFields()
    
    }

    function excelExport(){
        //groups grid currently loads all data at once with no sse so pass false here
        standardExcelExportHelper(gridApi, false, "groupsGridExport", ["assignedDistributionGroupId"])
    }

    function convertSSLPinningModeToFormattedValue(value){
        let returnVal = value
        sslPinningModeOptions.forEach(element => {
            if(element.value === value){
                returnVal = element.label
            }
        })
        return returnVal
    }
}

class Grid extends Component {
    rowData = []
    
    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateGroupsGridColumnStateReactive)
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }

        let groupManagementGridList = JSON.parse(decryptAndGetSessionVariable("groupManagementGridList"))
        if(groupManagementGridList){
            //found groupManagementGridList in session
            this.gridApi.setRowData(groupManagementGridList)
        }
        else{
            const data = await getGroupManagementListReactive()
            this.gridApi.setRowData(data)
        }

        onGridReadyHelper(params, "groupsGridFilterState");

        //params.api.sizeColumnsToFit()
    };

    getRowId = (params) => {
        return params.data.zenGroupId
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, RichSelectModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'groupsGridFilterState', updateGroupsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth, comparator,
                                    headerComponentFramework, width, valueFormatter, cellEditorSelector, valueGetter, cellEditorParams,
                                    cellEditorPopup,tooltipValueGetter, keyCreator},i
                        ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    width={width}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    valueFormatter={valueFormatter}
                                    onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    cellEditorPopup={cellEditorPopup ? cellEditorPopup : false}
                                    cellEditorParams={cellEditorParams ? cellEditorParams: { cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    enableCellChangeFlash={true}
                                    headerComponentFramework={headerComponentFramework}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    tooltipValueGetter={tooltipValueGetter}
                                    keyCreator={keyCreator}
                                    comparator={comparator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

class UsersGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit()
    }


    render() {
        return (
            <div className="m-10 mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule,MenuModule, ColumnsToolPanelModule, SetFilterModule,RichSelectModule]}
                        domLayout="autoHeight"
                        rowData={this.props.data}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        onFilterChanged={(params) => {
                            params.api.deselectAll();
                        }}
                        suppressMultiSort={true}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    >
                        {this.props.headers.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate,
                                    cellRenderer, cellRendererSelector, cellEditorType,
                                    hide, sortable, minWidth, suppressColumnsToolPanel, cellEditorPopup,cellEditorParams},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight={false}
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    cellEditorPopup={cellEditorPopup}
                                    cellEditorParams={cellEditorParams ? cellEditorParams:{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
