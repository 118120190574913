import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";

function PrivacyPolicy() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Cyber Crucible Dashboard Privacy Policy"/>
                <meta name="keywords" content="" />
                <meta name="author" content="" />
                <title>Cyber Crucible Privacy Policy</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>

            {/*Title Banner*/}
            <div className="banner-contact">
                <div className="py-20">
                    <div className="container text-center mx-auto px-6">
                        <h2 className="text-4xl font-bold mb-2 text-white">
                            Privacy Policy
                        </h2>
                        <h3 className="text-2xl mb-8 text-gray-200">
                            Protecting your private information is our priority
                        </h3>
                    </div>
                </div>
            </div>

            <section className="text-gray-600 body-font">
                <div className="container px-5 mx-auto">
                    <div className="flex flex-wrap w-full mb-20 flex-col items-start">
                        <article className="lg:p-24 md:p-10 sm:p-10 xs:p-10">
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mb-2 text-dark-grey">Who We Are</h2>
                            <p className="lg:w-full w-full leading-relaxed text-dark-grey mb-5">
                                Our website address is: <a className="text-theme hover:text-theme-teal" href="https://www.cybercrucible.com">https://www.cybercrucible.com</a>
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mb-2 text-dark-grey">What Personal Data We Collect And Why We Collect It?</h2>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Comments
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: <a className="text-theme hover:text-theme-teal" href="https://automattic.com/privacy/">https://automattic.com/privacy/</a>. After approval of your comment, your profile picture is visible to the public in the context of your comment.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Media
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Contact Forms
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Cookies
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select <strong>“Remember Me”</strong>, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Embedded Content From Other Websites
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Analytics</h2>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Who We Share Your Data With?
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                How Long We Retain Your Data?
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                What Rights You Have Over Your Data?
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Where We Send Your Data?
                            </h3>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                Visitor comments may be checked through an automated spam detection service.
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Your Contact Information
                            </h3>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Additional Information</h2>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                How We Protect Your Data?
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                What Data Breach Procedures We Have In Place?
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                What Third Parties We Receive Data From?
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                What Automated Decision Making And/Or Profiling We Do With User Data?
                            </h3>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Industry Regulatory Disclosure Requirements
                            </h3>
                        </article>
                    </div>
                </div>
            </section>

            <Footer></Footer>

        </div>
    )
}

export default PrivacyPolicy
