import axios from "../../utils/axiosInstance";

export const registerUserReactive = async (firstName, lastName, username, company, password) => {
    let compName = null;
    if(company){
        if(company.trim().length > 0){
            compName = company.trim();
        }
    }
    return await axios.post("/public/registerUserReactive", {
        firstName,
        lastName,
        username,
        companyName: compName,
        password: password
    });
};
