import axios from "../../utils/axiosInstance";

export const getSchedulesReactive = async () => {
    const response = await axios.post("/scheduleListReactive", {
    });
    return response.data;
};

export const createScheduleReactive = async (req) => {
    return await axios.post("/createScheduleReactive", req)
};

export const deleteScheduleReactive = async (zenGroup, id) => {
    return await axios.post("/deleteScheduleReactive", {
        groupId: zenGroup,
        id: id, //only one schedule id for now
    });
};
export const findByScheduleIdListReactive = async (ids) => {
    const response = await axios.post("/findByScheduleIdListReactive",{
        ids
    })
    return response.data
}

export const getSchedulesGridFilterStateReactive = async () => {
    const response =  await axios.post("/getSchedulesGridFilterStateReactive", {});
    return response.data;
};

export const updateSchedulesGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateSchedulesGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateSchedulesGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateSchedulesGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getSchedulesGridColumnStateReactive = async () => {
    const response =  await axios.post("/getSchedulesGridColumnStateReactive", {});
    return response.data;
};
export const updateSchedulesGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateSchedulesGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateSchedulesGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateSchedulesGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};