import React, {Component, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Dropdown from "react-dropdown";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import * as EmailValidator from "email-validator";
import {
    createSalesEmailNotificationObjectReactive,
    deleteSingleSalesNotificationReactive,
    findBySalesNotificationIdListReactive,
    updateSalesNotificationNameReactive,
    updateSalesNotificationsGridColumnStateReactive,
    updateSalesNotificationsGridFilterModelReactive,
    updateSalesNotificationsGridUseColumnStateReactive,
    updateSalesNotificationsGridUseFilterStateReactive,
    updateSalesNotificationTimeIntervalReactive,
    updateSalesNotificationTypeReactive
} from "../api/salesNotificationsApi";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {getChannelGroupsDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";

import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {checkPermissionAndDeleteRowNodeFromGrid,} from "../../utils/clientSideDataSessionStorageHelper";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {switchTheme} from "../../utils/muiStyling";
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import {MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const INTERVAL_OPTIONS = [
    //{
    //  value: "300",
    //  label: "5 Minutes",
    //},
    {
        value: 900,
        label: "15 Minutes",
    },
    {
        value: 1800,
        label: "30 Minutes",
    },
    {
        value: 3600,
        label: "1 Hour",
    },
    {
        value: 43200,
        label: "12 Hours",
    },
    {
        value: 86400,
        label: "24 Hours",
    },
];

const NOTIFICATION_TYPES = [
    //values line up with SalesEmailNotification model. Will need to update if model is changed
    {
        value: "dealExpired",
        label: "Deal Expired",
    },
    {
        value: "dealCreated",
        label: "New Partner Deal Registration (one time alert)",
    },
    {
        value: "dealDisabled",
        label: "Deal Disabled (one time alert)",
    },
    {
        value: "upcomingDealExpiration",
        label: "Upcoming Deal Expiration (one time alert)",
    },
    {
        value: "invoiceCreated",
        label: "Invoice Sent (one time alert)",
    }
]

//only displayed in new sales notification form, users can't select or deselect any of these options though
const UPCOMING_DEAL_EXPIRATION_OPTIONS = [
    {
        label: "14 Days",
    },
    {
        label: "7 Days",
    },
    {
        label: "3 Days",
    },
    {
        label: "1 Day",
    },
    {
        label: "Final Notice",
    }
]

let gridColumnStateSessionVariableName = "salesNotificationsGridColumnState"
export default function SalesNotifications() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroup,setZenGroup] = useState(); // eslint-disable-line no-unused-vars
    const [newNotificationObj, setNewNotificationObj] = useState({});
    const [newNotificationObjTypesChecked, setNewNotificationObjTypesChecked] = useState([]);
    const [activeSalesNotification, setActiveSalesNotification] = useState();
    const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState(false);
    const [notifyAllGroupMembersToggled, setNotifyAllGroupMembersToggled] = useState(true);
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("salesNotificationsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true, true, true),
        { field: "notificationName", name: "Notification Name",  filter: 'agTextColumnFilter', width: 400,
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Notification's Name", "notificationName")
            }
        },
        { field: "emailAddress", name: "Email Address to Notify", width: 300,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "timeIntervalInSeconds", name: "Time Interval", width: 280,
            valueGetter: function (params){
                return convertTimeIntervalToFormattedString(params.node.data.timeIntervalInSeconds)
            },
            keyCreator: function (params){
                return convertTimeIntervalToFormattedString(params.node.data.timeIntervalInSeconds)
            },
            cellEditorType: "agSelectCellEditor",
            editable: true,
            editableOptions: INTERVAL_OPTIONS.map(
                ({ value, label}) => label
            ),
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ["15 Minutes", "30 Minutes", "1 Hour", "12 Hours", "24 Hours", "1 Week", "1 Month"],
                suppressSorting: true,
            },
            sortable: true
        },
        { field: "dealCreated", name: "Deal Created", width: 200,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.dealCreated}
                                name={`cellToggleDealCreated${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealCreated").then(response => {
                                            params.node.setDataValue("dealCreated", !params.node.data.dealCreated);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Created', 'Not Created'],
                suppressSorting: false,
            },
            sortable: true,
            keyCreator: (params) => {
                if(params.node.data.dealCreated){
                    //console.log("Deal Created");
                    return "Created";
                }else{
                    //console.log("Deal Not Created");
                    return "Not Created";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealCreated){
                    //console.log("Deal Created");
                    return "Created";
                }else{
                    //console.log("Deal Not Created");
                    return "Not Created";
                }
            },
        },
        { field: "dealDisabled", name: "Deal Disabled",width: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                checked={params.node.data.dealDisabled}
                                name={`cellToggleDealDisabled${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealDisabled").then(response => {
                                            params.node.setDataValue("dealDisabled", !params.node.data.dealDisabled);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Disabled', 'Not Disabled'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.dealDisabled){
                    //console.log("Disabled");
                    return "Disabled";
                }else{
                    //console.log("Not Disabled");
                    return "Not Disabled";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealDisabled){
                    //console.log("Disabled");
                    return "Disabled";
                }else{
                    //console.log("Not Disabled");
                    return "Not Disabled";
                }
            },
            sortable: true
        },
        { field: "dealExpired", name: "Deal Expired",width: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.dealExpired}
                                name={`cellToggleDealExpired${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "dealExpired").then(response => {
                                            params.node.setDataValue("dealExpired", !params.node.data.dealExpired);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Expired', 'Not Expired'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.dealExpired){
                    //console.log("Expired");
                    return "Expired";
                }else{
                    //console.log("Not Expired");
                    return "Not Expired";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealExpired){
                    //console.log("Expired");
                    return "Expired";
                }else{
                    //console.log("Not Expired");
                    return "Not Expired";
                }
            },
            sortable: true
        },
        { field: "upcomingDealExpiration", name: "Upcoming Deal Expiration",width: 290,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                checked={params.node.data.upcomingDealExpiration}
                                name={`cellToggleUpcomingDealExpiration${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "upcomingDealExpiration").then(response => {
                                            params.node.setDataValue("upcomingDealExpiration", !params.node.data.upcomingDealExpiration);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Upcoming Expiration', 'Future Expiration'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.upcomingDealExpiration){
                    //console.log("Expired");
                    return "Upcoming Expiration";
                }else{
                    //console.log("Not Expired");
                    return "Future Expiration";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.upcomingDealExpiration){
                    //console.log("Expired");
                    return "Upcoming Expiration";
                }else{
                    //console.log("Not Expired");
                    return "Future Expiration";
                }
            },
            sortable: true
        },
        { field: "invoiceCreated", name: "Invoice Created",width: 250,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch checked={params.node.data.invoiceCreated}
                                name={`cellToggleInvoiceCreated${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        updateSalesNotificationTypeReactive(params.node.data.notificationId, "invoiceCreated").then(response => {
                                            params.node.setDataValue("invoiceCreated", !params.node.data.invoiceCreated);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                },
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Created', 'Not Created'],
                suppressSorting: true,
            },
            keyCreator: (params) => {
                if(params.node.data.invoiceCreated){
                    return "Created";
                }else{
                    return "Not Created";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.invoiceCreated){
                    return "Created";
                }else{
                    return "Not Created";
                }
            },
            sortable: true
        },
        { field: "lastMessageSent", name: "Last Repeating Alert Sent",width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "lastOneTimeAlertMessageSent", name: "Last One Time Alert Sent",width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "userCreatedItUsername", name: "Created By", width: 275,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        //TODO, come back when adding notifications to notificationGroups feature is in and add sorting, make sure to update API too
        //{ field: "notificationGroupName", name: "Notification Group Name", hide: true},
    ])

    //using getChannelGroupsDropDownContents() for groups
    /*useEffect(() => {
        async function populate() {
            try{
                const responseZenGroups = await getGroups();
                setZenGroups(responseZenGroups);
                if(responseZenGroups && responseZenGroups.length > 0){
                    let channelList = []
                    for (let i in responseZenGroups) {
                        let group = responseZenGroups[i]
                        if (group.channelPartner) {
                            channelList.push(group)
                        }
                    }
                    setChannelGroups(channelList)
                }
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving user's list of groups");
                }
            }
            setIsLoading(false);
        }
        populate();
    }, []);*/

    const createNewNotificationSubmit = async (data) => {
        //TODO, make confirmation modals
        if(data && newNotificationObj){
            if(newNotificationObj.group && newNotificationObj.timeInterval){
                let emailAddressValueToSend = null
                if(!notifyAllGroupMembersToggled){
                    //validate email if notifyAllGroupMembers is not toggled
                    if(!data.emailAddress || data.emailAddress.trim().length < 1){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    data.emailAddress = data.emailAddress.trim();
                    if(!EmailValidator.validate(data.emailAddress)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    //valid email if we get to here
                    emailAddressValueToSend = data.emailAddress;
                }
                if(newNotificationObjTypesChecked && newNotificationObjTypesChecked.length > 0){
                    let dealCreated = newNotificationObjTypesChecked.includes("dealCreated")
                    let dealDisabled = newNotificationObjTypesChecked.includes("dealDisabled")
                    let dealExpired = newNotificationObjTypesChecked.includes("dealExpired")
                    let upcomingDealExpiration = newNotificationObjTypesChecked.includes("upcomingDealExpiration")
                    let invoiceCreated = newNotificationObjTypesChecked.includes("invoiceCreated")

                    let nameToSend = null
                    if(data.name && data.name.trim().length > 0){
                        nameToSend = data.name.trim()
                    }
                    setIsLoading(true)
                    try{
                        await createSalesEmailNotificationObjectReactive(newNotificationObj.group, notifyAllGroupMembersToggled, emailAddressValueToSend, nameToSend, newNotificationObj.timeInterval,
                            dealCreated, dealDisabled, dealExpired, upcomingDealExpiration, invoiceCreated)
                        NotificationManager.success("Successfully created email notification")
                        setNewNotificationObj({})
                        setNewNotificationObjTypesChecked([])
                        setCreateNotificationModalOpen(false)
                        setNotifyAllGroupMembersToggled(true)
                        resetGrid();
                        reset({})
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this email notification");
                        }
                    }
                }
                else{
                    NotificationManager.error("Please initially select at least one notification type, you will be able to edit this in the grid below later")
                }
            }
            else{
                NotificationManager.error("Please fill out all of the fields")
            }
        }
        setIsLoading(false)
    };

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Sales Notifications</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Creat Notification Modal*/}
            <Modal contentLabel="Create New Sales Notification" isOpen={createNotificationModalOpen}
                   onRequestClose={() => {
                       setCreateNotificationModalOpen(false)
                       reset({})
                       setNewNotificationObj({})
                       setNewNotificationObjTypesChecked([])
                       setNotifyAllGroupMembersToggled(true)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl min-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createNewNotificationSubmit)}>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-row justify-center">
                            <h1 className="font-bold text-3xl">Create New Sales Notification</h1>
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <label>Channel Partner Group</label>
                            <Dropdown
                                options={getChannelGroupsDropDownContents()}
                                value={newNotificationObj.group}
                                onChange={({ value }) =>
                                    setNewNotificationObj({ ...newNotificationObj, group: value })
                                }
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={notifyAllGroupMembersToggled}
                                        name="toggle3"
                                        onChange={e => setNotifyAllGroupMembersToggled(e.target.checked)}
                                    />
                                } label={notifyAllGroupMembersToggled ? "Notify All Group Members" : "Notify Single Email Address"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${notifyAllGroupMembersToggled ? "hidden" : "block"} ml-1 mt-5`}>
                            <label>Email Address to Notify</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                required={!notifyAllGroupMembersToggled}
                                name="emailAddress"
                                {...register("emailAddress")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for Notification</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="name"
                                {...register("name")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Notification type:</label>
                            {NOTIFICATION_TYPES.map(({value, label}) => {
                                return (
                                    <div key={value}>
                                        {value === "upcomingDealExpiration" ? (
                                            <div className="flex flex-col">
                                                <div className="ml-1 mt-5 flex flex-row">
                                                    <input
                                                        className="mr-2"
                                                        type="checkbox"
                                                        defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                        onChange={(val) => {
                                                            let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                            if(selectedTypesArray.includes(value)){
                                                                let index = selectedTypesArray.indexOf(value);
                                                                selectedTypesArray.splice(index, 1);
                                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                                            }
                                                            else{
                                                                selectedTypesArray.push(value);
                                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                                            }
                                                        }}

                                                    />
                                                    <label>{label}</label>
                                                </div>
                                                {UPCOMING_DEAL_EXPIRATION_OPTIONS.map(({label}) => {
                                                    return (
                                                        <div key={label} className="ml-5 mt-1 flex flex-row">
                                                            <input
                                                                disabled={true}
                                                                className="mr-2"
                                                                type="checkbox"
                                                                checked={newNotificationObjTypesChecked.includes(value)}
                                                                defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                            />
                                                            <label>{label}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        ) : (
                                            <div className="ml-1 mt-5 flex flex-row">
                                                <input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                    onChange={(val) => {
                                                        let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                        if(selectedTypesArray.includes(value)){
                                                            let index = selectedTypesArray.indexOf(value);
                                                            selectedTypesArray.splice(index, 1);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                        else{
                                                            selectedTypesArray.push(value);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                    }}

                                                />
                                                <label>{label}</label>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Time Interval</label>
                            <Dropdown
                                defaultValue={newNotificationObj.timeInterval}
                                onChange={({ value }) =>
                                    setNewNotificationObj({ ...newNotificationObj, timeInterval: value })
                                }
                                options={INTERVAL_OPTIONS}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                        <ThemeProvider theme = {switchTheme}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                            >
                                Create
                            </Button>
                        </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 ml-10 mr-10 mt-8">
                    {privatePageHeaderHelper("Sales Notifications")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Sales Notification"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setNewNotificationObj({ ...newNotificationObj, group: zenGroup });
                                                setCreateNotificationModalOpen(!createNotificationModalOpen);
                                            }}
                                        />
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Sales Notification"}
                                            tooltipPlacement={"top"} disabled={!activeSalesNotification}
                                            onClick={() => deleteSalesNotifications()}>
                                        </MuiIconButtonWithTooltipAndBox>
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport} />
                        </div>
                    </div>

                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'salesNotificationsGridFilterState', updateSalesNotificationsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSalesNotificationsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                onClickRow={(currentSelectedRow) => {
                    setActiveSalesNotification(currentSelectedRow[0])
                }}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }

    async function deleteSalesNotifications() {
        if(activeSalesNotification && activeSalesNotification.notificationId){
            //setIsLoading(true)
            try{
                await deleteSingleSalesNotificationReactive(activeSalesNotification.notificationId)
                NotificationManager.success("Successfully deleted this notification");
                checkPermissionAndDeleteRowNodeFromGrid(gridApi, activeSalesNotification.notificationId, 'notificationId', activeSalesNotification.zenGroupId,
                    "notificationManager", "deleteNotification")
                //deleteObjectInSessionStorage("salesNotificationsGridList", "notificationId", activeSalesNotification.notificationId)
                resetGrid()
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error deleting notification");
                }
            }
            setIsLoading(false)
        }

    }
    /*
    async function updateNotificationTypeOnClick(params, event, specificType, inputElement){
        //setIsLoading(true);
        event.stopPropagation(); //don't select row in grid
        try{
            if(params.node.data.notificationId){
                await updateSalesNotificationTypeReactive(params.node.data.notificationId, specificType)
                //NotificationManager.success(`Successfully updated notification: ${params.node.data.notificationName}`) //could get annoying sending notifications each time, so only notify on error
                params.value = !params.value
                inputElement.checked = params.value
                params.node.setDataValue(specificType, params.value);
                //updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                  //  params.node.data.notificationId, specificType, params.value)
            }
            else{
                NotificationManager.error(`Error updating notification: ${params.node.data.notificationName}`)
                inputElement.checked = params.value
            }
        }
        catch(error){
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error(`Error updating notification: ${params.node.data.notificationName}`)
            }
            inputElement.checked = params.value
        }
        setNewNotificationObj({})
        setNewNotificationObjTypesChecked([])
        setIsLoading(false)

    }
    */

    function resetGrid(){
        //gridApi && gridApi.onFilterChanged()
        gridApi && gridApi.deselectAll();
        /*getSalesNotificationsReactive().then(data => {
            if(gridApi){
                //The ag grid gridApi.setRowData() clears all filters, so we need to get the filter model before, perform the setRowData, and then reapply the filter
                let filterModelBefore = gridApi.getFilterModel()
                gridApi.setRowData(data)
                gridApi.setFilterModel(filterModelBefore)
            }
        }).catch(function(error){})*/
        /*ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));*/
        setActiveSalesNotification(null);
        setNotifyAllGroupMembersToggled(true)
    }

    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "notificationId", "notificationId", findBySalesNotificationIdListReactive, [
            "lastMessageSent", "lastOneTimeAlertMessageSent", "dealCreated", "dealDisabled", "dealExpired", "upcomingDealExpiration", "invoiceCreated", "timeIntervalInSeconds",
            "notificationName"
        ])
    }
    /*
    function getColumnKeys(gridApi) {
        let columnIdsToIncludeInExport = [];
        gridApi.getColumnDefs().forEach(function (column) {
            if(!column.suppressColumnsToolPanel){
                columnIdsToIncludeInExport.push(column.colId);
            }
        });
        return columnIdsToIncludeInExport;
    }

     */

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "salesNotificationsGridExport")
    }

    function convertTimeIntervalToFormattedString(interval){
        switch(interval) {
            case 300:
                return "5 Minutes"
            case 900:
                return "15 Minutes"
            case 1800:
                return "30 Minutes"
            case 3600:
                return "1 Hour"
            case 43200:
                return "12 Hours"
            case 86400:
                return "24 Hours"
            case 604800:
                return "1 Week"
            case 2419200 :
                return "1 Month"
            default:
                return interval
        }
    }
}

function updateNotificationTimeIntervalOnChange(params, newTimeInterval, gridApi){
    //setIsLoading(true);
    let oldValue = params.oldValue
    if(params.newValue === params.oldValue){
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        return;
    }
    if(params.node.data.zenGroupId && params.node.data.notificationId && newTimeInterval){
        updateSalesNotificationTimeIntervalReactive(params.node.data.notificationId, newTimeInterval).then(() => {
            //params.node.setDataValue("timeIntervalInSeconds", newTimeInterval);
            NotificationManager.success(`Successfully updated notification: ${params.node.data.notificationName}`)
            /*updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                params.node.data.notificationId, "timeIntervalInSeconds", newTimeInterval)*/
        }).catch(function(error){
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Error updating notification")
            }
            params.node.data.timeIntervalInSeconds = oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        })

    }
    else{
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
    };
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSalesNotificationsGridColumnStateReactive)
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else if(event.column.colId === "notificationName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.notificationId){
                    NotificationManager.error("Error updating name")
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                updateSalesNotificationNameReactive(event.newValue.trim(), event.data.notificationId).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("notificationName", event.newValue.trim());
                    /*updateObjectInSessionStorage("salesNotificationsGridList", "notificationId",
                        event.data.notificationId, "notificationName", event.newValue.trim())*/
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.notificationName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "timeIntervalInSeconds"){
            let newTimeInterval
            INTERVAL_OPTIONS.forEach(element => {
                if(element.label === event.newValue){
                    newTimeInterval = element.value
                }
            })
            updateNotificationTimeIntervalOnChange(event, newTimeInterval, gridApi)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["notificationId"] = objectBody["id"]
        objectBody["emailAddress"] = objectBody["notifyAllGroupMembers"] ? "All Group Members" : objectBody["emailAddress"]
        objectBody["notificationName"] = objectBody["notificationUserSetName"] ? objectBody["notificationUserSetName"] : objectBody["notificationRandomName"]
        objectBody["notificationGroupName"] = objectBody["notificationGroupUserSetName"] ? objectBody["notificationGroupUserSetName"] : objectBody["notificationGroupRandomName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.notificationId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (params) => {
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);
        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }

        /*let salesNotificationsGridList = JSON.parse(decryptAndGetSessionVariable("salesNotificationsGridList"))
        if(salesNotificationsGridList){
            //found salesNotificationsGridList in session
            this.gridApi.setRowData(salesNotificationsGridList)
        }
        else{
            //else didn't find salesNotificationsGridList in session
            const data = await getSalesNotificationsReactive()
            this.gridApi.setRowData(data)
        }*/

        onGridReadyHelper(params, "salesNotificationsGridFilterState");

        await loadDataWithSSEAndStartChangeStreamListener("/sse/salesNotificationsListReactive", "/sse/listenToSalesNotificationEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        multiSortKey={"ctrl"}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'salesNotificationsGridFilterState', updateSalesNotificationsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth, suppressColumnsToolPanel,
                                    valueFormatter, width, cellEditorSelector, valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    width={width}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    enableCellChangeFlash={true}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    valueFormatter={valueFormatter}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
