import React, {Component, useState} from "react";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ClearRefresh} from "../../components/clearRefreshButtons"
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {
    findByTaskIdListReactive,
    tasksListReactive,
    updateTasksGridColumnStateReactive,
    updateTasksGridFilterModelReactive,
    updateTasksGridUseColumnStateReactive,
    updateTasksGridUseFilterStateReactive
} from "../api/tasksApi";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {defaultZenGroupColumnInitWithOptions} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultAgentNameColumnAndMachineNameInitWithFilters,
    defaultMachineNameColDefWithFilters
} from "../../utils/agentNameGridHelper";
import {agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped,} from "../../utils/gridCellEditing";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {ClickToShowButtonsExpandingLeft} from "../../components/clickToShowButtons";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {ToggleButton, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let keysToRefresh = ["dateCompleted", "initialTaskType", "currentTaskProgress", "dateCreated"]

let initialTaskTypeValues = ["RECEIVE_CLIENT_ID","RECEIVE_OAUTH_TOKEN","GET_HASH","UPLOAD_CANARY","UNINSTALL", "UPDATE",
    "UNKNOWN","RYUK_DECRYPT","AUTO_UPDATE_ON", "AUTO_UPDATE_OFF","TELEMETRY_ON", "TELEMETRY_OFF", "RESET_ATOMIC_COUNTS"]
let currentTaskProgressValues = ["COMPLETE", "FAILED","QUEUED", "CANCELLED"]

let gridColumnStateSessionVariableName = "tasksGridColumnState"
export default function Tasks() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("tasksGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptions(true, true, true),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},
        { field: "initialTaskType", name: "Initial Task Type", width: 250,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: initialTaskTypeValues,
                valueFormatter: (params) => {
                    if(params.value!=="(Select All)"){
                        return getTaskTypeValueFormatted(params.value)
                    }
                    else{
                        return params.value;
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            valueFormatter: (params) => {
                return getTaskTypeValueFormatted(params.value)
            },
            sortable: true
        },
        { field: "currentTaskProgress", name: "Current Task Status", width: 250,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: currentTaskProgressValues,
                valueFormatter: (params) => {
                    if(params.value!=="(Select All)"){
                        return getTaskTypeValueFormatted(params.value)
                    }
                    else{
                        return params.value;
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            valueFormatter: (params) => {
                return getTaskTypeValueFormatted(params.value)
            },
            sortable: true
        },
        defaultAgentNameColumnAndMachineNameInitWithFilters,
        defaultMachineNameColDefWithFilters,
        { field: "dateCreated", name: "Date Created", width: 300,
            filter: 'agDateColumnFilter',
            filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCompleted", name: "Date Completed", width: 300,
            filter: 'agDateColumnFilter',
            filterParamsInHeader:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
    ])
    const _ = require('lodash');

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Tasks</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col mt-8 ml-10 mr-10 gap-y-3">
                    {privatePageHeaderHelper("Agent Tasks")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={false} />
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'tasksGridFilterState', updateTasksGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateTasksGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
            />
        );
    }

    function getTaskTypeValueFormatted(value){
        switch(value) {
            case "RECEIVE_CLIENT_ID":
                return "Receive Client Id"
            case "RECEIVE_OAUTH_TOKEN":
                return "Receive OAuth Token"
            case "GET_HASH":
                return "Get Hash"
            case "UPLOAD_CANARY":
                return "Upload Canary"
            case "COMPLETE":
                return "Complete"
            case "FAILED":
                return "Failed"
            case "UNINSTALL":
                return "Uninstall"
            case "UPDATE":
                return "Update"
            case "QUEUED":
                return "Queued"
            case "UNKNOWN":
                return "Unknown"
            case "RYUK_DECRYPT":
                return "Ryuk Decrypt"
            case "AUTO_UPDATE_ON":
                return "Auto Update On"
            case "AUTO_UPDATE_OFF":
                return "Auto Update Off"
            case "TELEMETRY_ON":
                return "Telemetry On"
            case "TELEMETRY_OFF":
                return "Telemetry Off"
            case "CANCELLED":
                return "Cancelled"
            case "RESET_ATOMIC_COUNTS":
                return "Reset Atomic Counts"
            default:
                return value
        }
    }
    async function resetGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "taskId", "id", findByTaskIdListReactive, keysToRefresh, "agentId","machineName")
    }
}

class Grid extends Component {
    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        /*params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
    };

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateTasksGridColumnStateReactive)
    }

    getColumnKeys = (params) => {
        let columnIdsToIncludeInExport = [];
        params.getAllColumns().forEach(function (column) {
            if(!column.getColDef().suppressColumnsToolPanel){
                columnIdsToIncludeInExport.push(column.colId);
            }
        });

        return columnIdsToIncludeInExport
    }

    getContextMenuItems = (params) => {
        return [
            {
                name: 'Excel Export',
                action: function() {
                    let today = new Date();
                    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                    let time = today.getHours() + "-" + today.getMinutes();
                    let dateTime = date+'T'+time;
                    let fileName = `tasks-${dateTime}`
                    let columnKeys = [];

                    params.columnApi.getAllColumns().forEach(function (column) {
                        if(!column.suppressColumnsToolPanel &&
                            (column.colId !== "assignedDistributionGroupId")){
                            columnKeys.push(column.colId);
                        }
                    });

                    params.api.exportDataAsExcel({
                        processCellCallback: function(cell) {
                            if (cell.column.getColDef().filter === "agDateColumnFilter") {
                                let date = new Date(cell.value).toUTCString()
                                if (date === 'Invalid Date' || cell.value === null){
                                    return cell.value
                                }
                                return date
                            }

                            return cell.value
                        },
                        columnKeys: columnKeys,
                        fileName: fileName
                    });
                },
            },
        ];
    };

    onGridReady = async (gridReadyParams) => {
/*        gridReadyParams.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            gridReadyParams.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);
        onGridReadyHelper(gridReadyParams, "tasksGridFilterState");
        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        var dataSource = {
            getRows: async function (params) {
                try{
                    let start = params.request.startRow;
                    let end = params.request.endRow;
                    let filters = masterFilterHelper(params.request.filterModel)
                    let sortModel = params.request.sortModel;
                    let sortModelForRequest = null;
                    if(sortModel && sortModel.length > 0){
                        try{ //extra precaution for array access on the sort model so it does not break
                            if(sortModel[0].colId && sortModel[0].sort){
                                sortModelForRequest = sortModel[0]
                            }
                        }
                        catch(error){
                            console.log("Error generating sort request")
                        }
                    }
                    let count = end - start;
                    let page = Math.floor(start / count)
                    let objects = await tasksListReactive(count, page, filters, sortModelForRequest);
                    let finalPage = true
                    //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                    // endpoint instead of Mono with a Response object
                    if(objects && objects.length === count){
                        finalPage = false
                    }
                    let lastIdx = undefined
                    if (finalPage) {
                        lastIdx = (count * page) + objects.length
                    }

                    params.api.deselectAll();

                    const success = (objects.length !== 0);
                    if (success) {
                        params.api.hideOverlay()
                        params.successCallback(objects, lastIdx);
                    } else {
                        //params.api.showNoRowsOverlay();
                        params.successCallback(objects, lastIdx); //to stop loading circle from spinning and make it disappear, nothing in objects.
                        params.failCallback();
                    }

                }
                catch(error){
                    //console.log(error);
                    params.api.showNoRowsOverlay();
                    params.failCallback();
                }
            }
        }
        gridReadyParams.api.setServerSideDatasource(dataSource);
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]} //ExcelExportModule
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        serverSideInfiniteScroll={true}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        tooltipMouseTrack={true }
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'tasksGridFilterState', updateTasksGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        //getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, valueFormatter,tooltipField,tooltipComponent ,suppressColumnsToolPanel, cellEditorSelector,
                                    keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight={false}
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    valueFormatter={valueFormatter}
                                    onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    enableCellChangeFlash={true}
                                    tooltipField={tooltipField}
                                    tooltipComponent={tooltipComponent}
                                    width={width}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
