import axios from "../../utils/axiosInstance";

export const changeUserTimeZoneReactive = async (timeZone) => {
    const response = await axios.post("/changeUserTimeZoneReactive", {timeZone: timeZone});
    return response.data;
};
export const updateUserTrainingGroupsVisibleReactive = async (newSetting) => {
    const response = await axios.post("/updateUserTrainingGroupsVisibleReactive", {
        newSetting: newSetting
    });
    return response.data;
};

export const updateUserDemoGroupsVisibleReactive = async (newSetting) => {
    const response = await axios.post("/updateUserDemoGroupsVisibleReactive", {
        newSetting: newSetting
    });
    return response.data;
};
export const resetTrainingGroupDataReactive = async (zenGroupId) => {
    return await axios.post("/resetTrainingGroupDataReactive", {
        zenGroupId: zenGroupId
    });
};
export const getParentGroupsWithTrainingData = async () => {
    const response = await axios.post("/getParentGroupsWithTrainingData", {});
    return response.data;
};