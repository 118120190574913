import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {InformationModal} from "../../components/informationOnlyModal";
import * as EmailValidator from "email-validator";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {roundButtonTheme} from "../../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import CCLogo from "../../components/CCLogo";
import {useLocation} from "react-router-dom";

//const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function ResetPassword(){
    const [isLoading, setIsLoading] = useState(false);
    const [passwordSent, setPasswordSent] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { register, handleSubmit } = useForm();
    const location = useLocation();

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if (location && location.state && location.state.showFullForm === true){
                setPasswordSent(true)
            }
        })()
        return () => controller?.abort();
    }, []);

    const onSubmit = async ({ email }) => {
        try {
            email = email.trim();
            if(!EmailValidator.validate(email)){
                NotificationManager.error("Please enter a valid email address")
                return;
            }
            setIsLoading(true);
            const response = await Auth.forgotPassword(email.toLowerCase());
            NotificationManager.success("An email has been sent to your email address with a verification code for the next step.")
            response.CodeDeliveryDetails && setPasswordSent(true);
        } catch (error) {
            NotificationManager.error(error.message);
        }
        setIsLoading(false);
    };

    const onSubmitCode = async ({ code, email, resetPassword, passConfirm }) => {
        try {
            email = email.trim();
            if(!EmailValidator.validate(email)){
                NotificationManager.error("Please enter a valid email address")
                return;
            }
            if(resetPassword && passConfirm && resetPassword === passConfirm){
                setIsLoading(true);
                let response = await Auth.forgotPasswordSubmit(email.toLowerCase(), code, resetPassword);
                //TODO: encrypt this session variable?
                sessionStorage.setItem("usernameFromResetPassword", email)
                setShowSuccessModal(true);
            }
            else{
                NotificationManager.info("Please make sure the passwords match.")
            }

        } catch (error) {
            NotificationManager.error(error.message);
        }
        setIsLoading(false);
    };

    return(
        <div className="flex flex-col w-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Cyber Crucible Password Reset</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <InformationModal
                opened={showSuccessModal}
                onClose={() => {
                    document.getElementById("loginNavLink").click();
                }}
                text="Your password was changed successfully!"
                contentLabel={"Successfully Changed Password"}
            />
            <div className="flex flex-col justify-center content-center">
                <div className="flex flex-col gap-y-5 justify-center xl:max-w-lg lg:max-w-lg self-center">
                    <CCLogo className={"max-w-md mx-1"} />
                    <div className="flex flex-1 flex-col text-left items-start content-center ">
                        {!passwordSent && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-1 flex-col text-left px-6 md:p-0 items-start md:mx-12 xl:ml-30 md:mb-4 content-center gap-y-5">
                                    <label className="text-3xl">Reset Your Password</label>
                                    <span className="inline-block h-1 w-32 rounded bg-theme"></span>
                                    <div className="w-full gap-y-2 flex flex-col flex-wrap">
                                        <div className="text-dark-grey">Enter the email address associated with your account below.</div>
                                        <div className="text-dark-grey">We will either send you a verification code or a link to your email address to continue to the next step.</div>
                                    </div>
                                    <div className="w-full">
                                        <small className="">Email Address</small>
                                        <input
                                            autoFocus={true}
                                            type="text"
                                            required
                                            name="email"
                                            {...register("email")}
                                            className="focus:outline-none p-2 h-10 w-full rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="w-full flex flex-row justify-center">
                                        <ThemeProvider theme={roundButtonTheme}>
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                type={"submit"}
                                                className={"flex-1"}
                                                style={{ fontSize: 20 }}
                                            >
                                                Submit
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </form>
                        )}
                        {passwordSent && (
                            <form onSubmit={handleSubmit(onSubmitCode)}>
                                <div className="flex flex-1 flex-col text-left px-6 md:p-0 items-start md:mx-12 xl:ml-30 md:mb-4 content-center gap-y-5">
                                    <label className="text-3xl">Reset Password</label>
                                    <span className="inline-block h-1 w-32 rounded bg-theme"></span>
                                    <div className="w-full xl:max-w-lg lg:max-w-lg">
                                        <p className="text-dark-grey mb-3">
                                            Enter the verification code that was sent to your email
                                            address and fill out the remaining fields with your new
                                            password.
                                        </p>
                                    </div>
                                    <div className="w-full xl:max-w-lg lg:max-w-lg ">
                                        <small className="">Verification Code from Email</small>
                                        <input
                                            autoFocus={true}
                                            autoComplete="off"
                                            type="text"
                                            required
                                            name="code"
                                            {...register("code")}
                                            className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="w-full xl:max-w-lg lg:max-w-lg ">
                                        <small className="">Email Address</small>
                                        <input
                                            type="text"
                                            required
                                            name="email"
                                            {...register("email")}
                                            className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="w-full xl:max-w-lg lg:max-w-lg ">
                                        <small className="">Password</small>
                                        <input
                                            type="password"
                                            autoComplete="off"
                                            required
                                            name="resetPassword"
                                            {...register("resetPassword")}
                                            className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="w-full xl:max-w-lg lg:max-w-lg ">
                                        <small className="">Confirm Password</small>
                                        <input
                                            type="password"
                                            autoComplete="off"
                                            required
                                            name="passConfirm"
                                            {...register("passConfirm")}
                                            className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="w-full flex flex-row justify-center">
                                        <ThemeProvider theme={roundButtonTheme}>
                                            <Button variant={"contained"} color={"primary"}
                                                    type={"submit"}
                                                    className={"flex-1"}
                                                    style={{ fontSize: 20}}
                                            >
                                                Change Password
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div className="w-full xl:max-w-lg lg:max-w-lg mt-5 text-center self-center text-theme-teal hover:text-theme cursor-pointer"
                             onClick={() => {
                                 setPasswordSent(!passwordSent)
                             }}
                        >
                            {passwordSent ? "Need New Verification Code?" : "Already Received Verification Code?"}
                        </div>
                        <div className="w-full xl:max-w-lg lg:max-w-lg mt-5 text-center self-center">
                            <NavLink id="loginNavLink" className="text-theme-teal hover:text-theme" to="/login">
                                Back To Sign In
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    )
}
