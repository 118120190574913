import React from "react";

function Footer() {
    return (
        <footer className="text-gray-600 bg-dark-grey body-font">
            {/*<div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">*/}
            {/*    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">*/}
            {/*        <div className="lg:w-3/12 md:w-3/12 w-full px-4">*/}
            {/*            <h2 className="title-font text-white tracking-wider mb-3">About</h2>*/}
            {/*            <img className="logo mb-4" src="/images/logo/CyberCrucibleLogo-web.png" alt="Cyber Crucible Logo" />*/}
            {/*            <p className="text-white text-sm"><strong>Email:</strong> <a className="text-theme" href="mailto:info@cybercrucible.com">info@cybercrucible.com</a></p>*/}
            {/*            <p className="text-white text-sm"><strong>Phone:</strong> +14127752158</p>*/}
            {/*            <ul className="mt-6">*/}
            {/*                <li className="inline-block mr-2"><SocialIcon network="facebook" bgColor="#ffffff" url="https://www.facebook.com/cybercrucible" style={{ height: 35, width: 35 }} /></li>*/}
            {/*                <li className="inline-block mr-2"><SocialIcon network="twitter" bgColor="#ffffff" url="https://twitter.com/CyberCrucible" style={{ height: 35, width: 35 }}/></li>*/}
            {/*                <li className="inline-block mr-2"><SocialIcon network="linkedin" bgColor="#ffffff" url="https://www.linkedin.com/company/cybercrucible/" style={{ height: 35, width: 35 }}/></li>*/}
            {/*                <li className="inline-block mr-2"><SocialIcon network="youtube" bgColor="#ffffff" url="https://www.youtube.com/channel/UCM0Pj90f2-e1L_GgbStG3fg" style={{ height: 35, width: 35 }}/></li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="lg:w-3/12 md:w-3/12 w-full px-4">*/}
            {/*            <h2 className="title-font text-white tracking-wider mb-3">Company</h2>*/}
            {/*            <nav className="list-none footer-links mb-10">*/}
            {/*                <ul>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/">Home</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/about-us">About us</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/contact-sales">Contact sales</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://cybercrucible.atlassian.net/servicedesk/customer/portal/1">Get support</a>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </nav>*/}
            {/*        </div>*/}
            {/*        <div className="lg:w-3/12 md:w-3/12 w-full px-4">*/}
            {/*            <h2 className="title-font text-white tracking-wider mb-3">Products</h2>*/}
            {/*            <nav className="list-none footer-links mb-10">*/}
            {/*                <ul>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/product-page">Full feature list</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/zero-trust">Zero trust</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/hosting-your-own-data">Host your own data</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/pricing">Pricing</a>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </nav>*/}
            {/*        </div>*/}
            {/*        <div className="lg:w-3/12 md:w-3/12 w-full px-4">*/}
            {/*            <h2 className="title-font text-white tracking-wider mb-3">Resources</h2>*/}
            {/*            <nav className="list-none footer-links mb-10">*/}
            {/*                <ul>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/partner-portal">Partners</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/terms-and-conditions">Terms and Conditions</a>*/}
            {/*                    </li>*/}
            {/*                    <li className="mb-2">*/}
            {/*                        <a className="text-white" href="https://www.cybercrucible.com/privacy-policy">Privacy policy</a>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </nav>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="bg-white">
                <div className="mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row ">
                    <p className="text-gray-500 text-sm text-center sm:text-left text-left">© 2022 Cybercrucible, Inc. All rights reserved.</p>
                    {/*<span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">*/}
                    {/*    <ul>*/}
                    {/*        <li className="inline-block"><Link className="text-theme-teal hover:text-black" to="/sitemap">Site Map</Link> | </li>*/}
                    {/*        <li className="inline-block"><Link className="text-theme-teal hover:text-black" to="/privacy-policy">Privacy Policy</Link> | </li>*/}
                    {/*        <li className="inline-block"><Link className="text-theme-teal hover:text-black" to="/terms">EULA</Link></li>*/}
                    {/*    </ul>*/}
                    {/*</span>*/}
                </div>
            </div>
        </footer>
    )

}

export default Footer
