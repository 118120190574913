import {useForm} from "react-hook-form";
import Modal from "react-modal";
import React, {useState} from "react";
import Dropdown from "react-dropdown";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    createNewPartnerDealAndReturnStripeSessionIdReactive,
    createNewPartnerDealAndSendInvoiceForPurchaseLicensesReactive,
    partnerSendInvoiceOnlyForPurchaseLicensesReactive
} from "../pages/api/dealsApi";
import {returnSessionId} from "./stripeRedirectToCheckout";
import {buttonTheme} from "../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton} from "./muiComponents";

export const PartnerStripeModal = ({ onClose, opened, newDealData, zenGroups, setIsLoading, setShowNewDealModal, resetGrid, resetNewDealForm }) => {
    const { register, handleSubmit, reset} = useForm();
    const [zenGroup, setZenGroup] = useState();
    const [buttonClicked, setButtonClicked] = useState();

    const resetAll = () => {
        setZenGroup()
        if(!newDealData){
            //only reset license counts when not coming from new deal registration
            reset({
                serverLicenses: "",
                desktopLicenses: "",
            })
        }
        setButtonClicked()
        onClose()
    }

    const onSubmit = async (data) => {
        //setting input elements default values manually and disabling them makes the data.serverLicenses or data.desktopLicenses
        // values undefined when disabled is set to true, so we have to get the element itself and get the value that way
        var partnerModalServerLicensesCount = document.getElementById("partnerModalServerLicensesCount")
        var partnerModalDesktopLicensesCount = document.getElementById("partnerModalDesktopLicensesCount")
        if(!partnerModalDesktopLicensesCount || !partnerModalServerLicensesCount){
            NotificationManager.error("Unexpected error completing this request")
            return;
        }
        if(partnerModalServerLicensesCount.value < 1 && partnerModalDesktopLicensesCount.value < 1){
            NotificationManager.error("You must enter at least one desktop or server license")
            return;
        }
        if(zenGroup && data){
            data.serverLicenses = partnerModalServerLicensesCount.value
            data.desktopLicenses = partnerModalDesktopLicensesCount.value
            setIsLoading && setIsLoading(true)
            if(buttonClicked === "payNow"){
                if(data.serverLicenses > 99 || data.desktopLicenses > 99){
                    NotificationManager.info("The max amount of licenses you can pay for at a time is 99 for each license type. " +
                        "You may send an invoice instead.");
                    setIsLoading && setIsLoading(false)
                    return;
                }
                var pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                if(newDealData){
                    //pay now and insert new deal
                    try{
                        //save new deal
                        newDealData.zenGroupId = zenGroup
                        newDealData.desktopLicenseCount = data.desktopLicenses
                        newDealData.serverLicenseCount = data.serverLicenses
                        let response = await createNewPartnerDealAndReturnStripeSessionIdReactive(newDealData)
                        let sessionId = response.sessionId
                        if(sessionId){
                            try{
                                const stripe = window.Stripe(pk)
                                stripe.redirectToCheckout({sessionId: sessionId})
                            }
                            catch(error){
                                NotificationManager.info("The new deal was successfully registered, but an error occurred when continuing to payment.");
                                console.log(error)
                            }
                        }
                        else{
                            NotificationManager.info("The new deal was successfully registered, but an error occurred when continuing to payment.");
                        }
                        setIsLoading && setIsLoading(false)
                        resetAll()
                        setShowNewDealModal && setShowNewDealModal(false)
                        resetGrid && resetGrid()
                        resetNewDealForm && resetNewDealForm()
                    }
                    catch (error) {
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating new deal and continuing to payment");
                        }
                        setIsLoading && setIsLoading(false)
                        //resetAll()
                        return
                    }
                }
                else{
                    //not inserting new deal
                    let sessionId = await returnSessionId(zenGroup, data.desktopLicenses, data.serverLicenses)
                    if(sessionId){
                        try{
                            const stripe = window.Stripe(pk)
                            stripe.redirectToCheckout({sessionId: sessionId})
                        }
                        catch(error){
                            console.log(error)
                        }
                    }
                    else{
                        console.log("Error setting up stripe checkout")
                    }
                }
            }
            else if(buttonClicked === "sendInvoice"){
                if(newDealData){ //if newDealData is not null, then insert new deal and send invoice (we are coming from deals page new deal modal)
                    try{
                        //save new deal and send invoice by calling to createNewPartnerDealAndSendInvoiceForPurchaseLicenses endpoint
                        newDealData.zenGroupId = zenGroup
                        newDealData.desktopLicenseCount = data.desktopLicenses
                        newDealData.serverLicenseCount = data.serverLicenses
                        await createNewPartnerDealAndSendInvoiceForPurchaseLicensesReactive(newDealData)
                        NotificationManager.success("Successfully sent invoice and registered new deal");
                        setIsLoading && setIsLoading(false)
                        resetAll()
                        setShowNewDealModal && setShowNewDealModal(false)
                        resetGrid && resetGrid()
                        resetNewDealForm && resetNewDealForm()
                    }
                    catch (error) {
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating new deal and sending invoice");
                        }
                        setIsLoading && setIsLoading(false)
                        //resetAll()
                        return
                    }
                }
                else{
                    //not coming from new deal modal on deals page, the nav tab was clicked
                    try{
                        await partnerSendInvoiceOnlyForPurchaseLicensesReactive(zenGroup, data.desktopLicenses, data.serverLicenses)
                        NotificationManager.success("Successfully sent invoice");
                        setIsLoading && setIsLoading(false)
                        resetAll()
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating new deal and sending invoice");
                        }
                    }
                }
            }
            setIsLoading && setIsLoading(false)
        }
        else {
            NotificationManager.error("Please fill out all fields");
        }
    }
    return (
        <Modal
            onRequestClose={() => {
                resetAll()
            }}
            isOpen={opened}
            contentLabel={"Form Data Before Continuing to Payment"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-1 flex-col w-full gap-y-4">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Please Fill Out Form Before Continuing to Payment</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetAll()
                            }}
                        />
                    </div>
                    <hr className="h-0.5" />
                    {/*Form content*/}
                    <div className="ml-1">
                        <small>Select which distribution group to purchase licenses for</small>
                        <Dropdown
                            options={zenGroups.map(({ id, organizationName, friendlyName }) => ({
                                value: id,
                                label: organizationName || friendlyName || id,
                            }))}
                            value={zenGroup}
                            onChange={({ value }) => {
                                setZenGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <div className={"flex flex-row flex-wrap justify-center gap-x-10"}>
                        <div className={"ml-1 flex flex-col"}>
                            <small>Amount of server licenses</small>
                            <input
                                id={'partnerModalServerLicensesCount'}
                                autoComplete="off"
                                name="serverLicenses"
                                type="number"
                                disabled={newDealData ? true : false}
                                defaultValue={newDealData ? newDealData.serverLicenseCount : 0}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g,'');
                                }}
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("serverLicenses")}
                                className="text-center self-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={"ml-1 flex flex-col"}>
                            <small>Amount of desktop licenses</small>
                            <input
                                id={'partnerModalDesktopLicensesCount'}
                                autoComplete="off"
                                name="desktopLicenses"
                                type="number"
                                disabled={newDealData ? true : false}
                                defaultValue={newDealData ? newDealData.desktopLicenseCount : 0}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g,'');
                                }}
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("desktopLicenses")}
                                className="text-center self-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                    </div>
                    <div className={"ml-1 flex flex-1 flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                        <ThemeProvider theme={buttonTheme}>
                        <Button variant={"contained"} color={"primary"} className={"flex-1"}
                                type={"submit"}
                                onClick={(event) => {
                            setButtonClicked("payNow")
                        }}>
                            Pay Now
                        </Button>
                        <Button variant={"contained"} color={"primary"} className={"flex-1"}
                                type={"submit"}
                                onClick={(event) => {
                                setButtonClicked("sendInvoice")
                            }}>
                            Send Invoice
                        </Button>
                        </ThemeProvider>
                    </div>

                </div>

            </form>
        </Modal>
    );
};
