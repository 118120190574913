import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {
    assignAgentLicenseReactive,
    cancelUninstallAgentReactive,
    createEncryptionCheckTaskReactive,
    distinctAgentVersionsReactive,
    editAgentManagedUpdateAutoUpdateSettingReactive,
    findByAgentIdListReactive,
    getAgentVersionsCountPerGroupReactive,
    getLatestAgentVersionReactive,
    getRRInstallScriptBuilderBase64Reactive,
    getZenGroupCodesForSiteReactive,
    overrideGroupUpdatePolicyForAgentReactive,
    removeAgentManagedUpdateSettingsReactive,
    singleChangeAgentGroupReactive,
    singleSetAgentAutoUpgradeReactive,
    singleSetAgentVisibilityReactive,
    uninstallAgentReactive,
    updateAgentsGridColumnStateReactive,
    updateAgentsGridFilterModelReactive,
    updateAgentsGridUseColumnStateReactive,
    updateAgentsGridUseFilterStateReactive
} from "../api/agentsApi";
import {NotificationContainer} from 'react-notifications';
import {getLicenseNameWithAgentIdReactive,} from "../api/licensesApi";
import {downloadShutdownScriptFile, shutdownServiceScriptGenerate} from "../../utils/generator";
import Modal from "react-modal";
import {
    ConfirmationModal,
    ConfirmationModalWithPermissionsShown,
    PermissionsGrid,
    processGroupPermissionData
} from "../../components/confirmationModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SidebarMenu from "../../components/sideBarComponent";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide,
    licensesListWithAgentIdSessionStorageChangeStreamListener
} from "../../utils/licenseNameGridHelper";
import {agentPageCellEditingStopped} from "../../utils/gridCellEditing";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    findZenGroupById,
    getZenGroupDropDownContents,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/encryptDecryptHelper";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {checkPermission} from "../../utils/permissionCheckHelper";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";

import {AgChartsReact} from "ag-charts-react";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, cellButtonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupIcon from '@mui/icons-material/Group';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

Modal.setAppElement('#root')
let gridColumnStateSessionVariableName = "agentsGridColumnState"
let followingGroupPolicyText = "Following Group Policy"
let overridingGroupPolicyText = "Overriding Group Policy"
let doNotUpdateKeywordForApprovedVersion = "Do Not Update"
let minColumnIds = ["zenGroupDisplayName", "machineName", "version", "specialStatusMessage", "lastRebootDateTime"]
let medColumnIds = ["zenGroupDisplayName", "agentDisplayName", "machineName", "version", "specialStatusMessage", "licenseDisplayName", "lastTaskingDateTime",
    "lastAboutDateTime", "lastWhitelistDateTime", "lastValidateDateTime", "hiddenFromUI"]

export default function Agents() {
    //TODO: make sidebar for private pages?
    const [isLoading, setIsLoading] = useState(false);
    //const [zenGroups, setZenGroups] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [zenGroup, setZenGroup] = useState();
    const [updateAgentZG, setUpdateAgentZG] = useState();
    const [zenGroupNamesWithPermission, setZenGroupNamesWithPermission] = useState(new Set());
    const [zenGroupNamesWithoutPermission, setZenGroupNamesWithoutPermission] = useState(new Set());
    const [zenGroupIdsWithoutPermission, setZenGroupIdsWithoutPermission] = useState(new Set());
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [showSuspendServiceModal,setShowSuspendServiceModal] = useState(false);
    const [showUninstallConfirmation, setShowUninstallConfirmation] = useState(false);
    const [showInstallScriptModal, setShowInstallScriptModal] = useState(false);
    const [installScriptGroup, setInstallScriptGroup] = useState();
    const [installerType, setInstallerType] = useState("Script");
    const [showChangeGroupsModal, setShowChangeGroupsModal] = useState(false);
    const [newAgentZenGroup, setNewAgentZenGroup] = useState();
    const [showBulkVisibilityModal, setShowBulkVisibilityModal] = useState(false);
    const [bulkShowAgentsToggled, setBulkShowAgentsToggled] = useState(true);
    const [showAutoUpgradeModal, setShowAutoUpgradeModal] = useState(false);
    const [bulkAutoUpgradeToggled, setBulkAutoUpgradeToggled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [singleAgentAutoUpgradeData, setSingleAgentAutoUpgradeData] = useState({});
    const [versionStringList, setVersionStringList] = useState([]);
    const [latestAgentVersion, setlatestAgentVersion] = useState("");
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("agentsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [suspendServiceGroup,setSuspendServiceGroup] = useState();
    const [gridParams, setGridParams] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridFilters, setGridFilters] = useState(null);
    const [gridCount, setGridCount] = useState(null);
    const [gridPage, setGridPage] = useState(null);
    const [gridSortModel,setGridSortModel] = useState(null);
    const [enableButtons, setEnableButtons] = useState(false);
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [showAssignLicenseConfirmModal, setShowAssignLicenseConfirmModal] = useState(false);
    const [assignLicenseAgentData, setAssignLicenseAgentData] = useState({});
    const agentLocation = useLocation();
    const [showPermissionGrid, setShowPermissionGrid] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(true);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(1000);
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState([]);
    const [alignment, setAlignment] = useState('med');

    /*
        1000ms to start for the initial sse data pull, larger grids benefit from the second delay to give ag grid time to perform saved sorts to apply by default. If we have small number of
        ms then ag grid performance for certain column sorts is slower since it has to re-sort and re-render much more frequently.
     */
    const triggerAssignAgentLicense = (assignLicenseAgentData) => {
        if(assignLicenseAgentData && assignLicenseAgentData.agentId){
            if(assignLicenseAgentData.specialStatusMessage === "uninstalled"){
                NotificationManager.error("You cannot assign a license to an uninstalled agent.")
                return
            }
            assignAgentLicenseReactive(assignLicenseAgentData.agentId).then(response => {
                NotificationManager.success("Successfully assigned a license to this agent.")
                setAssignLicenseAgentData({})
                refreshGrid().then(r => {})

            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error assigning a license to this agent.")
                }
            })
        }
        else{
            NotificationManager.error("Unexpected error making this request");
        }

    }
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "agentWasInsertedInValidateWithoutLicense", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "licenseId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "agentWasInsertedInValidateWithoutLicense", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true,true, false),
        { field: "agentDisplayName", name: "Agent Name", sortable: true, width: 330,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
            let uninstallDiv = ""
                if(params.node.data.specialStatusMessage !== "uninstalled" && params.node.data.specialStatusMessage !== "uninstallInProgress"){
                    uninstallDiv =
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    icon="fa-duotone fa-trash-can"
                                    size="xs"
                                    className="object-contain mr-0"
                                />
                            }
                            onClick={() => {
                                if(params.node.data.version){
                                    try{
                                        let versionStr = params.node.data.version.replaceAll(".", "")
                                        const regex = /\D/g; //all non-digits
                                        versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                                        let versionInt = parseInt(versionStr, 10)
                                        if (versionInt <= 332 || isNaN(versionInt) || versionInt === null) {
                                            console.log(`Agent ${params.node.data.agentDisplayName} is not compatible with the remote uninstall feature.`)
                                        }
                                        else{
                                            if(params.node.data.specialStatusMessage === "uninstalled"){
                                                console.log(`Agent ${params.node.data.agentDisplayName} is uninstalled already`)
                                                NotificationManager.error(`This agent is already uninstalled`);
                                            }
                                            else{
                                                uninstallAgentReactive(params.node.data.agentId)
                                                    .then(() => {
                                                        NotificationManager.success(`Uninstall task successfully queued`)
                                                        params.node.setDataValue("specialStatusMessage","uninstallInProgress")
                                                        //need to refresh agent name col to make uninstall icon disappear, limiting to only this row's agentDisplayName cell
                                                        params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: true, force: true})
                                                    }).catch((error) => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message)
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error requesting uninstall")
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    catch(error){
                                        NotificationManager.error(`Error requesting remote uninstall, agent is not compatible with the remote uninstall feature`);
                                    }
                                }
                            }}
                            tooltipTitle={"Click to uninstall this agent"}
                            tooltipPlacement={"bottom-start"}
                        />
                }
                return (
                    <div className={"flex flex-nowrap justify-start gap-x-1 items-center"}>
                        {uninstallDiv}
                        {editNameIconOnlyCellRenderer(params, "Click to Edit this Agent's Name", "agentDisplayName")}
                    </div>
                )
            },
        },
        { field: "machineName", name: "Machine Name", sortable: true, width: 225,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
        },
        { field: "version", name: "Version", sortable: true, width: 175,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
        },
        { field: "specialStatusMessage", name: "Attention", minWidth: 200,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: function (params) {
                    if(params.value!=="(Select All)"){
                        switch(params.value) {
                            case "none":
                                return "None"
                            case "uninstallInProgress":
                                return "Uninstall In Progress"
                            case "uninstalled":
                                return "Uninstalled"
                            case "scanForEncryptedFiles":
                                return "Scan For Encrypted Files"
                            case "scanInProgress":
                                return "Scan In Progress"
                            case "noEncryptionFound":
                                return "No Encryption Found"
                            case "unknownEncryptionFound":
                                return "Unknown Encryption Found"
                            case "decrypting":
                                return "Decrypting"
                            case "decrypted":
                                return "Decrypted"
                            case "updateStaged":
                                return "Update Staged"
                            default:
                                return "";
                        }
                    }
                    else{
                        return params.value;
                    }
                },
                values: ['decrypted', 'decrypting', 'noEncryptionFound', 'none', 'scanForEncryptedFiles',
                    'scanInProgress', 'uninstallInProgress','uninstalled', 'unknownEncryptionFound',
                    'updateStaged'],
                suppressSorting: true,
            },
            sortable:true,
            cellRenderer:
                function (params) {
                    if(params.node.data.specialStatusMessage === "scanForEncryptedFiles"){
                        console.log("Found a scanForEncryptedFiles");
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        onClick={() => requestEncryptCheck(params.node)}
                                >Encryption Scan
                                </Button>
                            </ThemeProvider>

                        )
                    }else if(params.node.data.specialStatusMessage === "scanInProgress"){
                        return "Scanning"
                    }else if(params.node.data.specialStatusMessage === "decrypting"){
                        return "Decrypting"
                    }else if(params.node.data.specialStatusMessage === "decrypted"){
                        return "Decrypted"
                    }else if(params.node.data.specialStatusMessage === "uninstallInProgress"){
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button variant={"contained"}
                                    color={"primary"}
                                    onClick={() => cancelUninstall(params.node, params)}
                                >Cancel Uninstall
                                </Button>
                            </ThemeProvider>
                        )
                    }else if(params.node.data.specialStatusMessage === "uninstalled"){
                        return "Uninstalled"
                    }
                    else if(params.node.data.specialStatusMessage === "noEncryptionFound"){
                        return "No Encryption Found"
                    }
                    else if(params.node.data.specialStatusMessage === "unknownEncryptionFound"){
                        return "Unknown Encryption Found"
                    } else if(params.node.data.specialStatusMessage === "updateStagingStarted" || params.node.data.specialStatusMessage === "updateStaged" || params.node.data.specialStatusMessage === "updateStagedSuccessful"){
                        //format here since the params.node.data.updateStagedText was added in the populateGrid function below if agent is in one of the staged update status above
                        if(params.node.data.updateStagedText === undefined){
                            return null
                        }
                        return params.node.data.updateStagedText
                    }
                    else if(params.node.data.specialStatusMessage === "none"){
                        return null
                    }
                    else{
                        if(params.node.data.specialStatusMessage && params.node.data.specialStatusMessage.length>0 && params.node.data.specialStatusMessage!== "none") {
                            //console.log("params.node.data.specialStatusMessage: " + params.node.data.specialStatusMessage);
                        }
                        return null
                    }
                }
        },
        defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide(triggerAssignAgentLicense, setAssignLicenseAgentData),
        { field: "lastTaskingDateTime", name: "Latest Tasks Download", sortable: true, width: 285,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "installDate", name: "Installed", sortable: true, width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastRebootDateTime", name: "Latest Reboot", sortable: true, width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastAboutDateTime", name: "Latest Machine Data Update", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastSchedulesDateTime", name: "Latest Schedules Download", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastWhitelistDateTime", name: "Latest Whitelists Download", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastShouldAgentUpdateDateTime", name: "Latest Update Check", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastValidateDateTime", name: "Latest Validation Check", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastSSLCertListDateTime", name: "Latest Certificate Download", sortable: true, width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "updatePolicy", name: "Override Group Update Policy", width: 330,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: [overridingGroupPolicyText, followingGroupPolicyText],
                suppressSorting: false,
            },
            sortable: true,
            tooltipValueGetter: "This setting shows whether the agent is following its group policy for updating or overriding the group update policy and following its own update settings",
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                checked={params.node.data.updatePolicy !== followingGroupPolicyText}
                                color={"primary"}
                                name={`cellToggleUpdatePolicy${params.node.data.agentId}`}
                                onChange={(changeEvent) => {
                                    let oldValue = params.node.data.updatePolicy
                                    if(params.node.data.agentId){
                                        if(oldValue === followingGroupPolicyText){
                                            //Call to /overrideGroupUpdatePolicyForAgentReactive to override group policy and populate the agent's managedUpdateSettings
                                            overrideGroupUpdatePolicyForAgentReactive(params.node.data.agentId).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's update policy.`);
                                                //let change stream handle changing these fields
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's update policy");
                                                }
                                            })
                                        }
                                        else if(oldValue === overridingGroupPolicyText){
                                            //call to /removeAgentManagedUpdateSettingsReactive endpoint in order to follow group policy again
                                            removeAgentManagedUpdateSettingsReactive(params.node.data.agentId).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's update policy.`);
                                                //let change stream handle changing these fields
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's update policy");
                                                }
                                            })
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error making this request");
                                    }
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            }
        },
        /*
            The managedUpdateSettings column is really for autoUpdate setting, but there was issue with toggle not refreshing sometimes to disable/enable toggle when the field for the column was autoUpdate. By making it
            managedUpdateSettings instead we can define the equals function that handles if the cell renderer should be refreshed or not so we can always disable/enable correctly.
         */
        { field: "managedUpdateSettings", name: "Always Update to Latest Version", width: 350,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ["On", "Off"],
                suppressSorting: true,
            },
            sortable: true,
            tooltipValueGetter: "You can only edit this setting when agents override their group update policy. You can edit the group update policy on the groups page.",
            equals: (managedUpdateSettings1, managedUpdateSettings2) => {
                if((managedUpdateSettings1 === null && managedUpdateSettings2 !== null) || (managedUpdateSettings2 === null && managedUpdateSettings1 !== null)){
                    return false
                }
                else return managedUpdateSettings1?.autoUpdate === managedUpdateSettings2?.autoUpdate;
            },
            keyCreator: (params) => {
                if(params.node.data.autoUpdate){
                    return "On"
                }
                else{
                    return "Off"
                }
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                return (nodeA.data.autoUpdate === nodeB.data.autoUpdate) ? 0 : (nodeA.data.autoUpdate > nodeB.data.autoUpdate) ? 1 : -1;
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                disabled={params.node.data.updatePolicy === followingGroupPolicyText}
                                checked={params.node.data.autoUpdate}
                                name={`cellToggleAutoUpdate${params.node.data.agentId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.updatePolicy === followingGroupPolicyText){
                                        //should not have gotten through to be able to toggle in this instance since we disable in this case, but double check
                                        return
                                    }
                                    let oldValue = params.node.data.autoUpdate
                                    if(params.node.data.agentId){
                                        editAgentManagedUpdateAutoUpdateSettingReactive(params.node.data.agentId, !oldValue).then(result => {
                                            NotificationManager.success(`Successfully changed this agent's auto update setting.`);
                                            //let change stream handle changing these fields
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this agent's auto update settings");
                                            }
                                        })
                                    }
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
                }
        },
        { field: "latestAgentVersionApproved", name: "Latest Agent Version Approved for Updates", width: 425,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: false,
                suppressSorting: true, //we want values to be in descending order which we do before storing in session, so suppress ag grid sorting this list in asc order to show most recent versions first
                values: function(params){
                    try{
                        //should not use the distinctAgentVersionsList hook here to provide filter values because after testing the filter does not get the updated list when using the
                        // setDistinctAgentVersionsList in the useEffect above, so just do some processing here to provide the filter values
                        let agentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                        if(agentVersionsList === null){
                            distinctAgentVersionsReactive().then(data => {
                                //store list in descending order by version number
                                data?.sort()?.reverse()
                                data.unshift(doNotUpdateKeywordForApprovedVersion) //put at beginning
                                params.success(data)
                                encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                            }).catch(error => {})
                        }
                        params.success(agentVersionsList)
                    }catch (e) {
                        params.success([])
                    }
                },
            },
            sortable: true,
            tooltipValueGetter: "This setting controls what version agents are allowed to update to. You can only edit this setting when " +
                "agents override their group update policy. This setting will not downgrade agents.",
            editable: (params) => {
                return params.node.data.updatePolicy !== followingGroupPolicyText && params.node.data.autoUpdate === false;
            },
            cellEditorType: "agSelectCellEditor",
            editableOptions: { values: distinctAgentVersionsList },
        },
        { field: "hiddenFromUI", name: "Dashboard Visibility", width: 250,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values:['Hidden','Visible'],
                suppressSorting: false,
            },
            sortable: true,
            keyCreator: (params) => {
                if(params.node.data.hiddenFromUI){
                    return "Hidden";
                }else{
                    return "Visible";
                }
            },
            valueGetter: (params) => {
                if(params.node.data.hiddenFromUI){
                    return "Hidden";
                }else{
                    return "Visible";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={!params.node.data.hiddenFromUI}
                                    name={`cellToggleHiddenFromUI${params.node.data.agentId}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.agentId){
                                            singleSetAgentVisibilityReactive(params.node.data.agentId, !params.node.data.hiddenFromUI).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's visibility setting.`);
                                                params.node.setDataValue("hiddenFromUI",!params.node.data.hiddenFromUI);
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's visibility setting");
                                                }
                                            })
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error updating this agent's visibility setting");
                                        }
                                    }}
                                />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                }
        },
        { field: "buildType", name: "Build", sortable: true, width: 150,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['x86', 'x64'],
                suppressSorting: true,
                suppressSelectAll: false,
            },
        },
        { field: "ipList", name: "IP Addresses List", sortable: true, width: 300,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            valueGetter: function (params) {
                if(params.data.ipList){
                    let filteredIpList = params.data.ipList.filter(function(ip) {
                        return ip !== "0.0.0.0";
                    });
                    return filteredIpList.toString()
                }
            }
        },
        { field: "fqdn", name: "FQDN", sortable: true, width: 250,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
        },
    ]);
    var _ = require('lodash');

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            try{
                let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                if(sessionAgentVersionsList === null){
                    distinctAgentVersionsReactive().then(data => {
                        //store list in descending order by version number
                        data?.sort()?.reverse()
                        data.unshift(doNotUpdateKeywordForApprovedVersion)
                        setDistinctAgentVersionsList(data)
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "latestAgentVersionApproved"){
                                col.editableOptions = {values: data}
                            }
                        })
                        encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                    }).catch(error => {})
                }
                else{
                    setDistinctAgentVersionsList(sessionAgentVersionsList)
                    columnDefs?.forEach((col) => {
                        if(col && col.field === "latestAgentVersionApproved"){
                            col.editableOptions = {values: sessionAgentVersionsList}
                        }
                    })
                }
            } catch (e) {}
        }) ()
        return () => controller?.abort();
    }, [])

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setChartIsLoading(true)
            //async call for latest agent version
            getLatestAgentVersionReactive().then(response => {
                setlatestAgentVersion(response)
            }).catch(function (error) {
                console.error(error)
            })
            //async call for chart data
             getAgentVersionsCountPerGroupReactive().then(response => {
                let agentList = response
                 if (agentList) {
                     let versions = []
                     let finalData = []
                     //In this loop we get our y-value of versions for chart and format the data so each object has one zenGroup with each version/count key pair
                     for( let i = 0; i < agentList.length; i++ ){
                         let isInObject = false
                         if(agentList[i].version === null || agentList[i].zenGroupId === null){
                             continue
                         }
                         //handle adding to versions list for unique agent versions from the data
                         if(agentList[i].version !== null && !versions.includes(agentList[i].version)){
                             versions.push(agentList[i].version)
                         }
                         //handle formatting chart data
                         finalData.forEach(object =>{
                             if(object.zenGroupId === agentList[i].zenGroupId){
                                 //we already have an entry for this group in finalData, so we can add the version to this row/object in finalData
                                 isInObject = true
                                 object[agentList[i].version] = agentList[i].count //adding this agent version as a key to object for current group and the count as the value
                             }
                         })
                         //check if we found the groupId in finalData or not. If not then we need to initially populate finalData with this group and agent version
                         if(!isInObject){
                             //also populate zenGroupName
                             let group = findZenGroupById(agentList[i].zenGroupId)
                             if(group && group.friendlyName){
                                 //found group in session
                                 finalData.push({"zenGroupId":agentList[i].zenGroupId,[agentList[i].version]:agentList[i].count, "zenGroupName":group.friendlyName})
                             }
                             else{
                                 //else did not find group in session
                                 finalData.push({"zenGroupId":agentList[i].zenGroupId,[agentList[i].version]:agentList[i].count})
                             }
                         }
                     }
                     //sort finalData by zenGroupName, need to convert zenGroupName to lower case for comparison
                     finalData.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                     //sort list of agent versions
                     versions.sort()
                     //populate hooks used in chart
                     setVersionStringList(versions)
                     setChartData(finalData)

                } else {
                    setChartData([])
                }
                 setChartIsLoading(false)
            }).catch(function (error) {
                setChartIsLoading(false)
            })
        })()
        return () => controller?.abort();
    }, []);

    const downloadShutdownScript = async () => {
        try {
            if(zenGroupSessionStorage && zenGroupSessionStorage.length>0){
                if(!suspendServiceGroup){
                    setSuspendServiceGroup(zenGroupSessionStorage[0].id)
                }
                const codes = await getZenGroupCodesForSiteReactive(suspendServiceGroup);
                const file = shutdownServiceScriptGenerate(codes[0], codes[1], codes[2]);
                downloadShutdownScriptFile(file, "RR-shutdown-service-script");
            }
        } catch (error) {
            NotificationManager.error(
                "Error generating Shutdown Service Script. Please try again."
            );
        }
    };

    const onZenGroupUpdate = async () => {
        if (updateAgentZG && zenGroupSessionStorage && zenGroupSessionStorage.length>0) {
            const { agentId, zenGroupId, data } = updateAgentZG;
            const updatedZenGroup = zenGroupSessionStorage.find(
                (zg) => zg.friendlyName === zenGroupId || zg.id === zenGroupId
            );
            if(!updatedZenGroup){
                return;
            }
            singleChangeAgentGroupReactive(updatedZenGroup.id,agentId).then(function(response){
                data.zenGroupId = updatedZenGroup.id
                data.zenGroupDisplayName = updatedZenGroup.friendlyName

                NotificationManager.success(
                    'Group successfully updated for this agent'
                );
            }).catch(function(error){
                if(error.message) {
                    NotificationManager.error("Group change failed due to "+error.message);
                }else{
                    NotificationManager.error("Group change failed for agent");
                }
            })
        }
        setUpdateAgentZG(null);
        setShowUpdateConfirmation(false);
    };

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agents</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Update zenGroup confirm modal*/}
            <ConfirmationModal
                text="You are about to change the group of this agent, would you like to continue?"
                onConfirm={() => onZenGroupUpdate()}
                onClose={() => {
                    setShowUpdateConfirmation(false)
                }}
                opened={showUpdateConfirmation}
            />
            {/*Assign License Confirm Modal*/}
            <ConfirmationModal
                text={`The first available license in ${(assignLicenseAgentData && assignLicenseAgentData.agentDisplayName) ?
                    `agent: ${assignLicenseAgentData.agentDisplayName}'s` : "this agent's"} group (or the group's assigned distribution group) will be assigned to this agent, would you like to continue?`}
                onConfirm={() => {
                    if(assignLicenseAgentData && assignLicenseAgentData.agentId){
                        if(assignLicenseAgentData.specialStatusMessage === "uninstalled"){
                            NotificationManager.error("You cannot assign a license to an uninstalled agent.")
                            return
                        }
                        assignAgentLicenseReactive(assignLicenseAgentData.agentId).then(response => {
                            NotificationManager.success("Successfully assigned a license to this agent.")
                            setShowAssignLicenseConfirmModal(false)
                            setAssignLicenseAgentData({})
                            refreshGrid().then(r => {})
                        }).catch(function(error){
                            if(error.message){
                                NotificationManager.error(error.message)
                            }
                            else{
                                NotificationManager.error("Unexpected error assigning a license to this agent.")
                            }
                        })
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                }}
                onClose={() => {
                    setShowAssignLicenseConfirmModal(false)
                    setAssignLicenseAgentData({})
                }}
                opened={showAssignLicenseConfirmModal}
            />
            {/*Uninstall agent confirm modal*/}
            <ConfirmationModalWithPermissionsShown
                text="You are about to uninstall the selected agent(s). The grid below shows your permission level to do this action for each of the selected agents' group:"
                onConfirm={() => requestUninstall()}
                onClose={() => {
                    setShowUninstallConfirmation(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showUninstallConfirmation}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />
            {/*Cancel uninstall agent confirm modal
                /*<ConfirmationModal
                    text="You are about to cancel this agent's uninstall request, would you like to continue?"
                    onConfirm={() => cancelUninstall()}
                    onClose={() => {
                        setShowCancelUninstallAgentModal(false)
                        //setCancelUninstallAgentId(null)
                    }}
                    opened={showCancelUninstallAgentModal}
                />*/}

            {/*New Agent Download Modal for picking group to install agent for*/}
            <Modal contentLabel="Download Agent Modal" isOpen={showInstallScriptModal}
                   onRequestClose={() => {
                       setShowInstallScriptModal(false)
                       setInstallScriptGroup()
                       setInstallerType("Script")
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Download Agent</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowInstallScriptModal(false)
                                setInstallScriptGroup()
                                setInstallerType("Script")
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>Select a Group for the Agent</label>
                        <Dropdown
                            options={getZenGroupDropDownContents()}
                            value={installScriptGroup}
                            onChange={({ value }) =>{
                                setInstallScriptGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <div className="ml-1">
                        <label>Select an Installer Type</label>
                        <Dropdown
                            options={["Script", "GUI"]}
                            value={installerType}
                            onChange={({ value }) =>{
                                setInstallerType(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                if(installScriptGroup && installerType){
                                    if(installerType === "Script"){
                                        //TODO: send the selected group to the api
                                        setIsLoading(true)
                                        getRRInstallScriptBuilderBase64Reactive(installScriptGroup, "SCRIPT_GENERATOR").then(response  => {
                                            try{
                                                let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                let today = new Date();
                                                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                let time = today.getHours() + "-" + today.getMinutes();
                                                let dateTime = date+'T'+time;
                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);
                                                link.download=`RR-Install-Script-${dateTime}.bat`;
                                                link.click();
                                            }
                                            catch(error){
                                                console.error("Error creating generating installer script")
                                            }
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            //console.error(error);
                                            if(error.message){
                                                NotificationManager.error(error.message)
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error generating the installer script`);
                                            }
                                            setIsLoading(false)
                                        })
                                    }
                                    else if (installerType === "GUI"){
                                        //TODO: send the selected group to the api
                                        setIsLoading(true)
                                        getRRInstallScriptBuilderBase64Reactive(installScriptGroup, "GUI").then(response  => {
                                            try{
                                                let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                let today = new Date();
                                                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                let time = today.getHours() + "-" + today.getMinutes();
                                                let dateTime = date+'T'+time;

                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);
                                                link.download=`RR-Installer-${dateTime}.exe`;
                                                link.click();
                                            }
                                            catch(error){
                                                console.error("Error creating gui installer")
                                            }
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            //console.error(error);
                                            if(error.message){
                                                NotificationManager.error(error.message)
                                            }
                                            else{
                                                NotificationManager.error(`Unexpected error creating gui installer`);
                                            }
                                            setIsLoading(false)
                                        })

                                    }
                                }
                            }}>
                        Download Agent Installer
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>

            {/*Emergency Shutdown Service Modal*/}
            <Modal contentLabel="Emergency Shutdown Service" isOpen={showSuspendServiceModal}
                   onRequestClose={() => {
                       setShowSuspendServiceModal(false)
                       setSuspendServiceGroup(zenGroup)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Emergency Shutdown Service</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowSuspendServiceModal(false)
                                setSuspendServiceGroup(zenGroup)
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>Select a Group</label>
                        <Dropdown
                            options={getZenGroupDropDownContents()}
                            value={suspendServiceGroup}
                            onChange={({ value }) =>{
                                setSuspendServiceGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button  variant={"contained"}
                                 color={"primary"}
                                 onClick={downloadShutdownScript}>
                            Generate Emergency Shutdown Service Script
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Change Agent's Group Modal*/}
            <Modal contentLabel="Change Agent Group" isOpen={showChangeGroupsModal}
                   onRequestClose={() => {
                       setShowChangeGroupsModal(false)
                       setNewAgentZenGroup(null)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Group" : "Change Agents' Group"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowChangeGroupsModal(false)
                                setNewAgentZenGroup(null)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>New Group for Selected Agent(s)</label>
                        <Dropdown
                            options={getZenGroupDropDownContents()}
                            value={newAgentZenGroup}
                            onChange={({ value }) =>

                                setNewAgentZenGroup(value)
                            }
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change an agent's group for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", name: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", name: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button
                            disabled={zenGroupNamesWithPermission?.size < 1}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                changeAgentsGroup()
                            }}>
                            Update
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Bulk Set Agent Auto-Upgrade Modal*/}
            <Modal contentLabel="Set Agent Auto-Upgrade" isOpen={showAutoUpgradeModal}
                   onRequestClose={() => {
                       setShowAutoUpgradeModal(false)
                       setBulkAutoUpgradeToggled(true)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Auto-Upgrade Setting" : "Change Agents' Auto-Upgrade Setting"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowAutoUpgradeModal(false)
                                setBulkAutoUpgradeToggled(true)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <label className="ml-1 mt-2">
                        Only agents with version 4.2.3.0 or higher are able to remotely set their auto-upgrade setting. Duplicate tasks for agents will not be made.
                    </label>
                    <div className={`flex flex-row items-center ml-1 mt-2`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={bulkAutoUpgradeToggled}
                                    name="toggleBulkAutoUpgrade"
                                    onChange={e => setBulkAutoUpgradeToggled(e.target.checked)}
                                />
                            } label={bulkAutoUpgradeToggled ? "Auto-Upgrade On" : "Auto-Upgrade Off"}/>
                        </ThemeProvider>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change an agent's auto-upgrade setting for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", name: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", name: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                disabled={zenGroupNamesWithPermission?.size < 1}
                                onClick={() => {
                                    bulkSetAutoUpgradeRequest()
                                }}>
                                Update Selected Agent(s)
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Bulk set agent default visibility modal*/}
            <Modal contentLabel="Set Default Agent Visibility" isOpen={showBulkVisibilityModal}
                   onRequestClose={() => {
                       setShowBulkVisibilityModal(false)
                       setBulkShowAgentsToggled(true)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Default Visibility Setting" : "Change Agents' Default Visibility Setting"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowBulkVisibilityModal(false)
                                setBulkShowAgentsToggled(true)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <label className="ml-1 mt-2">
                        By default, only agents that do not have their visibility setting as hidden will be shown in the grid. You may see hidden agents by changing the filter in the
                        'Default Grid Visibility Setting' column header.
                    </label>
                    <div className={`flex flex-row items-center ml-1 mt-2`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={bulkShowAgentsToggled}
                                    name="toggleBulkShowAgentOnGrid"
                                    onChange={e => setBulkShowAgentsToggled(e.target.checked)}
                                />
                            } label={bulkShowAgentsToggled ? "Visible" : "Hidden"}/>
                        </ThemeProvider>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change an agent's default visibility setting for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", name: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", name: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                disabled={zenGroupNamesWithPermission?.size < 1}
                                onClick={() => {
                                    bulkSetAgentDefaultVisibilityRequest()
                                }}>
                                Update Selected Agent(s)
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading} />
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col">
                    <div className="flex flex-col ml-10 mr-10 mt-8 flex-nowrap gap-y-3">
                        <div className="flex flex-row justify-between">
                            {/*Top with manage agents title and download agents button, flex it with button at end*/}
                            <div className="flex flex-row justify-start flex-nowrap gap-x-5 gap-y-3">
                                <img
                                    src="/images/logo/result.svg"
                                    className="min-w-[115%]"
                                    alt="Cyber Crucible logo"
                                />
                                <div className="text-4xl self-center shrink-0">Agents</div>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-3">
                                {/*<div>
                                        <button className="focus:outline-none buttons p-4 rounded-3xl ml-10 text-white" onClick={() => {setShowSuspendServiceModal(true)}}>
                                            Generate Shutdown Script
                                        </button>
                                    </div>*/}

                                <div className="">
                                    <ThemeProvider theme = {roundButtonTheme}>
                                        <Button variant={"contained"}
                                                color={"primary"}
                                                className={`focus:outline-none buttons p-4 rounded-3xl text-white`}
                                                onClick={() => {setShowInstallScriptModal(true)}}
                                        >
                                        Download Agent
                                        </Button>
                                    </ThemeProvider>                                    {/*<div
                                            className={`${showDownloadOptions ? "block" : "hidden"}
                                    bg-white shadowMenu m-10 mt-0 mb-1`}>
                                            <button
                                                disabled={!guiBase64Data}
                                                onClick={() => {
                                                    if(guiBase64Data){
                                                        try{
                                                            let bytes = base64ToArrayBuffer(guiBase64Data); // pass your byte response to this constructor
                                                            let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes

                                                            let link=document.createElement('a');
                                                            link.href=window.URL.createObjectURL(blob);
                                                            link.download="RR-Installer.exe";
                                                            link.click();
                                                        }
                                                        catch(error){
                                                            console.error("Error creating gui installer")
                                                        }
                                                    }
                                                    else{
                                                        console.error("guiBase64Data not found")
                                                    }
                                                }}
                                                className={`${!guiBase64Data ? '' : 'downloadAgentButton'} focus:outline-none p-5 w-full text-left`}>
                                                <FontAwesomeIcon
                                                    className="object-contain mr-5"
                                                    icon="fa-solid fa-download"
                                                    size="lg"
                                                />
                                                Download GUI Installer
                                            </button>
                                            <br />
                                            <button
                                                disabled={!installScriptBase64Data}
                                                onClick={() => {
                                                    if(installScriptBase64Data){
                                                        try{
                                                            let bytes = base64ToArrayBuffer(installScriptBase64Data); // pass your byte response to this constructor
                                                            let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes

                                                            let link=document.createElement('a');
                                                            link.href=window.URL.createObjectURL(blob);
                                                            link.download="RR-Install-Script-Generator.exe";
                                                            link.click();
                                                        }
                                                        catch(error){
                                                            console.error("Error creating generating installer script")
                                                        }
                                                    }
                                                    else{
                                                        console.error("installScriptBase64Data not found")
                                                    }
                                                }}
                                                className={`${!installScriptBase64Data ? '' : 'downloadAgentButton'} focus:outline-none p-5 w-full text-left`}>
                                                <FontAwesomeIcon
                                                    className="object-contain mr-5"
                                                    icon="fa-solid fa-download"
                                                    size="lg"
                                                />
                                                Download Deployment Script Builder
                                            </button>

                                        </div>*/}
                                </div>
                            </div>
                        </div>
                        <hr className="bg-black h-0.5" />
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={chartToggled}
                                        name="licenseUsageToggle"
                                        onChange={e => setChartToggled(e.target.checked)}
                                    />
                                } label= {chartToggled ? "Showing Agent Version Chart" : "Hiding Agent Version Chart"}/>
                            </ThemeProvider>
                        </div>
                        {/*Agent Count Chart*/}
                        <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                            <AgChartsReact options={
                                {
                                    autoSize: true,
                                    data: chartData,
                                    title: {
                                        text: 'Number of Installed Agents Per Version',
                                        fontSize: 18,
                                    },
                                    subtitle:{
                                        text: 'Latest Version: ' + latestAgentVersion,
                                        fontSize: 18,
                                    },
                                    series: [
                                        {
                                            type: 'column',
                                            xKey: 'zenGroupId',
                                            yKeys: versionStringList,
                                            strokes: ['#989898', '#989898'],
                                            highlightStyle: {
                                                item: {
                                                    fill: "#E8E8E8",
                                                    stroke: "#181818"
                                                },
                                            },
                                            tooltip: { //for hovering over a column
                                                renderer: function (params) {
                                                    let content = "Count: " + params.yValue
                                                    return {
                                                        title: "Version: " + params.yKey,
                                                        content: content
                                                    };
                                                },
                                            },
                                        },
                                    ],
                                    axes: [
                                        {
                                            type: 'category',
                                            position: 'bottom',
                                            label: {
                                                rotation: 50,
                                                formatter: function(params){
                                                    if(params.axis.boundSeries[0].data[params.index].zenGroupName){
                                                        return params.axis.boundSeries[0].data[params.index].zenGroupName
                                                    }
                                                    else{
                                                        //else check again for group in session but should be handled in useEffect for chart data for initial population of zenGroupName for chart data
                                                        //params.value is zenGroupId
                                                        let group = findZenGroupById(params.value)
                                                        if(group && group.friendlyName){
                                                            //found group in session
                                                            params.axis.boundSeries[0].data[params.index].zenGroupName = group.friendlyName
                                                            return group.friendlyName
                                                        }
                                                        else{
                                                            return "Group not found"
                                                        }
                                                    }
                                                },
                                                fontSize: 11
                                            },
                                        },
                                        {
                                            type: 'number',
                                            position: 'left',
                                            //log shows now if you give undefined, not including min/max lets it set these automatically
                                            //min: chartData.length === 0 ? 0: undefined, //default min to 0 when chart data length is 0, else undefined leaves it to auto set the min according to the data given
                                            //max: chartData.length === 0 ? 100: undefined, //default max to 100 when chart data length is 0, else undefined leaves it to auto set the max according to the data given
                                        },
                                    ],
                                    legend: {
                                        spacing: 40,
                                        position: 'top',
                                    },
                                }
                            } />
                            <BackDropChartLoadingOverlay opened={chartIsLoading} />
                        </div>
                        <hr className="bg-black h-0.5" />
                        <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                            {/*Top with manage agents title and download agents button, flex it with button at end*/}
                            <div className={"self-end flex flex-col gap-y-3"}>
                                <GridColumnFilterStateSaving
                                    useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                    setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                    toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                    useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                    setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                    toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                                <ClickToShowButtonsExpandingRight
                                    buttonsText={"Automation"}
                                    tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                    buttonsDiv={
                                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                            <MuiIconButtonWithTooltipAndBox
                                                icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Uninstall All Selected Agents"}
                                                tooltipPlacement={"top"} disabled={!enableButtons}
                                                onClick={async () => {
                                                    let zenGroupIdSet = new Set();
                                                    let permissionCheckResult = true
                                                    if(gridApi.getSelectedNodes().length > 0){
                                                        gridApi.getSelectedNodes().forEach(rowNode => {
                                                            if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "uninstallAgent")) {
                                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                                    setZenGroupNamesWithoutPermission(new Set(zenGroupNamesWithoutPermission))
                                                                    setZenGroupIdsWithoutPermission(new Set(zenGroupIdsWithoutPermission))
                                                                    permissionCheckResult = false;
                                                                } else {
                                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                                    setZenGroupNamesWithPermission(new Set(zenGroupNamesWithPermission))
                                                                }
                                                            }
                                                        })
                                                    }
                                                    if (!permissionCheckResult) {
                                                        setShowUninstallConfirmation(true)
                                                    } else {
                                                        await requestUninstall()
                                                        setZenGroupNamesWithPermission(new Set())
                                                        setZenGroupNamesWithoutPermission(new Set())
                                                        setZenGroupIdsWithoutPermission(new Set())
                                                    }
                                                }}>
                                            </MuiIconButtonWithTooltipAndBox>
                                            <MuiIconButtonWithTooltipAndBox
                                                icon={<GroupIcon className={"cursor-pointer"}/>} tooltipTitle={"Bulk Change Group"}
                                                tooltipPlacement={"top"} disabled={!enableButtons}
                                                onClick={async () => {
                                                    let zenGroupIdSet = new Set();
                                                    let permissionCheckResult = true
                                                    if(gridApi.getSelectedNodes().length > 0){
                                                        gridApi.getSelectedNodes().forEach(rowNode => {
                                                            if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentGroup")) {
                                                                    permissionCheckResult = false;
                                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                                } else {
                                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                                }
                                                            }
                                                        })
                                                    }
                                                    setShowPermissionGrid(!permissionCheckResult)
                                                    setShowChangeGroupsModal(true)
                                                }}>
                                            </MuiIconButtonWithTooltipAndBox>
                                            <MuiIconButtonWithTooltipAndBox
                                                icon={<VisibilityIcon className={"cursor-pointer"}/>} tooltipTitle={"Bulk Set Agent Default Visibility"}
                                                tooltipPlacement={"top"} disabled={!enableButtons}
                                                onClick={async () => {
                                                    let zenGroupIdSet = new Set();
                                                    let permissionCheckResult = true;
                                                    gridApi.getSelectedNodes().forEach(rowNode => {
                                                        if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                            zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                            if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentVisibility")) {
                                                                permissionCheckResult = false
                                                                zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                                zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                                setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                                setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                            } else {
                                                                zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                                setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                            }
                                                        }
                                                    })
                                                    setShowPermissionGrid(!permissionCheckResult)
                                                    setShowBulkVisibilityModal(true)
                                                }}>
                                            </MuiIconButtonWithTooltipAndBox>
                                            <ThemeProvider theme = {buttonTheme}>
                                                {/*<Button disabled={!enableButtons}
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={async () => {
                                            let zenGroupIdSet = new Set();
                                            let permissionCheckResult = true;
                                            gridApi.getSelectedNodes().forEach(rowNode => {
                                                if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                    zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                    if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentAutoUpgrade")) {
                                                        permissionCheckResult = false
                                                        zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                        zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                        setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                    } else {
                                                        zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                    }
                                                }
                                            })
                                            setShowPermissionGrid(!permissionCheckResult)
                                            setShowAutoUpgradeModal(true)
                                        }}
                                >
                                    Bulk Set Auto-Upgrade
                                </Button>*/}
                                            </ThemeProvider>
                                        </div>
                                    }
                                />
                            </div>
                            <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                                <ClickToShowButtonsExpandingLeft
                                    buttonsText={"Columns"}
                                    tooltipTitle={"Column States"}
                                    buttonsDiv={
                                        <ToggleButtonGroup
                                            value={alignment}
                                            exclusive
                                            size={"small"}
                                            onChange={(event, newAlignment) => {
                                                setAlignment(newAlignment)
                                            }}
                                        >
                                            <ToggleButton value={"min"}
                                                          onClick={(event) => {
                                                              if(gridColumnApi){
                                                                  setAlignment("min")
                                                                  let currentColumnState = gridColumnApi.getColumnState()
                                                                  let columnStateUpdated = []
                                                                  currentColumnState.forEach(function (column) {
                                                                      column.hide = !minColumnIds.includes(column.colId);
                                                                      columnStateUpdated.push(column)
                                                                  });
                                                                  gridColumnApi.applyColumnState({applyOrder: false, state: columnStateUpdated})
                                                              }
                                                          }}
                                            >
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"med"}
                                                          onClick={(event) => {
                                                              if(gridColumnApi){
                                                                  setAlignment("med")
                                                                  let currentColumnState = gridColumnApi.getColumnState()
                                                                  let columnStateUpdated = []
                                                                  currentColumnState.forEach(function (column) {
                                                                      column.hide = !medColumnIds.includes(column.colId);
                                                                      columnStateUpdated.push(column)
                                                                  });
                                                                  gridColumnApi.applyColumnState({applyOrder: false, state: columnStateUpdated})
                                                              }
                                                          }}
                                            >
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"max"}
                                                          onClick={(event) => {
                                                              if(gridColumnApi){
                                                                  setAlignment("max")
                                                                  let colIds = []
                                                                  gridColumnApi.getColumns().forEach(function (column) {
                                                                      let colDef = column.getColDef()
                                                                      if(colDef.suppressColumnsToolPanel !== true){
                                                                          colIds.push(column.colId)
                                                                      }
                                                                  });
                                                                  gridColumnApi.setColumnsVisible(colIds, true)
                                                              }
                                                          }}
                                            >
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value={"custom"}
                                                          onClick={(event) => {
                                                              setAlignment("custom")
                                                              onGridReadyHelperForColumnState(gridParams, gridColumnStateSessionVariableName)
                                                          }}
                                            >
                                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                         title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                    <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                                </Tooltip>
                                            </ToggleButton>

                                        </ToggleButtonGroup>
                                    }
                                />
                                <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false} showColumnsIcon={false}
                                              refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                              excelExportFunction = {excelExport}/>
                            </div>
                        </div>
                        <div className="mb-4" id="gridRoot">
                            {getGrid()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function base64ToArrayBuffer(base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'agentsGridFilterState', updateAgentsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, 'agentsGridColumnState', updateAgentsGridUseColumnStateReactive);
    }


    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                gridApi={gridApi}
                alignment={alignment}
                setAlignment={setAlignment}
                setGridApi={setGridApi}
                setGridParams={setGridParams}
                //activeAgentsList={activeAgentsList}
                //setActiveAgentsList={setActiveAgentsList}
                setEnableButtons={setEnableButtons}
                gridColumnApi={gridColumnApi}
                setGridColumnApi={setGridColumnApi}
                gridCount = {gridCount}
                setGridCount = {setGridCount}
                gridPage = {gridPage}
                setGridPage = {setGridPage}
                gridFilters = {gridFilters}
                setGridFilters = {setGridFilters}
                gridSortModel = {gridSortModel}
                setGridSortModel = {setGridSortModel}
                setZenGroupSessionStorage={setZenGroupSessionStorage}
                zenGroupSessionStorage={zenGroupSessionStorage}
                agentLocation={agentLocation}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }

    async function refreshGrid(){
        //We are going to have change streams in the future, and with high quantities of data now in the grid, this code slows down the grid and becomes laggy, so disabling this for now by
        // just having a return line to stop the code from running
        return
        let agentIdsList = [];
        //let zenGroupIdSet = new Set();
        //let licenseIdList = [];
        gridApi.forEachNode(rowNode => {
            agentIdsList.push(rowNode.data.agentId);
            //zenGroupIdSet.add(rowNode.data.zenGroupId);
            //licenseIdList.push(rowNode.data.licenseId);
        })
        if(agentIdsList.length>0){
            let agentList = await findByAgentIdListReactive(agentIdsList);
            if(agentList.length>0){
                //DENNIS
                gridApi.getFilterInstance('zenGroupDisplayName').refreshFilterValues()
                //refresh zenGroupDisplayName filter values instead of the getZenGroupFriendlyNames() since group names may change, and the column uses the filter values for the group names in the cell
                //await zenGroupFilter.refreshFilterValues();
                //let licenseNameList = await getLicenseNamesByIdList(licenseIdList);
                //let zenGroupNameList = await getZenGroupFriendlyNames(Array.from(zenGroupIdSet))
                gridApi.forEachNode(rowNode => {
                    //let currentAgentId = rowNode.data.agentId
                    let agentIdExists = false
                    agentList.forEach(async newAgent => {
                        if(rowNode.data.agentId === newAgent.id){
                            agentIdExists = true
                            if(newAgent.ipList){
                                const filteredIpList = newAgent.ipList.filter(function(ip) {
                                    return ip !== "0.0.0.0";
                                });
                                newAgent.ipList = filteredIpList;
                            }
                            if(!_.isEqual(rowNode.data,newAgent)){
                                //let updated = false;
                                //let updatedRow = rowNode.data
                                for (const [originalKey, originalValue] of Object.entries(rowNode.data)) {
                                    if(originalKey==="agentDisplayName"){
                                        if(originalValue !== newAgent["agentDisplayName"]){
                                            //updatedRow.agentDisplayName = newAgent["agentDisplayName"];
                                            //updated = true
                                            rowNode.setDataValue("agentDisplayName",newAgent["agentDisplayName"]);
                                        }
                                    }
                                    else if(originalKey === "zenGroupId"){
                                        if(originalValue !== newAgent["zenGroupId"]){
                                            //updatedRow.zenGroupId = newAgent["zenGroupId"]
                                            //updated = true
                                            rowNode.setDataValue("zenGroupId",newAgent["zenGroupId"])
                                        }
                                    }
                                    else if(originalKey === "zenGroupDisplayName"){
                                        //get the value from the filterInstance for zenGroupDisplayName
                                        //DENNIS HERE ON 14NOV 2310
                                        /*let index = zenGroupFilter.valueModel.allValues.findIndex(element => element.includes(rowNode.data.zenGroupId))
                                        if(index !== -1){
                                            let group = JSON.parse(zenGroupFilter.valueModel.allValues[index]).friendlyName
                                            if(group && group !== originalValue){
                                                rowNode.setDataValue("zenGroupDisplayName",group);
                                            }
                                        }else{
                                            //console.log("index not found")
                                        }

                                         */
                                    }
                                    else if(originalKey === "licenseId"){
                                        //if license was unassigned from agent
                                        if(rowNode.data.licenseId && !newAgent["licenseId"]){
                                            rowNode.setDataValue("licenseId",null);
                                            rowNode.setDataValue("licenseDisplayName",null);
                                        }
                                        //if agent is assigned a license and it did not have one before
                                        else if(!rowNode.data.licenseId && newAgent["licenseId"]){
                                            let newLicenseId = newAgent["licenseId"]
                                            rowNode.setDataValue("licenseId",newLicenseId);
                                            /*let licenseName = await getLicenseNamesByIdList([newLicenseId])

                                            let licenseNameObject = licenseName.find(licenseNameObjectIterator => licenseNameObjectIterator.licenseId === newLicenseId);
                                            if(licenseNameObject){
                                                if(licenseNameObject["name"]){
                                                    rowNode.setDataValue("licenseDisplayName",licenseNameObject["name"]);
                                                }
                                            }*/
                                        }
                                        else if(newAgent["licenseId"]){
                                            //Just set the returned licenseId, and then at end of function there is a call to force cells in licenseDisplayName to
                                            // refresh their valueFormatter function which will trigger their async calls again and update name if need be
                                            let newLicenseId = newAgent["licenseId"]
                                            rowNode.setDataValue("licenseId",newLicenseId);
                                        }
                                    }
                                    else if(originalKey === "licenseDisplayName"){
                                        //DENNIS HERE 19NOV 2312 - I'm not sure we need this anymore with the new rendering
                                        /*let licenseNameObject = licenseNameList.find(licenseNameObjectIterator => licenseNameObjectIterator.licenseId === rowNode.data["licenseId"]);
                                        if(licenseNameObject){
                                            if(licenseNameObject["name"] && licenseNameObject["name"] !== originalValue){
                                                //updatedRow.licenseDisplayName = licenseNameObject["name"]
                                                //updated = true
                                                rowNode.setDataValue("licenseDisplayName",licenseNameObject["name"]);
                                            }
                                        }

                                         */
                                    }

                                    else if(originalKey === "specialStatusMessage"){
                                        if(originalValue && originalValue.startsWith("Update")) {
                                            if(newAgent["specialStatusMessage"] !== "updateStaged"){
                                                rowNode.setDataValue("specialStatusMessage",newAgent["specialStatusMessage"]);
                                            }

                                        }else{
                                            if(originalValue !== newAgent["specialStatusMessage"]){
                                                //updatedRow.specialStatusMessage = newAgent["specialStatusMessage"]
                                                //updated = true
                                                rowNode.setDataValue("specialStatusMessage",newAgent["specialStatusMessage"]);
                                            }
                                        }
                                    }
                                    else if(originalKey === "agentId"){
                                        //do nothing since in newAgent this is just 'id'
                                    }
                                    else if(originalKey === "autoUpgrade"){
                                        if(originalValue !== newAgent["autoUpgrade"]){
                                            //updatedRow.autoUpgrade = newAgent["autoUpgrade"]
                                            //updated = true
                                            rowNode.setDataValue("autoUpgrade",newAgent["autoUpgrade"]);
                                        }
                                    }
                                    else if(originalKey === "ipList"){
                                        let originalIpList = String(originalValue)
                                        let newIpList = String(newAgent["ipList"])
                                        if(originalIpList !== newIpList){
                                            rowNode.setDataValue("ipList",newAgent["ipList"]);
                                        }
                                    }
                                    else if(!originalKey){
                                        //console.log("originalKey is null")
                                    }
                                    else {
                                        if (originalValue !== newAgent[originalKey]) {
                                            //IP addresses
                                            //dates
                                            //console.log("got "+originalValue+" versus "+newAgent[originalKey]+" and we didn't know what to do specifically");
                                            //updatedRow[originalKey] = newAgent[originalKey];
                                            //updated = true
                                            try{
                                                rowNode.setDataValue(originalKey,newAgent[originalKey]);
                                            }
                                            catch(error){
                                                console.log(`Error setting cell (${originalKey}) dataValue`)
                                            }
                                        }
                                    }
                                }
                                /*
                                if(updated){
                                    rowNode.setData(updatedRow);
                                }
                                 */
                            }else{
                                //console.log("new and old agents are equal in refreshGrid");
                            }
                        }
                    })
                    if(!agentIdExists){
                        //console.log(currentAgentId)
                    }
                })
            } else{
                //console.log("agentList is 0 length in refreshGrid");
            }
        }else{
            //console.log("Agent Ids List is 0 length in refreshGrid");
        }
        //force refresh on licenseDisplayName col because this forces those cells to call their valueFormatter and cellRenderers again, which will get any updated values and
        // they are async calls
        //gridApi.refreshCells({columns: ["licenseDisplayName", "zenGroupDisplayName", "agentDisplayName"], suppressFlash: true, force: true})
        //refreshCells on all columns so that the tooltipValueGetter for each column gets updated so the tooltip for agent not activated does not always show if the agentWasInsertedInValidateWithoutLicense
        // value was to change for an agent
        if(gridColumnApi && gridApi){
            var allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridApi.refreshCells({columns:allColumnIds, suppressFlash: true, force: true})
        }
    }

    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
        setUpdateAgentZG(null);
        //setActiveAgentsList([])
        //setActiveAgentsZenGroupIdsList([])
        setEnableButtons(false)
        setZenGroupNamesWithPermission(new Set())
        setZenGroupNamesWithoutPermission(new Set())
        setZenGroupIdsWithoutPermission(new Set())
        setNewAgentZenGroup(null)
        setIsLoading(false)
        //setCancelUninstallAgentId(null)
        //setShowCancelUninstallAgentModal(false)
        setShowChangeGroupsModal(false)
        setShowAutoUpgradeModal(false)
        setBulkAutoUpgradeToggled(true)
        setSingleAgentAutoUpgradeData({})
    }
    */

    async function cancelUninstall(rowNode, params){
        if(rowNode && rowNode.data && rowNode.data.agentId){
            cancelUninstallAgentReactive(rowNode.data.agentId).then(function(response){
                NotificationManager.success("Successfully canceled uninstall");
                rowNode.setDataValue("specialStatusMessage","none");
                //need to refresh agent name col to make uninstall icon appear, limiting to only this row's agentDisplayName cell
                params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [rowNode], suppressFlash: true, force: true})
                //refreshGrid()
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error(`Unexpected error cancelling the uninstall`);
                }
            })
            //await refreshGrid()
        }else{
            Notification.info("Please select an agent to cancel uninstall");
        }
    }

    async function singleAgentAutoUpgrade(rowNode){
        if(rowNode && rowNode.data && rowNode.data.version){
            try{
                let versionStr = rowNode.data.version
                if(versionStr){
                    versionStr = versionStr.replaceAll(".", "")
                    const regex = /\D/g; //all non-digits
                    versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                    let versionInt = parseInt(versionStr, 10)
                    if (versionInt < 4230 || isNaN(versionInt) || versionInt === null) {
                        NotificationManager.error("This agent is not compatible with the remote feature to change the auto-upgrade setting.")

                        return;
                    }
                }
                else{
                    NotificationManager.error("This agent is not compatible with the remote feature to change the auto-upgrade setting.")
                    return;
                }
            }
            catch(error){
                NotificationManager.error("Unexpected error making this request.")
                return;
            }
            //if we get to here then we are good to call to api to queue the task
            //singleSetAgentAutoUpgrade(rowNode.data.agentId, rowNode.toggleElement.target.checked)
            return singleSetAgentAutoUpgradeReactive(rowNode.data.agentId,!rowNode.data.autoUpgrade).then(function(){
                //return singleSetAgentAutoUpgrade("611e62134309eb1bc7d235fa",!rowNode.data.autoUpgrade).then(function(){
                NotificationManager.success(`Successfully queued a task to update this agent's auto-upgrade setting.`);
                setSingleAgentAutoUpgradeData({})
                if(rowNode.data.autoUpgrade===false) {
                    rowNode.setDataValue("autoUpgrade",true);
                }else{
                    rowNode.setDataValue("autoUpgrade",false);
                    //todo: not worried about pending task that is then completed at this time
                }
            })/*.catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error(`Unexpected error making this request`);
                }
                console.log("Caught error line 1409")
                rowNode.setDataValue("autoUpgrade",rowNode.data.autoUpgrade);
            })*/
        }else{
            console.log("singleAgentUpgrade called with no selected row");
            NotificationManager.error("Please select an agent to check");
        }
    }

    async function requestEncryptCheck(rowNode){
        if(rowNode && rowNode.data && rowNode.data.agentId){
            createEncryptionCheckTaskReactive(rowNode.data.agentId).then(function(response){
                if(response){
                    rowNode.setDataValue("specialStatusMessage","scanInProgress");
                    NotificationManager.success("Client Encryption Check Tasked");
                }
                else{
                    NotificationManager.error(`Error requesting remote encryption check`);
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error(`Error requesting remote encryption check`);
                }
            });
        }else{
            console.log("requestEncryptCheck called with no selected row");
            Notification.error("Please select an agent to check");
        }
    }
    async function requestUninstall() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let agentIdsList = [];
            gridApi.getSelectedNodes().forEach(selectedNode => {
                if(selectedNode.data.version){
                    try{
                        let versionStr = selectedNode.data.version.replaceAll(".", "")
                        const regex = /\D/g; //all non-digits
                        versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                        let versionInt = parseInt(versionStr, 10)
                        if (versionInt <= 332 || isNaN(versionInt) || versionInt === null) {
                            console.log(`Agent ${selectedNode.data.agentDisplayName} is not compatible with the remote uninstall feature.`)
                        }
                        else{
                            if(selectedNode.data.specialStatusMessage === "uninstalled"){
                                console.log(`Agent ${selectedNode.data.agentDisplayName} is uninstalled already`)
                            }
                            else{
                                if(zenGroupIdsWithoutPermission.has(selectedNode.data.zenGroupId)){
                                    console.log(`User does not have permission inside Agent ${selectedNode.data.agentDisplayName}'s group to uninstall`)
                                }
                                else{
                                    agentIdsList.push(selectedNode.data.agentId)
                                    uninstallAgentReactive(selectedNode.data.agentId)
                                        .then(() => {
                                            /*
                                            *************************************
                                            * THIS IS UNTESTED
                                            * ***********************************
                                             */
                                            selectedNode.setDataValue("specialStatusMessage","uninstallInProgress")
                                            // gridApi.refreshCells({columns: ["agentDisplayName", "machineName"], suppressFlash: true, force: true, rowNodes: [currentLicense]})
                                        })
                                        .catch((error) => {
                                            console.log("Error requesting remote uninstall")
                                            /*if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Error requesting remote uninstall`);
                                            }*/
                                        })
                                }
                            }
                        }
                    }
                    catch(error){}
                }
            })
            //  Mason: still keeping this, so we don't lost the error message
            if(agentIdsList.length < 1){
                NotificationManager.error(`Error requesting remote uninstall, no selected agents are currently compatible with the remote uninstall feature`);
            }
            else{
                NotificationManager.success(`Uninstall task(s) queued for compatible agents in groups you have permission to do so in.`)
                setShowUninstallConfirmation(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        } else {
            console.log("requestUninstall called with no selected row");
            NotificationManager.error("Please select an agent");
        }
    }

    async function changeAgentsGroup() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0 && newAgentZenGroup) {
            let agentIdsList = []; //store agent ids of agents
            let newZenGroup = findZenGroupById(newAgentZenGroup)
            gridApi.getSelectedNodes().forEach(selectedNode => {
                if (selectedNode.data.agentId) {
                    if(!zenGroupIdsWithoutPermission.has(selectedNode.data.zenGroupId)){
                        agentIdsList.push(selectedNode.data.agentId);
                        singleChangeAgentGroupReactive(newAgentZenGroup, selectedNode.data.agentId)
                            .then(() => {
                                selectedNode.setDataValue("zenGroupId", newAgentZenGroup)
                                if(newZenGroup && newZenGroup.friendlyName){
                                    selectedNode.setDataValue("zenGroupDisplayName", newZenGroup.friendlyName)
                                }
                            })
                            .catch((error) => {
                                console.log("Unexpected error making this request")
                                /*if (error.message) {
                                    NotificationManager.error(error.message);
                                } else {
                                    NotificationManager.error(`Unexpected error`);
                                }*/
                            })
                    }
                }
            })
            if(agentIdsList.length === 0){
                NotificationManager.error(`You don't have permission to change any selected agent's group`);
                return
            }

            NotificationManager.success(`Successfully updated the selected ${agentIdsList.length < 2 ? "agent's" : "agents'"} group that you have permission to do so in.`);
            setShowChangeGroupsModal(false)
            setNewAgentZenGroup(null)
            setZenGroupNamesWithPermission(new Set())
            setZenGroupNamesWithoutPermission(new Set())
            setZenGroupIdsWithoutPermission(new Set())
        } else {
            console.log("changeAgentsGroup called with no selected row(s)");
            NotificationManager.error("Please select an agent to change");
        }
    }

    async function bulkSetAutoUpgradeRequest() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){

            let agentIdsList = []; //store agent ids of agents with compatible versions
            gridApi.getSelectedNodes().forEach(currentRowNode =>{
                if(currentRowNode.data.version){
                    let versionStr = "";
                    try{
                        versionStr = currentRowNode.data.version.replaceAll(".", "")
                        const regex = /\D/g; //all non-digits
                        versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                        let versionInt = parseInt(versionStr, 10)
                        if (versionInt < 4230 || isNaN(versionInt) || versionInt === null) {
                            console.log(`Agent ${currentRowNode.data.agentDisplayName} is not compatible with the remote set auto upgrade feature.`)
                        }
                        else{
                            if(zenGroupIdsWithoutPermission.has(currentRowNode.data.zenGroupId)){
                                console.log(`User does not have permission inside Agent ${currentRowNode.data.agentDisplayName}'s group to change auto upgrade setting`)
                            }
                            else{
                                agentIdsList.push(currentRowNode.data.agentId)

                                singleSetAgentAutoUpgradeReactive(currentRowNode.data.agentId, bulkAutoUpgradeToggled)
                                    .then(() => {
                                        currentRowNode.setDataValue("autoUpgrade", bulkAutoUpgradeToggled)
                                    })
                                    .catch((error) => {
                                        console.log("Error making this request")
                                        /*if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error(`Error making this request`);
                                        }*/
                                    })
                            }
                        }
                    }
                    catch(error){}
                }
            })

            if(agentIdsList.length < 1){
                NotificationManager.info(`None of the selected agents are compatible with this feature`);
            }
            else{
                NotificationManager.success(`Task(s) queued for updating the auto-upgrade setting for compatible agents in groups you have permission to do so in.`);
                setShowAutoUpgradeModal(false)

                setBulkAutoUpgradeToggled(true)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        } else {
            NotificationManager.info("Please choose at least one compatible agent.");
        }
    }

    function bulkSetAgentDefaultVisibilityRequest(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){

            let agentIdsList = []; //store agent ids of agents with compatible versions
            gridApi.getSelectedNodes().forEach(currentRowNode =>{
                if(zenGroupIdsWithoutPermission.has(currentRowNode.data.zenGroupId)){
                    console.log(`User does not have permission inside Agent ${currentRowNode.data.agentDisplayName}'s group to change it's visibility setting`)
                }
                else{
                    agentIdsList.push(currentRowNode.data.agentId)

                    singleSetAgentVisibilityReactive(currentRowNode.data.agentId, !bulkShowAgentsToggled)
                        .then(() => {
                            currentRowNode.setDataValue("hiddenFromUI", !bulkShowAgentsToggled)
                        })
                        .catch((error) => {
                            console.log("Error making this request")
                            /*if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error(`Error making this request`);
                            }*/
                        })
                }
            })

            if(agentIdsList.length < 1){
                NotificationManager.info(`You do not have permission in any of the selected agent's groups to change their visibility setting.`);
            }
            else{
                NotificationManager.success("Successfully queued update for agent visibility setting (you may need to refresh the grid periodically to see changes for larger requests)");
                setShowBulkVisibilityModal(false)
                setBulkShowAgentsToggled(true)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        } else {
            NotificationManager.info("Please choose at least one agent.");
        }
    }

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "agentsGridExport")

        /*let columnKeys = getColumnKeys(gridApi)

        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + "-" + today.getMinutes();
        let dateTime = date+'T'+time;
        let fileName = `agents-${dateTime}`

        if (!sseDataPullActive) {
            gridApi.exportDataAsExcel({
                processCellCallback: function(cell) {
                    if (cell.column.getColDef().field === "managedUpdateSettings"){
                        return cell.node.data.autoUpdate
                    }

                    // if cell is a date type
                    if (cell.column.getColDef().filter === "agDateColumnFilter") {
                        let date = new Date(cell.value).toUTCString()
                        if (date === 'Invalid Date' || cell.value === null){
                            return cell.value
                        }
                        return date
                    }

                    return cell.value
                },
                columnKeys: columnKeys,
                fileName: fileName
            })
        }*/
    }
}

let saveFilterChanges = true //used for if user clicks link to come to agents page and we auto filter grid to show that specific agent, in that case we don't want to save
// the filters because that would mess with their previous filters they still may want.
class Grid extends Component {
    rowData = []
    licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
    sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList")) //we have an await call on this data fetch after successful login so this should never be null
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        //console.log("onFirstDataRendered outside of filterInstance callback called in agents.js")
    };

    componentDidMount() {
        //console.log("componentDidMount in agents.js called")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("componentDidUpdate in agents.js called")
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setAlignment && this.props.setAlignment("custom")
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateAgentsGridColumnStateReactive)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        //abort the change stream listener before leaving page
        this.abortController.abort()
    }

    handleAgentSpecialStatusMessageFormatting = (rowData) => {
        if(rowData.specialStatusMessage === "updateStagingStarted" || rowData.specialStatusMessage === "updateStaged" || rowData.specialStatusMessage === "updateStagedSuccessful"){
            let latestStagedUpdateVersion = rowData.latestStagedUpdateVersion
            if(latestStagedUpdateVersion){
                if(rowData.version !== latestStagedUpdateVersion){
                    rowData.updateStagedText = "Update Staged ("+latestStagedUpdateVersion+")"
                }
                else{
                    //agent version and staged update version are the same
                    rowData.updateStagedText = null
                    rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
                }
            }
            else{
                //the response was null or the stagedAgentVersion was null
                rowData.updateStagedText = null
                rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
            }
        }
        else if(rowData.specialStatusMessage === "updateStagedFailed" || !rowData.specialStatusMessage){
            //talked about and decided to show nothing in this case of updateStagedFailed
            rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
        }
    }

    populateGrid = async (rowData) => {
        if(!this.licensesWithAgentIdList){
            this.licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        }
        await this.checkForLicenseNameInSessionStorageListForInitialPopulation(rowData)
        //check agent's specialStatusMessage
        this.handleAgentSpecialStatusMessageFormatting(rowData)
        this.prepUpdatePolicyValues(rowData)
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    callToGetLicenseNameWithAgentIdReactiveHelper = async (rowData) => {
        try {
            let license = await getLicenseNameWithAgentIdReactive(rowData.agentId)
            if(license && license.licenseId) {
                if (license.name) {
                    if(license.name === rowData.licenseDisplayName){
                        //console.debug(license.name+ " equals licenseDisplayName")
                    }else{
                        rowData.licenseDisplayName = license.name
                        rowData.licenseId = license.licenseId
                    }
                }else{
                    rowData.licenseDisplayName = " "
                    rowData.licenseId = null
                }
            }
            else{
                rowData.licenseDisplayName = " "
                rowData.licenseId = null
            }
        } catch (e) {
            rowData.licenseDisplayName = " "
            rowData.licenseId = null
        }
    }

    checkForLicenseNameInSessionStorageListForInitialPopulation = async (rowData) =>{
        /*if(rowData.licenseManuallyRemovedByUser || rowData.specialStatusMessage === "uninstalled"){
            rowData.licenseDisplayName = " "
            rowData.licenseId = null
        }*/
        if(this.licensesWithAgentIdList){
            let licenseFound = null
            for(let i = 0; i < this.licensesWithAgentIdList.length; i++){
                if(this.licensesWithAgentIdList[i].agentId === rowData.agentId){
                    licenseFound = this.licensesWithAgentIdList[i]
                }
            }
            if(licenseFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(licenseFound.licenseName){
                    rowData.licenseDisplayName = licenseFound.licenseName
                    rowData.licenseId = licenseFound.licenseId
                }
                else{
                    rowData.licenseDisplayName = " "
                    rowData.licenseId = null
                }
            }
            else{
                //we did not find license in session storage, we still should call to api in case user was added to a group and session storage was not updated
                await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
            }
        }
        else{
            //this.licensesWithAgentIdList was null, backup to callToGetLicenseNameWithAgentIdReactiveHelper to get license name value for this agent
            await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
        }
    }

    checkForLicenseNameInSessionStorageListForChangeStream = async (rowData) =>{
        //we have to get most updated licensesWithAgentIdList list since we added change streams to update this list for license agentId changes
        let licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        /*if(rowData.licenseManuallyRemovedByUser || rowData.specialStatusMessage === "uninstalled"){
            rowData.licenseDisplayName = " "
            rowData.licenseId = null
        }*/
        if(licensesWithAgentIdList){
            let licenseFound = null
            for(let i = 0; i < licensesWithAgentIdList.length; i++){
                if(licensesWithAgentIdList[i].agentId === rowData.agentId){
                    licenseFound = licensesWithAgentIdList[i]
                }
            }
            if(licenseFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(licenseFound.licenseName){
                    rowData.licenseDisplayName = licenseFound.licenseName
                    rowData.licenseId = licenseFound.licenseId
                }
                else{
                    rowData.licenseDisplayName = " "
                    rowData.licenseId = null
                }
            }
            else{
                //we did not find license in session storage, we still should call to api in case user was added to a group and session storage was not updated
                await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
            }
        }
        else{
            //licensesWithAgentIdList was null, backup to callToGetLicenseNameWithAgentIdReactiveHelper to get license name value for this agent
            await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
        }
    }

    prepUpdatePolicyValues = (rowData) =>{
        if(rowData.managedUpdateSettings !== null){
            //Then agent is overriding the group policy
            rowData.updatePolicy = overridingGroupPolicyText
            rowData.autoUpdate = rowData.managedUpdateSettings.autoUpdate
            //If auto update is true then show the latest agent version for this value, make sure to check length before we access index at [1] since first element is "Do Not Update"
            if(rowData.autoUpdate === true && this.sessionAgentVersionsList?.length >= 2 ){
                try{
                    rowData.latestAgentVersionApproved = this.sessionAgentVersionsList[1]
                } catch (e) {
                    //default to value in row data if error accessing list
                    rowData.latestAgentVersionApproved = rowData.managedUpdateSettings.latestAgentVersionApproved
                }
            }
            else{
                rowData.latestAgentVersionApproved = rowData.managedUpdateSettings.latestAgentVersionApproved
            }
        }
        else{
            //then agent is following the group policy
            let groupFound = this.props.zenGroupSessionStorage?.find(zenGroupObject => zenGroupObject.id === rowData.zenGroupId)
            let autoUpdate = true //default to true
            let latestAgentVersionApproved = null
            if(groupFound && groupFound.managedUpdateSettings){
                autoUpdate = groupFound.managedUpdateSettings.autoUpdate
                latestAgentVersionApproved = groupFound.managedUpdateSettings.latestAgentVersionApproved
            }
            rowData.updatePolicy = followingGroupPolicyText
            rowData.autoUpdate = autoUpdate
            if(rowData.autoUpdate === true && this.sessionAgentVersionsList?.length >= 2 ){
                try{
                    rowData.latestAgentVersionApproved = this.sessionAgentVersionsList[1]
                } catch (e) {
                    //default to latestAgentVersionApproved if error accessing list
                    rowData.latestAgentVersionApproved = latestAgentVersionApproved
                }
            }
            else{
                rowData.latestAgentVersionApproved = latestAgentVersionApproved
            }
        }
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let agentBody = changeStreamData.body
        //need to make sure the fields line up with the change stream body and the keys we use for columns for the grid before we apply the transaction
        agentBody["agentId"] = agentBody["id"]
        agentBody["version"] = agentBody["agentVersion"]
        agentBody["autoUpgrade"] = agentBody["autoUpgrade"] ? agentBody["autoUpgrade"] : true
        agentBody["agentDisplayName"] = agentBody["userSetFriendlyName"] ? agentBody["userSetFriendlyName"] : agentBody["friendlyName"]

        if(operationType === "UPDATE" || operationType === "REPLACE"){
            /*
                The incoming agent won't have the licenseDisplayName field since we do client side processing to get the license name in the valueGetter, so we need to
                find the row node to get the current licenseDisplayName value to supply (if passes checks below). We also do some processing in the populateGrid function above
                for agent's specialStatusMessage field that should be reflected below in the agent coming in from the change stream
            */
            //gridApi.getRowNode throws an error if the grid was destroyed (user goes to another page), so add in the destroyCalled check
            if(!this.gridApi.destroyCalled){
                let agentRowNode = this.gridApi.getRowNode(agentBody["agentId"])
                if(agentRowNode && agentRowNode.data){
                    let agentRowNodeData = agentRowNode.data
                    if(agentRowNodeData.licenseManuallyRemovedByUser !== agentBody.licenseManuallyRemovedByUser){
                        //if license was removed from agent manually by user, or license was assigned to agent after being initially removed by user
                        await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                    }
                    else if((agentRowNodeData.specialStatusMessage === "uninstalled" && agentBody.specialStatusMessage !== "uninstalled") ||
                            (agentRowNodeData.specialStatusMessage !== "uninstalled" && agentBody.specialStatusMessage === "uninstalled")){
                        //this is for if agent is reinstalled on a machine or is uninstalled, then we should call checkForLicenseNameInSessionStorageListForChangeStream since license will have been assigned/removed
                        await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                    }
                    else{
                        if(agentRowNodeData.licenseDisplayName === agentRowNodeData.agentId){
                            //console.log("in equals agentId for agent: " + agentRowNodeData.agentDisplayName)
                        }
                        else{
                            //else we can just re-use the existing row nodes licenseDisplayName value
                            agentBody.licenseDisplayName = agentRowNodeData.licenseDisplayName
                            agentBody.licenseId = agentRowNodeData.licenseId
                            if(agentBody.licenseDisplayName === null || agentBody.licenseDisplayName === undefined){
                                await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                            }
                            else if(agentRowNodeData.specialStatusMessage !== agentBody.specialStatusMessage && agentBody.licenseDisplayName.startsWith('TEMP LICENSE FOR UNINSTALL')){
                                //need to call to callToGetLicenseNameWithAgentIdReactiveHelper in case of cancelling uninstall and temp license deleted
                                await this.callToGetLicenseNameWithAgentIdReactiveHelper(agentBody)
                                if(agentBody.licenseId === null){
                                    //Agent is not assigned a temp license anymore, we need to remove the TEMP license from session storage since we need to do additional setup for delete change streams in mongo
                                    let licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                                    if(licensesWithAgentIdListInSession){
                                        licensesWithAgentIdListInSession = licensesWithAgentIdListInSession.filter(function (value, index, arr) {
                                            return value.licenseId !== agentRowNodeData.licenseId;
                                        })
                                        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    }
                                }
                            }
                        }

                    }

                    //See agent is following group policy, then we can re-use the values from existing row node to avoid looping through groups in session storage again
                    if(agentRowNodeData.managedUpdateSettings === null && agentBody.managedUpdateSettings === null && agentRowNodeData.updatePolicy !== null){
                        //Agent is following group update policy so just re-use values from agentRowNodeData, the agentRowNodeData.updatePolicy !== null check is
                        // just making sure agentRowNodeData was prepped with update policy values already at the time it was added to the grid, else we will just prep it in the else below
                        agentBody.updatePolicy = agentRowNodeData.updatePolicy
                        agentBody.autoUpdate = agentRowNodeData.autoUpdate
                        agentBody.latestAgentVersionApproved = agentRowNodeData.latestAgentVersionApproved
                    }
                    else{
                        //else call to prepUpdatePolicyValues
                        this.prepUpdatePolicyValues(agentBody)
                    }
                    this.handleAgentSpecialStatusMessageFormatting(agentBody)
                }
                else{
                    //else no row node was found which means this agent was not populated into grid yet, we need to prep the license name and special status (update staged) columns
                    await this.checkForLicenseNameInSessionStorageListForInitialPopulation(agentBody)
                    this.prepUpdatePolicyValues(agentBody)
                    this.handleAgentSpecialStatusMessageFormatting(agentBody)
                }
                standardHandleUpdateAndReplaceEvent(agentBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
            }
        }
        else if (operationType === "INSERT"){
            //if the initial supply of rowData to the grid is active, we don't want to apply this insert since the current sse data pull should include this agent
            if(!this.props.sseDataPullActive && !this.gridApi.destroyCalled){
                await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                this.prepUpdatePolicyValues(agentBody)
                this.handleAgentSpecialStatusMessageFormatting(agentBody)

                //not using standardHandleInsertEvent since we have this processing above for async calls
                this.gridApi.applyTransactionAsync({
                    add: [agentBody]
                })
            }
        }
    }

    onGridReady = async (params) => {
        this.gridApi = params.api;
        licensesListWithAgentIdSessionStorageChangeStreamListener(params.api)
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);
        this.props.setGridParams(params);

        //check if we want to apply saved column state
        let alignment = this.props.alignment
        if(alignment === "custom"){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if (alignment === "min"){
            let currentColumnState = params.columnApi.getColumnState()
            let columnStateUpdated = []
            currentColumnState.forEach(function (column) {
                column.hide = !minColumnIds.includes(column.colId);
                columnStateUpdated.push(column)
            });
            params.columnApi.applyColumnState({applyOrder: false, state: columnStateUpdated})
        }
        else if (alignment === "med"){
            let currentColumnState = params.columnApi.getColumnState()
            let columnStateUpdated = []
            currentColumnState.forEach(function (column) {
                column.hide = !medColumnIds.includes(column.colId);
                columnStateUpdated.push(column)
            });
            params.columnApi.applyColumnState({applyOrder: false, state: columnStateUpdated})
        }
        //else if alignment is max then the default column state already shows the max amount of columns no need to update

        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.agentLocation && this.props.agentLocation.state && this.props.agentLocation.state.agentDisplayNameClicked){
            let locationFilterModel = {"agentDisplayName": {filterType: "text", type: "equals", filter: this.props.agentLocation.state.agentDisplayNameClicked}}
            //we should have the machineNameClicked passed to us, but double-checking it is present
            if(this.props.agentLocation.state.machineNameClicked) {
                locationFilterModel["machineName"] = {
                    filterType: "text",
                    type: "equals",
                    filter: this.props.agentLocation.state.machineNameClicked
                }
            }
            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove this state or else when the user refreshes the page or clicks the back tab then the forward tab, they will keep seeing this agentLocation filter present.
            // Although if they have the use saved filters toggled, this agentLocation filter will be updated for their saved agent grid filter, except we add the hiddenFromUIFilter no matter what
            // in the else below
            //window.history.replaceState(this.props.agentLocation.state, '')
        }
        else {
            saveFilterChanges = true
            onGridReadyHelper(params, "agentsGridFilterState");

            //by default when users go to agents page, we don't want to show hidden agents even if they have the apply saved filters toggled
            let hiddenFromUIFilter = params.api.getFilterInstance('hiddenFromUI');
            hiddenFromUIFilter?.setModel({
                type: 'set',
                values: ['Visible']
            });
            params.api.onFilterChanged()
        }

        //check for licensesWithAgentIdList sessionStorage that will be used for the license name column values
        let licensesWithAgentIdListForInterval = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        if(!licensesWithAgentIdListForInterval){
            //if this is null then setInterval to check for it in session
            const interval = setInterval(() => {
                licensesWithAgentIdListForInterval = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                if(licensesWithAgentIdListForInterval){
                    params.api && params.api.onSortChanged()
                    params.api && params.api.onFilterChanged()
                    clearInterval(interval)
                }
            }, 2000);
            this.interval = interval
        }

        //await getAgentListReactiveSSE(this.populateGrid)
        await loadDataWithSSEAndStartChangeStreamListener("/sse/agentListReactive", "/sse/listenToAgentEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //params.api.sizeColumnsToFit()
    };

    getRowId = (params) => {
        return params.data.agentId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        gridOptions={{
                            rowClassRules: {
                                'agentInsertedWithoutLicenseInValidateTrue': params => params?.data?.agentWasInsertedInValidateWithoutLicense === true
                            }}}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule, RichSelectModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        multiSortKey={"ctrl"}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentPageCellEditingStopped}
                        valueCache={true}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            //only update session and user saved filters if saveFilterChanges is true. We added this in to not save filter changes if user clicked a link to agents page because
                            // this filtering out for one agent would mess with their saved filters that they may still want.
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'agentsGridFilterState', updateAgentsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        enableBrowserTooltips={false}
                        tooltipShowDelay={1500}
                        tooltipHideDelay={5000} //hides tooltip after 3.5 seconds
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer, cellRendererSelector,
                                    keyCreator, valueFormatter, cellEditorType, hide, sortable, minWidth, width, suppressColumnsToolPanel, lockVisible,
                                    cellEditorSelector, valueGetter, tooltipValueGetter, equals, filterValueGetter, comparator, cellEditorPopup},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    tooltipValueGetter={(params) => {
                                        //we always want this on every column for the agent grid, if agentWasInsertedInValidateWithoutLicense is true then show the tooltip so users know what is happening with their agent
                                        if(params && params.node && params.node.data && params.node.data.agentWasInsertedInValidateWithoutLicense === true){
                                            return "This agent is not activated because no valid licenses are available for this agent"
                                        }
                                        else if(tooltipValueGetter){
                                            return tooltipValueGetter
                                        }
                                        //else don't show a tooltip
                                        return null
                                    }}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply","cancel"],
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true,
                                    }}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    lockVisible={lockVisible}
                                    resizable
                                    editable={editable}
                                    equals={equals}
                                    //onCellValueChanged={onUpdate}
                                    keyCreator={keyCreator}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    //cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellEditorParams={editableOptions}
                                    cellEditorPopup={cellEditorPopup ? cellEditorPopup : false}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    filterValueGetter={filterValueGetter}
                                    comparator={comparator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}










