import React, {Component, useState} from "react";
import moment from "moment";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropdown from "react-dropdown";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    createScheduleReactive,
    deleteScheduleReactive,
    findByScheduleIdListReactive,
    getSchedulesReactive,
    updateSchedulesGridColumnStateReactive,
    updateSchedulesGridFilterModelReactive,
    updateSchedulesGridUseColumnStateReactive,
    updateSchedulesGridUseFilterStateReactive
} from "../api/schedulesApi";
import {ConfirmationModal} from "../../components/confirmationModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {defaultZenGroupColumnInitWithOptions,} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {decryptAndGetSessionVariable} from "../../utils/encryptDecryptHelper";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {deleteObjectInSessionStorage} from "../../utils/clientSideDataSessionStorageHelper";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {buttonTheme} from "../../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton} from "../../components/muiComponents";

let gridColumnStateSessionVariableName = "schedulesGridColumnState"

const DAY_OF_WEEK_OPTIONS = [
    {
        value: "monday",
        label: "Monday",
    },
    {
        value: "tuesday",
        label: "Tuesday",
    },
    {
        value: "wednesday",
        label: "Wednesday",
    },
    {
        value: "thursday",
        label: "Thursday",
    },
    {
        value: "friday",
        label: "Friday",
    },
    {
        value: "saturday",
        label: "Saturday",
    },
    {
        value: "sunday",
        label: "Sunday",
    },
];

const REPEAT_OPTIONS = [
    {
        value: "always",
        label: "Always",
    },
    {
        value: "daily",
        label: "Daily",
    },
    {
        value: "weekly",
        label: "Weekly",
    },
    //{
    //value: "monthly",
    //label: "Monthly",
    //},
    {
        value: "never",
        label: "Never",
    },
];

const TASK_OPTIONS = [
    {
        value: "isolate",
        label: "Isolate",
    },
    {
        value: "safe kill",
        label: "Kill Safely",
    },
    {
        value: "safe suspend",
        label: "Suspend Safely",
    },
    {
        value: "suspend",
        label: "Suspend Immediately",
    },
    {
        value: "kill",
        label: "Kill Immediately",
    },
];
//const DAY_OF_MONTH_OPTIONS = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]

export default function Schedules() {
    const [isLoading, setIsLoading] = useState(false);
    const [newSchedule, setNewSchedule] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [enableButtons, setEnableButtons] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("schedulesGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptions(true, true, true),
        { field: "occurrence", name: "Repeat", width: 200,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['NEVER', 'DAILY', 'WEEKLY', 'MONTHLY', 'ALWAYS'],
                valueFormatter: (params) => {
                    if (params.value) {
                        let lower = params.value.toLowerCase().substring(1)
                        return params.value.charAt(0) + lower
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            sortable: true,
            cellRenderer: function (params) {
                if (params.value) {
                    let lower = params.value.toLowerCase().substring(1)
                    return params.value.charAt(0) + lower
                }
                return ""
            }
        },
        //TODO: come back and figure out filtering/sorting for these two columns
        { field: "scheduledStart", name: "Start", width: 300,
            cellRenderer: function (params) {
                if (params.node.data) {
                    switch (params.node.data.occurrence) {
                        case "ALWAYS": {
                            return "*"
                        }
                        case "DAILY":
                        {
                            let millis = params.node.data.repeatingStart
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                /*fix the grid showing negative value for minutes. Say the time is supposed to be 11:45pm, without this it would
                                    show up as 24:-15. With this added in it now shows up as 23:45.
                                 */
                                minutes = 60 + minutes //subtracts from 60 since minutes is negative
                                hours = hours - 1 //subtract 1 from hours so it will not be an hour ahead of what it should be
                            }

                            return (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                        }
                        case "WEEKLY": {
                            let day = params.node.data.dayOfWeek
                            let millis = params.node.data.repeatingStart
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                minutes = 60 + minutes
                                hours = hours - 1
                            }

                            let timeStr = (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                            let dayStr = day.charAt(0) + day.toLowerCase().substring(1)

                            return timeStr + " on " + dayStr
                        }
                        case "MONTHLY": {
                            let day = params.node.data.dayOfMonth
                            let millis = params.node.data.repeatingStart
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                minutes = 60 + minutes
                                hours = hours - 1
                            }

                            let timeStr = (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                            let dayStr = day + ""
                            let st = [1, 21, 31]
                            let nd = [2, 22]
                            let rd = [3, 23]

                            if (st.some((it) => {return it === day})) {
                                dayStr += "st"

                            } else if (nd.some((it) => {return it === day})) {
                                dayStr += "nd"

                            } else if (rd.some((it) => {return it === day})) {
                                dayStr += "rd"

                            } else {
                                dayStr += "th"

                            }

                            return timeStr + " on the " + dayStr

                        }
                        case "NEVER":
                            if(params.node.data.scheduledStart){
                                if(decryptAndGetSessionVariable("timezone")){
                                    try{
                                        return new Date(params.node.data.scheduledStart).toLocaleString('en-US', {
                                            timeZone: decryptAndGetSessionVariable("timezone"),
                                            dateStyle: "long",
                                            timeStyle: "long"
                                        })
                                    }
                                    catch(error){
                                        //default to UTC String
                                        return new Date(params.node.data.scheduledStart).toUTCString()
                                    }
                                }
                                else{
                                    //default to UTC String
                                    return new Date(params.node.data.scheduledStart).toUTCString()
                                }
                            }else{
                                return ""
                            }
                        default:{
                            return "*"
                        }
                        //return date.getHours().padStart(2, '0') + ":" + date.getMinutes() + " on " + date.getMonth() + " " + date.getDay() + " " + date.getFullYear()
                    }
                }
                return ""
            }
        },
        { field: "scheduledEnd", name: "End", width: 300,
            cellRenderer: function (params) {
                if (params.node.data) {
                    switch (params.node.data.occurrence) {
                        case "ALWAYS": {
                            return "*"
                        }
                        case "DAILY":
                        {
                            let millis = params.node.data.repeatingEnd
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                minutes = 60 + minutes
                                hours = hours - 1
                            }

                            return (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                        }
                        case "WEEKLY": {
                            let day = params.node.data.dayOfWeek
                            let millis = params.node.data.repeatingEnd
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                minutes = 60 + minutes
                                hours = hours - 1
                            }

                            let timeStr = (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                            let dayStr = day.charAt(0) + day.toLowerCase().substring(1)

                            return timeStr + " on " + dayStr
                        }
                        case "MONTHLY": {
                            let day = params.node.data.dayOfMonth
                            let millis = params.node.data.repeatingEnd
                            let hours = Math.round(millis / (1000 * 60 * 60))
                            let minutes = Math.round(millis / (1000 * 60)) - (hours * 60)
                            if(minutes < 0){
                                minutes = 60 + minutes
                                hours = hours - 1
                            }

                            let timeStr = (hours + "").padStart(2, '0') + ":" + (minutes + "").padStart(2, '0')
                            let dayStr = day + ""
                            let st = [1, 21, 31]
                            let nd = [2, 22]
                            let rd = [3, 23]

                            if (st.some((it) => {return it === day})) {
                                dayStr += "st"

                            } else if (nd.some((it) => {return it === day})) {
                                dayStr += "nd"

                            } else if (rd.some((it) => {return it === day})) {
                                dayStr += "rd"

                            } else {
                                dayStr += "th"

                            }

                            return timeStr + " on the " + dayStr

                        }
                        case "NEVER":
                            if(params.node.data.scheduledEnd){
                                if(decryptAndGetSessionVariable("timezone")){
                                    try{
                                        return new Date(params.node.data.scheduledEnd).toLocaleString('en-US', {
                                            timeZone: decryptAndGetSessionVariable("timezone"),
                                            dateStyle: "long",
                                            timeStyle: "long"
                                        })
                                    }
                                    catch(error){
                                        //default to UTC String
                                        return new Date(params.node.data.scheduledEnd).toUTCString()
                                    }
                                }
                                else{
                                    //default to UTC String
                                    return new Date(params.node.data.scheduledEnd).toUTCString()
                                }
                            }else{
                                return ""
                            }
                        default:{
                            return "*"
                        }
                    }
                }
                return ""
            }
        },
        { field: "status", name: "Task", width: 225,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['KILL', 'SAFE_KILL', 'ISOLATE', 'SUSPEND', 'SAFE_SUSPEND'],
                valueFormatter: (params) => {
                    if (params.value) {
                        switch (params.value) {
                            case "KILL":
                                return "Kill Immediately"
                            case "SAFE_KILL":
                                return "Kill Safely"
                            case "ISOLATE":
                                return "Isolate"
                            case "SUSPEND":
                                return "Suspend Immediately"
                            case "SAFE_SUSPEND":
                                return "Suspend Safely"
                            default:
                                return params.value
                        }
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            sortable: true,
            cellRenderer: function (params) {
                if (params.node.data) {
                    switch (params.node.data.status) {
                        case "KILL":
                            return "Kill Immediately"
                        case "SAFE_KILL":
                            return "Kill Safely"
                        case "ISOLATE":
                            return "Isolate"
                        case "SUSPEND":
                            return "Suspend Immediately"
                        case "SAFE_SUSPEND":
                            return "Suspend Safely"
                        default:
                            return params.node.data.status
                    }
                }
                return ""
            }
        },
    ])

    const onCreateSchedule = () => {
        if(!newSchedule){ //double check to make sure newSchedule is not null
            return;
        }
        try {
            setIsLoading(true);
            let req = {task: newSchedule.task, occurrence: newSchedule.ocurrence, groupId: newSchedule.group}
            switch (newSchedule.ocurrence) { //taken and adapted from old site
                case "always": {
                    break
                }
                case "daily": {
                    let start = newSchedule.startTime.format("HH:mm");
                    let end = newSchedule.endTime.format("HH:mm")
                    let startSplit = start.split(':')
                    let endSplit = end.split(':')
                    let startMillis = (Number(startSplit[0]) * 60 + Number(startSplit[1])) * 60 * 1000
                    let endMillis = (Number(endSplit[0]) * 60 + Number(endSplit[1])) * 60 * 1000

                    req.startTime = startMillis
                    req.endTime = endMillis

                    break
                }
                case "weekly": {
                    let dayOfWeekIndex = -1;
                    for(let i=0; i< DAY_OF_WEEK_OPTIONS.length; i++){
                        if(newSchedule.dayOfWeek === DAY_OF_WEEK_OPTIONS[i].value){
                            dayOfWeekIndex = i + 1; //need +1 because in api Monday starts at 1 not 0, so bump it by 1
                            break;
                        }
                    }
                    if(!(dayOfWeekIndex > 0 && dayOfWeekIndex < 8)){ //needs to be between 1-7
                        return;
                    }
                    let start = newSchedule.startTime.format("HH:mm");
                    let end = newSchedule.endTime.format("HH:mm")
                    let startSplit = start.split(':')
                    let endSplit = end.split(':')
                    let startMillis = (Number(startSplit[0]) * 60 + Number(startSplit[1])) * 60 * 1000
                    let endMillis = (Number(endSplit[0]) * 60 + Number(endSplit[1])) * 60 * 1000

                    req.startTime = startMillis
                    req.endTime = endMillis

                    req.dayOfWeek = dayOfWeekIndex;

                    break
                }
                case "monthly": {
                    let start = newSchedule.startTime.format("HH:mm");
                    let end = newSchedule.endTime.format("HH:mm")
                    let startSplit = start.split(':')
                    let endSplit = end.split(':')
                    let startMillis = (Number(startSplit[0]) * 60 + Number(startSplit[1])) * 60 * 1000
                    let endMillis = (Number(endSplit[0]) * 60 + Number(endSplit[1])) * 60 * 1000

                    req.startTime = startMillis
                    req.endTime = endMillis

                    let dayOfMonth = newSchedule.dayOfMonth.value;
                    if(!(dayOfMonth > 0 && dayOfMonth < 32)){
                        return;
                    }
                    req.dayOfMonth = dayOfMonth;

                    break
                }
                case "never": {
                    let dateStart = moment(newSchedule.startDate).format("YYYY-MM-DD");
                    let dateEnd = moment(newSchedule.endDate).format("YYYY-MM-DD");
                    let start = newSchedule.startTime.format("HH:mm");
                    let end = newSchedule.endTime.format("HH:mm")
                    let startSplit = start.split(':')
                    let endSplit = end.split(':')
                    let startMillis = (Number(startSplit[0]) * 60 + Number(startSplit[1])) * 60 * 1000
                    let endMillis = (Number(endSplit[0]) * 60 + Number(endSplit[1])) * 60 * 1000

                    req.startTime = startMillis
                    req.endTime = endMillis

                    let startDateSplit = dateStart.split("-")
                    let endDateSplit = dateEnd.split("-")
                    let startYear = Number(startDateSplit[0])
                    let startMonth = Number(startDateSplit[1])
                    let startDay = Number(startDateSplit[2])
                    let endYear = Number(endDateSplit[0])
                    let endMonth = Number(endDateSplit[1])
                    let endDay = Number(endDateSplit[2])

                    req.dayOfMonth = startDay
                    req.dayOfMonthEnd = endDay
                    req.monthStart = startMonth
                    req.monthEnd = endMonth
                    req.yearStart = startYear
                    req.yearEnd = endYear

                    break
                }
                default:{
                    break
                }
            }
            createScheduleReactive(req).then(response => {
                NotificationManager.success("Schedule successfully created!");
                resetGrid();
                setNewSchedule({});
                setIsLoading(false);
                setModalIsOpen(false);
                setShowCreateConfirmation(false);
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error creating schedule, please make sure all fields are filled out properly and try again");
                }
                setShowCreateConfirmation(false)
                setIsLoading(false);
            })
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error creating schedule, please make sure all fields are filled out properly and try again");
            }
            setShowCreateConfirmation(false)
            setIsLoading(false);
        }
    };

    const removeSchedule = () => {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let currentScheduleNode = gridApi.getSelectedNodes()[0] //only allowing single selection on this page, so grab first element
            let currentSchedule = currentScheduleNode.data
            if(currentSchedule && currentSchedule.scheduleId && currentSchedule.zenGroupId){
                setIsLoading(true);
                deleteScheduleReactive(currentSchedule.zenGroupId, currentSchedule.scheduleId).then(response => {
                    NotificationManager.success("Schedule removed successfully!");
                    deleteObjectInSessionStorage("schedulesGridList", "scheduleId", currentSchedule.scheduleId)
                    resetGrid();
                    setIsLoading(false)
                    setShowDeleteConfirmation(false);
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error removing schedule");
                    }
                    setIsLoading(false)
                    setShowDeleteConfirmation(false);
                })
            }
        }
    };

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Schedules</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Delete confirmation modal*/}
            <ConfirmationModal
                text="You are about to delete this schedule, would you like to continue?"
                onConfirm={() => removeSchedule()}
                onClose={() => {
                    setShowDeleteConfirmation(false)
                }}
                opened={showDeleteConfirmation}
            />
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap mt-8 ml-10 mr-10 gap-y-2">
                    {privatePageHeaderHelper("Schedules")}
                    <hr className="bg-black h-0.5" />
                    {/*Add a Schedule*/}
                    <div>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                onClick={() => {
                                    setNewSchedule({ ...newSchedule});
                                    setModalIsOpen(!modalIsOpen);
                                }}>
                            Add Schedule
                        </Button>
                        </ThemeProvider>
                        {/*Create Schedule Modal*/}
                        <Modal contentLabel="Create New Schedule" isOpen={modalIsOpen}
                               onRequestClose={() => {
                                   setModalIsOpen(false)
                                   setNewSchedule({});
                               }}
                               shouldCloseOnOverlayClick={true}
                               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-1/3 lg:w-1/2
                                        md:w-1/2 w-3/4 inset-y-10 mx-auto rounded-2xl overflow-auto max-w-2xl`}
                               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

                        >
                            <ConfirmationModal
                                text="You are about to create this schedule, would you like to continue?"
                                onConfirm={() => onCreateSchedule()}
                                onClose={() => {
                                    setShowCreateConfirmation(false)
                                }}
                                opened={showCreateConfirmation}
                            />
                            <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4">
                                {/*Top*/}
                                <div className="flex flex-row justify-between">
                                    <h1 className="font-bold text-3xl">Create New Schedule</h1>
                                    <MuiCloseIconButton
                                        onClick={() => {
                                            setModalIsOpen(false)
                                            setNewSchedule({});
                                        }}
                                    />
                                </div>
                                <hr className="mt-3 h-0.5" />
                                {/*Fields*/}
                                <div className="ml-1 mt-5">
                                    <label>Group</label>
                                    <Dropdown
                                        onChange={({ value }) =>
                                            setNewSchedule({ ...newSchedule, group: value })
                                        }
                                        options={getZenGroupDropDownContents()}
                                        value={newSchedule.group}
                                        placeholder="Select"
                                        className="mt-3"
                                        controlClassName="dropdown"
                                        placeholderClassName="text-black-40"
                                        arrowClassName="text-black-70 text-base my-1"
                                    />
                                </div>
                                <div className="ml-1 mt-5">
                                    <label>Repeat</label>
                                    <Dropdown
                                        onChange={({ value }) =>
                                            setNewSchedule({ ...newSchedule, ocurrence: value })
                                        }
                                        options={REPEAT_OPTIONS}
                                        value={newSchedule.ocurrence}
                                        placeholder="Select"
                                        className="mt-3"
                                        controlClassName="dropdown"
                                        placeholderClassName="text-black-40"
                                        arrowClassName="text-black-70 text-base my-1"
                                    />
                                </div>
                                <div className="ml-1 mt-5">
                                    <label>Task</label>
                                    <Dropdown
                                        onChange={({ value }) =>
                                            setNewSchedule({ ...newSchedule, task: value })
                                        }
                                        options={TASK_OPTIONS}
                                        value={newSchedule.task}
                                        placeholder="Select"
                                        className="mt-3"
                                        controlClassName="dropdown"
                                        placeholderClassName="text-black-40"
                                        arrowClassName="text-black-70 text-base my-1"
                                    />
                                </div>
                                {newSchedule.ocurrence === "never" && (
                                    <div className="ml-1 flex flex-row flex-wrap justify-between">
                                        <div className="flex flex-col mt-5">
                                            <label>Start Date</label>
                                            <DatePicker
                                                selected={newSchedule.startDate}
                                                onChange={(value) => {
                                                    setNewSchedule({ ...newSchedule, startDate: value });
                                                }}
                                                className="mt-3 focus:outline-none rounded-lg border border-4 border-solid h-12 pl-2 text-base"
                                            />
                                        </div>
                                        <div className="flex flex-col mt-5">
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={newSchedule.endDate}
                                                onChange={(value) => {
                                                    setNewSchedule({ ...newSchedule, endDate: value });
                                                }}
                                                className="mt-3 focus:outline-none rounded-lg border border-4 border-solid h-12 pl-2 text-base"
                                            />
                                        </div>
                                    </div>
                                )}
                                {/*Monthly is not an option on nextJS site*/}
                                {/*{newSchedule.ocurrence === "monthly" && (

                                )
                            }*/}
                                {newSchedule.ocurrence === "weekly" && (
                                    <div className="ml-1 mt-5">
                                        <label>Day of Week</label>
                                        <Dropdown
                                            onChange={({ value }) =>
                                                setNewSchedule({ ...newSchedule, dayOfWeek: value })
                                            }
                                            options={DAY_OF_WEEK_OPTIONS}
                                            value={newSchedule.dayOfWeek}
                                            placeholder="Select"
                                            className="mt-3"
                                            controlClassName="dropdown"
                                            placeholderClassName="text-black-40"
                                            arrowClassName="text-black-70 text-base my-1"
                                        />
                                    </div>
                                )
                                }

                                {newSchedule.ocurrence && newSchedule.ocurrence !== "always" && (
                                    //TODO: adjusting TimePicker inputClassName height width not working
                                    <div className="ml-1 flex flex-row flex-wrap justify-between">
                                        <div className="flex flex-col mt-5">
                                            <label>Start Time</label>
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={newSchedule.startTime}
                                                className="mt-3 focus:outline-none rounded-lg h-12 w-full"
                                                inputClassName="mt-3 focus:outline-none rounded-lg h-12 pl-2 text-base"
                                                onChange={(value) => {
                                                    setNewSchedule({ ...newSchedule, startTime: value });
                                                }}
                                                format="HH:mm"
                                            />
                                        </div>
                                        <div className="flex flex-col mt-5">
                                            <label>End Time</label>
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={newSchedule.endTime}
                                                className="mt-3 focus:outline-none rounded-lg h-12 w-full"
                                                inputClassName="mt-3 focus:outline-none rounded-lg h-12 pl-2 text-base"
                                                onChange={(value) => {
                                                    setNewSchedule({ ...newSchedule, endTime: value });
                                                }}
                                                format="HH:mm"
                                            />
                                        </div>
                                    </div>

                                )
                                }

                                <div className="flex flex-col mt-5">

                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            color={"primary"}
                                            onClick={() => {
                                                //check to make sure necessary fields are filled out and times are valid if not an always occurrence
                                                if(!newSchedule){
                                                    return;
                                                }
                                                if(newSchedule.group && newSchedule.ocurrence && newSchedule.task){
                                                    if(newSchedule.ocurrence === "always"){ //don't need to check times
                                                        setShowCreateConfirmation(true);
                                                    }
                                                    else{ //need to check times
                                                        if(newSchedule.ocurrence === "never"){
                                                            if(newSchedule.startDate && newSchedule.endDate && newSchedule.startTime && newSchedule.endTime){
                                                                if(!absoluteDateErrorCheck()){ //false is no error
                                                                    setShowCreateConfirmation(true);
                                                                }
                                                            }
                                                        }
                                                        else{ //daily, weekly, or monthly
                                                            if(newSchedule.startTime && newSchedule.endTime){
                                                                if(newSchedule.ocurrence === "weekly"){
                                                                    if(!newSchedule.dayOfWeek){
                                                                        return;
                                                                    }
                                                                }
                                                                else if(newSchedule.ocurrence === "monthly"){
                                                                    if(!newSchedule.dayOfMonth){
                                                                        return;
                                                                    }
                                                                }
                                                                if(!timesErrorCheck()){ //false means no error
                                                                    setShowCreateConfirmation(true);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                            }}>
                                    Create
                                    </Button>
                                </ThemeProvider>
                                </div>

                            </div>
                        </Modal>
                    </div>
                    <hr className="bg-black h-0.5" />
                    <GridColumnFilterStateSaving
                        useFilterStateSettingToggled = {useFilterStateSettingToggled}
                        setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                        toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                        useColumnStateSettingToggled = {useColumnStateSettingToggled}
                        setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                        toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>

                    <div className="flex flex-row justify-between flex-wrap">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    disabled={!enableButtons}
                                    startIcon={<FontAwesomeIcon
                                        className="object-contain text-black"
                                        icon="fa-duotone fa-trash-can"
                                        size="1x"
                                    /> }
                                    onClick={() => {
                                        setShowDeleteConfirmation(true);
                                    }}
                             >
                            Delete
                            </Button>
                        </ThemeProvider>
                        <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi}
                                      refreshGridFunction = {refreshGrid}/>
                    </div>

                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );

    function timesErrorCheck(){ //for daily, weekly, or monthly occurences
        //return val of true means error;
        try{
            let start = newSchedule.startTime.format("HH:mm");
            let end = newSchedule.endTime.format("HH:mm")
            if (!start || !end) {
                return true;
            }
            if (start >= end) {
                NotificationManager.error("The end time must be after the start time");
                return true;
            }
            return false;
        }
        catch(error){
            return true;
        }
    }

    function absoluteDateErrorCheck() { //for never occurrence
        //return val of true means error;
        try{
            let startDate = moment(newSchedule.startDate).format("YYYY-MM-DD");
            let endDate = moment(newSchedule.endDate).format("YYYY-MM-DD");
            let start = newSchedule.startTime.format("HH:mm");
            let end = newSchedule.endTime.format("HH:mm")
            let err = false
            if (!startDate || !endDate || !start || !end) {
                err = true
            }
            // If we're missing errors just stop before more checks
            if (err) {
                return err
            }
            if (startDate > endDate) {
                NotificationManager.error("The end date must be after the start date");
                return true
            }

            if (startDate === endDate && start >= end) {
                NotificationManager.error("The end time must be after the start time");
                return true
            }
            return err
        }
        catch(error){
            return true;
        }
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'schedulesGridFilterState', updateSchedulesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSchedulesGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
            />
        );
    }
    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "scheduleId", "scheduleId", findByScheduleIdListReactive, null)
    }

    function resetGrid(){
        //this function is used when a new notification was created or a notification was deleted since refreshGrid() doesn't cover this yet as of July 30th
        //gridApi.onFilterChanged() //preserves filter and sort models
        gridApi && gridApi.deselectAll();
        getSchedulesReactive().then(data => {
            if(gridApi){
                //The ag grid gridApi.setRowData() clears all filters, so we need to get the filter model before, perform the setRowData, and then reapply the filter
                let filterModelBefore = gridApi.getFilterModel()
                gridApi.setRowData(data)
                gridApi.setFilterModel(filterModelBefore)
            }
        }).catch(function(error){})
    }
}

class Grid extends Component {
    rowData = []

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        //params.api.getFilterInstance("zenGroupDisplayName");
        params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
    };

    cellEditingStoppedFunctionCaller = (event) => {
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSchedulesGridColumnStateReactive)
    }

    onGridReady = async (gridReadyParams) => {
        gridReadyParams.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            gridReadyParams.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }

        let schedulesGridList = JSON.parse(decryptAndGetSessionVariable("schedulesGridList"))
        if(schedulesGridList){
            //found schedulesGridList in session
            this.gridApi.setRowData(schedulesGridList)
        }
        else{
            const data = await getSchedulesReactive()
            gridReadyParams.api.setRowData(data)
        }

        onGridReadyHelper(gridReadyParams, "schedulesGridFilterState");


        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'schedulesGridFilterState', updateSchedulesGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}

                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={editableOptions}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
