import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {getRRInstallScriptBuilderBase64Reactive, getZenGroupCodesForSiteReactive,} from "../api/agentsApi";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {
    bulkChangeLicensesGroupReactive,
    findByLicenseIdListReactive,
    licenseUsageReactive,
    singleReleaseLicensesReactive,
    updateLicensesGridColumnStateReactive,
    updateLicensesGridFilterModelReactive,
    updateLicensesGridUseColumnStateReactive,
    updateLicensesGridUseFilterStateReactive
} from "../api/licensesApi";
import {NotificationContainer} from 'react-notifications';
import {downloadShutdownScriptFile, shutdownServiceScriptGenerate} from "../../utils/generator";
import Modal from "react-modal";
import {ConfirmationModalWithPermissionsShown} from "../../components/confirmationModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import SidebarMenu from "../../components/sideBarComponent";
import {useForm} from "react-hook-form";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultAgentNameColumnAndMachineNameInitWithFiltersWithValueGetterForClientSide,
    defaultMachineNameColDefWithFiltersWithValueGetter
} from "../../utils/agentNameGridHelper";
import {licensePageCellEditingStopped} from "../../utils/gridCellEditing";
import {AgChartsReact} from "ag-charts-react";
import {
    findZenGroupById,
    getZenGroupDropDownContents,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {decryptAndGetSessionVariable} from "../../utils/encryptDecryptHelper";
import {checkPermission} from "../../utils/permissionCheckHelper";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let gridColumnStateSessionVariableName = "licensesGridColumnState"
Modal.setAppElement('#root')


export default function Licenses() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroups,setZenGroups] = useState([]);
    const [zenGroup, setZenGroup] = useState();
    const [showBulkMoveLicenses, setShowBulkMoveLicenses] = useState(false);
    const [groupToMoveFrom, setGroupToMoveFrom] = useState();
    const [groupToMoveTo, setGroupToMoveTo] = useState();
    const { register, handleSubmit, reset } = useForm();
    const [licenseType, setLicenseType] = useState();
    const [showReleaseLicensesConfirmation, setShowReleaseLicensesConfirmation] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [zenGroupNamesWithPermission, setZenGroupNamesWithPermission] = useState(new Set());
    const [zenGroupIdsWithoutPermission, setZenGroupIdsWithoutPermission] = useState(new Set());
    const [zenGroupNamesWithoutPermission, setZenGroupNamesWithoutPermission] = useState(new Set());
    const [showSuspendServiceModal,setShowSuspendServiceModal] = useState(false);
    const [suspendServiceGroup,setSuspendServiceGroup] = useState();
    const [enableButtons, setEnableButtons] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("licensesGridFilterState"));
    const [showInstallScriptModal, setShowInstallScriptModal] = useState(false);
    const [installScriptGroup, setInstallScriptGroup] = useState();
    const [installerType, setInstallerType] = useState("Script");
    const licenseLocation = useLocation();
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(1000);
    /*
        1000ms to start for the initial sse data pull, larger grids benefit from the second delay to give ag grid time to perform saved sorts to apply by default. If we have small number of
        ms then ag grid performance for certain column sorts is slower since it has to re-sort and re-render much more frequently.
     */
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true,true, false),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},
        { field: "agentId", hide: true, suppressColumnsToolPanel: true},
        { field: "licenseName", name: "License Name", width: 380,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: false,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this License's Name", "licenseName")
            }
        },
        defaultAgentNameColumnAndMachineNameInitWithFiltersWithValueGetterForClientSide,
        defaultMachineNameColDefWithFiltersWithValueGetter,
        { field: "licenseType", name: "License Type", width: 225,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Desktop', 'Server'],
                suppressSorting: false,
                suppressSelectAll: false,
            },
            sortable: true
        },
        { field: "expirationDate", name: "Renews", width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "purchasedDate", name: "Purchased", width: 280,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
    ])

    var _ = require('lodash');

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setZenGroups(zenGroupSessionStorage)
            if(zenGroupSessionStorage !== null && zenGroupSessionStorage.length > 0){
                setZenGroup(zenGroupSessionStorage[0].id);
                setSuspendServiceGroup(zenGroupSessionStorage[0].id);
            }

            setChartIsLoading(true)
            licenseUsageReactive().then(licenseUsagesList => {
                setChartIsLoading(false)
                if(licenseUsagesList){
                    let data = []
                    for (let i in licenseUsagesList){
                        let group = findZenGroupById(licenseUsagesList[i].zenGroupId)
                        if(group && group.friendlyName){
                            //found group in session
                            data.push({"zenGroupId":licenseUsagesList[i].zenGroupId, "zenGroupName":group.friendlyName,
                                "used":licenseUsagesList[i].used,
                                "available":licenseUsagesList[i].available
                            })
                        }
                        else{
                            //else did not find group in session
                            data.push({"zenGroupId":licenseUsagesList[i].zenGroupId,
                                "used":licenseUsagesList[i].used,
                                "available":licenseUsagesList[i].available
                            })
                        }
                    }

                    data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                    setChartData(data)
                }
                else{
                    setChartData([])
                }
            }).catch(function (error) {
                setChartIsLoading(false)
            })

        })()
        return () => controller?.abort();
    }, [zenGroupSessionStorage])

    const downloadShutdownScript = () => {
        if(suspendServiceGroup){
            getZenGroupCodesForSiteReactive(suspendServiceGroup).then(codes => {
                const file = shutdownServiceScriptGenerate(codes[0], codes[1], codes[2]);
                downloadShutdownScriptFile(file, "RR-shutdown-service-script");
            }).catch(function (error) {
                NotificationManager.error(
                    "Error generating Shutdown Service Script. Please try again."
                );
            })
        }
    };

    const onSubmit = async (data) => {
        if(data.count && groupToMoveFrom && groupToMoveTo && licenseType){
            if(groupToMoveFrom === groupToMoveTo){
                NotificationManager.info("You cannot select the same group for both fields");
                return;
            }
            try{
                let numLicenses = Number(data.count)
                if(numLicenses > 10000){
                    NotificationManager.info("The number of licenses cannot exceed 10,000");
                    return;
                }
                if(numLicenses < 1){
                    NotificationManager.info("The number of licenses must be greater than 0");
                    return;
                }
                bulkChangeLicensesGroupReactive(numLicenses, groupToMoveFrom, groupToMoveTo, licenseType).then(response => {
                    NotificationManager.success("Successfully queued update for licenses (you may need to refresh the grid periodically to see changes for larger requests)");
                    refreshGrid()
                    resetMoveLicenseForm()

                }).catch(function (error) {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error(
                            `Error updating licenses.`
                        );
                    }
                })
            }
            catch(error){
                console.log(error)
            }
        }
        else{
            NotificationManager.error("Please fill out all fields")
        }
    }

    const resetMoveLicenseForm = () => {
        setShowBulkMoveLicenses(false)
        reset({count: ""})
        setGroupToMoveFrom()
        setGroupToMoveTo()
        setLicenseType()
    }

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Licenses</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*New Agent Download Modal for picking group to install agent for*/}
            <Modal contentLabel="Download Agent Modal" isOpen={showInstallScriptModal}
                   onRequestClose={() => {
                       setShowInstallScriptModal(false)
                       setInstallScriptGroup()
                       setInstallerType("Script")
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Download Agent</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowInstallScriptModal(false)
                                setInstallScriptGroup()
                                setInstallerType("Script")
                            }}
                        />

                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>Select a Group for the Agent</label>
                        <Dropdown
                            options={getZenGroupDropDownContents()}
                            value={installScriptGroup}
                            onChange={({ value }) =>{
                                setInstallScriptGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <div className="ml-1">
                        <label>Select an Installer Type</label>
                        <Dropdown
                            options={["Script", "GUI"]}
                            value={installerType}
                            onChange={({ value }) =>{
                                setInstallerType(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                if(installScriptGroup && installerType){
                                    if(installerType === "Script"){
                                        //TODO: send the selected group to the api
                                        setIsLoading(true)
                                        getRRInstallScriptBuilderBase64Reactive(installScriptGroup, "SCRIPT_GENERATOR").then(response  => {
                                            try{
                                                let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                let today = new Date();
                                                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                let time = today.getHours() + "-" + today.getMinutes();
                                                let dateTime = date+'T'+time;
                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);
                                                link.download=`RR-Install-Script-${dateTime}.bat`;
                                                link.click();
                                            }
                                            catch(error){
                                                console.error("Error creating generating installer script")
                                            }
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            console.error(error);
                                            setIsLoading(false)
                                        })
                                    }
                                    else if (installerType === "GUI"){
                                        //TODO: send the selected group to the api
                                        setIsLoading(true)
                                        getRRInstallScriptBuilderBase64Reactive(installScriptGroup, "GUI").then(response  => {
                                            try{
                                                let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                let today = new Date();
                                                let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                let time = today.getHours() + "-" + today.getMinutes();
                                                let dateTime = date+'T'+time;

                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);
                                                link.download=`RR-Installer-${dateTime}.exe`;
                                                link.click();
                                            }
                                            catch(error){
                                                console.error("Error creating gui installer")
                                            }
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            console.error(error);
                                            setIsLoading(false)
                                        })

                                    }
                                }
                            }}>
                        Download Agent Installer
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>

            {/*Emergency Shutdown Service Modal*/}
            <Modal contentLabel="Emergency Shutdown Service" isOpen={showSuspendServiceModal}
                   onRequestClose={() => {
                       setShowSuspendServiceModal(false)
                       setSuspendServiceGroup(zenGroup)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Emergency Shutdown Service</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowSuspendServiceModal(false)
                                setSuspendServiceGroup(zenGroup)
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>Select a Group</label>
                        <Dropdown
                            options={getZenGroupDropDownContents()}
                            value={suspendServiceGroup}
                            onChange={({ value }) =>{
                                setSuspendServiceGroup(value)
                            }}
                            placeholder="Select"
                            className="mt-3"
                            controlClassName="dropdown-auto-height"
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base my-1"
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                onClick={downloadShutdownScript}>
                                Generate Emergency Shutdown Service Script
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <Modal contentLabel="Bulk Change License Groups" isOpen={showBulkMoveLicenses}
                   onRequestClose={() => {
                       resetMoveLicenseForm()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Move Free Licenses Between Groups</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetMoveLicenseForm()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <label>How Many Licenses Would You Like to Move?</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                autoFocus={true}
                                required
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g,'');
                                }}
                                name="count"
                                {...register("count")}
                                placeholder="Number of Licenses to Move"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Move From Group:</label>
                            <Dropdown
                                options={getZenGroupDropDownContents()}
                                value={groupToMoveFrom}
                                onChange={({ value }) =>
                                    setGroupToMoveFrom(value)
                                }
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>To Group:</label>
                            <Dropdown
                                options={getZenGroupDropDownContents()}
                                value={groupToMoveTo}
                                onChange={({ value }) =>
                                    setGroupToMoveTo(value)
                                }
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>License Type to Move:</label>
                            <Dropdown
                                options={["Desktop", "Server"]}
                                value={licenseType}
                                onChange={({ value }) =>
                                    setLicenseType(value)
                                }
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"}
                                        type={"submit"}
                                        color={"primary"}>
                                Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <ConfirmationModalWithPermissionsShown
                text="WARNING: You are about to release the selected licenses from their agents. The grid below shows your permission level to do this action for each of the selected licenses' group:"
                onConfirm={async () => {
                    if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                        let validLicenseIdList = []
                        gridApi.getSelectedNodes().forEach(currentLicense => {
                            if(currentLicense.data.licenseId && currentLicense.data.agentId && !zenGroupIdsWithoutPermission.has(currentLicense.data.zenGroupId)) { //make sure license has an id (probably unnecessary check)
                                validLicenseIdList.push(currentLicense.data.licenseId)
                                singleReleaseLicensesReactive(currentLicense.data.licenseId)
                                    .then(() => {
                                        currentLicense.setDataValue("agentId",null)
                                        currentLicense.setDataValue("agentDisplayName",null)
                                        currentLicense.setDataValue("machineName",null)
                                        //gridApi.refreshCells({columns: ["agentDisplayName", "machineName"], suppressFlash: true, force: true, rowNodes: [currentLicense]})
                                    })
                                    .catch(function(error){
                                        // if(error.message){
                                        //     NotificationManager.error(error.message);
                                        // }
                                        // else{
                                        //     NotificationManager.error(
                                        //         `Error requesting the release of these licenses.`
                                        //     );
                                        // }
                                        // setShowReleaseLicensesConfirmation(false)
                                    })
                            }
                        })

                        if(validLicenseIdList.length < 1){
                            NotificationManager.info(`None of the selected licenses in groups you have permission in were eligible to be released`);
                        }
                        else{
                            NotificationManager.success("Queued the release of eligible licenses for groups you have permission to do so in (you may need to refresh the grid periodically to see changes for larger requests)");
                            // refreshGrid()
                            setShowReleaseLicensesConfirmation(false)
                            setZenGroupNamesWithPermission(new Set())
                            setZenGroupNamesWithoutPermission(new Set())
                            setZenGroupIdsWithoutPermission(new Set())
                        }
                    }
                }}
                onClose={() => {
                    setShowReleaseLicensesConfirmation(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showReleaseLicensesConfirmation}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading} />
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col ml-10 mr-10 mt-8 gap-y-3">
                    {privatePageHeaderHelper("Agent Licenses")}
                    <hr className="bg-black h-0.5" />
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={chartToggled}
                                    name="licenseUsageToggle"
                                    onChange={e => setChartToggled(e.target.checked)}
                                />
                            } label={chartToggled ? "Showing License Usage Chart" : "Hiding License Usage Chart"}/>
                        </ThemeProvider>
                    </div>
                    {/*License Usage Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            {
                                autoSize: true,
                                data: chartData,
                                title: {
                                    text: 'License Usage Per Group',
                                    fontSize: 18,
                                },
                                series: [
                                    {
                                        type: 'column',
                                        xKey: 'zenGroupId',
                                        yKeys: [
                                            'used',
                                            'available',
                                        ],
                                        yNames: [
                                            'Used',
                                            'Free',
                                        ],
                                        fills: ['#4194a5', '#e76a24'],
                                        strokes: ['#989898', '#989898'],
                                        highlightStyle: {
                                            item: {
                                                fill: "#E8E8E8",
                                                stroke: "#181818"
                                            },
                                        },
                                        tooltip: { //for hovering over a column
                                            renderer: function (params) {
                                                let content = params.datum.zenGroupName + ": " + params.yValue
                                                return {
                                                    title: params.yName,
                                                    content: content
                                                };
                                            },
                                        },
                                    },
                                ],
                                axes: [
                                    {
                                        type: 'category',
                                        position: 'bottom',
                                        label: {
                                            rotation: 50,
                                            formatter: function(params){
                                                if(!params.axis.boundSeries[0].data[params.index].zenGroupName){
                                                    let group = findZenGroupById(params.value)
                                                    if(group && group.friendlyName){
                                                        //found group in session
                                                        params.axis.boundSeries[0].data[params.index].zenGroupName = group.friendlyName
                                                    }
                                                    else{
                                                        return "Group not found"
                                                    }
                                                }
                                                let used = params.axis.boundSeries[0].data[params.index].used
                                                let available = params.axis.boundSeries[0].data[params.index].available
                                                let total = used + available
                                                return params.axis.boundSeries[0].data[params.index].zenGroupName + " (" + total + ")"
                                            },
                                            fontSize: 11
                                        },
                                    },
                                    {
                                        type: 'number',
                                        position: 'left',
                                        //log shows now if you give undefined, not including min/max lets it set these automatically
                                        //min: chartData.length === 0 ? 0: undefined, //default min to 0 when chart data length is 0, else undefined leaves it to auto set the min according to the data given
                                        //max: chartData.length === 0 ? 100: undefined, //default max to 100 when chart data length is 0, else undefined leaves it to auto set the max according to the data given
                                    },
                                ],
                                legend: {
                                    spacing: 40,
                                    position: 'top',
                                },
                            }
                        } />
                        <BackDropChartLoadingOverlay opened={chartIsLoading} />
                    </div>
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<GroupIcon className={"cursor-pointer"}/>} tooltipTitle={"Move Licenses Between Groups"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setShowBulkMoveLicenses(true)
                                            }}/>
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Bulk Release Selected Licenses"}
                                            tooltipPlacement={"top"} disabled={!enableButtons}
                                            onClick={async () => {
                                                let zenGroupIdSet = new Set();
                                                let permissionCheckResult = true
                                                for (const rowNode of gridApi.getSelectedNodes()) {
                                                    if(rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)){
                                                        zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                        if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "releaseAgentLicense")) {
                                                            permissionCheckResult = false
                                                            zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                            zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                            setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                            setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                        } else {
                                                            zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                            setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                        }
                                                    }
                                                }

                                                if (!permissionCheckResult) {   //  permission check failed, show modal
                                                    setShowReleaseLicensesConfirmation(true)
                                                } else {    //  permission check pass, do not show modal and directly call api
                                                    if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
                                                        let validLicenseIdList = []
                                                        gridApi.getSelectedNodes().forEach(currentLicense => {
                                                            if(currentLicense.data.licenseId && currentLicense.data.agentId && !zenGroupIdsWithoutPermission.has(currentLicense.data.zenGroupId)) { //make sure license has an id (probably unnecessary check)
                                                                validLicenseIdList.push(currentLicense.data.licenseId)
                                                                singleReleaseLicensesReactive(currentLicense.data.licenseId)
                                                                    .then(() => {
                                                                        //let change stream take care of updating grid
                                                                        /*currentLicense.setDataValue("agentId",null)
                                                                        currentLicense.setDataValue("agentDisplayName",null)
                                                                        currentLicense.setDataValue("machineName",null)*/
                                                                    })
                                                                    .catch(function(error){
                                                                    })
                                                            }
                                                        })
                                                        if(validLicenseIdList.length < 1){
                                                            NotificationManager.info(`None of the selected licenses in groups you have permission in were eligible to be released`);
                                                        }
                                                        else{
                                                            NotificationManager.success("Queued the release of eligible licenses for groups you have permission to do so in (you may need to refresh the grid periodically to see changes for larger requests)");
                                                            setZenGroupNamesWithPermission(new Set())
                                                            setZenGroupNamesWithoutPermission(new Set())
                                                            setZenGroupIdsWithoutPermission(new Set())
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                        </MuiIconButtonWithTooltipAndBox>
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'licensesGridFilterState', updateLicensesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateLicensesGridUseColumnStateReactive);
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                setEnableButtons={setEnableButtons}
                licenseLocation={licenseLocation}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "licensesGridExport")
    }

    //This should mostly be in the renderer
    async function refreshGrid(){
        //We are going to have change streams in the future, and with high quantities of data now in the grid, this code slows down the grid and becomes laggy, so disabling this for now by
        // just having a return line to stop the code from running
        return
        /*
        //let agentIdsList = [];
        let licenseIdList = [];
        //let zenGroupIdSet = new Set();
        gridApi.forEachNode(rowNode => {
            //agentIdsList.push(rowNode.data.agentId);
            licenseIdList.push(rowNode.data.licenseId);
            //zenGroupIdSet.add(rowNode.data.zenGroupId);
        })
        if(licenseIdList.length>0){
            findByLicenseIdListReactive(licenseIdList).then(licenseList => {
                if(licenseList.length > 0){
                    //refresh zenGroup filter values in case names have changed
                    gridApi.getFilterInstance('zenGroupDisplayName').refreshFilterValues()
                    //await zenGroupFilter.refreshFilterValues();

                    //let zenGroupNameList = await getZenGroupFriendlyNames(Array.from(zenGroupIdSet))
                    //let agentNameList = await getAgentNamesByIdList(agentIdsList)
                    gridApi.forEachNode(rowNode => {
                        //need to check if the license zenGroupId got moved to a group they are not a part of. Use the zenGroupIdsList from
                        //let currentLicenseId = rowNode.data.licenseId
                        let licenseIdExists = false
                        licenseList.forEach(async newLicense => {
                            if(rowNode.data.licenseId === newLicense.id){
                                licenseIdExists = true
                                if(!_.isEqual(rowNode.data, newLicense)){
                                    for (const [originalKey, originalValue] of Object.entries(rowNode.data)) {
                                        if(originalKey === "licenseName"){
                                            if(newLicense["userSetFriendlyName"]){
                                                if(originalValue !== newLicense["userSetFriendlyName"]){
                                                    rowNode.setDataValue("licenseName",newLicense["userSetFriendlyName"]);
                                                }
                                            }else if(newLicense["friendlyName"]){
                                                if(originalValue !== newLicense["friendlyName"]){
                                                    rowNode.setDataValue("licenseName",newLicense["friendlyName"]);
                                                }
                                            }
                                        }
                                        else if(originalKey === "agentId"){
                                            if(rowNode.data.agentId && !newLicense["agentId"]){ //check if license was de-allocated from agent
                                                rowNode.setDataValue("agentId",null);
                                                rowNode.setDataValue("agentDisplayName",null);
                                                rowNode.setDataValue("machineName",null);
                                            }
                                            else if(!rowNode.data.agentId && newLicense["agentId"]){ //check if license was allocated to agent
                                                let newAgentId = newLicense["agentId"]
                                                rowNode.setDataValue("agentId",newAgentId);
                                                //let newAgentName = await getAgentNamesByIdList([newAgentId])
                                                //let agentNameObject = newAgentName.find(agentNameObjectIterator => agentNameObjectIterator.agentId === newAgentId);
                                                //if(agentNameObject){
                                                //    if(agentNameObject["name"]){
                                                //        rowNode.setDataValue("agentDisplayName",agentNameObject["name"]);
                                                //    }
                                                //    if(agentNameObject["machineName"]){
                                                //        rowNode.setDataValue("machineName",agentNameObject["machineName"]);
                                                //    }
                                                //}
                                            }
                                            else if(newLicense["agentId"]){
                                                //Just set the returned agentId, and then at end of function there is a call to force cells in agentDisplayName to
                                                // refresh their valueFormatter function which will trigger their async calls again and update name if need be. And that will take care of
                                                // any updates for a machineName col if present as well
                                                let newAgentId = newLicense["agentId"]
                                                rowNode.setDataValue("agentId",newAgentId);
                                            }
                                        }
                                        else if(originalKey === "agentDisplayName"){

                                            //let agentNameObject = agentNameList.find(agentNameObjectIterator => agentNameObjectIterator.agentId === rowNode.data["agentId"]);
                                            //if(agentNameObject){
                                            //    if(agentNameObject["name"] && agentNameObject["name"] !== originalValue){
                                            //        rowNode.setDataValue("agentDisplayName",agentNameObject["name"]);
                                            //    }
                                           // }


                                        }
                                        else if(originalKey === "zenGroupId"){
                                            if(originalValue !== newLicense["zenGroupId"]){
                                                rowNode.setDataValue("zenGroupId",newLicense["zenGroupId"]);
                                            }
                                        }
                                        else if(originalKey === "zenGroupDisplayName"){

                                            //let zenGroupNameObject = zenGroupNameList.find(zenGroupNameObjectIterator => zenGroupNameObjectIterator.zenGroupId === rowNode.data["zenGroupId"]);
                                            //if(zenGroupNameObject){
                                            //    if(zenGroupNameObject["name"] && zenGroupNameObject["name"] !== originalValue){
                                            //        rowNode.setDataValue("zenGroupDisplayName",zenGroupNameObject["name"]);
                                            //    }
                                            //}

                                        }
                                    }
                                }
                            }
                        })
                        if(!licenseIdExists){
                            //console.log(currentLicenseId)
                        }
                    })

                }
            })
        }

         */
        //force refresh on agentDisplayName and machineName cols because this forces those cells to call their valueFormatter and cellRenderers again, which will get any updated values and
        // they are async calls
        //gridApi.refreshCells({columns: ["agentDisplayName", "machineName", "zenGroupDisplayName"], suppressFlash: true, force: true})
    }
    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
        setIsLoading(false)
        setShowBulkMoveLicenses(false)
        setGroupToMoveFrom()
        setGroupToMoveTo()
        setLicenseType()
        setShowReleaseLicensesConfirmation(false)
        setZenGroupNamesWithPermission(new Set())
        setZenGroupNamesWithoutPermission(new Set())
        setZenGroupIdsWithoutPermission(new Set())
    }

     */
}

 let saveFilterChanges = true //used for if user clicks link to come to licenses page and we auto filter grid to show that specific license, in that case we don't want to save
                                // the filters because that would mess with their previous filters they still may want.
class Grid extends Component {
    rowData = []
    agentNamesListSessionStorage = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        // params.api.getFilterInstance("zenGroupDisplayName");
        // params.api.getFilterInstance("agentDisplayName");
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateLicensesGridColumnStateReactive)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.abortController.abort()
    }

    populateGrid = (rowData) => {
        if(!this.agentNamesListSessionStorage){
            this.agentNamesListSessionStorage = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
        }
        this.checkForAgentNameInSessionStorage(rowData)
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    checkForAgentNameInSessionStorage = (rowData) => {
        if(rowData.agentId && this.agentNamesListSessionStorage){
            let agentFound = null
            for(let i = 0; i < this.agentNamesListSessionStorage.length; i++){
                if(this.agentNamesListSessionStorage[i].agentId === rowData.agentId){
                    agentFound = this.agentNamesListSessionStorage[i]
                }
            }
            if(agentFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                //agentDisplayName
                if(agentFound.agentDisplayName){
                    rowData.agentDisplayName = agentFound.agentDisplayName
                }
                else{
                    rowData.agentDisplayName = " "
                }
                //machineName
                if(agentFound.machineName){
                    rowData.machineName = agentFound.machineName
                }
                else{
                    rowData.machineName = " "
                }
            }
        }
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["licenseId"] = objectBody["id"]
        objectBody["licenseName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            //gridApi.getRowNode throws an error if the grid was destroyed (user goes to another page), so add in the destroyCalled check
            if(!this.gridApi.destroyCalled){
                let rowNode = this.gridApi.getRowNode(objectBody["licenseId"])
                if(rowNode && rowNode.data){
                    let rowNodeData = rowNode.data
                    //check if objectBody from change stream has agentId, if it does then see if licenseIds changed with the update that came in, if not then don't update objectBody with a value
                    if(rowNodeData.agentId && rowNodeData.agentId === objectBody.agentId){
                        if(rowNodeData.agentDisplayName === rowNodeData.agentId){
                            //if agentDisplayName is the same as agentId (set in agentNameGridHelper value getter), don't set the agentDisplayName  or else the cell value will be stuck as the agentId
                            /*console.log("agentDisplayName was the same as agentId")
                            console.log(rowNodeData)*/
                        }
                        else{
                            objectBody["agentDisplayName"] = rowNodeData.agentDisplayName
                            objectBody["machineName"] = rowNodeData.machineName
                        }
                    }
                }
                else{
                    //else license row now was not found in grid yet, need to prep the agent name column before applying transaction
                    this.checkForAgentNameInSessionStorage(objectBody)
                }
                standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
            }
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.licenseId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }

        if(this.props.licenseLocation && this.props.licenseLocation.state && this.props.licenseLocation.state.licenseDisplayNameClicked){
            let locationFilterModel = {"licenseName": {filterType: "text", type: "equals", filter: this.props.licenseLocation.state.licenseDisplayNameClicked}}
            //we should have the agentDisplayNameClicked passed to us, but double-checking it is present
            if(this.props.licenseLocation.state.agentDisplayNameClicked) {
                locationFilterModel["agentDisplayName"] = {
                    filterType: "text",
                    type: "equals",
                    filter: this.props.licenseLocation.state.agentDisplayNameClicked
                }
            }

            //we don't want to save filter changes for the user if we are coming from a page where they clicked the license link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove this state or else when the user refreshes the page or clicks the back tab then the forward tab, they will keep seeing this licenseLocation filter present.
            // Although if they have the use saved filters toggled, this licenseLocation filter will be updated for their saved grid filter
            //window.history.replaceState(this.props.licenseLocation.state, '')
        }
        else{
            saveFilterChanges = true
            onGridReadyHelper(params, "licensesGridFilterState");
        }

        let agentNamesList = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
        if(!agentNamesList){
            //if this is null then setInterval to check for it in session
            const interval = setInterval(() => {
                agentNamesList = JSON.parse(decryptAndGetSessionVariable("agentNamesList"))
                if(agentNamesList){
                    params.api && params.api.onSortChanged()
                    params.api && params.api.onFilterChanged()
                    clearInterval(interval)
                }
            }, 2000);
            this.interval = interval
        }
        //await getLicensesReactiveSSE(this.populateGrid)
        await loadDataWithSSEAndStartChangeStreamListener("/sse/licenseListReactive", "/sse/listenToLicenseEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //params.api.sizeColumnsToFit()
    };

    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="licenseGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={licensePageCellEditingStopped}
                        valueCache={true}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'licensesGridFilterState', updateLicensesGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector, valueGetter, keyCreator},
                                i
                            ) => (

                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0 width:full"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    valueFormatter={valueFormatter}
                                    //onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={editableOptions}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}











