import React, {Component, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    changeSessionExpirationDurationReactive,
    changeSessionExpirationNameReactive,
    findBySessionExpirationIdListReactive,
    updateSessionDurationsGridColumnStateReactive,
    updateSessionDurationsGridFilterModelReactive,
    updateSessionDurationsGridUseColumnStateReactive,
    updateSessionDurationsGridUseFilterStateReactive
} from "../api/sessionExpirationApi";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {ClickToShowButtonsExpandingLeft} from "../../components/clickToShowButtons";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {ToggleButton, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let keysToRefresh = ["lastEditedDatetime", "lastEditedUsername", "sessionExpirationDisplayName", "duration"]
const durationOptions = [
    {
        value: "ONE_HOUR",
        label: "One Hour",
    },
    {
        value: "ONE_DAY",
        label: "One Day",
    },
    {
        value: "ONE_WEEK",
        label: "One Week",
    },
    {
        value: "ONE_MONTH",
        label: "One Month",
    },
    {
        value: "NEVER",
        label: "Never",
    },
];
/*
const typeOptions = [
    {
        value: "INTERACTIVE",
        label: "Interactive",
    },
    {
        value: "INTEGRATION",
        label: "Integration",
    },
];

 */
let gridColumnStateSessionVariableName = "sessionDurationsGridColumnState"
export default function SessionExpiration() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("sessionDurationsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true, true, true),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},
        { field: "sessionExpirationDisplayName", name: "Name", width: 450,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Session Expiration's Name", "sessionExpirationDisplayName")
            }

        },
        { field: "duration", name: "Duration", width: 225,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['One Hour', 'One Day', 'One Week', 'One Month', 'Never'],
                suppressSorting: true,
            },
            sortable: true,
            keyCreator: (params) => {
                return returnFormattedDurationValue(params.node.data.duration)
            },
            valueGetter: function(params){
                return returnFormattedDurationValue(params.node.data.duration)
            },
            cellEditorType: "agSelectCellEditor",
            editable: true,
            editableOptions: durationOptions.map(
                ({ value, label}) => label
            ),
        },
        { field: "type", name: "Type", width: 200,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Interactive', 'Integration'],
                suppressSorting: true,
            },
            sortable: true,
            keyCreator: function(params){
                switch(params.node.data.type) {
                    case "INTERACTIVE":
                        return "Interactive"
                    case "INTEGRATION":
                        return "Integration"
                    default:
                        return "";
                }
            },
            valueGetter: function(params){
                switch(params.node.data.type) {
                    case "INTERACTIVE":
                        return "Interactive"
                    case "INTEGRATION":
                        return "Integration"
                    default:
                        return "";
                }
            },
        },
        { field: "lastEditedDatetime", name: "Last Edited", width: 350,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "lastEditedUsername", name: "Last Editor Username", width: 350,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
    ])

    const _ = require('lodash');

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Session Timeout Rules</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 mt-8 ml-10 mr-10">
                    {privatePageHeaderHelper("Session Timeout Rules")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false} sseDataPullActive={sseDataPullActive}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={true}
                                          excelExportFunction = {excelExport}
                            />
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );

    function returnFormattedDurationValue(duration){
        switch(duration) {
            case "ONE_HOUR":
                return "One Hour"
            case "ONE_DAY":
                return "One Day"
            case "ONE_WEEK":
                return "One Week"
            case "ONE_MONTH":
                return "One Month"
            case "NEVER":
                return "Never"
            default:
                return duration;
        }
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'sessionDurationsGridFilterState', updateSessionDurationsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSessionDurationsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }

    async function resetGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "sessionExpirationId", "sessionExpirationId", findBySessionExpirationIdListReactive, keysToRefresh)
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "sessionDurationsGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSessionDurationsGridColumnStateReactive)
    }
    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "sessionExpirationDisplayName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.sessionExpirationDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.sessionExpirationId){
                    NotificationManager.error("Error updating name")
                    event.data.sessionExpirationDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                changeSessionExpirationNameReactive(event.newValue.trim(), event.data.sessionExpirationId).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("sessionExpirationDisplayName", event.newValue.trim());
                    /*updateObjectInSessionStorage("sessionExpirationsGridList", "sessionExpirationId",
                        event.data.sessionExpirationId, "sessionExpirationDisplayName", event.newValue.trim())*/
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.sessionExpirationDisplayName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                //TODO: add in code to remove the userSetFriendlyName in api first, then make the call to it below
                event.data.sessionExpirationDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "duration"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.duration = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.sessionExpirationId){
                    NotificationManager.error("Error updating duration")
                    event.data.duration = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                let durationValueToSend = durationOptions.find(({value, label}) => label === event.newValue)

                changeSessionExpirationDurationReactive(durationValueToSend.value, event.data.sessionExpirationId).then(function(response){
                    NotificationManager.success("Successfully updated the duration")
                    //event.node.setDataValue("duration", durationValueToSend.value);
                    /*updateObjectInSessionStorage("sessionExpirationsGridList", "sessionExpirationId",
                        event.data.sessionExpirationId, "duration", event.newValue.trim())*/
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating duration")
                    }
                    event.data.duration = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.duration = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }

    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["sessionExpirationId"] = objectBody["id"]
        objectBody["sessionExpirationDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.sessionExpirationId
    }

    getColumnKeys = (params) => {
        let columnIdsToIncludeInExport = [];
        params.getAllColumns().forEach(function (column) {
            if(!column.getColDef().suppressColumnsToolPanel){
                columnIdsToIncludeInExport.push(column.colId);
            }
        });

        return columnIdsToIncludeInExport
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (gridReadyParams) => {
        gridReadyParams.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            gridReadyParams.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }

        /*let sessionExpirationsGridList = JSON.parse(decryptAndGetSessionVariable("sessionExpirationsGridList"))
        if(sessionExpirationsGridList){
            //found sessionExpirationsGridList in session
            this.gridApi.setRowData(sessionExpirationsGridList)
        }
        else{
            const data = await getSessionExpirationsReactive()
            this.gridApi.setRowData(data)
        }*/


        onGridReadyHelper(gridReadyParams, "sessionDurationsGridFilterState");

        await loadDataWithSSEAndStartChangeStreamListener("/sse/getSessionExpirationsReactive", "/sse/listenToSessionExpirationEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'sessionDurationsGridFilterState', updateSessionDurationsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector, valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    valueFormatter={valueFormatter}
                                    //onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    enableCellChangeFlash={true}
                                    width={width}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
