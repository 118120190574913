export function base64DecodedValueFormatter(params){
    if(params.value){
        try{
            return atob(params.value) //decode value
        }
        catch(error){
            return null
        }
    }
    return null
}
