import React, {Component, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import Dropdown from "react-dropdown";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide
} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {handleGroupColumnChangeNameOnly, SilentIncidentCellEditingStopped} from "../../utils/gridCellEditing";
import {findZenGroupById, getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    changeSilentIncidentNameReactive,
    deleteSilentIncidentReactive,
    findBySilentIncidentIdListReactive,
    updateSilentIncidentsGridColumnStateReactive,
    updateSilentIncidentsGridFilterModelReactive,
    updateSilentIncidentsGridUseColumnStateReactive,
    updateSilentIncidentsGridUseFilterStateReactive,
    userCreateSilentIncidentReactive
} from "../api/silentResponsesApi";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
/*import {
    cellRenderingFrameWorkForResponsesWithEnabledAndDisabled,
    responsesFilterParameterRenderingEnabledAndDisabled, valueFormatterForResponsesWithEnabledAndDisabled
} from "../../utils/incidentNameGridHelper";

 */
let gridColumnStateSessionVariableName = "silentIncidentsGridColumnState"

export default function SilentResponses() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroup, setZenGroup] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [programArgumentsToggled, setProgramArgumentsToggled] = useState(true);
    const [gridApi, setGridApi] = useState();
    const [enableButtons, setEnableButtons] = useState(false);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("silentIncidentsGridFilterState"));
    const [createSilentIncidentDefaultPathValue, setCreateSilentIncidentDefaultPathValue] = useState();
    const [createSilentIncidentDefaultArgsValue, setCreateSilentIncidentDefaultArgsValue] = useState();
    const silentIncidentLocation = useLocation();
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetterForClientSide(true, true, true),
        { field: "silentIncidentDisplayName", name: "Name", width : 330,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Silent Response's Name", "silentIncidentDisplayName")
            }
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true},
        { field: "path", name: "Path", width: 600,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "programArguments", name: "Program Arguments", width: 700,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "created", name: "Created", width: 300,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "userCreatedItUsername", name: "Created By", width: 600,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
    ])

    useEffect(() => {
        if(silentIncidentLocation && silentIncidentLocation.state){
            window.scroll({behavior: "smooth", top: 0, left: 0})
            setModalIsOpen(true)
            if(silentIncidentLocation.state.zenGroupId){
                setZenGroup(findZenGroupById(silentIncidentLocation.state.zenGroupId).id)
            }
            if(silentIncidentLocation.state.path){
                setCreateSilentIncidentDefaultPathValue(silentIncidentLocation.state.path)
            }
            if(silentIncidentLocation.state.programArguments){
                setProgramArgumentsToggled(true)
                setCreateSilentIncidentDefaultArgsValue(silentIncidentLocation.state.programArguments)
            }
            else{
                setProgramArgumentsToggled(false)
            }
            //so on refresh or clicking back then forward tabs this modal does not keep popping up
            window.history.replaceState(silentIncidentLocation.state, '')
        }
    }, [silentIncidentLocation]);
    const createSilentIncident = (data) => {
        if (data.path && data.path.trim().length > 0 && zenGroup) {
            setIsLoading(true);
            let programArguments = null;
            if(programArgumentsToggled){
                if(!data.programArguments){
                    NotificationManager.error("Please make sure all fields are filled out and try again.");
                    setIsLoading(false)
                    return;
                }
                else{
                    if(data.programArguments.trim().length < 1){
                        NotificationManager.error("Please make sure all fields are filled out and try again.");
                        setIsLoading(false)
                        return;
                    }
                    else{
                        programArguments = data.programArguments.trim()
                    }
                }
            }
            let friendlyName = null
            if(data.friendlyName && data.friendlyName.trim().length > 0){
                friendlyName = data.friendlyName.trim()
            }
            userCreateSilentIncidentReactive(data.path.trim(), zenGroup, programArguments, friendlyName).then(response => {
                setIsLoading(false);
                if(response.silentIncidentExistedAlready === true && response.silentIncidentEnabled === false){
                    NotificationManager.info("A Silent Response existed already for this path and program argument combination within the same group");
                }
                else if(response.silentIncidentExistedAlready === true && response.silentIncidentEnabled === true){
                    NotificationManager.success("Successfully enabled the silent response that already existed for this path and program argument combination within the same group!");
                    setModalIsOpen(false);
                    setCreateSilentIncidentDefaultPathValue()
                    setCreateSilentIncidentDefaultArgsValue()
                    reset({
                        path: "",
                        friendlyName: ""
                    })
                    setZenGroup(null)
                    resetGrid();
                }
                else{
                    NotificationManager.success("Silent Response created successfully!");
                    setModalIsOpen(false);
                    setCreateSilentIncidentDefaultPathValue()
                    setCreateSilentIncidentDefaultArgsValue()
                    reset({
                        path: "",
                        friendlyName: ""
                    })
                    setZenGroup(null)
                    resetGrid();
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };

    const removeSilentIncident = () => {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            //loop through all the nodes
            setIsLoading(true)
            for (let i in gridApi.getSelectedNodes()){
                let silentIncidentToDelete = gridApi.getSelectedNodes()[i] //only allowing single selection on this page, so grab first element
                if(silentIncidentToDelete && silentIncidentToDelete.data && silentIncidentToDelete.data.id){
                    deleteSilentIncidentReactive(silentIncidentToDelete.data.id).then(response => {
                        gridApi.applyTransactionAsync({
                            remove: [silentIncidentToDelete.data]
                        })
                    }).catch(function(error){
                       // if(error.message){
                       //     NotificationManager.error(error.message);
                       // }
                       // else{
                       //     NotificationManager.error("Unexpected error, please try again.");
                       // }
                       // setIsLoading(false)
                    })
                }
            }

            // successfully removed all
            setIsLoading(false)
            NotificationManager.success("Silent Response(s) successfully removed");
            resetGrid();
        }
    };

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Silent Response Rules</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create Silent Response Modal*/}
            <Modal contentLabel="Create Silent Response"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       reset({
                           path: "",
                           friendlyName: ""
                       })
                       setZenGroup(null)
                       setModalIsOpen(false)
                       setProgramArgumentsToggled(true)
                       setCreateSilentIncidentDefaultPathValue()
                       setCreateSilentIncidentDefaultArgsValue()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createSilentIncident)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Create Silent Response</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({
                                        path: "",
                                        friendlyName: ""
                                    })
                                    setZenGroup(null)
                                    setModalIsOpen(false)
                                    setProgramArgumentsToggled(true)
                                    setCreateSilentIncidentDefaultPathValue()
                                    setCreateSilentIncidentDefaultArgsValue()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <label>Select which group to add to</label>
                            <Dropdown
                                options={getZenGroupDropDownContents()}
                                value={zenGroup}
                                onChange={({ value }) => {
                                    setZenGroup(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Only use a single \ for your paths, and you can use '.*' as a wildcard. e.g. C:\Program Files\.*\word.exe</label>
                            <br />
                            <input
                                name="path"
                                type="text"
                                defaultValue={createSilentIncidentDefaultPathValue}
                                required
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("path")}
                                placeholder={"Path"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={programArgumentsToggled}
                                        name="toggleSilentIncidentProgramArguments"
                                        onChange={e => setProgramArgumentsToggled(e.target.checked)}
                                    />
                                } label={programArgumentsToggled ? "Limit to Program + Arguments" : "Do Not Limit to Program + Arguments"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${programArgumentsToggled ? "block": "hidden"} ml-1 mt-5`}>
                            <label>Program Arguments. You may use '.*' as a wildcard.</label>
                            <br />
                            <input
                                name="programArguments"
                                type="text"
                                defaultValue={createSilentIncidentDefaultArgsValue}
                                required={programArgumentsToggled}
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("programArguments")}
                                placeholder={"Program Arguments"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for the silent response</label>
                            <br />
                            <input
                                name="friendlyName"
                                type="text"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("friendlyName")}
                                placeholder={"Optional"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                            >
                            Create
                            </Button>
                        </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>

            {/*Confirm Disable the silent response*/}
            {/*<ConfirmationModal
                    text={`WARNING: You are about to disable ${(clickedIconSilentIncidentData && clickedIconSilentIncidentData.silentIncidentDisplayName) ? `the "${clickedIconSilentIncidentData.silentIncidentDisplayName}"` : "this "} silent response, which
                    will trigger an update to not apply this silent response for any new or existing incidents within this silent response's group`}
                    onConfirm={() => {
                        if(clickedIconSilentIncidentData && clickedIconSilentIncidentData.id){
                            setIsLoading(true)
                            disableSilentIncident(clickedIconSilentIncidentData.id).then(response => {
                                NotificationManager.success("Successfully disabled!")
                                //updateObjectInSessionStorage("silentIncidentsGridList", "id",clickedIconSilentIncidentData.id, "disabled", true)
                                setShowConfirmDisableModal(false)
                                setClickedIconSilentIncidentData()
                                setIsLoading(false)
                                //refreshGrid().then()
                            }).catch(function(error){
                                if(error.message){
                                    NotificationManager.error(error.message);
                                }
                                else{
                                    NotificationManager.error("Unexpected error disabling this silent response.");
                                }
                                setIsLoading(false)
                            })
                        }
                    }}
                    onClose={() => {
                        setShowConfirmDisableModal(false)
                        setClickedIconSilentIncidentData()
                    }}
                    opened={showConfirmDisableModal}
                />
                */}
            {/*Confirm Enable the silent response*/}
            {/*<ConfirmationModal
                    text={`WARNING: You are about to enable ${(clickedIconSilentIncidentData && clickedIconSilentIncidentData.silentIncidentDisplayName) ? `the "${clickedIconSilentIncidentData.silentIncidentDisplayName}"` : "this "} silent response, which
                    will trigger an update to apply this silent response to any new incidents and matching existing incidents within this silent response's group`}
                    onConfirm={() => {
                        if(clickedIconSilentIncidentData && clickedIconSilentIncidentData.id){
                            setIsLoading(true)
                            enableSilentIncident(clickedIconSilentIncidentData.id).then(response => {
                                NotificationManager.success("Successfully enabled!")
                                //updateObjectInSessionStorage("silentIncidentsGridList", "id",clickedIconSilentIncidentData.id, "disabled", false)
                                setShowConfirmEnableModal(false)
                                setClickedIconSilentIncidentData()
                                setIsLoading(false)
                                //refreshGrid().then()
                            }).catch(function(error){
                                if(error.message){
                                    NotificationManager.error(error.message);
                                }
                                else{
                                    NotificationManager.error("Unexpected error enabling this silent response.");
                                }
                                setIsLoading(false)
                            })
                        }
                    }}
                    onClose={() => {
                        setShowConfirmEnableModal(false)
                        setClickedIconSilentIncidentData()
                    }}
                    opened={showConfirmEnableModal}
                />
                */}
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col mr-10 ml-10 mt-8 flex-nowrap gap-y-2">
                    {privatePageHeaderHelper("Silent Response Rules")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create a Silent Response"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setModalIsOpen(!modalIsOpen)
                                            }}/>
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Silent Responses"}
                                            tooltipPlacement={"top"} disabled={!enableButtons}
                                            onClick={() => {
                                                removeSilentIncident()
                                            }}
                                        >
                                        </MuiIconButtonWithTooltipAndBox>
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false} refreshGridFunction = {refreshGrid} sseDataPullActive={sseDataPullActive}
                                          showExcelExportIcon={true} excelExportFunction={excelExport}/>
                        </div>

                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'silentIncidentsGridFilterState', updateSilentIncidentsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSilentIncidentsGridUseColumnStateReactive);
    }


    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }
    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "id", "id", findBySilentIncidentIdListReactive, ["silentIncidentDisplayName"])
    }
    function resetGrid(){
        //this function is used when a new notification was created or a notification was deleted since refreshGrid() doesn't cover this yet as of July 30th
        //gridApi.onFilterChanged() //preserves filter and sort models
        setProgramArgumentsToggled(true)
    }
    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "silentResponsesGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        //params.api.getFilterInstance("zenGroupDisplayName");
        params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSilentIncidentsGridColumnStateReactive)
    }

    cellEditingStoppedFunctionCaller = (event) => {
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else{
            SilentIncidentCellEditingStopped(event, changeSilentIncidentNameReactive,"silentIncidentDisplayName")
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }

        /*let silentIncidentsGridList = JSON.parse(decryptAndGetSessionVariable("silentIncidentsGridList"))
        if(silentIncidentsGridList){
            //found silentIncidentsGridList in session
            this.gridApi.setRowData(silentIncidentsGridList)
        }
        else{
            const data = await getSilentIncidentsReactive()
            this.gridApi.setRowData(data)
        }
*/

        onGridReadyHelper(gridReadyParams, "silentIncidentsGridFilterState");
        await loadDataWithSSEAndStartChangeStreamListener("/sse/getSilentIncidentsReactive", "/sse/listenToSilentIncidentEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'silentIncidentsGridFilterState', updateSilentIncidentsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate,
                                    cellRenderer, cellRendererSelector, cellEditorType,
                                    hide, sortable, minWidth, width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector,
                                    valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={editableOptions}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    enableCellChangeFlash={true}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>

            </div>
        );
    }
}
