import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {Button, ThemeProvider} from "@mui/material";
import {roundButtonTheme} from "../../utils/muiStyling";
import {decryptAndGetSessionVariable} from "../../utils/encryptDecryptHelper";

export default function RestIntegration() {
    const [isLoading, setIsLoading] = useState(false);
    const appClientId = process.env.REACT_APP_APP_CLIENT_ID;
    const oauthTokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;
    const refreshToken = decryptAndGetSessionVariable("refresh_token")

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Rest Integration</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 ml-10 mr-10 mt-8">
                    {privatePageHeaderHelper("Rest Integration")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-col flex-wrap gap-y-5">
                        <label>Download our REST API Documentation for Integration:</label>
                        <div>Client Id: {appClientId}</div>
                        <div>AWS Token endpoint: {oauthTokenEndpoint}</div>
                        <p> <a className={"cursor-pointer ml-2 hover:underline"} href ="https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html" rel="noreferrer" target="_blank">See more about the token endpoint here</a></p>
                        <div className={"break-all"}>Refresh Token: {refreshToken}</div>
                        <div>
                            <a
                                href="../documents/restDocumentation.html"
                                download
                            >
                                <ThemeProvider  theme={roundButtonTheme}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}>
                                        Download
                                    </Button>
                                </ThemeProvider>
                            </a>
                        </div>
                    </div>
                    <hr className="mb-4 bg-black h-0.5" />
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );
}

