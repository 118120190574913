import React, {useEffect, useState} from 'react';
import SortedObjectArray from "sorted-object-array";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";

import {Link, NavLink} from "react-router-dom";
import {
    storeGroupListForCCUsersOnDealsPageInSessionSSE,
    userGroupListWithChannelInfoReactive
} from "../pages/api/groupsApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PartnerStripeModal} from "./partnerStripeCheckoutSetupModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {NotificationContainer} from "react-notifications";
import {StripeLoggedInModalSetup} from "./StripeLoggedInModalSetup";
import {getDistributionGroups, useZenGroupSessionStorage} from "../utils/zenGroupSessionStorageManager";
import {Auth} from "aws-amplify";
import {checkRefreshToken, handleLogout} from "../utils/axiosInstance";
import ReactGA from "react-ga";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../utils/encryptDecryptHelper";
import {getAndStoreAllRoleNamesOfCurrentUserInSession} from "../utils/roleNamesOfCurrentUserHelper";
import {Helmet} from "react-helmet";
import SessionTimer from "./sessionTimer";
import {
    getAndStoreAllClientSideDataInSession,
    getAndStoreDistinctAgentVersionsInSession
} from "../utils/clientSideDataSessionStorageHelper";
import {generateMonthlyReport} from "../pages/api/notificationsApi";
import {InformationModal} from "./informationOnlyModal";
import {licensesListWithAgentIdSessionStorageChangeStreamListener} from "../utils/licenseNameGridHelper";
import {getParentGroupsWithTrainingData} from "../pages/api/settingsApi";
import {Button, ThemeProvider, Tooltip} from "@mui/material";
import {roundButtonTheme} from "../utils/muiStyling";
import Modal from "react-modal";
import {checkUserLastPasswordResetDateReactive} from "../pages/api/loginApi";
export default function SidebarMenu({setIsLoading}){
    const [accordionOpen,setAccordionOpen] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(getSidebarSessionState("isMenuOpen"));
    const [operationsOpen, setOperationsOpen] = useState(getSidebarSessionState("operationsOpen"));
    const [partnersOpen, setPartnersOpen] = useState(getSidebarSessionState("partnersOpen"));
    const [administrationOpen, setAdministrationOpen] = useState(getSidebarSessionState("administrationOpen"));
    const [threatHuntingOpen, setThreatHuntingOpen] = useState(getSidebarSessionState("threatHuntingOpen"));
    const [isPartner, setIsPartner] = useState(JSON.parse(decryptAndGetSessionVariable("isPartner")));
    const [partnerPurchaseModalOpen, setPartnerPurchaseModalOpen] = useState(false);
    const [distributionGroups, setDistributionGroups] = useState([]);
    const [showStripeLoggedInModal, setShowStripeLoggedInModal] = useState(false);
    const [showPurchaseLicenses, setShowPurchaseLicenses] = useState(false);
    const [showCollectipede, setShowCollectipede] = useState(false);
    const [generateReportInProgress, setGenerateReportInProgress] = useState(false);
    const [showGenerateReportTextModal, setShowGenerateReportTextModal] = useState(false);
    const [usernameEndsWithCyberCrucible, setUsernameEndsWithCyberCrucible] = useState(JSON.parse(decryptAndGetSessionVariable("usernameEndsWithCyberCrucible")));
    const [showResetPasswordNeededModal, setShowResetPasswordNeededModal] = useState(false);

    //  transferred from header
    const [loggedUser, setLoggedUser] = useState();
    // eslint-disable-next-line no-unused-vars
    const [showLoggedInOptions, setShowLoggedInOptions] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const trackingCode = process.env.REACT_APP_GA_ID

    const handleAccordionOpen = (value) => {
        setAccordionOpen(accordionOpen ===  value ? 0 : value);
    };

    const handleAccordionChange = (setHookFunction, oldValue, sessionVariableName) => {
        let newValue = !oldValue
        setSidebarSessionState(sessionVariableName, newValue)
        setHookFunction(newValue)
    }

    const logOut = async () => {
        await Auth.signOut();
        handleLogout()
    };

    useEffect(() =>{
        if(generateReportInProgress){
            //console.debug("Setting generateReportInProgress animation text");
            let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"

            generateReportObject = <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                            title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
                <div  className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 disabled:opacity-25 flex shrink-0 flex-nowrap`}
            >Generate Activity Report</div>
            </Tooltip>
        }else{
            let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"
            //console.debug("Setting generateReport ***NOT*** in progress text");
            generateReportObject = <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                            title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
                                    <div className={`cursor-pointer text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 flex shrink-0 flex-nowrap`}
                                        onClick={() => {
                                            //to avoid them double clicking before endpoint returns and receiving multiple emails, disable click until response comes back.
                                            //TODO: always set to disabled after first click?
                                            setGenerateReportInProgress(true)
                                            generateMonthlyReport().then(r => {
                                                setShowGenerateReportTextModal(true)
                                                setGenerateReportInProgress(false)
                                            }).catch(error => {
                                                setGenerateReportInProgress(false)
                                            })
                                        }}>Generate Activity Report</div>
                                    </Tooltip>
        }

    },[generateReportInProgress])

    let partnerAccordionObject = ""
    if(isPartner){
        partnerAccordionObject = <Accordion id="partnersAccordion" open={partnersOpen}>
            <AccordionHeader className="flex shrink-0 p-0" onClick={() => handleAccordionChange(setPartnersOpen, partnersOpen, "partnersOpen")}>
                <div className="flex shrink-0">Partners</div>
            </AccordionHeader>
            <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                <NavLink className="tracking-wider text-dark-grey" to="/private/partnerRegisterDeal">
                    <div className="flex shrink-0">Register Deal</div>
                </NavLink>
                <NavLink className="tracking-wider text-dark-grey" to="/private/salesNotifications">
                    <div className="flex shrink-0">Sales Notifications</div>
                </NavLink>
                <div className="flex shrink-0 tracking-wider text-dark-grey cursor-pointer" onClick={async () => {
                    try{
                        let distributionGroupsList = getDistributionGroups()
                        if(distributionGroupsList && distributionGroupsList.length > 0){
                            setDistributionGroups(distributionGroupsList)
                            setPartnerPurchaseModalOpen(true)
                        }
                        else{
                            NotificationManager.error("You are not a member of any groups that are distribution groups, " +
                                "you are not eligible to continue to the purchase license form.")
                            setPartnerPurchaseModalOpen(false)
                            setDistributionGroups([])
                        }
                    }
                    catch(error){
                        NotificationManager.error("Error retrieving list of groups for the purchase license form.")
                        setPartnerPurchaseModalOpen(false)
                        setDistributionGroups([])
                    }
                }}
                >Purchase Agent Licenses</div>
            </AccordionBody>
        </Accordion>
    }

    let generateReportObject = ""
    let generateReportObjectString = "An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"

    if(generateReportInProgress){
        generateReportObject = <Tooltip slotProps={{tooltip: {sx: {maxWidth: 700}}}} arrow enterDelay={750} title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
            <div className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 disabled:opacity-25 flex shrink-0 flex-nowrap`}
             >Generate Activity Report</div>
        </Tooltip>
    }else{
        generateReportObject = <Tooltip slotProps={{tooltip: {sx: {maxWidth: 700}}}} arrow enterDelay={750} title={<div className={"text-sm"}>{generateReportObjectString}</div>} placement={"right"}>
            <div className={`cursor-pointer text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 flex shrink-0 flex-nowrap`}
             onClick={() => {
                 //to avoid them double clicking before endpoint returns and receiving multiple emails, disable click until response comes back.
                 //TODO: always set to disabled after first click?
                 setGenerateReportInProgress(true)
                 generateMonthlyReport().then(r => {
                     setShowGenerateReportTextModal(true)
                     setGenerateReportInProgress(false)
                 }).catch(error => {
                     setGenerateReportInProgress(false)
                 })
             }}>Generate Activity Report</div>
        </Tooltip>
    }
    /*
    <div title={"An executive summary report sent to your user's email address detailing information on your licenses, agents, incidents, and process behaviors"}
             className={`tracking-wider text-dark-grey cursor-pointer ${generateReportDisabled ? "pointer-events-none" : ""}`}
             onClick={() => {
                 //to avoid them double clicking before endpoint returns and receiving multiple emails, disable click until response comes back.
                 //TODO: always set to disabled after first click?
                 setGenerateReportInProgress(true)
                 generateMonthlyReport().then(r => {
                     setShowGenerateReportTextModal(true)
                     setGenerateReportInProgress(false)
                 }).catch(error => {
                     setGenerateReportInProgress(false)
                 })
             }}>Generate Activity Report</div>
     */

    let collectipedeObject = "";
    if(showCollectipede){
        collectipedeObject =
            <NavLink className={`tracking-wider text-dark-grey`} to="/private/collectipede">
                <div className="flex shrink-0">Collectipede</div>
            </NavLink>
    }
    let agentReleaseEmailSubmissionsObject = ""
    if(usernameEndsWithCyberCrucible){
        agentReleaseEmailSubmissionsObject =
            <NavLink className={`tracking-wider text-dark-grey`} to="/private/agentReleaseEmailSubmissions">
                <div className="flex shrink-0">Agent Release Emails</div>
            </NavLink>
    }

    let masterAccordionObject = "";
    if(isMenuOpen){
        masterAccordionObject = <div className={`flex flex-col w-full basis-full`}>
            {partnerAccordionObject}
            <Accordion id="operationsAccordion" open={operationsOpen}>
                <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setOperationsOpen, operationsOpen, "operationsOpen")}>
                    <div className="flex shrink-0">Operations</div>
                </AccordionHeader>
                <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                    <NavLink className="tracking-wider text-dark-grey" to="/private/agents">
                        <div className="flex shrink-0">Agents</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/whitelists">
                        <div className="flex shrink-0">Whitelists</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/silentResponses">
                        <div className="flex shrink-0">Silent Response Rules</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/incidents">
                        <div className="flex shrink-0">Extortion Responses</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/tasks">
                        <div className="flex shrink-0">Agent Tasks</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/decryptors">
                        <div className="flex shrink-0">Decryptors</div>
                    </NavLink>
                    {collectipedeObject}
                </AccordionBody>
            </Accordion>
            <Accordion id="threatHuntingAccordion" open={threatHuntingOpen}>
                <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setThreatHuntingOpen, threatHuntingOpen, "threatHuntingOpen")}>
                    <div className="flex shrink-0">Threat Hunting</div>
                </AccordionHeader>
                <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                    <NavLink className="tracking-wider text-dark-grey" to="/private/processInjections">
                        Process Injections
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/processCreations">
                        Process Creations
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/browserDataIncidents">
                        Identity Access
                    </NavLink>
                </AccordionBody>
            </Accordion>
            <Accordion id="administrationAccordion" open={administrationOpen}>
                <AccordionHeader className="flex flex-row flw-nowrap shrink-0 p-0" onClick={() => handleAccordionChange(setAdministrationOpen, administrationOpen, "administrationOpen")}>
                    <div className="flex shrink-0">Administration</div>
                </AccordionHeader>
                <AccordionBody className="flex flex-col ml-5 gap-y-2 p-0">
                    <NavLink className="tracking-wider text-dark-grey" to="/private/groups">
                        <div className="flex shrink-0">Groups</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/roles">
                        <div className="flex shrink-0">Roles</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/sessionDurations">
                        <div className="flex shrink-0">Session Timeout Rules</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/notifications">
                        <div className="flex shrink-0">Security Notifications</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/trainingLicenses">
                        <div className="flex shrink-0">Training Licenses</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/settings">
                        <div className="flex shrink-0">Account Settings</div>
                    </NavLink>
                    <NavLink className="tracking-wider text-dark-grey" to="/private/restIntegration">
                        <div className="flex shrink-0">Rest Integration</div>
                    </NavLink>
                    {agentReleaseEmailSubmissionsObject}
                </AccordionBody>
            </Accordion>

            {/*<NavLink className="flex shrink-0 text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" to="/private/settings">
                <div className="flex shrink-0">Account Settings</div>
            </NavLink>*/}
            <NavLink className="text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" to="/private/licenses">
                <div className="flex shrink-0">Agent Licenses</div>
            </NavLink>
            <div className={`text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0 ${(showPurchaseLicenses && !isPartner) ? 'block' : 'hidden'}`} onClick={() => {
                setShowStripeLoggedInModal(true)
            }}>Purchase Agent Licenses</div>
            {generateReportObject}
            <NavLink className="text-blue-gray-700 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors p-0" onClick={logOut} to="">
                <div className="flex shrink-0">Logout</div>
            </NavLink>

            <NotificationContainer />
        </div>}

    //}

    //initialize Google Analytics and set pageview, and checkRefreshToken()
    useEffect(() => {
        //ReactGA initialized in App.js
        try{
            ReactGA.initialize(trackingCode);
            ReactGA.set({ page: window.location.pathname});
            ReactGA.pageview(window.location.pathname);
        }
        catch(error){
            console.log(error)
        }
        checkRefreshToken()
    }, [trackingCode])

    //user logged in check
    useEffect(() => {
        async function auth() {
            let path = window.location.pathname
            let privatePage = false;
            if(/^\/private\//.test(path)){ //check if we are on a private page
                privatePage = true
            }
            setShowLoggedInOptions(!privatePage)
            try {
                let refreshToken = decryptAndGetSessionVariable("refresh_token")
                if(refreshToken !== null){
                    //user logged in
                    loadJiraScript() //loads jira script if it's not loaded already on page
                    //console.log("refresh token found, user logged in")
                    if(!loggedUser)
                        setLoggedUser(true)
                    if(/^\/login/.test(path)){ //check if we are on the login page
                        window.location.href='./private/agents'; //take user to agents page
                    }
                }
                else{
                    //user not logged in
                    unloadJiraScript() //hide jira widget if it does exist when a user is not logged in
                    //console.log("refresh token not found, user not logged in")
                    setLoggedUser(false)
                    //TODO: is there a better way to redirect users to another page?
                    if(privatePage){
                        window.location.href='.././'; //take user back to home page
                    }

                }
            } catch (error) {
                if(privatePage){
                    window.location.href='.././'; //take user back to home page
                }
            }
        }
        auth()
        //!collapsed && window.addEventListener("scroll", handleScroll);
    }, [loggedUser])

    //isPartner check
    useEffect(() => {
        if(loggedUser){
            Auth.currentSession().then(response => {
                let accessToken = response.getAccessToken().getJwtToken()
                checkUserLastPasswordResetDateReactive(false, accessToken).then(response => {
                    if(response && response.passwordResetNeeded === true){
                        //user needs to reset password
                        setShowResetPasswordNeededModal(true)
                        try{
                            //log user out since they need to reset their password and login again after
                            sessionStorage.clear();
                            Auth.signOut().then().catch(e => {})
                        } catch (e) {}
                    }
                }).catch(e => {})
            }).catch(error => {
                console.error(error)
            })
            licensesListWithAgentIdSessionStorageChangeStreamListener()
            //getAndStoreLicensesListWithAgentIdReactive()
            storeGroupListForCCUsersOnDealsPageInSessionSSE()
            getAndStoreAllRoleNamesOfCurrentUserInSession()
            userGroupListWithChannelInfoReactive().then(zenGroupsFromRest => {
                //console.log("starting to process getGroupsWithChannelPartnerInformationForUser");
                let zenGroupSortedObjectArray = new SortedObjectArray("friendlyName");
                let zenGroupBareObjects = []
                let userName = sessionStorage.getItem("username")
                let isMemberOfChannelPartnerCustomerGroup = false
                let isMemberOfChannelPartnerGroup = false
                zenGroupsFromRest.forEach(groupFromRest => {
                    //username and the member fields here should be the same for each result
                    //userName = groupFromRest.username
                    isMemberOfChannelPartnerCustomerGroup = groupFromRest.isMemberOfChannelPartnerCustomerGroup
                    isMemberOfChannelPartnerGroup = groupFromRest.isMemberOfChannelPartnerGroup
                    zenGroupSortedObjectArray.insert({"friendlyName":groupFromRest.zenGroup.friendlyName,zenGroup:groupFromRest.zenGroup})
                    //zenGroups.push(group.zenGroup)
                })
                //console.log(zenGroupSortedObjectArray);
                //we can potentially really amke this more efficient:
                //https://www.npmjs.com/package/sorted-object-array
                //instead, we'll prune it
                let changeDetected = false;


                zenGroupSortedObjectArray.array.forEach(sortedObject => {

                    zenGroupBareObjects.push(sortedObject.zenGroup)
                });
                //console.log(zenGroupBareObjects);
                setZenGroupSessionStorage(zenGroupBareObjects)

                let isPartner = false;
                for (let idx in zenGroupBareObjects) {
                    let group = zenGroupBareObjects[idx]
                    if (group.channelPartner) {
                        isPartner = true;
                        break;
                    }
                }
                let endsWithCyberCrucible = false
                if(userName){
                    //stored in session already from login
                    //sessionStorage.setItem("username",result.username)
                    if(userName.endsWith("@cybercrucible.com")){
                        endsWithCyberCrucible = true
                        isPartner = true
                    }
                }
                if(userName === "mark.weideman@cybercrucible.com"){
                    setShowCollectipede(true)
                }
                else{
                    setShowCollectipede(false)

                    let path = window.location.pathname
                    //check if user is on this collectipede page and is not allowed to be
                    if(/^\/private\/collectipede$/.test(path)){ //check if we are on collectipede page
                        //take them to agents page
                        window.location.href='./agents';
                    }
                }
                let isPartnerSessionValue = JSON.parse(decryptAndGetSessionVariable("isPartner"))
                if(isPartnerSessionValue !== isPartner){
                    encryptAndStoreSessionVariable("isPartner",JSON.stringify(isPartner))
                    setIsPartner(isPartner);
                }

                let endsWithCyberCrucibleSessionVariable = JSON.parse(decryptAndGetSessionVariable("usernameEndsWithCyberCrucible"))
                if(endsWithCyberCrucibleSessionVariable !== endsWithCyberCrucible){
                    encryptAndStoreSessionVariable("usernameEndsWithCyberCrucible",JSON.stringify(endsWithCyberCrucible))
                    setUsernameEndsWithCyberCrucible(endsWithCyberCrucible);
                }

                if(isMemberOfChannelPartnerCustomerGroup){
                    //we need to check if they are also a part of a channelPartner group
                    if(isMemberOfChannelPartnerGroup){
                        //good to show
                        setShowPurchaseLicenses(true)
                    }
                    else{
                        //don't allow tabs to show
                        setShowPurchaseLicenses(false)
                    }
                }
                else{
                    //good to show
                    setShowPurchaseLicenses(true)
                }
                if(!isPartner){
                    let path = window.location.pathname
                    let onDealsPage = false;
                    if(/^\/private\/partnerRegisterDeal$/.test(path)){ //check if we are on partnerRegisterDeal page
                        onDealsPage = true
                    }
                    if(onDealsPage){
                        window.location.href='./agents'; //take user to agents page since they should not be on the deals page
                    }
                }
                //if username does not end with @cybercrucible.com, check if they are on the agentReleaseEmailSubmissions page. If so, redirect them to agents page
                if(!endsWithCyberCrucible){
                    let path = window.location.pathname
                    let onAgentReleaseEmailsPage = false;
                    if(/^\/private\/agentReleaseEmailSubmissions$/.test(path)){ //check if we are on agentReleaseEmailSubmissions page
                        onAgentReleaseEmailsPage = true
                    }
                    if(onAgentReleaseEmailsPage){
                        window.location.href='./agents'; //take user to agents page since they should not be on the deals page
                    }
                }
            }).catch(function (error) {
                //console.log("getGroupsWithChannelPartnerInformationForUser error received: ");
                //console.log(error);
                setIsPartner(false);
                setShowPurchaseLicenses(false)
                setZenGroupSessionStorage(null)
                encryptAndStoreSessionVariable("isPartner","false")
                encryptAndStoreSessionVariable("usernameEndsWithCyberCrucible", "false")

            })
            getAndStoreAllClientSideDataInSession() //update all client side grid data
            getAndStoreDistinctAgentVersionsInSession()
            getParentGroupsWithTrainingData().then(zenGroupIds => {
                if(zenGroupIds === undefined || zenGroupIds === null){
                    encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
                }
                else{
                    encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify(zenGroupIds))
                }
            }).catch(function (error) {
                encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
            })
        }
        else{

        }
    }, [loggedUser,setZenGroupSessionStorage]);

    function loadJiraScript(){
        //jira script for widget
        try{
            let jiraWidgetScript = document.createElement('script');
            jiraWidgetScript.type = 'text/javascript';
            jiraWidgetScript.id = "jiraHelpDeskWidget";
            jiraWidgetScript.setAttribute('data-jsd-embedded', null);
            jiraWidgetScript.setAttribute('data-key', '93b9f178-ca0f-47e9-bb55-4a681006da70');
            jiraWidgetScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
            jiraWidgetScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
            if(jiraWidgetScript.readyState) { // old IE support
                jiraWidgetScript.onreadystatechange = function() {
                    if ( jiraWidgetScript.readyState === 'loaded' || jiraWidgetScript.readyState === 'complete' ) {
                        jiraWidgetScript.onreadystatechange = null;
                        jiraHelpdesk();
                    }
                };
            } else { //modern browsers
                jiraWidgetScript.onload = function() {
                    jiraHelpdesk();
                };
            }
            if (!document.getElementById('jiraHelpDeskWidget')) {
                document.getElementsByTagName('head')[0].appendChild(jiraWidgetScript);
                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                    bubbles: true,
                    cancelable: true
                }));
            }

            let jiraHelpdesk =(function() {
                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                    bubbles: true,
                    cancelable: true
                }));
            });
        }
        catch(error){
            //console.log(error)
        }
    }

    function unloadJiraScript(){
        let iframe = document.querySelector("#jsd-widget");
        //hide widget if it exists
        if(iframe){
            iframe.style.display = 'none';
        }
    }

    //  original sideBar useEffect
    // useEffect(() => {
    //     getGroupsWithChannelPartnerInformationForUser().then(result => {
    //         const zenGroups = result.zenGroups
    //         let isPartner = false;
    //         for (let idx in zenGroups) {
    //             let group = zenGroups[idx]
    //             if (group.channelPartner) {
    //                 isPartner = true;
    //                 break;
    //             }
    //         }
    //         if(result.username){
    //             if(result.username.endsWith("@cybercrucible.com")){
    //                 isPartner = true
    //             }
    //         }
    //         if(result.username === "mark.weideman@cybercrucible.com"){
    //             setShowCollectipede(true)
    //         }
    //         else{
    //             setShowCollectipede(false)
    //         }
    //         setIsPartner(isPartner);
    //         let isMemberOfChannelPartnerCustomerGroup = result.isMemberOfChannelPartnerCustomerGroup
    //         let isMemberOfChannelPartnerGroup = result.isMemberOfChannelPartnerGroup
    //         if(isMemberOfChannelPartnerCustomerGroup){
    //             //we need to check if they are also a part of a channelPartner group
    //             if(isMemberOfChannelPartnerGroup){
    //                 //good to show
    //                 setShowPurchaseLicenses(true)
    //             }
    //             else{
    //                 //don't allow tabs to show
    //                 setShowPurchaseLicenses(false)
    //             }
    //         }
    //         else{
    //             //good to show
    //             setShowPurchaseLicenses(true)
    //         }
    //
    //     }).catch(function (error) {
    //         setIsPartner(false);
    //         setShowPurchaseLicenses(false)
    //     })
    // }, []);
        //<div className={`ml-2 xl:block lg:block md:hidden sm:hidden xs:hidden `}>
    return (
        <div className={`ml-2 lg:flex md:hidden sm:hidden xs:hidden lg:shrink-0`}>
            <div id="sideBarComponent" className={`ml-2 flex flex-col mt-8`}>
                <Helmet>
                    <script>{`var DID=260241;`}</script>
                    <script type="text/javascript">{`
                            linkedin_partner_id = "1395570";
                            window.linkedin_data_partner_ids = window.linkedin_data_partner_ids || [];
                            window.linkedin_data_partner_ids.push(linkedin_partner_id);
                    `}</script>
                    <noscript>{`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1395570&fmt=gif" />`}
                    </noscript>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-YD4ZG9H383"></script>
                    <script type="text/javascript">{`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-YD4ZG9H383');
                    `}</script>
                </Helmet>
                <SessionTimer loggedUser={loggedUser}/>
                <PartnerStripeModal
                    onClose={() => {
                        setPartnerPurchaseModalOpen(false)
                    }}
                    setIsLoading={setIsLoading}
                    opened={partnerPurchaseModalOpen}
                    newDealData={null}
                    zenGroups={distributionGroups}
                />
                <StripeLoggedInModalSetup
                    onClose={() => {
                        setShowStripeLoggedInModal(false)
                    }}
                    setIsLoading={setIsLoading}
                    opened={showStripeLoggedInModal}
                    setIsOpened={setShowStripeLoggedInModal}
                />
                <Modal contentLabel={"User password reset needed modal"} isOpen={showResetPasswordNeededModal}
                       onRequestClose={async () => {
                           setShowResetPasswordNeededModal(false)
                       }}
                       shouldCloseOnOverlayClick={false}
                       shouldCloseOnEsc={false}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-col p-5 pl-8 pr-8">
                        <div className="flex-1 mb-6 text-xl">
                            <small>
                                The password for your account needs reset. A verification code to reset your password was sent to your email address.
                                You need to immediately login within the same session after resetting your password to complete the process.
                            </small>
                        </div>
                        <div className="flex flex-row flex-wrap">
                            <Link to={{pathname:"../resetPassword"}} state={{showFullForm: true}} className="">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"} color={"primary"}>
                                        Reset Password
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>
                    </div>
                </Modal>
                {/*<CCLogo className=""/>*/}
                <div className={`flex flex-row justify-end mb-3 `}>
                    <FontAwesomeIcon
                        onClick={() => {
                            //console.log("Got a hamburger click");
                            //setOperationsOpen(true);
                            //setPartnersOpen(true);
                            //setThreatHuntingOpen(true);
                            //setRolesOpen(true);
                            handleAccordionChange(setIsMenuOpen, isMenuOpen, "isMenuOpen")
                        }}
                        size="1x"
                        className="cursor-pointer text-2xl text-black"
                        icon="fa-light fa-bars"
                        pull="right"
                    />
                </div>
                <InformationModal
                    opened={showGenerateReportTextModal}
                    onClose={() => {
                        setShowGenerateReportTextModal(false)
                    }}
                    text="Please allow a few minutes for the report to generate and be sent to your user's email address"
                    contentLabel={"Activity Report Modal"}
                />
                {masterAccordionObject}
            </div>
        </div>
    )

}

function getSidebarSessionState(variable){
    try{
        let value = JSON.parse(decryptAndGetSessionVariable(variable))
        if(value === null || value === undefined){
            return false
        }
        else{
            return value
        }
    } catch (e){}
    return false //return false by default
}

function setSidebarSessionState(variable, value){
    try{
        encryptAndStoreSessionVariable(variable, JSON.stringify(value))
    } catch (e) {}
}
