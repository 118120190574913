export function advancedCommandGenerate(zenGroupId, refreshToken) {
    //Moved to API now.
  return (
      "@if (@X)==(@Y) @end /*\n" +
      "@echo off\n" +
      "\n" +
      ":: Automatically \"punched in\" OAUTH:\n" +
      "set \"GROUP_ID=" + zenGroupId + "\"\n" +
      "set \"REFRESH_TOKEN=" + refreshToken + "\"\n" +
      "\n" +
      "::::::::::::::::::::::::::::::::::::::::::::\n" +
      ":: Automatically check & get admin rights V2\n" +
      "::::::::::::::::::::::::::::::::::::::::::::\n" +
      "@echo off\n" +
      "CLS\n" +
      ":init\n" +
      "setlocal DisableDelayedExpansion\n" +
      "set \"batchPath=%CD%\\%~0\"\n" +
      "for %%k in (%0) do set batchName=%%~nk\n" +
      "set \"vbsGetPrivileges=%temp%OEgetPriv_%batchName%.vbs\"\n" +
      "setlocal EnableDelayedExpansion\n" +
      ":checkPrivileges\n" +
      "NET FILE 1>NUL 2>NUL\n" +
      "if '%errorlevel%' == '0' ( goto gotPrivileges ) else ( goto getPrivileges )\n" +
      ":getPrivileges\n" +
      "if '%1'=='ELEV' (echo ELEV & shift /1 & goto gotPrivileges)\n" +
      "echo.\n" +
      "echo =======================================\n" +
      "echo Invoking UAC for Privilege Escalation\n" +
      "echo =======================================\n" +
      "echo Set UAC = CreateObject^(\"Shell.Application\"^) > \"%vbsGetPrivileges%\"\n" +
      "echo args = \"ELEV \" >> \"%vbsGetPrivileges%\"\n" +
      "echo For Each strArg in WScript.Arguments >> \"%vbsGetPrivileges%\"\n" +
      "echo args = args ^& strArg ^& \" \"  >> \"%vbsGetPrivileges%\"\n" +
      "echo Next >> \"%vbsGetPrivileges%\"\n" +
      "echo UAC.ShellExecute \"!batchPath!\", args, \"\", \"runas\", 1 >> \"%vbsGetPrivileges%\"\n" +
      "\"%SystemRoot%\\System32\\WScript.exe\" \"%vbsGetPrivileges%\" %*\n" +
      "exit /B\n" +
      ":gotPrivileges\n" +
      "CLS\n" +
      "echo.\n" +
      "echo ======================\n" +
      "echo Running Admin shell\n" +
      "echo ======================\n" +
      "\n" +
      "setlocal & pushd .\n" +
      "cd /d %~dp0\n" +
      "if '%1'=='ELEV' (del \"%vbsGetPrivileges%\" 1>nul 2>nul  &  shift /1)\n" +
      "set INSTALLERPATH=C:\\\n" +
      "set RESTARTDELAYINSECONDS=300\n" +
      "\n" +
      ":: DOWNLOAD FROM AWS\n" +
      "for /f \"tokens=2,3,4 delims=/ \" %%f in ('date /t') do set currentDate=%%h%%g%%f\n" +
      "for /f \"tokens=1,2,3,4,5,6 delims=/:.\" %%a in (\"%TIME%\") do (\n" +
      "	REM set HH24=%%a\n" +
      "	REM set MI=%%b\n" +
      "	REM set SS=%%c\n" +
      "	REM set FF=%%d\n" +
      "	set currentTime=%%a%%b_%%c_%%d\n" +
      ")\n" +
      "set NAME=RR-installer-x64_%currentDate%_%currentTime%.exe\n" +
      "set FULLPATH=%INSTALLERPATH%%NAME%\n" +
      "bitsadmin /transfer RansomwareRewindInstallerDownloadJob /download /priority normal https://ransomware-rewind-installers.s3-us-west-2.amazonaws.com/RR-installer-x64-3.5.2.exe \"%FULLPATH%\"\n" +
      "\n" +
      ":: If bits download fails, use JSC\n" +
      "if not exist %FULLPATH% (\n" +
      "   echo File was not downloaded successfully with bitsadmin! Retrying with .NET JSC util\n" +
      "   echo.\n" +
      "   for /f \"tokens=* delims=\" %%v in ('dir /b /s /a:-d  /o:-n \"%SystemRoot%\\Microsoft.NET\\Framework\\*jsc.exe\"') do (\n" +
      "      set \"jsc=%%v\"\n" +
      "   )\n" +
      "   \n" +
      "   if not exist \"%~n0.exe\" (\n" +
      "      !jsc! /nologo /out:\"%~n0.exe\" \"%~dpsfnx0\"\n" +
      "   )\n" +
      "   \n" +
      "   %~n0.exe %FULLPATH%\n" +
      "   del %~n0.exe\n" +
      "   \n" +
      "   if not exist %FULLPATH% (\n" +
      "      echo File still did not download correctly with JSC.\n" +
      "      echo File path: %FULLPATH%\n" +
      "      echo.\n" +
      "      echo Installation cannot continue after failed download. Error 2.\n" +
      "      PAUSE\n" +
      "      exit /b 2\n" +
      "   )\n" +
      ")\n" +
      "echo Installer file downloaded successfully.\n" +
      "\n" +
      ":: RUN THE INSTALLER\n" +
      "\"%FULLPATH%\" --eula-accept --group-id %GROUP_ID% --install-path \"C:\\Program Files\\CyberCrucible\" --auto-update 2 --refresh-token %REFRESH_TOKEN%\n" +
      "\n" +
      ":: **********************************\n" +
      ":: IF YOU WISH TO RESTART, AND DO NOT HAVE THE ABILITY TO SCHEDULE RESTARTS WITH YOUR DEPLOYMENT TOOL, RESTART HERE\n" +
      "\n" +
      ":: REMOVE 'REM' from the following line:\n" +
      "REM shutdown.exe /r /t 30\n" +
      "\n" +
      ":: **********************************\n" +
      "\n" +
      "endlocal\n" +
      "exit /b %errorlevel%\n" +
      "*/\n" +
      "\n" +
      "import System;\n" +
      "import System.Uri;\n" +
      "import System.Net.WebClient;\n" +
      "import System.Net.NetworkCredential;\n" +
      "import System.Net.WebProxy;\n" +
      "import System.Security.Cryptography.X509Certificates;\n" +
      "\n" +
      "var arguments:String[] = Environment.GetCommandLineArgs();\n" +
      "\n" +
      "var url=\"https://ransomware-rewind-installers.s3-us-west-2.amazonaws.com/RR-installer-x64-3.5.2.exe\";\n" +
      "var filePath=arguments[1];\n" +
      "\n" +
      "function downloadFile(){\n" +
      "	var client:System.Net.WebClient = new System.Net.WebClient();\n" +
      "	\n" +
      "	Console.WriteLine(\"Downloading file: \" + filePath);\n" +
      "	client.DownloadFile(url, filePath);\n" +
      "}\n" +
      "\n" +
      "downloadFile();\n" +
      "\n"
  );
}


export function shutdownServiceScriptGenerate(code1, code2, code3) {
  return (
    "@echo off\r\n" +
    "net.exe session 1>NUL 2>NUL || (Echo This script requires elevated rights. & Exit /b 1)\r\n" +
    `SC CONTROL "Ransomware Rewind Assistant" ${code1}\r\n` +
    `SC CONTROL "Ransomware Rewind Assistant" ${code2}\r\n` +
    `SC CONTROL "Ransomware Rewind Assistant" ${code3}\r\n`
  );
}

export const downloadShutdownScriptFile = (file, name) => {
  const data = new Blob([file], { type: "application/octet-stream" });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + "-" + today.getMinutes();
  let dateTime = date+'T'+time;
  tempLink.setAttribute("download", `${name}-${dateTime}.bat`);
  tempLink.click();
};
