import NotificationManager from "react-notifications/lib/NotificationManager";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function standardExcelExportHelper(gridApi, sseDataPullActive, fileNamePrefixBeforeDate, colIdsNotToIncludeInExport=[]){
    if(!gridApi){
        console.debug("No gridApi to export to excel")
        return
    }
    if(sseDataPullActive){
        NotificationManager.info(`Please wait until all data is loaded into the grid before exporting`);
        console.debug("Cannot export to excel until all data is loaded into the grid")
        return
    }
    //else good to go
    let columnIdsToIncludeInExport = [];
    gridApi.getColumnDefs().forEach(function (column) {
        //colIdsNotToIncludeInExport can be used to not include certain columns in the export, up to the caller to decide what to pass
        if(!column.suppressColumnsToolPanel && column.hide !== true && !colIdsNotToIncludeInExport.includes(column.colId)){
            columnIdsToIncludeInExport.push(column.colId);
        }
    });

    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + "-" + today.getMinutes();
    let dateTime = date+'T'+time;
    let fileName = `${fileNamePrefixBeforeDate}-${dateTime}`

    //Any custom checks/formatting for grids should be added as an else if below
    gridApi.exportDataAsExcel({
        processCellCallback: function(cell) {
            if (cell.column.getColDef().filter === "agDateColumnFilter") {
                let date = new Date(cell.value).toUTCString()
                if (date === 'Invalid Date' || cell.value === null){
                    return cell.value
                }
                return date
            }
            else if (cell.column.getColDef().field === "managedUpdateSettings"){ //for agents grid only
                if(cell.node.data.autoUpdate){
                    return "On"
                }
                else{
                    return "Off"
                }
            }
            else if (cell.column.getColDef().field === "specialStatusMessage"){ //for agents grid only
                switch(cell.node.data.specialStatusMessage) {
                    case "none":
                        return "None"
                    case "uninstallInProgress":
                        return "Uninstall In Progress"
                    case "uninstalled":
                        return "Uninstalled"
                    case "scanForEncryptedFiles":
                        return "Scan For Encrypted Files"
                    case "scanInProgress":
                        return "Scan In Progress"
                    case "noEncryptionFound":
                        return "No Encryption Found"
                    case "unknownEncryptionFound":
                        return "Unknown Encryption Found"
                    case "decrypting":
                        return "Decrypting"
                    case "decrypted":
                        return "Decrypted"
                    case "updateStaged": case "updateStagingStarted": case "updateStagedSuccessful":
                        //use updateStagedText so we can get the number that is staged included in export
                        if(cell.node.data.updateStagedText === undefined){
                            return ""
                        }
                        return cell.node.data.updateStagedText
                    default:
                        return cell.node.data.specialStatusMessage
                }
            }
            else if (cell.column.colId === "approximateTotalCashValue"){ //for deals grid only
                return formatter.format(cell.value)
            }
            else if (cell.value === "(Double Click to Assign)"){ //for groups grid only
                return ""
            }
            return cell.value
        },
        columnKeys: columnIdsToIncludeInExport,
        fileName: fileName
    })
}

export function standardExcelExportObjectInContextMenu(excelExport){
    return {
        name: 'Excel Export',
        tooltip: "This button will export the grid data to Excel and will only contain what is shown on the grid. Clear all filters and column states to export all data.",
        action: function() {
            excelExport && excelExport()
        },
    }
}