import React, {Component, useEffect, useState} from "react";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Modal from "react-modal";
import Dropdown from "react-dropdown";
import {useForm} from "react-hook-form";
import {sendAgentReleaseEmailToAllUsersReactive} from "../api/agentReleaseEmailSubmissionApi";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {decryptAndGetSessionVariable} from "../../utils/encryptDecryptHelper";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {buttonTheme} from "../../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import {ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import SendIcon from '@mui/icons-material/Send';

let doNotUpdateKeywordForApprovedVersion = "Do Not Update"

export default function AgentReleaseEmailSubmissions() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState([]);
    const [agentVersion, setAgentVersion] = useState();
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "userCreatedItUsername", width: 330, name: "Submitted By",
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "dateOfSubmission", name: "Submitted",  width: 330,
            filter: 'agDateColumnFilter',
            filterParamsInHeader: dateFilterParametersInHeader,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "agentVersion", name: "Agent Version", width: 200,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "agentReleaseNotesUrl", width: 700, name: "Agent Release Notes Url",
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: false,
            },
            sortable: true
        },
    ])
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            try{
                //We don't want to show "Do Not Update" since that doesn't apply to this page, so if sessionAgentVersionsList is null then just query for the versions, else remove "Do Not Update" from list
                let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                if(sessionAgentVersionsList === null){
                    distinctAgentVersionsReactive().then(data => {
                        //store list in descending order by version number
                        data?.sort()?.reverse()
                        setDistinctAgentVersionsList(data)
                    }).catch(error => {})
                }
                else{
                    sessionAgentVersionsList = sessionAgentVersionsList.filter(version => version !== doNotUpdateKeywordForApprovedVersion)
                    setDistinctAgentVersionsList(sessionAgentVersionsList)
                }
            } catch (e) {}
        })()
        return () => controller?.abort();
    }, [])

    const sendUsersEmails = (data) => {
        if (agentVersion && agentVersion.trim().length > 0 && data.agentReleaseNotesUrl && data.agentReleaseNotesUrl.trim().length > 0) {
            setIsLoading(true);
            sendAgentReleaseEmailToAllUsersReactive(data.agentReleaseNotesUrl.trim(), agentVersion.trim()).then(response => {
                setIsLoading(false);
                NotificationManager.success("Successfully triggered emails to send");
                setModalIsOpen(false);
                reset({
                    agentReleaseNotesUrl: ""
                })
                setAgentVersion()
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };
    var _ = require('lodash');

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Release Emails</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Send New Agent Release Email To Users"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       reset({
                           agentReleaseNotesUrl: ""
                       })
                       setAgentVersion()
                       setModalIsOpen(false)
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(sendUsersEmails)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Send New Agent Release Email To All Users</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({
                                        agentReleaseNotesUrl: ""
                                    })
                                    setAgentVersion()
                                    setModalIsOpen(false)
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className={`ml-1 mt-5`}>
                            <label>Agent Version</label>
                            <Dropdown
                                options={distinctAgentVersionsList}
                                value={agentVersion}
                                onChange={({ value }) => {
                                    setAgentVersion(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Agent Release Notes URL to Include in Email</label>
                            <br />
                            <input
                                name="agentReleaseNotesUrl"
                                type="text"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("agentReleaseNotesUrl")}
                                required={true}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                            <Button type={"submit"} color={"primary"} variant={"contained"}>
                                Send Email To All Users
                            </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 mt-8 ml-10 mr-10">
                    {privatePageHeaderHelper("Agent Release Emails")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <ClickToShowButtonsExpandingRight
                            buttonsText={"Automation"}
                            tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                            buttonsDiv={
                                <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                    <MuiIconButtonWithTooltipAndBox
                                        icon={<SendIcon className={"cursor-pointer"}/>}
                                        tooltipTitle={"Send Email to all Users for New Release"}
                                        tooltipPlacement={"top"}
                                        onClick={() => {
                                            setModalIsOpen(true)
                                        }}/>
                                </div>
                            }
                        />
                        <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false} />
                    </div>
                    <div className="mb-4" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            setGridApi={setGridApi}
                            setGridColumnApi={setGridColumnApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                        />
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        await loadDataWithSSEAndStartChangeStreamListener("/sse/agentReleaseEmailSubmissionListReactive", "/sse/listenToAgentReleaseEmailSubmissionEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, null, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, valueFormatter, suppressColumnsToolPanel, cellEditorSelector, valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    valueFormatter={valueFormatter}
                                    cellEditor={cellEditorType}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    enableCellChangeFlash={true}
                                    width={width}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                    floatingFilter={true}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
