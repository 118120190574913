import {FormControlLabel, FormGroup, Switch, ThemeProvider} from "@mui/material";
import React from "react";
import {switchTheme} from "../utils/muiStyling";


export const GridColumnFilterStateSaving = ({useFilterStateSettingToggled, setUseFilterStateSettingToggled, toggleUpdateUseFilterState, useColumnStateSettingToggled,setUseColumnStateSettingToggled,toggleUpdateUseColumnState}) => {

    return (
        <div className={`flex flex-row items-center`}>
            <ThemeProvider theme = {switchTheme}>
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={useFilterStateSettingToggled}
                            name="useFilterStateSettingToggled"
                            color={"primary"}
                            onChange={toggleSetting => {
                                setUseFilterStateSettingToggled(toggleSetting.target.checked)
                                toggleUpdateUseFilterState(toggleSetting.target.checked)
                            }}
                        />
                    } label={useFilterStateSettingToggled ? "Grid Filter Saving Enabled" : "Grid Filter Saving Disabled"}/>
                    <FormControlLabel control={
                        <Switch
                            checked={useColumnStateSettingToggled}
                            name="useColumnStateSettingToggled"
                            color={"primary"}
                            onChange={toggleSetting => {
                                setUseColumnStateSettingToggled(toggleSetting.target.checked)
                                toggleUpdateUseColumnState(toggleSetting.target.checked)
                            }}
                        />
                    } label={useColumnStateSettingToggled ? "Column State Saving Enabled" : "Column State Saving Disabled"}/>
                </FormGroup>
            </ThemeProvider>
        </div>
    );
};
