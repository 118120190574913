import {getIncidentFriendlyNamesReactive} from "../pages/api/incidentsApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React from "react";
import {getEditIconComponent} from "./customCellEditor";
import {MuiIconButtonWithTooltip} from "../components/muiComponents";

export const defaultIncidentNameColumnInitWithOptions = (sortable=true, getValuesAsync=false, whitelistIconOnClickMethod = null,
                                                         initializeDetailsModalForIconClick=null, turnSilentSettingOnMethod=null, turnSilentSettingOffMethod=null,
                                                         objectWording="Response") => {
    //objectWording is used for Response on incidents page vs Access on browser data incidents page
    //if we are coming from the incidents page, we already have the incidentDisplayName included in query. No need to make any async calls for the incident name
    return {
        field: "incidentDisplayName",
        name: `${objectWording} Name`,
        sortable: sortable,
        width: 415,
        editable: true,
        cellEditorType: "customNameCellEditor",
        filter: 'agTextColumnFilter',
        filterParamsInHeader: {
            suppressSorting: true,
            buttons: ["reset", "apply"],
            
            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
            suppressAndOrCondition: true,
        },
        valueFormatter: getValuesAsync ? incidentNameValueFormatter : null,
        //cellRenderer: getValuesAsync ? incidentNameCellRendererFrameworkAsync : incidentNameCellRendererFrameworkForIncidentsGrid
        cellRenderer: (params) => {
            if(getValuesAsync){
                return incidentNameCellRendererFrameworkAsync(params, objectWording)
            }
            else{
                return incidentNameCellRendererFrameworkForIncidentsGrid(params, whitelistIconOnClickMethod, initializeDetailsModalForIconClick, turnSilentSettingOnMethod, turnSilentSettingOffMethod, objectWording)
            }
        }
    }
}
/*
export function incidentNameEditableOptions(params){
    if (params && params.node && params.node.data && params.node.data.incidentId) {
        return {values: getIncidentFriendlyNames()}
    } else {
        //console.log("no param, data, or zenGroupId.");
    }
    return {values: []}
}
*/
export function incidentNameValueFormatter(params){
    //console.log(params.node.data)
    if(params.node.data.incidentId){
        let incidentIdList = []
        incidentIdList.push(params.node.data.incidentId);
        getIncidentFriendlyNamesReactive(incidentIdList).then(function(incidentNameObjects){
            if(incidentNameObjects && incidentNameObjects.length>0) {
                if (incidentNameObjects[0].name) {
                    if(incidentNameObjects[0].name === params.node.data.incidentDisplayName){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("incidentDisplayName", incidentNameObjects[0].name)
                    }
                }
                else{
                    //in this case, stop showing spinning div since we have returned from api call
                    params.node.setDataValue("incidentDisplayName", " ")
                }
            }
            else{
                //in this case, stop showing spinning div since we have returned from api call
                params.node.setDataValue("incidentDisplayName", " ")
            }
        }).catch(function(error){
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.setDataValue("incidentDisplayName", " ")
        })
        if(params.node.data.incidentDisplayName){
            return params.node.data.incidentDisplayName
        }
        console.log("valueFormatter: "+params.node.data.incidentId);
        return params.node.data.incidentId
    }
    //return ""
}

//intended for incident cells where you are not on the incident's page
export function incidentNameCellRendererFrameworkAsync(params, objectWording){
    let spinnerDiv = ""
    let incidentLinkDiv = ""

    if(params.data.incidentId === params.valueFormatted){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="IncidentNameLoading"
        />
    }else{
        console.log("renderer params.data.incidentId: "+params.data.incidentId);
        console.log("renderer params.valueFormatted: "+params.valueFormatted);
        spinnerDiv = ""
    }

    //machineNameForLocationLink is a made up field in the above agentNameValueFormatters
    if(params.data.incidentId && params.node.data.incidentDisplayName){
        incidentLinkDiv =
            <Link to={{pathname:"/private/incidents"}} state={{incidentDisplayNameClicked: params.node.data.incidentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                incidentIdClicked : params.node.data.incidentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                <div className={"mb-1"}>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-user-gear"
                                size="xs"
                            />
                        }
                        tooltipTitle={`Click to Manage This ${objectWording}`}
                        tooltipPlacement={"bottom-start"}
                    />
                </div>
            </Link>
    }

    return(
        <div id ="fortooltip" className={"flex flex-nowrap items-center gap-x-1"}>
            {spinnerDiv}
            {incidentLinkDiv}
            {params.valueFormatted}
        </div>
    )
}

//intended for the incidentDisplayName renderer on the incident's grid only! No async calls are needed for the incidentDisplayName in this case so no spinner/link needed
export function incidentNameCellRendererFrameworkForIncidentsGrid(params, whitelistIconOnClickMethod, detailsIconOnClickMethod,
                                                                  turnSilentSettingOnMethod, turnSilentSettingOffMethod, objectWording){
    let incidentIconDiv = ""
    let silentIconDiv = ""
    let detailsIconDiv = ""
    let editNameIconDiv = ""
    if(params.data.incidentId && params.node.data.incidentDisplayName){
        if(params.data.whitelistAppliedId){
            incidentIconDiv =
                <MuiIconButtonWithTooltip
                    icon={
                        <FontAwesomeIcon
                            className={"self-center object-contain"}
                            icon="fa-duotone fa-shield-slash"
                            size="xs"
                        />
                    }
                    tooltipTitle={`A response on the machine was not performed due to this whitelist rule, and no notification ` +
                        `was sent for this ${objectWording.toLowerCase()}`}
                    tooltipPlacement={"bottom-start"}
                />
        }
        if(!params.data.whitelistAppliedId){
            incidentIconDiv =
                <MuiIconButtonWithTooltip
                    onClick={(event) => {
                        /*
                            To line up with the program args editing that was in place before on incidents page, don't think we need to do this though
                         */
                        let data = params.node.data
                        let programArgumentsString = ""
                        if(data) {
                            programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
                        }

                        data.programArgumentsEditedString = programArgumentsString
                        whitelistIconOnClickMethod && whitelistIconOnClickMethod(params.node.data)
                    }}
                    icon={
                        <FontAwesomeIcon
                            className={"self-center object-contain"}
                            icon="fa-duotone fa-shield-halved"
                            size="xs"
                        />
                    }
                    tooltipTitle={`Click to Whitelist This ${objectWording}`}
                    tooltipPlacement={"bottom-start"}
                />
        }
        if(params.data.silent && params.node.data.whitelistAppliedId == null){
            //silent is true
            silentIconDiv =
                <MuiIconButtonWithTooltip
                    onClick={(event) => {
                        turnSilentSettingOffMethod && turnSilentSettingOffMethod(params.node.data)
                    }}
                    icon={
                        <FontAwesomeIcon
                            className={"self-center object-contain"}
                            icon="fa-duotone fa-volume-slash"
                            size="xs"
                        />
                    }
                    tooltipTitle={`Silent Rule(s) were applied to not send a notification for this ${objectWording.toLowerCase()}, ` +
                        `but a response on the machine was performed. Click to turn the silent setting off`}
                    tooltipPlacement={"bottom-start"}
                />
        }
        else if(!params.data.silent && params.node.data.whitelistAppliedId == null) {
            //silent is false
            silentIconDiv =
                <MuiIconButtonWithTooltip
                    onClick={(event) => {
                        turnSilentSettingOnMethod && turnSilentSettingOnMethod(params.node.data)
                    }}
                    icon={
                        <FontAwesomeIcon
                            className={"self-center object-contain"}
                            icon="fa-duotone fa-volume"
                            size="xs"
                        />
                    }
                    tooltipTitle={`This ${objectWording.toLowerCase()} is not silent, click to turn the silent setting on`}
                    tooltipPlacement={"bottom-start"}
                />
        }
        detailsIconDiv =
            <MuiIconButtonWithTooltip
                onClick={(event) => {
                    detailsIconOnClickMethod && detailsIconOnClickMethod(params.node.data)
                }}
                icon={
                    <FontAwesomeIcon
                        className={"self-center object-contain"}
                        icon="fa-duotone fa-binoculars"
                        size="xs"
                    />
                }
                tooltipTitle={`Click to See Additional Details About This ${objectWording}`}
                tooltipPlacement={"bottom-start"}
            />
        editNameIconDiv = getEditIconComponent(params, `Click to Edit this ${objectWording}'s Name`, "incidentDisplayName")
    }

    return(
        <div id ="fortooltip" className={"flex flex-nowrap items-center gap-x-0"}>
            {detailsIconDiv}
            {incidentIconDiv}
            {silentIconDiv}
            {editNameIconDiv}
            {params.value}
        </div>
    )
}
//intended for the incidentDisplayName renderer on the incident's grid only! No async calls are needed for the incidentDisplayName in this case so no spinner/link needed
export function responsesFilterParameterRenderingEnabledAndDisabled(){
    return {
        buttons: ["reset", "apply", "cancel"],
        cellRenderer: (params) => {
            if(params.value!=="(Select All)"){
                if(params.value === "true"){
                    return "Enabled"
                }else{
                    return "Disabled"
                }
            }
            else{
                return params.value;
            }
        },
        values: ['true', 'false'],
        suppressSorting: true,
    }
}
export function valueFormatterForResponsesWithEnabledAndDisabled(params){
    if(params.value === true){
        return "Silencing"
    }
    else{
        return "Not Silencing"
    }
}

/*
export function cellRenderingFrameWorkForResponsesWithEnabledAndDisabled(params,setIsLoading){
            let iconDiv = ""
    let iconType = faVolume;
            if(params.valueFormatted === "Silencing") {
                console.debug("Silencing active " + params.data.id + " and " + params.data.path);
                iconType = faVolume
            }else {
                iconType = faVolumeSlash
            }

                iconDiv =
                    <FontAwesomeIcon
                        className={'cursor-pointer self-center'}
                        icon={iconType}
                        size="2x"
                        title={"Response notifications are not silenced."}
                        onClick={(event) => {
                            //setClickedIconSilentIncidentData(params.node.data)
                            //setShowConfirmEnableModal(true)
                            enableSilentIncident(params.data.id).then(response => {
                                NotificationManager.success("Successfully enabled!")
                                //updateObjectInSessionStorage("silentIncidentsGridList", "id",clickedIconSilentIncidentData.id, "disabled", false)
                                //setShowConfirmEnableModal(false)
                                setClickedIconSilentIncidentData()
                                //setIsLoading(false)
                                //refreshGrid().then()
                            }).catch(function(error){
                                if(error.message){
                                    NotificationManager.error(error.message);
                                }
                                else{
                                    NotificationManager.error("Unexpected error enabling this silent response.");
                                }
                                setIsLoading(false)
                            })
                        }}
                    />
            }
            else {

                console.debug("Not silencing: "+params.data);

                iconDiv =
                    <FontAwesomeIcon
                        className={'cursor-pointer self-center'}
                        icon={iconType}
                        size="2x"
                        title={"Currently silencing response notifications.  Responses are silently occurring."}
                        onClick={(event) => {
                            //setClickedIconSilentIncidentData(params.node.data)
                            //setShowConfirmDisableModal(true)
                            disableSilentIncident(params.data.id).then(response => {
                                NotificationManager.success("Successfully disabled!")
                                //updateObjectInSessionStorage("silentIncidentsGridList", "id",clickedIconSilentIncidentData.id, "disabled", true)
                                //setShowConfirmDisableModal(false)
                                setClickedIconSilentIncidentData()
                                setIsLoading(false)
                                //refreshGrid().then()
                            }).catch(function(error){
                                if(error.message){
                                    NotificationManager.error(error.message);
                                }
                                else{
                                    NotificationManager.error("Unexpected error disabling this silent response.");
                                }
                                setIsLoading(false)
                            })
                        }}
                    />
            }
            return(
                <div className={"flex flex-nowrap flex-row items-center justify-start"}>
                    {iconDiv}
                </div>
            )

}

 */

//checks if program arguments starts with the path or path in quotes and removes this from start of program arguments.
export function removePathFromStartOfProgramArgumentsHelper(processPath, programArguments){
    let cellContents = "";
    if(programArguments && String(programArguments).length>0) {
        processPath = processPath.toLowerCase().replace(/\\\\/g, "\\");
        //if program args starts with path or starts with path surrounded in quotes, then strip the path from start of the program args
        if(programArguments.toLowerCase().startsWith(processPath)){
            cellContents = programArguments.substr(processPath.length);
        }else if(programArguments.toLowerCase().startsWith('"'+processPath+'"')){
            //console.log("Got a quotes match");
            cellContents = programArguments.substr(processPath.length+2);
        }else{
            //if no match is found then we should return the regular program arguments
            cellContents = programArguments
            //console.log("no match:  "+'"'+processPath+'"'+" vs "+params.node.data.programArguments);
        }
        if(cellContents.length===0 && programArguments.length>(processPath.length+2)){
            //console.log("Got a bug with "+processPath+" vs "+params.node.data.programArguments);
        }
    }
    return cellContents;
}