import React, {useEffect, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import Dropdown from "react-dropdown";
import SidebarMenu from "../../components/sideBarComponent";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import {
    changeUserTimeZoneReactive,
    resetTrainingGroupDataReactive,
    updateUserDemoGroupsVisibleReactive,
    updateUserTrainingGroupsVisibleReactive
} from "../api/settingsApi";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/encryptDecryptHelper";
import {
    getUserAccountExecutiveSummarySettingsReactive,
    updateUserAccountExecutiveSummaryFormatReactive,
    updateUserAccountMonthlyExecutiveSummaryDisabledSettingReactive,
    updateUserAccountWeeklyExecutiveSummaryDisabledSettingReactive
} from "../api/executiveSummaryNotificationsApi";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {getResetTrainingGroupDataDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {prepDataAfterSwitchingModes} from "../../utils/trainingAndDemoModeHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import {MuiCloseIconButton, MuiIconWithTooltip} from "../../components/muiComponents";

const REPORT_FORMAT = {
    HTML: "HTML",
    ZIP: "ZIP",
    TAR_GZ: "TAR_GZ"
    //PDF: "PDF" //PDF disabled until this is implemented
}

export default function Settings() {
    const [isLoading, setIsLoading] = useState(false);
    /*const [zenGroups, setZenGroups] = useState([]);
    const [defaultZenGroup, setDefaultZenGroup] = useState();*/
    const [user, setUser] = useState();
    const [sendWeeklyReports, setSendWeeklyReports] = useState(null);
    const [weeklyReportsToggleDisabled, setWeeklyReportsToggleDisabled] = useState(true);
    const [sendMonthlyReports, setSendMonthlyReports] = useState(null);
    const [monthlyReportsToggleDisabled, setMonthlyReportsToggleDisabled] = useState(true);
    const [reportFormat, setReportFormat] = useState(null);
    const [reportFormatSelectDisabled, setReportFormatSelectDisabled] = useState(true);
    //const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [showDemoModeDiv, setShowDemoModeDiv] = useState(false);
    const [trainingMode, setTrainingMode] = useState(JSON.parse(decryptAndGetSessionVariable("trainingGroupsVisible")));
    const [trainingModeToggleDisabled, setTrainingModeToggleDisabled] = useState(false);
    const [demoMode, setDemoMode] = useState(JSON.parse(decryptAndGetSessionVariable("demoGroupsVisible")));
    const [demoModeToggleDisabled, setDemoModeToggleDisabled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [groupIdsWithTrainingData, setGroupIdsWithTrainingData] = useState(JSON.parse(decryptAndGetSessionVariable("groupIdsWithTrainingData")))
    const [resetTrainingGroupModalOpen, setResetTrainingGroupModalOpen] = useState(false);
    const [selectedGroupToResetTrainingData, setSelectedGroupToResetTrainingData] = useState();


    useEffect(() => {
        async function populate() {
            try{
                let username = sessionStorage.getItem("username")
                setUser(username);
                if(username !== null && username.trim().endsWith("@cybercrucible.com")){
                    setShowDemoModeDiv(true)
                }
                setIsLoading(false);
                /*let defaultGroup = null
                zenGroupSessionStorage.forEach(group => {
                    if(group.defaultGroup === true){
                        defaultGroup = group.id
                    }
                })
                setDefaultZenGroup(defaultGroup);*/

            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving user's list of groups");
                }
            }
            setIsLoading(false);
        }
        //setIsLoading(true);
        populate();
    }, []);

    //executive summary notifications settings
    useEffect(() => {
        getUserAccountExecutiveSummarySettingsReactive().then(response => {
            setSendWeeklyReports(response.weeklyReportEnabled)
            setSendMonthlyReports(response.monthlyReportEnabled)
            if(response.reportFormat){
                setReportFormat(response.reportFormat)
            }
            else{
                //default to HTML format
                setReportFormat(REPORT_FORMAT.HTML)
            }
            setWeeklyReportsToggleDisabled(false)
            setMonthlyReportsToggleDisabled(false)
            setReportFormatSelectDisabled(false)
        }).catch(function (error) {
            //set to false in case of error
            setSendWeeklyReports(false)
            setSendMonthlyReports(false)
            //default to HTML format
            setReportFormat(REPORT_FORMAT.HTML)
            setWeeklyReportsToggleDisabled(false)
            setMonthlyReportsToggleDisabled(false)
            setReportFormatSelectDisabled(false)
        })
    }, []);

    /*const onChangeGroup = async (newGroup) => {
        try {
            if (newGroup) {
                setIsLoading(true);
                await changeDefaultGroupReactive(newGroup);
                setDefaultZenGroup(newGroup);
                NotificationManager.success("Default Group changed successfully!");
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error updating your default Group ");
            }
        }
        setIsLoading(false);
    };*/

    const onChangeReportFormat = async (newFormat) => {
        if(newFormat !== reportFormat){
            updateUserAccountExecutiveSummaryFormatReactive(newFormat).then(result => {
                NotificationManager.success("Successfully updated your executive summary report format");
                setReportFormat(newFormat)
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error sending your request");
                }
            })
        }
    };

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Account Settings</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className={`flex flex-1 flex-row border border-grey `}>
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap gap-y-5 ml-10 mr-10 mt-8">
                    {privatePageHeaderHelper("Account Settings")}
                    <Modal contentLabel="Reset Training Data" isOpen={resetTrainingGroupModalOpen}
                           onRequestClose={() => {
                               setResetTrainingGroupModalOpen(false)
                               setSelectedGroupToResetTrainingData()
                           }} shouldCloseOnOverlayClick={true}
                           className={`focus:outline-none focus:shadow-sm border-2 border-2 flex relative z-50 bg-white max-w-lg inset-y-10 mx-auto rounded-2xl focus:outline-none focus:shadow-sm border-2`}
                           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                    >
                        <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-4">
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">Reset Training Data</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        setResetTrainingGroupModalOpen(false)
                                        setSelectedGroupToResetTrainingData()
                                    }}
                                />
                            </div>
                            <hr className="h-0.5" />
                            <div className="flex flex-row mb-0">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"The data in each training group associated with the selected group will be reset back to its original state"}
                                    tooltipPlacement={"bottom-start"}
                                />
                                <label>Select Group to Reset Training Data for:</label>
                            </div>
                            <div>
                                <Dropdown
                                    options={getResetTrainingGroupDataDropDownContents(groupIdsWithTrainingData)}
                                    value={selectedGroupToResetTrainingData}
                                    onChange={({ value }) =>
                                        setSelectedGroupToResetTrainingData(value)
                                    }
                                    placeholder="Select"
                                    className="mt-0"
                                    controlClassName="dropdown"
                                    placeholderClassName="text-black-40"
                                    arrowClassName="text-black-70 text-base my-1"
                                />
                            </div>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                        onClick={() => {
                                            if(selectedGroupToResetTrainingData !== null && selectedGroupToResetTrainingData !== undefined){
                                                //selectedGroupToResetTrainingData is the groupId
                                                setIsLoading(true)
                                                resetTrainingGroupDataReactive(selectedGroupToResetTrainingData).then(r => {
                                                    NotificationManager.success("Training data successfully reset");
                                                    setResetTrainingGroupModalOpen(false)
                                                    setSelectedGroupToResetTrainingData()
                                                    setIsLoading(false)
                                                }).catch(function (error) {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error sending your request");
                                                    }
                                                    setIsLoading(false)
                                                })

                                            }}
                                }

                                >
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </Modal>
                    <hr className="bg-black h-0.5" />
                    <div className="text-xl font-semibold">
                        Current User: {user}
                    </div>
                    {/*<div className="flex flex-col flex-nowrap gap-y-1">
                        <label>Change Default Group</label>
                        <Dropdown
                            options={zenGroups.map(({ id, friendlyName }) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={defaultZenGroup}
                            onChange={({ value }) => onChangeGroup(value)}
                            placeholder="Select"
                            className="max-w-md text-sm rounded"
                            controlClassName=""
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base"
                        />
                    </div>*/}
                    <div className="flex flex-col gap-y-1">
                        <label>Change Default Timezone Setting</label>
                        <TimezonePicker
                            absolute={true}
                            defaultValue={decryptAndGetSessionVariable("timezone")}
                            placeholder="Select timezone..."
                            className="timezone max-w-md w-full "
                            onChange={async (value) => {
                                if(value){
                                    try{
                                        setIsLoading(true)
                                        await changeUserTimeZoneReactive(value)
                                        NotificationManager.success("Default Timezone successfully updated");
                                        encryptAndStoreSessionVariable("timezone", value)
                                    }
                                    catch(error){
                                        NotificationManager.error("Error updating your default timezone setting");
                                    }
                                    setIsLoading(false)
                                }
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <label>Executive Summary Report Format</label>
                        <Dropdown
                            options={Object.keys(REPORT_FORMAT)}
                            disabled={reportFormatSelectDisabled}
                            value={reportFormat}
                            onChange={({ value }) => onChangeReportFormat(value)}
                            placeholder="Select"
                            className="max-w-md text-sm rounded"
                            controlClassName=""
                            placeholderClassName="text-black-40"
                            arrowClassName="text-black-70 text-base"
                        />
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        disabled={weeklyReportsToggleDisabled}
                                        checked={sendWeeklyReports === null ? false : sendWeeklyReports}
                                        name="sendWeeklyReports"
                                        onChange={e => {
                                            let newCheckedValue = e.target.checked
                                            setSendWeeklyReports(newCheckedValue)
                                            //temp disable the toggle until response comes back in case of error and we have to set value to previous value
                                            setWeeklyReportsToggleDisabled(true)
                                            updateUserAccountWeeklyExecutiveSummaryDisabledSettingReactive(!newCheckedValue).then(response => {
                                                NotificationManager.success("Successfully updated your weekly report setting");
                                                setWeeklyReportsToggleDisabled(false)
                                            }).catch(function (error) {
                                                //if we get error then reset fields back to what it was (endpoint returns void so we should not get back an error though unless request is bad)
                                                NotificationManager.error("Unexpected error sending your request");
                                                setSendWeeklyReports(!newCheckedValue)
                                                setWeeklyReportsToggleDisabled(false)
                                            })
                                        }}
                                    />
                                } label={<div>
                                    Weekly Executive Summary Reports are <b>{sendWeeklyReports ? "Enabled" : "Disabled"}</b>
                                </div>}/>
                            </ThemeProvider>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-3">
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        disabled={monthlyReportsToggleDisabled}
                                        checked={sendMonthlyReports === null ? false : sendMonthlyReports}
                                        name="sendMonthlyReports"
                                        onChange={e => {
                                            let newCheckedValue = e.target.checked
                                            setSendMonthlyReports(newCheckedValue)
                                            //temp disable the toggle until response comes back in case of error and we have to set value to previous value
                                            setMonthlyReportsToggleDisabled(true)
                                            updateUserAccountMonthlyExecutiveSummaryDisabledSettingReactive(!newCheckedValue).then(response => {
                                                NotificationManager.success("Successfully updated your monthly report setting");
                                                setMonthlyReportsToggleDisabled(false)
                                            }).catch(function (error) {
                                                //if we get error then reset fields back to what it was (endpoint returns void so we should not get back an error though unless request is bad)
                                                NotificationManager.error("Unexpected error sending your request");
                                                setSendMonthlyReports(!newCheckedValue)
                                                setMonthlyReportsToggleDisabled(false)
                                            })
                                        }}
                                    />
                                } label={<div>
                                    Monthly Executive Summary Reports are <b>{sendMonthlyReports ? "Enabled" : "Disabled"}</b>
                                </div>}/>
                            </ThemeProvider>
                        </div>
                    </div>
                    <div className={`flex flex-col flex-nowrap gap-y-5`}>
                        <hr className="bg-black h-0.5" />
                        <div className="flex flex-col gap-y-3">
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme = {switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            disabled={trainingModeToggleDisabled}
                                            checked={trainingMode}
                                            name="trainingMode"
                                            onChange={e => {
                                                let newCheckedValue = e.target.checked
                                                //temp disable the toggle until response comes back in case of error and we have to set value to previous value
                                                setTrainingModeToggleDisabled(true)
                                                updateUserTrainingGroupsVisibleReactive(newCheckedValue).then(response => {
                                                    encryptAndStoreSessionVariable("trainingGroupsVisible", JSON.stringify(newCheckedValue))
                                                    NotificationManager.success(`Successfully turned training mode ${newCheckedValue === true ? "On" : "Off"}, please allow a few seconds to prep the data`);
                                                    setTrainingMode(newCheckedValue)
                                                    prepDataAfterSwitchingModes(setIsLoading)
                                                    setTrainingModeToggleDisabled(false)
                                                }).catch(function (error) {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error sending your request");
                                                    }
                                                    setTrainingMode(!newCheckedValue)
                                                    setTrainingModeToggleDisabled(false)
                                                })
                                            }}
                                        />
                                    } label={<div>
                                        Training Mode is <b>{trainingMode ? "On" : "Off"}</b>
                                    </div>}/>
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className={`${showDemoModeDiv ? "block" : "hidden"} flex flex-col gap-y-3`}>
                            <div className={`flex flex-row items-center`}>
                                <ThemeProvider theme = {switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            disabled={demoModeToggleDisabled}
                                            checked={demoMode}
                                            name="demoMode"
                                            onChange={e => {
                                                let newCheckedValue = e.target.checked
                                                //temp disable the toggle until response comes back in case of error and we have to set value to previous value
                                                setDemoModeToggleDisabled(true)
                                                updateUserDemoGroupsVisibleReactive(newCheckedValue).then(response => {
                                                    encryptAndStoreSessionVariable("demoGroupsVisible", JSON.stringify(newCheckedValue))
                                                    NotificationManager.success(`Successfully turned demo mode ${newCheckedValue === true ? "On" : "Off"}, please allow a few seconds to prep the data`);
                                                    setDemoMode(newCheckedValue)
                                                    prepDataAfterSwitchingModes(setIsLoading)
                                                    setDemoModeToggleDisabled(false)
                                                }).catch(function (error) {
                                                    //if we get error then reset fields back to what it was (endpoint returns void so we should not get back an error though unless request is bad)
                                                    NotificationManager.error("Unexpected error sending your request");
                                                    setDemoMode(!newCheckedValue)
                                                    setDemoModeToggleDisabled(false)
                                                })
                                            }}
                                        />
                                    } label={<div>
                                        Demo Mode is <b>{demoMode ? "On" : "Off"}</b>
                                    </div>} />
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className={`${(!trainingMode && !demoMode && groupIdsWithTrainingData !== null && groupIdsWithTrainingData !== undefined && groupIdsWithTrainingData?.length > 0) ? "block" : "hidden"}`}>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        onClick={() => {
                                            setResetTrainingGroupModalOpen(true)
                                        }}>
                                    Reset Training Data
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                    <hr className="mb-20 bg-black h-0.5" />
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );
}

