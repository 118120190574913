import axios from "../../utils/axiosInstance";

export const createEmailNotificationObjectReactive = async (zenGroupId, notifyAllGroupMembers, emailAddress, userSetName, timeInterval, ransomwareActivity, non_RansomwareIntrusionActivity,
                                                            offlineAgent, sendOfflineAlertsForHiddenAgents, sendRansomwareActivityAlertsForSilentIncidents) => {
    return await axios.post("/createEmailNotificationObjectReactive", {
        zenGroupId: zenGroupId,
        notifyAllGroupMembers: notifyAllGroupMembers,
        emailAddressToNotify: emailAddress,
        userSetName: userSetName,
        timeInterval: timeInterval,
        ransomwareActivity: ransomwareActivity,
        non_RansomwareIntrusionActivity: non_RansomwareIntrusionActivity,
        offlineAgent: offlineAgent,
        sendOfflineAlertsForHiddenAgents: sendOfflineAlertsForHiddenAgents,
        sendRansomwareActivityAlertsForSilentIncidents: sendRansomwareActivityAlertsForSilentIncidents
    });
};

export const notificationsListReactive = async () => {
    const response = await axios.post("/notificationsListReactive", {
    });
    return response.data;
};
export const deleteSingleSecurityNotificationReactive = async (securityNotificationId) => {
    return await axios.post("/deleteSingleSecurityNotificationReactive", {
        securityNotificationId: securityNotificationId
    });
};

export const updateNotificationTypeReactive = async (zenGroupId, notificationId, specificType) => {
    return await axios.post("/updateNotificationTypeReactive", {
        zenGroupId: zenGroupId,
        notificationId: notificationId,
        specificType: specificType
    });
};

export const updateNotificationTimeIntervalReactive = async (zenGroupId, timeInterval, notificationId) => {
    return await axios.post("/updateNotificationTimeIntervalReactive", {
        zenGroupId: zenGroupId,
        notificationId: notificationId,
        newTimeInterval: timeInterval
    });
};
export const updateSecurityNotificationNameReactive = async (newName, emailNotificationId) => {
    return await axios.post("/updateSecurityNotificationNameReactive", {
        newName: newName,
        emailNotificationId: emailNotificationId
    });
};
export const findBySecurityNotificationIdListReactive = async (ids) => {
    const response = await axios.post("/findBySecurityNotificationIdListReactive",{
        ids
    })
    return response.data
}
export const singleUpdateSecurityNotificationSendHiddenAgentAlertsReactive = async (notificationId, sendOfflineAlertsForHiddenAgents) => {
    return await axios.post("/singleUpdateSecurityNotificationSendHiddenAgentAlertsReactive", {
        notificationId: notificationId,
        sendOfflineAlertsForHiddenAgents: sendOfflineAlertsForHiddenAgents
    });
};
export const singleUpdateSecurityNotificationSendRansomwareActivityAlertsForSilentIncidentsReactive = async (notificationId, sendRansomwareActivityAlertsForSilentIncidents) => {
    return await axios.post("/singleUpdateSecurityNotificationSendRansomwareActivityAlertsForSilentIncidentsReactive", {
        notificationId: notificationId,
        sendRansomwareActivityAlertsForSilentIncidents: sendRansomwareActivityAlertsForSilentIncidents
    });
};

export const getSecurityNotificationsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getSecurityNotificationsGridFilterStateReactive", {});
    return response.data;
};

export const updateSecurityNotificationsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateSecurityNotificationsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateSecurityNotificationsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateSecurityNotificationsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getSecurityNotificationsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getSecurityNotificationsGridColumnStateReactive", {});
    return response.data;
};
export const updateSecurityNotificationsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateSecurityNotificationsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateSecurityNotificationsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateSecurityNotificationsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const generateMonthlyReport = async () => {
    return await axios.post("/generateMonthlyReport", {});
};