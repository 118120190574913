import {decryptAndGetSessionVariable} from "./encryptDecryptHelper";

export const checkPermission = (zenGroupId, permissionManagerName, permissionActionName) => {
    let roleGridData = JSON.parse(decryptAndGetSessionVariable("roleGridData"))
    let masterRoleList = JSON.parse(decryptAndGetSessionVariable("MasterRoleList"))
    let currentUserName = sessionStorage.getItem("username")
    let currentUserData
    for (const data of roleGridData) {
        if (data.username === currentUserName) {
            currentUserData = data
            break
        }
    }
    let hasPermission = false;
    if(currentUserData && currentUserData.roleMap){
        let roleMap = currentUserData.roleMap
        let roleIdList = roleMap[zenGroupId]
        //  the input zenGroupId is not in roleMap, which indicated that current user does not have permission
        if (roleIdList === undefined) return false
        for (const masterRole of masterRoleList) {
            if (roleIdList.includes(masterRole.id)) {
                let permissionManager = masterRole[permissionManagerName]
                if (permissionManager !== undefined && permissionManager[permissionActionName] === true) {
                    hasPermission = true;
                    break;
                }
            }
        }
    }

    return hasPermission
};