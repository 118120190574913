import axios from "../../utils/axiosInstance";

export const agentReleaseEmailSubmissionListReactive = async () => {
    const response = await axios.post("/agentReleaseEmailSubmissionListReactive", {});
    return response.data;
};

export const sendAgentReleaseEmailToAllUsersReactive = async (agentReleaseNotesUrl, agentVersion) => {
    return await axios.post("/sendAgentReleaseEmailToAllUsersReactive", {
        agentReleaseNotesUrl: agentReleaseNotesUrl,
        agentVersion: agentVersion
    });
};