import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./encryptDecryptHelper";
import {getAndStoreLicensesListWithAgentIdReactiveWithSSE} from "./licenseNameGridHelper";
import {getAndStoreAllClientSideDataInSession} from "./clientSideDataSessionStorageHelper";
import {groupListReactive, storeGroupListForCCUsersOnDealsPageInSessionSSE} from "../pages/api/groupsApi";
import {getAndStoreAgentNamesListInSession} from "./agentNameGridHelper";
import {getAndStoreAllRoleNamesOfCurrentUserInSession} from "./roleNamesOfCurrentUserHelper";
import {
    getAndStoreAllFilterStatesInSession,
    getAndStoreAllGridColumnStatesInSession
} from "./gridFilterStateAndColumnStateHelper";
import {getParentGroupsWithTrainingData} from "../pages/api/settingsApi";

export function getTrainingModeSetting(){
    let trainingMode = false //default to false
    try{
        let trainingModeFromSession = JSON.parse(decryptAndGetSessionVariable("trainingGroupsVisible"))
        if(trainingModeFromSession === null || trainingModeFromSession === undefined){
            return false
        }
        else{
            return trainingModeFromSession
        }
    } catch (e) {}

    return trainingMode
}

export function getDemoModeSetting(){
    let demoMode = false //default to false
    try{
        let demoModeFromSession = JSON.parse(decryptAndGetSessionVariable("demoGroupsVisible"))
        if(demoModeFromSession === null || demoModeFromSession === undefined){
            return false
        }
        else{
            return demoModeFromSession
        }
    } catch (e) {}

    return demoMode
}

export function prepDataAfterSwitchingModes(setIsLoading, setGroupIdsWithTrainingData){
    //we need to prep some session storage data after flipping between modes, and also prep column/filter states
    groupListReactive().then(groups => {
        encryptAndStoreSessionVariable("zenGroupSessionStorage", JSON.stringify(groups))
    }).catch(error => {
        console.log(error)
    })

    getAndStoreAllRoleNamesOfCurrentUserInSession()
    getAndStoreAgentNamesListInSession()
    getAndStoreLicensesListWithAgentIdReactiveWithSSE()
    getAndStoreAllClientSideDataInSession()

    storeGroupListForCCUsersOnDealsPageInSessionSSE().then()
    //If user turned off training and demo mode, then query for production filters and column states, else we should clear the filter and column states in session so there is no overlap of production and
    // training/demo data in filters
    //TODO: We might be able to still use column states no matter training/demo mode or production mode, but filters should be reset
    //The session storage variables for trainingGroupsVisible and demoGroupsVisible are updated before this function is called in settings.js
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if(!trainingMode && !demoMode){
        //If going back to production mode, then query for production filter models and column states saved
        getAndStoreAllFilterStatesInSession(false);
        getAndStoreAllGridColumnStatesInSession(false);

        //Also if going back to production mode then call for parent groups with training groups made
        getParentGroupsWithTrainingData().then(zenGroupIds => {
            if(zenGroupIds === undefined || zenGroupIds === null){
                encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
                setGroupIdsWithTrainingData && setGroupIdsWithTrainingData([])
            }
            else{
                encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify(zenGroupIds))
                setGroupIdsWithTrainingData && setGroupIdsWithTrainingData(zenGroupIds)
            }
        }).catch(function (error) {
            encryptAndStoreSessionVariable("groupIdsWithTrainingData", JSON.stringify([]))
            setGroupIdsWithTrainingData && setGroupIdsWithTrainingData([])
        })
    }
    else{
        //else we should clear the filter/column states from session since user is either in training or demo mode
        getAndStoreAllFilterStatesInSession(true);
        getAndStoreAllGridColumnStatesInSession(true);
    }

    //Allow some time for the api calls to return and update in session
    setIsLoading(true)
    let millisecondsToShowLoadingOverlay = 3000
    const timer = setTimeout(() => {
        setIsLoading(false)
    }, millisecondsToShowLoadingOverlay)
    return () => clearTimeout(timer)
}




































