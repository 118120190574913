import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {useForm} from "react-hook-form";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as EmailValidator from 'email-validator';
import {NotificationContainer} from 'react-notifications';
import NotificationManager from "react-notifications/lib/NotificationManager";
import {contactUsFormSubmitReactive} from "../api/contactUsApi";
import ReCAPTCHA from "react-google-recaptcha";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {Button, ThemeProvider} from "@mui/material";
import {blueButtonTheme} from "../../utils/muiStyling";

function Contact() {
    const { register, handleSubmit, reset } = useForm();
    const [phoneNumber, setPhoneNumber] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState()

    const key = process.env.REACT_APP_SITE_KEY



    function resetForm(){
        reset({})
        setPhoneNumber()
    }

    function onChange(value) {
        if(value === null){
            //expired
            setRecaptchaValue()
        }
        else{
            //good
            setRecaptchaValue(value)
        }
    }

    const submitForm = async ({first, last, email, message, agree}) => {
        if(!recaptchaValue){
            NotificationManager.error("Please complete the verification")
            return;
        }
        if(email && email.trim().length > 0){
            if(!EmailValidator.validate(email.trim())){
                NotificationManager.error("Please enter a valid email address")
                return;
            }
            //else valid email address, good to continue to if statement below
        }
        else{
            NotificationManager.error("Please enter a valid email address")
            return;
        }
        if(first && first.trim().length > 0 && last && last.trim().length > 0
            && message && message.trim().length > 0 && agree === true){
            if(isValidPhoneNumber(phoneNumber) && isPossiblePhoneNumber(phoneNumber)){
                //good input, send to api
                try{
                    const phoneNumberParsed = parsePhoneNumber(phoneNumber)
                    setIsLoading(true)
                    await contactUsFormSubmitReactive(first.trim(), last.trim(), email.toLowerCase().trim(), phoneNumber.trim(), message.trim(), phoneNumberParsed.country, recaptchaValue)
                    NotificationManager.success("Successfully submitted!")
                    //TODO: touch up this message like we will get back to you soon or something?
                    setIsLoading(false)
                    resetForm()
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error submitting this form.")
                    }
                    setIsLoading(false)
                }
            }
            else{
                NotificationManager.error("Please enter a valid phone number")
            }
        }
        else{
            NotificationManager.error("Please fill out all fields")
        }

    }

        return (
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="Cyber Crucible Dashboard Contact Us"/>
                    <meta name="keywords" content="" />
                    <meta name="author" content="" />
                    <script src="https://js.stripe.com/v3/"/>
                    <title>Contact Us</title>
                    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
                </Helmet>
                <BackDropPageLoadingOverlay opened={isLoading}/>
                <Header setIsLoading={setIsLoading}/>

                {/*Title Banner*/}
                <div className="banner-contact">
                    <div className="pt-20 pb-8">
                        <div className="container text-center mx-auto px-6">
                            <h2 className="text-4xl font-bold mb-2 text-white">
                                Contact Us
                            </h2>
                        </div>
                    </div>
                </div>

                {/*CTA Section & Contact Form */}
                <section className="text-gray-600 body-font">
                    <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
                        <div className="lg:w-1/4 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                            <h2 className="text-lg text-gray-900 font-bold uppercase mb-4">Contact Information</h2>
                            <h3 className="tracking-widest text-theme uppercase">Locations</h3>
                            <ul className="mb-4">
                                <li>Pittsburgh, PA (HQ)</li>
                                <li>Severna Park, MD</li>
                                <li>Boston, MA</li>
                            </ul>
                            <h3 className="tracking-widest text-theme uppercase">Sales</h3>
                            <ul className="mb-4">
                                <li><a href="tel:+1.412.775.2158 x501">+1.412.775.2158 x501</a></li>
                                <li><a href="mailto:sales@cybercrucible.com">sales@cybercrucible.com</a></li>
                            </ul>
                            <h3 className="tracking-widest text-theme uppercase">Information</h3>
                            <ul className="mb-4">
                                <li><a href="tel:+1.412.775.2158">+1.412.775.2158</a></li>
                                <li><a href="mailto:info@cybercrucible.com">info@cybercrucible.com</a></li>
                            </ul>
                            <h3 className="tracking-widest text-theme uppercase">Support</h3>
                            <ul className="mb-4">
                                <li><a href="tel:+1.412.775.2158 x502">+1.412.775.2158 x502</a></li>
                                <li><a href="mailto:support@cybercrucible.com">support@cybercrucible.com</a></li>
                            </ul>
                        </div>
                        <form className="lg:w-3/4 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0" onSubmit={handleSubmit(submitForm)}>
                            <div>
                                <h2 className="text-dark-grey text-md mb-5">Please fill out the form below, and we will get back to you as soon as we can.</h2>
                                <div className="flex flex-wrap -m-2">
                                    <div className="p-2 w-1/2">
                                        <div className="">
                                            <label htmlFor="first" className="leading-7 text-sm text-dark-grey">First Name <span className="required-text">*</span></label>
                                            <input
                                                {...register("first")}
                                                required type="text" id="first" name="first"
                                                className="w-full bg-white rounded border border-gray-300 focus:border-theme focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="">
                                            <label htmlFor="last" className="leading-7 text-sm text-dark-grey">Last Name <span className="required-text">*</span></label>
                                            <input
                                                {...register("last")}
                                                required
                                                type="text" id="last" name="last"
                                                className="w-full bg-white rounded border border-gray-300 focus:border-theme text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="">
                                            <label htmlFor="email" className="leading-7 text-sm text-dark-grey">Email <span className="required-text">*</span></label>
                                            <input
                                                {...register("email")}
                                                required
                                                type="text" id="email" name="email"
                                                className="w-full bg-white rounded border border-gray-300 focus:border-theme focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="">
                                            <label htmlFor="phoneNumber" className="leading-7 text-sm text-dark-grey">Phone Number <span className="required-text">*</span></label>
                                            <PhoneInput
                                                required
                                                name="phoneNumber"
                                                maxLength="16" //TODO: what should max length be if any?
                                                className="w-full bg-white rounded border border-gray-300 focus:border-theme text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"                                            defaultCountry="US"
                                                placeholder="Enter phone number"
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="">
                                            <label htmlFor="message" className="leading-7 text-sm text-dark-grey">Message <span className="required-text">*</span></label>
                                            <textarea
                                                {...register("message")}
                                                required
                                                id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-theme focus:bg-white h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="">
                                            <p className="mb-4 text-dark-grey">If you consent to us contacting you, which you can unsubscribe from at any time, please check below.</p>
                                            <div className="flex items-center mb-4">
                                                <input
                                                    {...register("agree")}
                                                    required
                                                    name="agree"
                                                    type="checkbox" id="checkbox-large-example"
                                                    className="h-5 w-5 text-gray-700 border rounded mr-2"/>
                                                <label htmlFor="checkbox-large-example" className="text-dark-grey font-normal">I agree to receive other communications from <span className="font-semibold">Cyber Crucible.</span></label>
                                            </div>
                                            <p className="mb-4 text-dark-grey">Please feel free to review our <Link className="text-theme" to="/privacy-policy">Privacy Policy</Link>.</p>
                                            <p className="mb-4 text-dark-grey">By clicking submit below, you consent to allow <span className="font-semibold">Cyber Crucible</span> to store and process the personal information submitted above to provide you the content requested.</p>
                                            <div className="mb-4">
                                                <ReCAPTCHA
                                                    sitekey={key}
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="flex justify-start">
                                                <ThemeProvider theme={blueButtonTheme}>
                                                    <Button
                                                        variant={"contained"}
                                                        type={"submit"}
                                                        color={"primary"}
                                                    >
                                                        Send Message
                                                    </Button>
                                                </ThemeProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </section>

                {/*CTA Section*/}

                <Footer></Footer>
                <NotificationContainer />
            </div>
    )
}

export default Contact
