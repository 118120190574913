import axios from "../../utils/axiosInstance";

export const changeTrainingLicenseNameReactive = async (newName, zenGroupId, licenseId) => {
    return await axios.post("/changeTrainingLicenseNameReactive", {
        "newName": newName,
        "zenGroupId": zenGroupId,
        "id": licenseId
    });
};
export const createStripeTrainingLicensesCheckoutSessionReactive = async (zenGroupId, trainingLicensesCount) => {
    const response = await axios.post("/createStripeTrainingLicensesCheckoutSessionReactive", {
        zenGroupId: zenGroupId,
        trainingLicensesCount: trainingLicensesCount
    });
    return response.data;
};
export const releaseTrainingLicenseReactive = async (trainingLicenseId) => {
    return await axios.post("/releaseTrainingLicenseReactive", {
        trainingLicenseId: trainingLicenseId
    });
};
export const assignUserTrainingLicenseReactive = async (trainingLicenseId, username) => {
    return await axios.post("/assignUserTrainingLicenseReactive", {
        trainingLicenseId: trainingLicenseId,
        username: username
    });
};
export const sendInvoiceForTrainingLicensesReactive = async (zenGroupId, trainingLicensesCount) => {
    const response = await axios.post("/sendInvoiceForTrainingLicensesReactive", {
        zenGroupId: zenGroupId,
        trainingLicensesCount: trainingLicensesCount
    });
    return response.data;
};

export const getTrainingLicensesGridColumnStateReactive = async () => {
    const response =  await axios.post("/getTrainingLicensesGridColumnStateReactive", {});
    return response.data;
};
export const updateTrainingLicensesGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateTrainingLicensesGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateTrainingLicensesGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateTrainingLicensesGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const getTrainingLicensesGridFilterStateReactive = async () => {
    const response =  await axios.post("/getTrainingLicensesGridFilterStateReactive", {});
    return response.data;
};

export const updateTrainingLicensesGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateTrainingLicensesGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateTrainingLicensesGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateTrainingLicensesGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};