import React from 'react';
import {Route} from 'react-router';
import {BrowserRouter, Routes} from "react-router-dom";
import Incidents from "../pages/private/incidents";
import BrowserDataIncidents from "../pages/private/browserDataIncidents";
import Roles from "../pages/private/roles";
import Schedules from "../pages/private/schedules";
import Groups from "../pages/private/groups";
import Notifications from "../pages/private/notifications";
import Deals from "../pages/private/deals";
import Settings from "../pages/private/settings";
import ProcessInjections from "../pages/private/processInjections";
import Decryptors from "../pages/private/decryptors";
import Whitelists from "../pages/private/whitelists";
import Licenses from "../pages/private/licenses";
import Agents from "../pages/private/agents";
import Sitemap from "./sitemap";
import Callback from "../pages/private/callback";
import Login from "../pages/static/login";
import Register from "../pages/static/register";
import ResetPassword from "../pages/static/resetPassword";
import Contact from "../pages/static/contact";
import PrivacyPolicy from "../pages/static/privacy-policy";
import Terms from "../pages/static/terms-service";
import SalesNotifications from "../pages/private/salesNotifications";
import Tasks from "../pages/private/tasks";
import RestIntegration from "../pages/private/restIntegration";
import SessionExpiration from "../pages/private/sessionExpiration";
import ProcessCreations from "../pages/private/processCreations";
import SilentResponses from "../pages/private/silentResponses";

export default function SitemapRoutes() {
    return(
        <BrowserRouter>
                <Routes>
            <Route sitemapIndex={true} path="/" element={<Login/>}/>
            <Route sitemapIndex={false} path="/sitemap" element={<Sitemap />}/>
            <Route sitemapIndex={false} path="/callback" element={<Callback />}/>
            <Route sitemapIndex={true} path="/login" element={<Login />}/>
            <Route sitemapIndex={true} path="/createAccount" element={<Register />}/>
            <Route sitemapIndex={true} path="/resetPassword" element={<ResetPassword />}/>
            <Route sitemapIndex={true} path="/contact" element={<Contact />}/>
            <Route sitemapIndex={true} path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route sitemapIndex={true} path="/terms" element={<Terms />}/>
            <Route sitemapIndex={true} path="/private/incidents" element={<Incidents />}/>
            <Route sitemapIndex={true} path="/private/browserDataIncidents" element={<BrowserDataIncidents />}/>
            <Route sitemapIndex={true} path="/private/tasks" element={<Tasks />}/>
            <Route sitemapIndex={true} path="/private/roles" element={<Roles />}/>
            <Route sitemapIndex={true} path="/private/scheduling" element={<Schedules />}/>
            <Route sitemapIndex={true} path="/private/groups" element={<Groups />}/>
            <Route sitemapIndex={true} path="/private/notifications" element={<Notifications />}/>
            <Route sitemapIndex={true} path="/private/salesNotifications" element={<SalesNotifications />}/>
            <Route sitemapIndex={true} path="/private/partnerRegisterDeal" element={<Deals />}/>
            <Route sitemapIndex={true} path="/private/settings" element={<Settings />}/>
            <Route sitemapIndex={true} path="/private/processInjections" element={<ProcessInjections />}/>
            <Route sitemapIndex={true} path="/private/decryptors" element={<Decryptors />}/>
            <Route sitemapIndex={true} path="/private/whitelists" element={<Whitelists />}/>
            <Route sitemapIndex={true} path="/private/licenses" element={<Licenses />}/>
            <Route sitemapIndex={true} path="/private/agents" element={<Agents />}/>
            <Route sitemapIndex={true} path="/private/restIntegration" element={<RestIntegration />}/>
            <Route sitemapIndex={true} path="/private/sessionDurations" element={<SessionExpiration />}/>
            <Route sitemapIndex={true} path="/private/processCreations" element={<ProcessCreations />}/>
            <Route sitemapIndex={true} path="/private/silentResponses" element={<SilentResponses />}/>
                </Routes>
        </BrowserRouter>
    );
}
