import axios from "../../utils/axiosInstance";

export const getGroupAndUserInfoReactive = async () => {
    const response = await axios.post("/getGroupAndUserInfoReactive", {
    });
    return response.data;
};

//this endpoint is reactive just does not have "Reactive" at the end
export const getAllMasterRole = async () => {
    const response = await axios.post("/getAllMasterRoleByZenGroupId", {
    });
    return response.data;
};

export const createRoleReactive = async (roleName, permissions, zenGroup) => {
    const response = await axios.post("/createRoleReactive", {
        roleName,
        permissionsListForNewRole: permissions,
        zenGroupToAssociateRole: zenGroup,
    });
    return response.data;
};

export const updateRoleReactive = async (currentRoleName, updatedRoleName, permissions, roleId) => {
    const response = await axios.post("/updateRoleReactive", {
        currentRoleName: currentRoleName,
        editedRoleName: updatedRoleName,
        updatedPermissionsList: permissions,
        roleId: roleId,
    });
    return response.data;
};

export const deleteRoleReactive = async (roleName, roleId) => {
    return await axios.post("/deleteRoleReactive", {
        roleNameToDelete: roleName,
        roleId: roleId,
    });
};
export const findByRoleIdListReactive = async (ids) => {
    const response = await axios.post("/findByRoleIdListReactive",{
        ids
    })
    return response.data
}

export const getRolesGridFilterStateReactive = async () => {
    const response =  await axios.post("/getRolesGridFilterStateReactive", {});
    return response.data;
};

export const updateRolesGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateRolesGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateRolesGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateRolesGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getRolesGridColumnStateReactive = async () => {
    const response =  await axios.post("/getRolesGridColumnStateReactive", {});
    return response.data;
};
export const updateRolesGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateRolesGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateRolesGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateRolesGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const removeRoleFromUserRoleMapReactive = async (roleId, usernameToUpdate, zenGroupId) => {
    return await axios.post("/removeRoleFromUserRoleMapReactive", {
        roleId: roleId,
        usernameToUpdate: usernameToUpdate,
        zenGroupId: zenGroupId
    });
};
export const addRoleToUserRoleMapReactive = async (roleId, usernameToUpdate, zenGroupId) => {
    return await axios.post("/addRoleToUserRoleMapReactive", {
        roleId: roleId,
        usernameToUpdate: usernameToUpdate,
        zenGroupId: zenGroupId
    });
};