import {findZenGroupByFriendlyName} from "./zenGroupSessionStorageManager";
import {decryptAndGetSessionVariable} from "./encryptDecryptHelper";

export function setFilterHelper(filter, clazz){
    let f = {}
    if(filter[clazz].values) {
        f.key = clazz
        f.type = "inSet" //custom set for this filterType
        f.valueType = filter[clazz].filterType
        if(clazz === "zenGroupDisplayName" || clazz === "friendlyName" || clazz === "assignedDistributionGroupName"){
            let zenGroupFilters = []
            filter[clazz].values.forEach(friendlyName => {
                zenGroupFilters.push(findZenGroupByFriendlyName(friendlyName).id);
            });
            f.valsArray = zenGroupFilters;
        }else if(clazz === "licenseDisplayName"){
            //save this for next
            f.valsArray = filter[clazz].values
        }else if(clazz === "agentDisplayName"){
            //TODO: figure out how to filter this value - copied from licenses.js
            //save this for next
            //f.valsArray = filter[clazz].values
        }
        else if(clazz === "autoUpgrade" || clazz === "hiddenFromUI" || clazz === "sendOfflineAlertsForHiddenAgents" || clazz === "silent" || clazz === "disabled" || clazz === "sendRansomwareActivityAlertsForSilentIncidents"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        }
        else if(clazz === "specialStatusMessage"){
            let filterValues = filter[clazz].values
            if(filterValues.includes("none")){
                filterValues.push(null)
            }
            f.valsArray = filterValues;
        } else if(clazz === "channelPartner" || clazz === "distributionGroup" || clazz === "channelPartnerCustomer" || clazz === "collectAgentTelemetry" || clazz === "whcpOnly"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        } else if(clazz === "ransomwareActivity" || clazz === "offlineAgent" || clazz === "non_RansomwareIntrusionActivity"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        } else if(clazz === "dealCreated" || clazz === "dealDisabled" || clazz === "dealExpired" || clazz === "upcomingDealExpiration" || clazz === "invoiceCreated"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        }
        else{
            f.valsArray = filter[clazz].values
        }
    }
    return f
}

export function dateFilterHelper(filter,clazz){
    try{ //for extra precaution
        let f = {}
        let test = new Date()
        let dateFrom = new Date(filter[clazz].dateFrom)


        let testSessionTime = (new Date(Date.parse(test.toLocaleString('en-US', {
            timeZone: decryptAndGetSessionVariable("timezone")
        }))));

        let sessionTZOffset = Math.ceil((testSessionTime - test) / 36e5) * 60 * 60* 1000 ;
        let filterLocalDate = (dateFrom.getTime() - sessionTZOffset)
        let dateFromWithOffset = new Date(filterLocalDate)


        let splitArray = dateFromWithOffset.toISOString().split("T")
        let splitArray2 = splitArray[1].split(".000Z")
        let finalDateFrom = splitArray[0] + " " + splitArray2[0]

        f.val = finalDateFrom

        if(filter[clazz].dateTo){
            let dateTo = new Date(filter[clazz].dateTo)
            let filterLocalDate2 = (dateTo.getTime() - sessionTZOffset)
            let dateToWithOffset = new Date(filterLocalDate2)
            let splitArray3 = dateToWithOffset.toISOString().split("T")
            let splitArray4 = splitArray3[1].split(".000Z")
            let finalDateTo = splitArray3[0] + " " + splitArray4[0]
            f.val = finalDateFrom+ "&" + finalDateTo
        }

        f.type = filter[clazz].type
        f.key = clazz
        f.valueType = filter[clazz].filterType
        return f
    }
    catch(error){
        console.error("error parsing dateFilterHelper contents")
    }
}

export function defaultFilterHelper(filter,clazz){
    let f={}
    f.val = filter[clazz].filter
    f.type = filter[clazz].type
    f.key = clazz
    if(clazz === "agentVersionFormatted"){
        //need to convert the agentVersionFormatted back to agentVersion before sending filter for server side data since we changed the field name to be agentVersionFormatted on frontend
        f.key = "agentVersion"
    }
    f.valueType = filter[clazz].filterType
    return f
}

export function timeFilterHelper(filter,clazz){
    //have to customize this since we format the time interval from number to string
    let f = {}
    let value = 0;
    let filterTypedIn = filter[clazz].filter
    filterTypedIn = filterTypedIn.toLowerCase();
    switch(filterTypedIn){
        case "5 minutes":
            value = 300
            break;
        case "15 minutes":
            value = 900
            break;
        case "30 minutes":
            value = 1800
            break;
        case "1 hour":
            value = 3600
            break;
        case "12 hours":
            value = 43200
            break;
        case "24 hours":
            value = 86400
            break;
        default:
            value = 0;
            break;
    }
    f.val = value;
    f.type = filter[clazz].type
    f.key = clazz;
    f.valueType = "number";
    return f
}

export function masterFilterHelper(filter){
    let filters = []
    for (let clazz in filter) {
        if (filter[clazz] == null) {
            continue;
        }
        //let f = {}
        if(filter[clazz].filterType === "set"){
            filters.push(setFilterHelper(filter,clazz))
        }
        else if(filter[clazz].filterType === "date"){
            filters.push(dateFilterHelper(filter,clazz))
        }
        else if(clazz === "timeIntervalInSeconds"){
            filters.push(timeFilterHelper(filter,clazz))
        }
        else if(filter[clazz].filterType === "multi" && clazz === "whitelistAppliedId"){
            //only enabled for status filter on incidents grid currently
            try{
                let filterModels = filter[clazz].filterModels
                //Per ag grid docs, the filterModels indexes for multi filter types are in the same order as they are given in the column def. So for status column, index 0 is the
                // status filter, and index 1 is the silent filter. If no filter is present for one of them, then the filter model for that index will be null, but the indexes do not change.
                if(filterModels[0] != null){
                    //whitelistAppliedId filter
                    let f = {}
                    f.key = "whitelistAppliedId" //in the backend it accounts for this as the key and checking for Not Whitelisted and Whitelisted from the filter
                    f.type = "inSet" //custom set for this filterType
                    f.valueType = "set"
                    f.valsArray = filterModels[0].values
                    filters.push(f)
                }
                if(filterModels[1] != null){
                    //silent filter
                    let f = {}
                    f.key = "silent"
                    f.type = "inSet" //custom set for this filterType
                    f.valueType = "booleanSet" //boolean
                    //need to convert 'Silent', 'Not Silent' to true and false
                    let valsArrayToSend = []
                    filterModels[1].values.forEach(value => {
                        if(value === "Silent"){
                            valsArrayToSend.push(true)
                        }
                        else if(value === "Not Silent"){
                            valsArrayToSend.push(false)
                        }
                    })
                    f.valsArray = valsArrayToSend
                    filters.push(f)
                }
            } catch (e){
                console.log("Error grabbing filters from multi filter in filter helper ")
            }
        }
        else{
            filters.push(defaultFilterHelper(filter,clazz))
        }
    }
    return filters
}
