import React, {Component, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {groupListReactive} from "../api/groupsApi";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {NotificationContainer} from 'react-notifications';
import Modal from "react-modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    addRoleToUserRoleMapReactive,
    createRoleReactive,
    deleteRoleReactive,
    getGroupAndUserInfoReactive,
    removeRoleFromUserRoleMapReactive,
    updateRoleReactive,
    updateRolesGridColumnStateReactive,
    updateRolesGridFilterModelReactive,
    updateRolesGridUseColumnStateReactive,
    updateRolesGridUseFilterStateReactive
} from "../api/rolesApi";
import {useForm} from "react-hook-form";
import SidebarMenu from "../../components/sideBarComponent";
import {
    zenGroupCellRenderFramework,
    zenGroupFilterParamsCellRenderer,
    zenGroupFilterParamsValues
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import {getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/encryptDecryptHelper";
import {
    getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid,
    reviverForMapStringify
} from "../../utils/roleNamesOfCurrentUserHelper";
import {useLocation} from "react-router-dom";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {Box, Button, Checkbox, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip} from "../../components/muiComponents";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

Modal.setAppElement('#root')

const PERMISSIONS = [
    "Add User To Group",
    "Remove User From Group",
    "Change User Role",
    "Change Group Name",
    "Create Group",
    "Edit Session Duration",
    "View Session Duration",
    "Edit Group Use WHCP Drivers Only",
    "Upload SSL Certificate",
    "Remove SSL Certificate",
    "Edit Group SSL Pinning Mode"
];
const INCIDENTS = [
    "Ignore Incident",
    "Safe Kill Process",
    "Unsafe Kill Process",
    "Isolate Process",
    "Dismiss Process",
    "Suspend Process",
    "Edit Whitelist Name",
    "Remove Whitelist Name",
    "Silence Incident"
];
const RESPONSE_AUTOMATION = [
    "Create Schedule",
    "Edit Schedule",
    "Delete Schedule",
];
const PURCHASE = [
    "Purchase Licenses",
    "Edit Partner Deal Name",
    "Remove Partner Deal Name"
];

const NOTIFICATIONS = [
    "Create Notification",
    "Edit Notification",
    "Delete Notification",
];

const MANAGE_AGENTS = [
    "Change Agent Group",
    "Uninstall Agent",
    "Change Agent Auto-Upgrade",
    "Change Agent Name",
    "Release Agent License",
    "Change Collect Agent Telemetry",
    "Change Agent Grid Visibility"
];

const APPLIANCE = [
    "Create Appliance",
    "View Appliance",
    "Create Appliance Job",
    "Cancel Appliance Job",
    "View Appliance Job"
];

const toChecklistFormat = (plain) =>
    plain.map((opt) => ({
        id: opt,
        label: opt,
    }));

//let keysToRefresh = ["roleName"]
//let gridColumnStateSessionVariableName = "rolesGridColumnState"
export default function Roles() {
    const scrollTo = useRef(null);
    const { register, handleSubmit, reset } = useForm();
    const [activeRole, setActiveRole] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [zenGroups,setZenGroups] = useState([]);
    const [zenGroup, setZenGroup] = useState();
    const permissions = toChecklistFormat(PERMISSIONS);
    const [isPermissionChecked, setIsPermissionChecked] = React.useState(() =>
        permissions.map((i) => false)
    );
    const [checkedPermissions, setCheckedPermissions] = useState(new Set())
    const incidents = toChecklistFormat(INCIDENTS);
    const [isIncidentChecked, setIsIncidentChecked] = React.useState(() =>
        incidents.map((i) => false)
    );
    const [checkedIncidents, setCheckedIncidents] = useState(new Set())
    const responses = toChecklistFormat(RESPONSE_AUTOMATION);
    const [isResponseChecked, setIsResponseChecked] = React.useState(() =>
        responses.map((i) => false)
    );
    const [checkedResponses, setCheckedResponses] = useState(new Set())
    const purchase = toChecklistFormat(PURCHASE);
    const [isPurchaseChecked, setIsPurchaseChecked] = React.useState(() =>
        purchase.map((i) => false)
    );
    const [checkedPurchases, setCheckedPurchases] = useState(new Set())
    const notifications = toChecklistFormat(NOTIFICATIONS);
    const [isNotificationChecked, setIsNotificationChecked] = React.useState(() =>
        notifications.map((i) => false)
    );
    const [checkedNotifications, setCheckedNotifications] = useState(new Set())
    const agents = toChecklistFormat(MANAGE_AGENTS)
    const [isAgentChecked, setIsAgentChecked] = React.useState(() =>
        agents.map((i) => false)
    );
    const [checkedAgents, setCheckedAgents] = useState(new Set())
    const appliances = toChecklistFormat(APPLIANCE);
    const [isApplianceChecked, setIsApplianceChecked] = React.useState(() =>
        appliances.map((i) => false)
    );
    const [checkedAppliances, setCheckedAppliances] = useState(new Set())

    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("rolesGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession("rolesGridColumnState"));
    const zenGroupDisplayNameAndUsernameLocation = useLocation();
    const [showCreateRole, setShowCreateRole] = useState(false);

    const handleChecklist = (setIsChecked, values, checkedSet, setCheckedSet, index, checked) => {
        setIsChecked((isChecked) => {
            return isChecked.map((c, i) => {
                if (i === index) {
                    return checked
                }
                return c;
            });
        });

        let newSet = new Set(checkedSet)
        if (checked) {
            newSet.add(values[index].id)
            setCheckedSet(newSet)
        } else {
            newSet.delete(values[index].id)
            setCheckedSet(newSet)
        }
    };

    const handleCheckAll = (setIsChecked, setCheckedSet, values, checked) => {
        setIsChecked((isChecked) => {
            return isChecked.map(() => {
                return checked;
            });
        });
        setCheckedSet(new Set())

        if (checked) {
            let newSet = new Set()
            for (let index in values){
                newSet.add(values[index].id)
            }
            setCheckedSet(newSet)
        }
    }

    const clearForm = () => {
        setZenGroup(null);
        reset({ roleName: null });
        setIsEditing(false);
        setActiveRole(null);
        setCheckedPermissions(new Set());
        setCheckedIncidents(new Set());
        setCheckedResponses(new Set());
        setCheckedPurchases(new Set());
        setCheckedNotifications(new Set());
        setCheckedAgents(new Set());
        setCheckedAppliances(new Set());

        setIsPermissionChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsIncidentChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsResponseChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsPurchaseChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsNotificationChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsAgentChecked((isChecked) => {return isChecked.map(() => {return false});});
        setIsApplianceChecked((isChecked) => {return isChecked.map(() => {return false});});
    };

    const populateRole = () => {
        const { zenGroupId, roleName } = activeRole;
        setIsEditing(true);
        setZenGroup(zenGroupId);
        reset({ roleName: roleName });
        let currentSetPermissions = getCurrentSetPermissions();
        const plainPermissions = [...currentSetPermissions];
        setCheckedPermissions(
            new Set(PERMISSIONS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedIncidents(
            new Set(INCIDENTS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedResponses(
            new Set(RESPONSE_AUTOMATION.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedPurchases(
            new Set(PURCHASE.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedNotifications(
            new Set(NOTIFICATIONS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedAgents(
            new Set(MANAGE_AGENTS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedAppliances(
            new Set(APPLIANCE.filter((p) => plainPermissions.includes(p)))
        );
        scrollTo.current.scrollIntoView();
    };

    const deleteSelectedRole = async () => {
        try {
            setIsLoading(true);
            const { roleName, roleId } = activeRole;
            await deleteRoleReactive(roleName, roleId);
            NotificationManager.success(`Successfully deleted role: ${roleName}`);
            resetGrid();
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error deleting role");
            }
            console.log(error);
            resetGrid();
        }
        setShowDeleteWarning(false);
        setIsLoading(false);
    };

    useEffect(() => {
        async function populate() {
            try{
                const zenGroups = await groupListReactive();
                setZenGroups(zenGroups);
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving user's list of groups");
                }
            }
            setIsLoading(false);
        }
        //setIsLoading(true);
        populate();
    }, [zenGroup]);

    const onSubmit = async ({ roleName }) => {
        try {
            setIsLoading(true);
            const checkedItems = [
                ...checkedPermissions,
                ...checkedIncidents,
                ...checkedResponses,
                ...checkedPurchases,
                ...checkedNotifications,
                ...checkedAgents,
                ...checkedAppliances
            ];
            if(!isEditing){ //creating a role
                if(roleName){
                    if(roleName.trim().length < 1){
                        NotificationManager.info("Please make sure the Role Name field is not empty before continuing.");
                        setIsLoading(false);
                        return;
                    }
                }
                else{
                    NotificationManager.info("Please make sure the Role Name field is not empty before continuing.");
                    setIsLoading(false);
                    return;
                }
            }
            let editRoleName = null;
            if(isEditing){ //editing a role
                if(roleName){
                    if(roleName.trim().length > 0){
                        if(roleName.trim() !== activeRole.roleName){ //make sure they aren't the same name
                            editRoleName = roleName.trim();
                            if(editRoleName === "Admin"){
                                NotificationManager.error("This name is reserved for default Admin roles, please enter a different name.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                }
            }
            let response;
            if(isEditing){ //editing a role
                if(activeRole){
                    if(activeRole.roleName === "Admin"){
                        NotificationManager.error("You cannot edit the default Admin role.");
                        setIsLoading(false);
                        return;
                    }
                    response = await updateRoleReactive(
                        activeRole.roleName,
                        editRoleName,
                        checkedItems,
                        activeRole.roleId
                    );
                }
                else{
                    setIsLoading(false);
                    return;
                }
            }
            else{ //creating role
                if(zenGroup){ //make sure a zenGroup is selected
                    if(roleName.trim() === "Admin"){
                        NotificationManager.error("This name is reserved for default Admin roles, please enter a different name.");
                        setIsLoading(false);
                        return;
                    }
                    response = await createRoleReactive(roleName.trim(), checkedItems, zenGroup);
                }
                else{
                    NotificationManager.info("Please make sure a Group is selected before continuing.");
                    setIsLoading(false);
                    return;
                }
            }
            //if we get to here, the api call should have been made and the response stored in the 'response' variable
            if(response.roleNameExistsAlready === true){
                //the role name exists already
                NotificationManager.error("A role in this group exists with this name already, enter a different name");
                setIsLoading(false);
                return
            }
            else if(response.roleNameExistsAlready === false){
                //success for both create and updating a role
                getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid(resetGrid, refreshGridCells)
                /*getAndStoreAllRoleNamesOfCurrentUserInSession()
                resetGrid();*/
                if(isEditing){
                    NotificationManager.success(`Successfully updated this role`);
                }
                else{
                    NotificationManager.success(`Successfully created this role`);
                }
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error completing the request");
            }
            setIsLoading(false);
            return
        }
        setShowCreateRole(false)
        setIsLoading(false);
    };

    const MuiChecklist = (setIsChecked, checkedSet, values, setCheckedSet, isChecked, formLabel) => (
        <Box boxShadow={3} sx={{ borderRadius: 2, display: 'inline-flex', flexDirection: 'column', ml: 0, paddingBlock: 1, paddingLeft: 2}}>
            <FormControlLabel
                label={formLabel}
                componentsProps={{ typography: { fontFamily: ['Open Sans', 'sans-serif'].join(','), fontSize: 19, fontWeight: 'bold'} }}
                control={
                    <Checkbox
                        checked={checkedSet.size === values.length}
                        indeterminate={checkedSet.size > 0 && checkedSet.size !== values.length}
                        onChange={(e) => {
                            handleCheckAll(setIsChecked, setCheckedSet, values, e.target.checked)
                        }}
                    />
                }
            />
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', ml: 0.5, mr: 0.5}}>
                {values.map(
                    ({ label, id }, i) => (
                        <FormControlLabel
                            componentsProps={{ typography: { fontFamily: ['Open Sans', 'sans-serif'].join(',') } }}
                            key={id}
                            label={label}
                            control={
                                <Checkbox
                                    checked={isChecked[i]}
                                    style={{position: "relative", paddingTop: 1, paddingBottom: 1}}
                                    name={id}
                                    id={id}
                                    onChange={(e)=>{handleChecklist(setIsChecked, values, checkedSet, setCheckedSet, i, e.target.checked)}}
                                />
                            }
                        />
                    )
                )}
            </Box>
        </Box>
    )

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Roles</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col flex-nowrap mt-8 ml-10 mr-10 gap-y-2">
                    {privatePageHeaderHelper("Roles")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row flex-wrap gap-x-3 gap-y-4">
                        <Modal contentLabel="Confirm"
                               isOpen={showCreateRole}
                               onRequestClose={() => {
                                   setShowCreateRole(false)
                                   clearForm()
                               }}
                               shouldCloseOnOverlayClick={true}
                               className={`focus:outline-none focus:shadow-sm border-2 border-2 flex relative z-50 bg-white w-3xl max-w-7xl inset-y-10 mx-auto rounded-2xl`}
                               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-auto"
                        >
                            <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                                {/*Title with exit button*/}
                                <div className="flex flex-row justify-between">
                                    <h1 className="font-bold text-3xl">{isEditing ? `Edit Role: ${activeRole.roleName}` : "Create New Role"}</h1>
                                    <MuiCloseIconButton
                                        onClick={() => {
                                            setShowCreateRole(false);
                                            clearForm()
                                        }}
                                    />
                                </div>
                                <hr className="mt-3 h-0.5" />
                                {/*Form content*/}
                                <div className="flex flex-row flex-wrap w-full ">
                                    <div className="ml-1 mr-1 mt-5 w-full xl:w-1/3">
                                        <label>{isEditing ? "Change Role Name" : "Role Name"}</label>
                                        <input
                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter'){
                                                    e.preventDefault();
                                                }}}
                                            name="roleName"
                                            type="text"
                                            required
                                            {...register("roleName")}
                                            className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                        />
                                    </div>
                                    <div className="ml-1 mt-5 w-full xl:w-3/5">
                                        <label>Group</label>
                                        <Dropdown
                                            options={getZenGroupDropDownContents()}
                                            disabled={isEditing}
                                            value={zenGroup}
                                            onChange={({ value }) => setZenGroup(value)}
                                            placeholder="Select"
                                            className="mt-3"
                                            controlClassName="dropdown"
                                            placeholderClassName="text-black-40"
                                            arrowClassName="text-black-70 text-base my-1"
                                        />
                                    </div>
                                </div>
                                <label className="text-xl">Permissions</label>
                                <div className="flex flex-col w-full flex-wrap gap-x-1 gap-y-5 justify-evenly xl:flex-row">
                                    {MuiChecklist(setIsAgentChecked, checkedAgents, agents, setCheckedAgents, isAgentChecked, "Agent Manager")}
                                    {MuiChecklist(setIsPermissionChecked, checkedPermissions, permissions, setCheckedPermissions, isPermissionChecked, "Group Manager")}
                                    {MuiChecklist(setIsIncidentChecked, checkedIncidents, incidents, setCheckedIncidents, isIncidentChecked, "Incident Manager")}
                                    {MuiChecklist(setIsApplianceChecked, checkedAppliances, appliances, setCheckedAppliances, isApplianceChecked, "Appliance Manager")}
                                    {MuiChecklist(setIsResponseChecked, checkedResponses, responses, setCheckedResponses, isResponseChecked, "Response Automation Manager")}
                                    {MuiChecklist(setIsNotificationChecked, checkedNotifications, notifications, setCheckedNotifications, isNotificationChecked, "Notification Manager")}
                                    {MuiChecklist(setIsPurchaseChecked, checkedPurchases, purchase, setCheckedPurchases, isPurchaseChecked, "Purchase Manager")}
                                </div>
                                <div className="flex flex-row flex-wrap justify-around mb-2">
                                    <ThemeProvider theme = {buttonTheme}>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={() => {
                                                clearForm();
                                            }}
                                        > Clear Form
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={handleSubmit(onSubmit)}
                                        > Create
                                        </Button>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className="flex flex-row justify-between gap-y-3 gap-x-3 mt-2">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Role"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setShowCreateRole(true)
                                            }}
                                        />
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<EditIcon className={"cursor-pointer"}/>} tooltipTitle={"Edit Role"}
                                            tooltipPlacement={"top"} disabled={!activeRole}
                                            onClick={() => {
                                                setShowCreateRole(true);
                                                populateRole();
                                            }}
                                        />
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Role"}
                                            tooltipPlacement={"top"} disabled={!activeRole || activeRole.roleName === "Admin"}
                                            onClick={() => deleteSelectedRole()}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>
            <Footer />
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'rolesGridFilterState', updateRolesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, "rolesGridColumnState", updateRolesGridUseColumnStateReactive);
    }

    function getCurrentSetPermissions(){

        if (activeRole === null || activeRole === undefined) {
            return []; //return empty list
        }
        //TODO: probably is a better way to add permissions to a list, but doing manual checks for now
        let allPermissionsList = [];
        if(activeRole.groupManager){
            let gm = activeRole.groupManager
            if(gm.addUserToGroup === true){
                allPermissionsList.push("Add User To Group")
            }
            if(gm.removeUserFromGroup === true){
                allPermissionsList.push("Remove User From Group")
            }
            if(gm.changeUserRole === true){
                allPermissionsList.push("Change User Role")
            }
            if(gm.changeGroupName === true){
                allPermissionsList.push("Change Group Name")
            }
            if(gm.createGroup === true){
                allPermissionsList.push("Create Group")
            }
            if(gm.editSessionExpiration === true){
                allPermissionsList.push("Edit Session Duration")
            }
            if(gm.listSessionExpiration === true){
                allPermissionsList.push("View Session Duration")
            }
            if(gm.editGroupWhcp === true){
                allPermissionsList.push("Edit Group Use WHCP Drivers Only")
            }
            if(gm.uploadSSLCert === true){
                allPermissionsList.push("Upload SSL Certificate")
            }
            if(gm.deleteSSLCert === true){
                allPermissionsList.push("Remove SSL Certificate")
            }
            if(gm.editGroupSSLPinningMode === true){
                allPermissionsList.push("Edit Group SSL Pinning Mode")
            }
        }
        if(activeRole.incidentManager){
            let im = activeRole.incidentManager
            if(im.ignoreIncident === true){
                allPermissionsList.push("Ignore Incident")
            }
            if(im.safeKillProcess === true){
                allPermissionsList.push("Safe Kill Process")
            }
            if(im.unsafeKillProcess === true){
                allPermissionsList.push("Unsafe Kill Process")
            }
            if(im.isolateProcess === true){
                allPermissionsList.push("Isolate Process")
            }
            if(im.dismissProcess === true){
                allPermissionsList.push("Dismiss Process")
            }
            if(im.suspendProcess === true){
                allPermissionsList.push("Suspend Process")
            }
            if(im.changeWhitelistName === true){
                allPermissionsList.push("Edit Whitelist Name")
            }
            if(im.removeWhitelistName === true){
                allPermissionsList.push("Remove Whitelist Name")
            }
            if(im.silenceIncident === true){
                allPermissionsList.push("Silence Incident")
            }
        }
        if(activeRole.responseAutomationManager){
            let rm = activeRole.responseAutomationManager
            if(rm.createSchedule === true){
                allPermissionsList.push("Create Schedule")
            }
            if(rm.editSchedule === true){
                allPermissionsList.push("Edit Schedule")
            }
            if(rm.deleteSchedule === true){
                allPermissionsList.push("Delete Schedule")
            }
        }
        if(activeRole.purchaseManager){
            let pm = activeRole.purchaseManager
            if(pm.purchaseLicenses === true){
                allPermissionsList.push("Purchase Licenses")
            }
            if(pm.changePartnerDealName === true){
                allPermissionsList.push("Edit Partner Deal Name")
            }
            if(pm.removePartnerDealName === true){
                allPermissionsList.push("Remove Partner Deal Name")
            }
        }
        if(activeRole.notificationManager){
            let nm = activeRole.notificationManager
            if(nm.createNotification === true){
                allPermissionsList.push("Create Notification")
            }
            if(nm.editNotification === true){
                allPermissionsList.push("Edit Notification")
            }
            if(nm.deleteNotification === true){
                allPermissionsList.push("Delete Notification")
            }
        }
        if(activeRole.agentManager){
            let am = activeRole.agentManager
            if(am.changeAgentGroup === true){
                allPermissionsList.push("Change Agent Group")
            }
            if(am.uninstallAgent === true){
                allPermissionsList.push("Uninstall Agent")
            }
            if(am.changeAgentAutoUpgrade === true){
                allPermissionsList.push("Change Agent Auto-Upgrade")
            }
            if(am.changeAgentName === true){
                allPermissionsList.push("Change Agent Name")
            }
            if(am.releaseAgentLicense === true){
                allPermissionsList.push("Release Agent License")
            }
            if(am.changeAgentTelemetrySetting === true){
                allPermissionsList.push("Change Collect Agent Telemetry")
            }
            if(am.changeAgentVisibility === true){
                allPermissionsList.push("Change Agent Grid Visibility")
            }
        }
        if(activeRole.applianceManager){
            let am = activeRole.applianceManager
            if(am.createAppliance === true){
                allPermissionsList.push("Create Appliance")
            }
            if(am.queryAppliance === true){
                allPermissionsList.push("View Appliance")
            }
            if(am.createApplianceJob === true){
                allPermissionsList.push("Create Appliance Job")
            }
            if(am.queryApplianceJob === true){
                allPermissionsList.push("View Appliance Job")
            }
            if(am.cancelApplianceJob === true){
                allPermissionsList.push("Cancel Appliance Job")
            }
        }
        return allPermissionsList;
    }

    function getGrid(){
        //getAndStoreAllRoleNamesOfCurrentUserInSession()
        let loggedInUsername = sessionStorage.getItem("username")
        //Get set of roleNames to use for column headers and make map of zenGroupId to list of roleNames for the group
        let roleNameSet = new Set()
        //let validRoleNameMap = new Map()
        JSON.parse(decryptAndGetSessionVariable("MasterRoleList"))?.forEach((value) => {
            roleNameSet.add(value.roleName)
            /*if (validRoleNameMap.has(value.zenGroupId)) {
                let roleNameList = validRoleNameMap.get(value.zenGroupId)
                roleNameList.add(value.roleName)
                validRoleNameMap.set(value.zenGroupId, roleNameList)
            } else {
                let roleNameList = new Set()
                roleNameList.add(value.roleName)
                validRoleNameMap.set(value.zenGroupId, roleNameList)
            }*/
        })

        let headers = [
            {
                field: "zenGroupDisplayName",
                name: "Group",
                width: 450,
                filter: 'agSetColumnFilter',
                //pinned: 'left',
                filterParamsInHeader: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: zenGroupFilterParamsCellRenderer,
                    values: zenGroupFilterParamsValues,
                    refreshValuesOnOpen: true,
                    suppressSorting: true,
                    showTooltips: true
                },
                sortable: true,
                cellRenderer: zenGroupCellRenderFramework,
                editable: true,
                cellEditorType: "customNameCellEditor"
            },
            {
                field: "username", name: "User Name", width: 400,
                filter: 'agSetColumnFilter',
                //pinned: 'left',
                filterParamsInHeader: {
                    suppressSorting: false,
                    buttons: ["reset", "apply", "cancel"],
                    //
                    //filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                    //suppressAndOrCondition: true,
                },
                valueGetter: function (params) {
                    //valueGetter instead of valueFormatter so the filter will work if they filter for "me" or something similar
                    if(loggedInUsername){
                        if (params.data.username === loggedInUsername) {
                            //for the current logged-in user, add a (me) to end of string
                            return params.data.username + " (me)"
                        }
                    }
                    return params.data.username
                },
                sortable: true
            },
            { field: "roleMap", hide: true, suppressColumnsToolPanel: true},
        ]
        //get sessionStorage values from getAndStoreAllRoleNamesOfCurrentUserInSession that will be used in valueGetters below for figuring out if user is assigned a role or not
        //let roleNameMapSessionStorage = decryptAndGetSessionVariable("roleIdToRoleNameMap")
        let zenGroupToRoleNameAndIdMapSessionStorage = decryptAndGetSessionVariable("zenGroupIdToRoleNameAndIdMap")
        //let roleNameMap = new Map()
        let globalRoleMap = new Map()
        /*if(roleNameMapSessionStorage){
            roleNameMap = JSON.parse(decryptAndGetSessionVariable("roleIdToRoleNameMap"), reviverForMapStringify)
        }*/
        if(zenGroupToRoleNameAndIdMapSessionStorage){
            globalRoleMap = JSON.parse(decryptAndGetSessionVariable("zenGroupIdToRoleNameAndIdMap"), reviverForMapStringify)
        }
        //add a column for each role name in the roleNameSet
        roleNameSet.forEach((roleName) => {
            headers.push({
                field: roleName,
                name: roleName,
                width: 400,
                filter: 'agSetColumnFilter',
                filterParamsInHeader: {
                    buttons: ["reset", "apply", "cancel"],
                    values: ["Assigned", "Not Assigned", "Role Does Not Exist For Group"],
                    suppressSorting: true
                },
                sortable: true,
                valueGetter: function (params) {
                    /*
                        See if a role exists in this group (params.data.zenGroupId):
                            -It does exist:
                                -check this user's roleMap for this group and see if they have this roleId
                                -They are assigned this role then return Assigned
                                -They are not assigned this role then return Not Assigned
                            -It does not exist for this group
                                -Return blank/null or does not exist
                     */
                    if(params && params.data && params.data.zenGroupId){
                        let zenGroupId = params.data.zenGroupId
                        let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                        if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                            //there are possible roles for this group and a role with this roleName exists for this group. Check to see if user is assigned this role
                            if(params.data.roleMap && params.data.roleMap.hasOwnProperty(zenGroupId)){
                                //user has a roleMap and a key in the roleMap for zenGroupId
                                let userAssignedRoleIdsList = params.data.roleMap[zenGroupId]
                                let roleIdToCheck = groupPossibleRolesMap.get(roleName)
                                if(userAssignedRoleIdsList && userAssignedRoleIdsList.includes(roleIdToCheck)){
                                    params.data[roleName] = "Assigned"
                                    return "Assigned"
                                }
                                else{
                                    params.data[roleName] = "Not Assigned"
                                    return "Not Assigned"
                                }
                            }
                            else{
                                //else user does not have a roleMap or does not have a key in their roleMap for this zenGroupId
                                params.data[roleName] = "Not Assigned"
                                return "Not Assigned"
                            }
                        }
                        else{
                            //no existing roles for this group or group does not have this roleName
                            params.data[roleName] = "Role Does Not Exist For Group"
                            return "Role Does Not Exist For Group"
                        }
                    }
                },
                cellRenderer:
                    function (params) {
                        let data = params.node.data
                        /*if (!validRoleNameMap.get(data.zenGroupId)?.has(roleName)) {
                            return (<div className={`flex flex-row items-center`}></div>)
                        }*/
                        if (data[roleName] !== "Assigned" && data[roleName] !== "Not Assigned") {
                            //then does not exist
                            return (<div className={`flex flex-row items-center`}></div>)
                        }
                        //  produce info icon information
                        let infoString
                        let disableToggle = false
                        if (data.username === loggedInUsername) {
                            infoString = "You can not change your own role"
                            disableToggle = true
                        } else {
                            infoString = "Toggle to assign/remove other users' roles"
                        }
                        return (
                            <div className={`flex flex-row items-center gap-x-2`}>
                                <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data[roleName] === "Assigned"}
                                    name={data.zenGroupId + data.userId + roleName} //  to create a unique name/input id
                                    disabled={disableToggle} //  disable the toggle for current user
                                    onChange={(e) => {
                                        //  prevent current logged-in user from changing their own roles
                                        if (data.username === loggedInUsername) {
                                            NotificationManager.error(`You can not change your own role`);
                                        } else {
                                            //else get the roleId for this roleName in this row's group
                                            let zenGroupId = data.zenGroupId
                                            let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                                            if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                                                let roleIdToUpdate = groupPossibleRolesMap.get(roleName)
                                                if (!e.target.checked) {
                                                    // Remove roleId from roleMap, remove this role from user
                                                    removeRoleFromUserRoleMapReactive(roleIdToUpdate, data.username, zenGroupId).then(() => {
                                                        //NotificationManager.success(`Successfully updated user`);
                                                        e.target.checked = false
                                                        //need to remove this roleId from user roleMap
                                                        if(data.roleMap && data.roleMap.hasOwnProperty(zenGroupId)){
                                                            let userAssignedRoleIdsList = data.roleMap[zenGroupId]
                                                            if(userAssignedRoleIdsList && userAssignedRoleIdsList.includes(roleIdToUpdate)){
                                                                data.roleMap[zenGroupId] = userAssignedRoleIdsList.filter(function (value, index, arr) {
                                                                    return value !== roleIdToUpdate;
                                                                })
                                                                updateUserRoleMapForRolesGridInSessionStorage(data.username, data.roleMap, zenGroupId)
                                                                params.node.setDataValue("roleMap", data.roleMap)
                                                            }
                                                        }
                                                        params.node.setDataValue(roleName, "Not Assigned")
                                                        params.api.refreshCells({columns: [roleName], rowNodes: [params.node], suppressFlash: true, force: true})
                                                    }).catch(error => {
                                                        if (error.message) {
                                                            NotificationManager.error(error.message)
                                                        } else {
                                                            NotificationManager.error("Unexpected error sending request")
                                                        }
                                                    })
                                                } else {
                                                    // Add roleId to roleMap for this group, assign user this role
                                                    addRoleToUserRoleMapReactive(roleIdToUpdate, data.username, zenGroupId).then(() => {
                                                        //NotificationManager.success(`Successfully updated user`);
                                                        e.target.checked = true
                                                        //need to add this roleId to user roleMap
                                                        if(data.roleMap && data.roleMap.hasOwnProperty(zenGroupId)){
                                                            let userAssignedRoleIdsList = data.roleMap[zenGroupId]
                                                            //make sure userAssignedRoleIdsList is not null first
                                                            if(!userAssignedRoleIdsList){
                                                                userAssignedRoleIdsList = []
                                                            }
                                                            if(!userAssignedRoleIdsList.includes(roleIdToUpdate)){
                                                                //add roleIdToUpdate to  userAssignedRoleIdsList
                                                                userAssignedRoleIdsList.push(roleIdToUpdate)
                                                                data.roleMap[zenGroupId] = userAssignedRoleIdsList
                                                                updateUserRoleMapForRolesGridInSessionStorage(data.username, data.roleMap, zenGroupId)
                                                                params.node.setDataValue("roleMap", data.roleMap)
                                                            }
                                                        }
                                                        params.node.setDataValue(roleName, "Assigned")
                                                        params.api.refreshCells({columns: [roleName], rowNodes: [params.node], suppressFlash: true, force: true})
                                                    }).catch(error => {
                                                        if (error.message) {
                                                            NotificationManager.error(error.message)
                                                        } else {
                                                            NotificationManager.error("Unexpected error sending request")
                                                        }
                                                    })
                                                }
                                            }
                                            else{
                                                //error, this should not happen since cell renderer toggle should not be visible if role does not exist for this group
                                            }
                                        }
                                    }}
                                />
                                </ThemeProvider>
                                {/*params.node.data[roleName] === "Assigned" ? "Assigned" : "Not Assigned"*/}
                                <div className={"mb-3"}>
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={infoString}
                                        tooltipPlacement={"right"}
                                    />
                                </div>
                            </div>
                        )
                    }
            })
        })
        return (
            <Grid
                headers={headers}
                onClickRow={(row) => {
                    //check for if a row is selected, since this function is called when a row is selected or deselected
                    // clearForm(); //reset the create/edit role form
                    if(row[0] && row[0].roleName){ //row is selected, added the right hand-side check for the super rare case a "{}" is returned for a row (from the API)
                        // to make sure the row isn't a blank row. So we don't get null pointer exception trying to edit or delete a role.
                        setActiveRole(row[0]);
                    }
                }}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                zenGroupDisplayNameAndUsernameLocation={zenGroupDisplayNameAndUsernameLocation}
                excelExport={excelExport}
            />
        );
    }

    function updateUserRoleMapForRolesGridInSessionStorage(username, newRoleMap, zenGroupId){
        let sessionStorageVariableName = "roleGridData"
        let gridDataList = JSON.parse(decryptAndGetSessionVariable(sessionStorageVariableName))
        if(gridDataList){
            for(let i = 0; i < gridDataList.length; i++){
                //for extra precaution surround in try catch when accessing sessionStorage variables and current iterations
                try{
                    let currentObject = gridDataList[i]
                    //update this user's roleMap for this group only
                    if(currentObject["username"] === username && currentObject["zenGroupId"] === zenGroupId){
                        currentObject["roleMap"] = newRoleMap
                    }
                } catch (e) {}
            }
            encryptAndStoreSessionVariable(sessionStorageVariableName, JSON.stringify(gridDataList))
        }
    }

    async function refreshGrid(){
        //commenting out for now since this refresh code has not been updated for the new role man client side grid
        //await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "roleId", "id", findByRoleIdList, keysToRefresh)
        getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid(resetGrid, refreshGridCells)
        //this only is checking for new/deleted roles for the columns, does not refresh user permissions yet.
    }

    function resetGrid(){
        clearForm();
        //this function is used when a new notification was created or a notification was deleted since refreshGrid() doesn't cover this yet as of July 30th
        gridApi.onFilterChanged() //preserves filter and sort models
    }
    function refreshGridCells(){
        gridApi.refreshCells()
    }

    function excelExport(){
        //roles currently loads all data at once with no sse so pass false here
        standardExcelExportHelper(gridApi, false, "rolesGridExport")
    }
}

export const Checklist = ({
                              title,
                              data = [],
                              margin,
                              handleCheck,
                              checkedItems = [],
                              isCheckedAll,
                              className,
                              style,
                          }) => {
    return (
        <div
            style={style}
            className={`flex flex-col shadow rounded-2xl gap-y-1 w-full ${
                margin
            } `}
        >
            <div
                className={`flex flex-row`}
            >
                <input
                    key={title}
                    className="checkbox checked:bg-orange-100 checked:border-orange-100 mr-2"
                    type="checkbox"
                    onChange={handleCheck}
                    checked={isCheckedAll}
                />
                <div className={"font-semibold text-lg"}>{title}</div>
            </div>
            <div className="flex flex-col">
                {data.map((v, index) => (
                    <div key={index} className="flex flex-row">
                        <input
                            className="checkbox checked:bg-orange-100 checked:border-orange-100 mr-3"
                            type="checkbox"
                            data-key={v.id}
                            onChange={handleCheck}
                            checked={checkedItems.has(v.id)}
                        />
                        <div className={"text-m"}>{v.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};


let saveFilterChanges = true //used for if user clicks link to come to agents page and we auto filter grid to show that specific agent, in that case we don't want to save
// the filters because that would mess with their previous filters they still may want.

class Grid extends Component {
    rowData = []
    //  Map<zenGroupId, Map<roleName, roleId>>


    constructor(props, onClickRow, filterVals, headers) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };

    onCellEditingStopped = (event) => {
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
    }
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, "rolesGridColumnState", updateRolesGridColumnStateReactive)
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.gridColumnApi = gridReadyParams.columnApi;
        this.props.setGridApi(gridReadyParams.api);
        this.props.setGridColumnApi(gridReadyParams.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession("rolesGridColumnState")){
            onGridReadyHelperForColumnState(gridReadyParams, "rolesGridColumnState")
        }

        let roleGridData = JSON.parse(decryptAndGetSessionVariable("roleGridData"))
        if(roleGridData){
            //found roleGridData in session
            this.gridApi.setRowData(roleGridData)
        }
        else{
            const data = await getGroupAndUserInfoReactive();
            gridReadyParams.api.setRowData(data)
        }

        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.zenGroupDisplayNameAndUsernameLocation && this.props.zenGroupDisplayNameAndUsernameLocation.state
            && this.props.zenGroupDisplayNameAndUsernameLocation.state.zenGroupDisplayName && this.props.zenGroupDisplayNameAndUsernameLocation.state.user){
            let zenGroupDisplayName = this.props.zenGroupDisplayNameAndUsernameLocation.state.zenGroupDisplayName
            let username = this.props.zenGroupDisplayNameAndUsernameLocation.state.user.username
            let locationFilterModel = {"zenGroupDisplayName": {filterType: "set", values: [zenGroupDisplayName]}}
            if(username) {
                locationFilterModel["username"] = {
                    filterType: "set",
                    values: [username]
                }
            }
            // console.log(locationFilterModel)
            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            gridReadyParams.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            //window.scroll({behavior: "smooth", top: 100, left: 0})
        } else {
            saveFilterChanges = true
            onGridReadyHelper(gridReadyParams, "rolesGridFilterState");
        }

        gridReadyParams.api.hideOverlay()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        rowData={this.rowData}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        onGridReady={this.onGridReady}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            //only update session and user saved filters if saveFilterChanges is true. We added this in to not save filter changes if user clicked a link to agents page because
                            // this filtering out for one agent would mess with their saved filters that they may still want.
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'rolesGridFilterState', updateRolesGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.headers.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer,
                                    cellRendererSelector, cellEditorType, hide, sortable, minWidth,
                                    width, suppressColumnsToolPanel, valueFormatter, cellEditorSelector, pinned, valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    //onCellValueChanged={onUpdate}
                                    cellEditor={cellEditorType}
                                    enableCellChangeFlash={true}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    valueFormatter={valueFormatter}
                                    valueGetter={valueGetter}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    width={width}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    keyCreator={keyCreator}
                                    //pinned={pinned}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}












