import React, {Component, useEffect, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    changePartnerDealNameReactive,
    completePartnerDealAndSendInvoiceForPurchasingLicensesReactive,
    createNewPartnerDealAndSendInvoiceReactive,
    disablePartnerDealReactive,
    enablePartnerDealReactive,
    newPartnerDealReactive,
    removePartnerDealNameReactive,
    updateDealsGridColumnStateReactive,
    updateDealsGridFilterModelReactive,
    updateDealsGridUseColumnStateReactive,
    updateDealsGridUseFilterStateReactive
} from "../api/dealsApi";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import SidebarMenu from "../../components/sideBarComponent";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as EmailValidator from 'email-validator';
import {PartnerStripeModal} from "../../components/partnerStripeCheckoutSetupModal";
import Dropdown from "react-dropdown";
import {ConfirmationModal} from "../../components/confirmationModal";
import {partnerCompleteDealAndCreateCheckoutSessionReactive} from "../api/stripeApi";
import {dateValueFormatter} from "../../utils/gridDateFormatter";

import {
    getChannelGroups,
    getChannelGroupsDropDownContents,
    getDistributionGroups,
    getDistributionGroupsDropDownContents,
    getGroupNameForDealsGridForChangeStream,
    getZenGroupOrOrganizationNames,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {decryptAndGetSessionVariable} from "../../utils/encryptDecryptHelper";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeader} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {MuiCloseIconButton, MuiIconButtonWithTooltipAndBox} from "../../components/muiComponents";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {ClickToShowButtonsExpandingLeft, ClickToShowButtonsExpandingRight} from "../../components/clickToShowButtons";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
let gridColumnStateSessionVariableName = "partnerDealsGridColumnState"
export default function Deals() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [channelGroups, setChannelGroups] = useState([]);
    const [channelGroupForNewDeal, setChannelGroupForNewDeal] = useState();
    // eslint-disable-next-line no-unused-vars
    const [zenGroup, setZenGroup] = useState();
    const [user, setUser] = useState();
    const [showNewDealModal, setShowNewDealModal] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const [phoneNumber, setPhoneNumber] = useState();
    const [buttonClicked, setButtonClicked] = useState();
    const [showStripeSetupModal, setShowStripeSetupModal] = useState(false);
    const [distributionGroups, setDistributionGroups] = useState([]);
    const [newDealData, setNewDealData] = useState()
    const [activeDeal, setActiveDeal] = useState();
    const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);
    const [showEnableConfirmation, setShowEnableConfirmation] = useState(false);
    const [threeYearDesktopToggled, setThreeYearDesktopToggled] = useState(true);
    const [threeYearServerToggled, setThreeYearServerToggled] = useState(true);
    const [serverTotalCashValue, setServerTotalCashValue] = useState(0);
    const [desktopTotalCashValue, setDesktopTotalCashValue] = useState(0);
    const [saveAndPurchaseButtonDisabled, setSaveAndPurchaseButtonDisabled] = useState(true);
    const [showCompleteDealModal, setShowCompleteDealModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [distributionGroupsForCompleteDeal, setDistributionGroupsForCompleteDeal] = useState([]);
    const [completeDealZenGroup, setCompleteDealZenGroup] = useState();
    const [completeDealButtonClicked, setCompleteDealButtonClicked] = useState();
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [gridApi, setGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("dealsGridFilterState"));
    const serverLicensePriceYearly = 120.00
    const desktopLicensePriceYearly = 60.00
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "prospectOrganization", name: "Prospect Org", width: 325,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "partnerDealDisplayName", name: "Deal Name", width: 430,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true,
            editable: true,
            cellEditorType: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Deal's Name", "partnerDealDisplayName")
            },
            onUpdate: async (event) => {
                let newName = event.newValue;
                if(newName && newName.trim().length > 0){
                    if(!event.data.dealId){
                        NotificationManager.error("Error updating deal name")
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                        resetGrid()
                        return;
                    }
                    try{
                        setIsLoading(true)
                        await changePartnerDealNameReactive(newName.trim(), event.data.dealId)
                        NotificationManager.success("Successfully changed this deal's name")
                        /*updateObjectInSessionStorage("partnerDealsGridList", "dealId",
                            event.data.dealId, "partnerDealDisplayName", newName.trim())*/
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Error updating deal name")
                        }
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                    }
                    resetGrid()
                    setIsLoading(false)
                }
                else{
                    /*
                        Else make call to remove the deal's userSetFriendlyName, then in the future the random name
                        will be shown on the grid for the specific deal
                     */
                    if(!event.data.dealId){
                        NotificationManager.error("Error updating deal name")
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                        resetGrid()
                        return;
                    }
                    try{
                        setIsLoading(true)
                        await removePartnerDealNameReactive(event.data.dealId)
                        NotificationManager.success("Removed the user set name for this deal," +
                            " the random name will now be displayed.")
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Error updating deal name")
                        }
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                    }
                    resetGrid()
                    setIsLoading(false)
                }
            },
        },
        { field: "submitted", name: "Submitted", width: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "expirationDate", name: "Expiration Date", width: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "dealCompleted", name: "Completed Date", width: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "numberOfServerLicensesBought", name: "Expected Server Licenses",
            width: 325,
            filter: 'agNumberColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "numberOfDesktopLicensesBought", name: "Expected Desktop Licenses",
            width: 325,
            filter: 'agNumberColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "approximateTotalCashValue", name: "Expected Total Deal Value",
            width: 325,
            cellRenderer: function(params) {
                if(params.node.data && params.node.data.approximateTotalCashValue){
                    return formatter.format(params.node.data.approximateTotalCashValue)
                }
                else{
                    return ""
                }
            },
            filter: 'agNumberColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "disabledDate", name: "Disabled Date", width: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParamsInHeader: dateFilterParametersInHeader,
            valueFormatter: dateValueFormatter
        },
        { field: "zenGroupDisplayName", name: "Group",
            width: 400,
            filter: 'agSetColumnFilter',
            filterParamsInHeader: {
                buttons: ["reset", "apply", "cancel"],
                values: function(params) {
                    //the endpoint that is used to supply the ccUsersDealsZenGroupSessionStorage variable handles confirming if user is a cc user
                    let CCSessionStorageValue = decryptAndGetSessionVariable("ccUsersDealsZenGroupSessionStorage");
                    if (CCSessionStorageValue) {
                        //combine the two lists, cc groups list and regular groups list into set for values for this col
                        try{
                            let CCNames = JSON.parse(CCSessionStorageValue);
                            let groupFriendlyNames = new Set();
                            CCNames.forEach(CCNames => {
                                // for groupListForCCUsersOnDealsPage() org name is sent as friendlyName (if it is present) in the query already
                                groupFriendlyNames.add(CCNames.friendlyName);
                            })
                            let zenGroups = getZenGroupOrOrganizationNames()
                            zenGroups.forEach(zenGroups => {
                                groupFriendlyNames.add(zenGroups);
                            })
                            params.success(Array.from(groupFriendlyNames));
                        } catch (error){
                            params.success(getZenGroupOrOrganizationNames());
                        }
                    }
                    else{
                        params.success(getZenGroupOrOrganizationNames());
                    }
                },
                refreshValuesOnOpen: true,
                showTooltips: true
            },
            sortable: true,
        },
        { field: "prospectName", name: "Prospect Name",
            width: 280,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "prospectEmail", name: "Prospect Email",
            width: 325,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "prospectPhone", name: "Prospect Phone",
            width: 250,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "prospectZipCode", name: "Prospect Zipcode",
            width: 250,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "prospectCity", name: "Prospect City",
            width: 250,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "prospectAddress", name: "Prospect Address",
            width: 325,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        },
        { field: "createdBy", name: "Created By",
            width: 325,
            filter: 'agTextColumnFilter',
            filterParamsInHeader: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                suppressAndOrCondition: true,
            },
            sortable: true
        }

    ]);

    useEffect(() => {
        async function populate() {
            try{
                setUser(sessionStorage.getItem("username"))
                setChannelGroups(getChannelGroups())
            }
            catch(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error retrieving user's list of groups");
                }
            }
            setIsLoading(false);
        }
        //setIsLoading(true);
        populate();
    }, [zenGroupSessionStorage]);

    function resetNewDealFormData(){
        reset({
            salespersonEmail: user,
            serverLicenseCount: "",
            desktopLicenseCount: "",
            associatedProductsAndServices: 0,
            totalDealValue: 0,
            prospectName: "",
            prospectEmail: "",
            prospectPhone: "",
            prospectAddress: "",
            prospectCity: "",
            prospectOrganization: "",
            prospectZipCode: "",
            prospectDescription: "",
            userSetFriendlyName: ""
        })
        setServerTotalCashValue(0)
        setDesktopTotalCashValue(0)
        setPhoneNumber()
        setButtonClicked()
        setDistributionGroups([])
        setNewDealData()
        setChannelGroupForNewDeal()
        setThreeYearServerToggled(true)
        setThreeYearDesktopToggled(true)
        setSaveAndPurchaseButtonDisabled(true)
    }

    const submitDeal = async (data) => {
        //verify deal information
        if(!channelGroupForNewDeal){
            return;
        }
        if(!data.prospectEmail || !data.salespersonEmail){
            return;
        }
        if(data.prospectEmail.trim().length > 0){
            if(!EmailValidator.validate(data.prospectEmail.trim())){
                NotificationManager.error("Please enter a valid prospect email address")
                return;
            }
        }
        else{
            NotificationManager.error("Please enter a valid prospect email address");
            return;
        }
        if(data.salespersonEmail.trim().length > 0){
            if(!EmailValidator.validate(data.salespersonEmail.trim())){
                NotificationManager.error("Please enter a valid salesperson email address")
                return;
            }
        }
        else{
            NotificationManager.error("Please enter a valid salesperson email address");
            return;
        }
        //check to see if at least one type of license is being bought
        if(data.serverLicenseCount < 1 && data.desktopLicenseCount < 1){
            NotificationManager.error("You must enter at least one desktop or server license")
            return;
        }

        if(isValidPhoneNumber(phoneNumber) && isPossiblePhoneNumber(phoneNumber)){
            if(buttonClicked === "saveAndPurchase"){
                //we need to setup modal for grabbing info for stripe checkouts
                try{
                    setIsLoading(true)

                    let distributionGroupsList = getDistributionGroups()
                    if(distributionGroupsList && distributionGroupsList.length > 0){
                        //good to proceed
                        const phoneNumberParsed = parsePhoneNumber(phoneNumber)
                        data.channelPartnerZenGroupId = channelGroupForNewDeal
                        data.phoneCountry = phoneNumberParsed.country
                        data.prospectPhone = phoneNumber
                        data.prospectEmail = data.prospectEmail.trim().toLowerCase()
                        data.salespersonEmail = data.salespersonEmail.trim().toLowerCase()
                        //TODO: update these to be either 1 or 3 years
                        if(data.serverLicenseCount > 0){
                            data.serverLicensesSubscriptionYears = 1
                        }
                        if(data.desktopLicenseCount > 0){
                            data.desktopLicensesSubscriptionYears = 1
                        }
                        setDistributionGroups(distributionGroupsList)
                        setNewDealData(data)
                        setShowStripeSetupModal(true)
                    }
                    else{
                        NotificationManager.error("You are not a member of any groups that are distribution groups, " +
                            "you are not eligible to continue to the purchase license form.")
                    }
                }
                catch (error) {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error setting up next form")
                    }
                }
                setIsLoading(false)
            }
            else{
                try{
                    const phoneNumberParsed = parsePhoneNumber(phoneNumber)
                    data.channelPartnerZenGroupId = channelGroupForNewDeal
                    data.phoneCountry = phoneNumberParsed.country
                    data.prospectPhone = phoneNumber
                    setIsLoading(true)
                    data.prospectEmail = data.prospectEmail.trim().toLowerCase()
                    data.salespersonEmail = data.salespersonEmail.trim().toLowerCase()
                    //TODO: update these to be either 1 or 3 years
                    if(data.serverLicenseCount > 0){
                        data.serverLicensesSubscriptionYears = 1
                    }
                    if(data.desktopLicenseCount > 0){
                        data.desktopLicensesSubscriptionYears = 1
                    }
                    switch(buttonClicked) {
                        case "saveAndInvoice":
                            await createNewPartnerDealAndSendInvoiceReactive(data)
                            break;
                        case "saveOnly":
                            await newPartnerDealReactive(data);
                            break;
                        default:
                            await createNewPartnerDealAndSendInvoiceReactive(data); //default to send invoice
                        // code block
                    }
                    setShowNewDealModal(false)
                    resetGrid()
                    resetNewDealFormData()
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error saving this new deal");
                    }
                }

            }
        }
        else{
            NotificationManager.error("Please enter a valid phone number")
        }
        setIsLoading(false)
    }

    function resetCompleteDeal(){
        setShowCompleteDealModal(false)
        setCompleteDealZenGroup()
        reset({
            completeDealServerLicenses: "",
            completeDealDesktopLicenses: ""
        })

    }

    const onCompleteDeal = async (data) => {
        //setting input elements default values manually and disabling them makes the data.serverLicenses or data.desktopLicenses
        // values undefined when disabled is set to true, so we have to get the element itself and get the value that way
        if(!activeDeal || !activeDeal.dealId){
            return;
        }
        if(activeDeal.disabledDate){
            //deal is already disabled
            NotificationManager.error("This partner deal is disabled, you cannot complete this deal");
            return;
        }
        if(activeDeal.dealCompleted){
            //deal is already disabled
            NotificationManager.info("This partner deal is already completed");
            return;
        }
        var completeDealServerLicensesCount = document.getElementById("completeDealServerLicensesCount")
        var completeDealDesktopLicensesCount = document.getElementById("completeDealDesktopLicensesCount")
        if(!completeDealDesktopLicensesCount || !completeDealServerLicensesCount){
            NotificationManager.error("Unexpected error completing this request")
            return;
        }
        if(completeDealServerLicensesCount.value < 1 && completeDealDesktopLicensesCount.value < 1){
            NotificationManager.error("You must enter at least one desktop or server license")
            return;
        }
        let numServerLicenses = completeDealServerLicensesCount.value
        let numDesktopLicenses = completeDealDesktopLicensesCount.value
        if(completeDealZenGroup && data){
            if(completeDealButtonClicked === "payNow"){
                if(numServerLicenses > 99 || numDesktopLicenses > 99){
                    NotificationManager.info("The max amount of licenses you can pay for at a time is 99 for each license type. " +
                        "You may send an invoice instead.");
                    setIsLoading && setIsLoading(false)
                    return;
                }
                //call to api to get stripe checkout id
                setIsLoading(true)
                var pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                try{
                    let response = await partnerCompleteDealAndCreateCheckoutSessionReactive(activeDeal.dealId, completeDealZenGroup, numDesktopLicenses, numServerLicenses)
                    if(response.sessionId){
                        try{
                            const stripe = window.Stripe(pk)
                            stripe.redirectToCheckout({sessionId: response.sessionId})
                        }
                        catch(error){
                            NotificationManager.error("Error continuing to payment");
                        }
                    }
                    else{
                        NotificationManager.error("Error continuing to payment");
                    }
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error continuing to payment");
                    }
                }
                setIsLoading(false)
            }
            else if(completeDealButtonClicked === "sendInvoice"){
                //call to api to send invoice, we can set deal completed in this endpoint too.
                try{
                    setIsLoading(true)
                    await completePartnerDealAndSendInvoiceForPurchasingLicensesReactive(activeDeal.dealId, completeDealZenGroup, numDesktopLicenses, numServerLicenses)
                    NotificationManager.success("Successfully sent invoice!");
                    resetCompleteDeal()
                    resetGrid()
                }
                catch(error){
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error sending invoice");
                    }
                }
                setIsLoading(false)
            }
        }

    }
    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Register Deal</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Register New Deal Modal*/}
            <Modal contentLabel="Deal Information" isOpen={showNewDealModal}
                   onRequestClose={() => {
                       setShowNewDealModal(false)
                       resetNewDealFormData()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-5xl max-w-5xl min-w-5xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(submitDeal)}>
                    <div className="flex flex-1 flex-col gap-y-5 ml-1">
                        <div className="flex flex-row justify-center">
                            <h1 className="font-bold text-3xl">Deal Information</h1>
                        </div>
                        <hr className="h-0.5" />
                        <div className="">
                            <label>Select which channel partner group this deal is for</label>
                            <Dropdown
                                options={getChannelGroupsDropDownContents()}
                                value={channelGroupForNewDeal}
                                onChange={({ value }) => {
                                    setChannelGroupForNewDeal(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className="">
                            <label>Salesperson Email</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                required
                                name="salespersonEmail"
                                {...register("salespersonEmail")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                defaultValue={user}
                            />
                        </div>
                        <div className="flex xl:flex-row flex-col flex-wrap xl:justify-around gap-y-5 gap-x-10">
                            <div className={`flex flex-col xl:items-center gap-y-2`}>
                                <div className={`flex flex-row items-center hidden`} >
                                    <ThemeProvider theme = {switchTheme}>
                                        <FormControlLabel control={
                                            <Switch
                                            checked={threeYearServerToggled}
                                            name="threeYearServerToggle"
                                            onChange={e => setThreeYearServerToggled(e.target.checked)}
                                    />} label = {threeYearServerToggled ? "3-Year Server Licenses" : "1-Year Server Licenses"}/>
                                    </ThemeProvider>
                                </div>
                                <div className="flex flex-row items-center gap-x-3 flex-wrap">
                                    <label>Server Licenses</label>
                                    <input
                                        autoComplete="off"
                                        name="serverLicenseCount"
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g,'');
                                            setServerTotalCashValue(e.target.value * serverLicensePriceYearly)
                                        }}
                                        onKeyPress={(e) => {
                                            if(e.key === 'Enter'){
                                                e.preventDefault();
                                            }}}
                                        {...register("serverLicenseCount")}
                                        className="text-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                            </div>
                            <div className={`flex flex-col xl:items-center gap-y-2`}>
                                <div className={`flex flex-row items-center hidden`} >
                                    <ThemeProvider theme = {switchTheme}>
                                        <FormControlLabel control={
                                            <Switch
                                            checked={threeYearDesktopToggled}
                                            name="threeYearDesktopToggle"
                                            onChange={e => setThreeYearDesktopToggled(e.target.checked)}
                                            />
                                        } label = {threeYearDesktopToggled ? "3-Year Desktop Licenses" : "1-Year Desktop Licenses"}/>
                                    </ThemeProvider>
                                </div>
                                <div className="flex flex-row items-center gap-x-3 flex-wrap">
                                    <label>Desktop Licenses</label>
                                    <input
                                        autoComplete="off"
                                        name="desktopLicenseCount"
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g,'');
                                            setDesktopTotalCashValue(e.target.value * desktopLicensePriceYearly)
                                        }}
                                        onKeyPress={(e) => {
                                            if(e.key === 'Enter'){
                                                e.preventDefault();
                                            }}}
                                        {...register("desktopLicenseCount")}
                                        className="text-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <label>Deal Name</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="userSetFriendlyName"
                                {...register("userSetFriendlyName")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-row flex-wrap justify-between gap-y-5">
                            <div className="w-full xl:w-5/12 lg:w-5/12">
                                <label>Prospect Name</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    name="prospectName"
                                    {...register("prospectName")}
                                    required
                                    placeholder="Required"
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className="w-full xl:w-5/12 lg:w-5/12">
                                <label>Prospect Organization</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    name="prospectOrganization"
                                    {...register("prospectOrganization")}
                                    required
                                    placeholder="Required"
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className="">
                            <label>Prospect Email</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                required
                                name="prospectEmail"
                                {...register("prospectEmail")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="">
                            <label>Prospect Phone</label>
                            <PhoneInput
                                required
                                name="prospectPhone"
                                maxLength="16" //TODO: what should max length be if any?
                                className="w-full bg-white rounded border border-gray-300 focus:border-theme text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"                                            defaultCountry="US"
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                        </div>
                        <div className="">
                            <label>Prospect Address</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="prospectAddress"
                                {...register("prospectAddress")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-row flex-wrap justify-between gap-y-5">
                            <div className="w-full xl:w-5/12 lg:w-5/12">
                                <label>City</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    name="prospectCity"
                                    {...register("prospectCity")}
                                    required
                                    placeholder="Required"
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className="w-full xl:w-5/12 lg:w-5/12">
                                <label>Zip Code</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    name="prospectZipCode"
                                    {...register("prospectZipCode")}
                                    required
                                    placeholder="Required"
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>

                        <div className="">
                            <label>Description</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="prospectDescription"
                                {...register("prospectDescription")}
                                required
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-row flex-wrap justify-between gap-y-5">
                            <div className="w-full xl:w-4/6 lg:w-4/6">
                                <label>Associated Products and Services</label>
                                <textarea
                                    onInput={(e) => {
                                        if(e.target.value && e.target.value.trim() !== "0" && e.target.value.trim() !== ""){
                                            setSaveAndPurchaseButtonDisabled(false)
                                        }
                                        else{
                                            setSaveAndPurchaseButtonDisabled(true)
                                        }
                                    }}
                                    name="associatedProductsAndServices"
                                    {...register("associatedProductsAndServices")}
                                    className={"w-full p-2 mt-3 rounded-lg border border-black border-opacity-25 border-solid"}
                                    rows={5}
                                    defaultValue={0}
                                >
                                    </textarea>
                            </div>
                            <div className="w-full xl:w-1/4 lg:w-1/4">
                                <label>Total Deal Value</label>
                                <input
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    type="text"
                                    name="totalDealValue"
                                    value={formatter.format(serverTotalCashValue + desktopTotalCashValue)}
                                    {...register("totalDealValue")}
                                    disabled
                                    className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        style={{minWidth:"200px"}}
                                        disabled={saveAndPurchaseButtonDisabled}
                                        type={"submit"}
                                        onClick={(event) => {
                                            setButtonClicked("saveAndPurchase")
                                        }}>
                                    Save and Purchase Licenses
                                </Button>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        type={"submit"}
                                        style={{minWidth:"200px"}}
                                        onClick={(event) => {
                                            setButtonClicked("saveAndInvoice")
                                        }}>
                                        Save and Send Invoice
                                </Button>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        type={"submit"}
                                        style={{minWidth:"200px"}}
                                        onClick={() => {
                                            setButtonClicked("saveOnly")
                                        }}>
                                    Save
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <PartnerStripeModal
                onClose={() => {
                    setShowStripeSetupModal(false)
                }}
                setIsLoading={setIsLoading}
                opened={showStripeSetupModal}
                newDealData={newDealData}
                zenGroups={distributionGroups}
                setShowNewDealModal={setShowNewDealModal}
                resetGrid={resetGrid}
                resetNewDealForm={() => {
                    setShowNewDealModal(false)
                    resetNewDealFormData()
                }}
            />
            {/*Disable*/}
            <ConfirmationModal
                text={`WARNING: You are about to disable this deal, would you like to continue?`}
                onConfirm={async () => {
                    if(activeDeal && activeDeal.dealId){
                        if(activeDeal.disabledDate){
                            //deal is already disabled
                            NotificationManager.info("This partner deal is already disabled");
                            return;
                        }
                        setIsLoading(true)
                        try{
                            await disablePartnerDealReactive(activeDeal.dealId)
                            NotificationManager.success("Successfully disabled this deal");
                            resetGrid()
                        }
                        catch(error){
                            if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error("Error disabling this deal");
                            }
                        }
                        setIsLoading(false)
                    }
                }}
                onClose={() => {
                    setShowDisableConfirmation(false);
                }}
                opened={showDisableConfirmation}
            />
            {/*Enable*/}
            <ConfirmationModal
                text={`You are about to enable this deal, would you like to continue?`}
                onConfirm={async () => {
                    if(activeDeal && activeDeal.dealId){
                        if(!activeDeal.disabledDate){
                            //deal is already disabled
                            NotificationManager.info("This partner deal is already enabled");
                            return;
                        }
                        setIsLoading(true)
                        try{
                            await enablePartnerDealReactive(activeDeal.dealId)
                            NotificationManager.success("Successfully enabled this deal");
                            resetGrid()
                        }
                        catch(error){
                            if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error("Error enabling this deal");
                            }
                        }
                        setIsLoading(false)
                    }
                }}
                onClose={() => {
                    setShowEnableConfirmation(false);
                }}
                opened={showEnableConfirmation}
            />
            {/*Complete deal modal*/}
            <Modal
                onRequestClose={() => {
                    resetCompleteDeal()
                }}
                isOpen={showCompleteDealModal}
                contentLabel={"Form Data Before Continuing to Payment"}
                className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl overflow-auto`}
                overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                shouldCloseOnOverlayClick={false}
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onCompleteDeal)}>
                    <div className="flex flex-1 flex-col w-full gap-y-4">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Please Fill Out Form Before Continuing to Payment</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetCompleteDeal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1">
                            <small>Select which distribution group to purchase licenses for</small>
                            <Dropdown
                                options={getDistributionGroupsDropDownContents()}
                                value={completeDealZenGroup}
                                onChange={({ value }) => {
                                    setCompleteDealZenGroup(value)
                                }}
                                placeholder="Select"
                                className="mt-3"
                                controlClassName="dropdown"
                                placeholderClassName="text-black-40"
                                arrowClassName="text-black-70 text-base my-1"
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap justify-center gap-x-10"}>
                            <div className={"ml-1 flex flex-col"}>
                                <small>Amount of server licenses</small>
                                <input
                                    id={'completeDealServerLicensesCount'}
                                    autoComplete="off"
                                    name="completeDealServerLicenses"
                                    type="number"
                                    placeholder={(activeDeal && activeDeal.numberOfServerLicensesBought) ? activeDeal.numberOfServerLicensesBought : 0}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g,'');
                                    }}
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    {...register("completeDealServerLicenses")}
                                    className="text-center self-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <div className={"ml-1 flex flex-col"}>
                                <small>Amount of desktop licenses</small>
                                <input
                                    id={'completeDealDesktopLicensesCount'}
                                    autoComplete="off"
                                    name="completeDealDesktopLicenses"
                                    type="number"
                                    placeholder={(activeDeal && activeDeal.numberOfDesktopLicensesBought) ? activeDeal.numberOfDesktopLicensesBought : 0}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g,'');
                                    }}
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            e.preventDefault();
                                        }}}
                                    {...register("completeDealDesktopLicenses")}
                                    className="text-center self-center w-24 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                            <ThemeProvider theme = {buttonTheme}>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        type={"submit"}
                                        onClick={(event) => {
                                            setCompleteDealButtonClicked("payNow")
                                        }}>
                                Pay Now
                                </Button>
                            <Button variant={"contained"} className={"flex-1"}
                                    color={"primary"}
                                    type={"submit"}
                                    onClick={(event) => {
                                        setCompleteDealButtonClicked("sendInvoice")
                                    }}>
                                Send Invoice
                            </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row border border-grey">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="border border-grey ml-8 xl:block lg:block md:hidden sm:hidden xs:hidden" />
                <div className="flex flex-1 flex-col mr-10 ml-10 mt-8 flex-nowrap gap-y-3">
                    {privatePageHeaderHelper("Register Deal")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <ClickToShowButtonsExpandingRight
                                buttonsText={"Automation"}
                                tooltipTitle={"Automated, Bulk, and Scripted Operations"}
                                buttonsDiv={
                                    <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<AddShoppingCartIcon className={"cursor-pointer"}/>} tooltipTitle={"Register a New Deal"}
                                            tooltipPlacement={"top"}
                                            onClick={() => {
                                                setShowNewDealModal(true)
                                            }}
                                        />
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={<CheckCircleOutlineIcon className={"cursor-pointer"}/>} tooltipTitle={"Complete deal"}
                                            tooltipPlacement={"top"} disabled={!activeDeal}
                                            onClick={async () => {
                                                if(activeDeal){
                                                    if(activeDeal.disabledDate){
                                                        //deal is already disabled
                                                        NotificationManager.error("This partner deal is disabled, you cannot complete this deal");
                                                        return;
                                                    }
                                                    if(activeDeal.dealCompleted){
                                                        //deal is already disabled
                                                        NotificationManager.info("This partner deal is already completed");
                                                        return;
                                                    }
                                                    try{
                                                        let distributionGroupsList = getDistributionGroups()
                                                        if(distributionGroupsList && distributionGroupsList.length > 0){
                                                            setDistributionGroupsForCompleteDeal(distributionGroupsList)
                                                            setShowCompleteDealModal(true)
                                                        }
                                                        else{
                                                            NotificationManager.error("You are not a member of any groups that are distribution groups, " +
                                                                "you are not eligible to continue.")
                                                            setShowCompleteDealModal(false)
                                                            setDistributionGroupsForCompleteDeal([])
                                                        }
                                                    }
                                                    catch(error){
                                                        NotificationManager.error("Error retrieving list of groups for the compete deal form.")
                                                        setShowCompleteDealModal(false)
                                                        setDistributionGroupsForCompleteDeal([])
                                                    }
                                                }
                                            }}
                                        />
                                        <MuiIconButtonWithTooltipAndBox
                                            icon={activeDeal ? (activeDeal.disabledDate ? <DoneAllIcon className={"cursor-pointer"}/> : <RemoveCircleOutlineIcon className={"cursor-pointer"}/>) : <RemoveCircleOutlineIcon className={"cursor-pointer"}/>}
                                            tooltipTitle={activeDeal ? (activeDeal.disabledDate ? "Enable Deal" : "Disable Deal") : "Disable Deal"}
                                            tooltipPlacement={"top"} disabled={!activeDeal}
                                            onClick={() => {
                                                if(activeDeal){
                                                    if(activeDeal.disabledDate){
                                                        setShowEnableConfirmation(true)
                                                    }
                                                    else{
                                                        setShowDisableConfirmation(true)
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3 self-end justify-end"}>
                            <ClickToShowButtonsExpandingLeft
                                buttonsText={"Columns"}
                                tooltipTitle={"Column States"}
                                buttonsDiv={
                                    <ToggleButtonGroup
                                        value={"med"}
                                        exclusive
                                        size={"small"}
                                        onChange={(event, newAlignment) => {
                                            //setAlignment(newAlignment)
                                        }}
                                    >
                                        <ToggleButton value={"min"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"med"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"max"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"custom"}>
                                            <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                                     title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                                                <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-table-columns" swapOpacity/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            />
                            <ClearRefresh gridApi = {gridApi} gridColumnApi={gridColumnApi} showRefreshIcon={false}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport}/>
                        </div>
                    </div>
                    <div className="mb-4" id="gridRoot">
                        {getGrid()}
                    </div>
                </div>
            </div>

            <Footer />
            <NotificationContainer />
        </div>
    );

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                onClickRow={(row) => {
                    setActiveDeal(row[0]);
                }}
                setGridApi={setGridApi}
                setGridColumnApi={setGridColumnApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
            />
        );
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'dealsGridFilterState', updateDealsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateDealsGridUseColumnStateReactive);
    }

    function resetGrid(){
        setButtonClicked()
        setShowDisableConfirmation(false);
        setShowEnableConfirmation(false)
        setActiveDeal(null)
        setShowCompleteDealModal(false)
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "dealsGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateDealsGridColumnStateReactive)
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["dealId"] = objectBody["id"]
        objectBody["createdBy"] = objectBody["createdByUsername"]
        objectBody["partnerDealDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        //Need to populate the zenGroupDisplayName with the group's organization name if not null, else the group's zenGroupDisplayName since for initial sse data population we do a join with zenGroup collection
        objectBody["zenGroupDisplayName"] = getGroupNameForDealsGridForChangeStream(objectBody["zenGroupId"])

        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.dealId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport)
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.props.setGridApi(params.api);
        this.props.setGridColumnApi(params.columnApi);

        //check if we want to apply saved column state
        if(getUseColumnStateInSession(gridColumnStateSessionVariableName)){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }

        /*let partnerDealsGridList = JSON.parse(decryptAndGetSessionVariable("partnerDealsGridList"))
        if(partnerDealsGridList){
            //found partnerDealsGridList in session
            this.gridApi.setRowData(partnerDealsGridList)
        }
        else{
            const data = await getDealsReactive()
            this.gridApi.setRowData(data)
        }*/

        onGridReadyHelper(params, "dealsGridFilterState");

        await loadDataWithSSEAndStartChangeStreamListener("/sse/getPartnerDealsReactive", "/sse/listenToPartnerDealEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <div
                    id="registeredDealGrid"
                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: true,
                        }}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        rowSelection={'multiple'}
                        multiSortKey={"ctrl"}
                        rowMultiSelectWithClick={false}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'dealsGridFilterState', updateDealsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                    >
                        {this.props.columnDefs.map(
                            (
                                { field, name, filter, filterParamsInHeader, editable, editableOptions, onUpdate, cellRenderer, cellRendererSelector,
                                    cellEditorType, hide, sortable, minWidth, suppressColumnsToolPanel, valueFormatter, width,
                                    cellEditorSelector, valueGetter, keyCreator},
                                i
                            ) => (
                                <AgGridColumn
                                    hide={hide}
                                    headerClass="border-0 border-b-0"
                                    cellClass="outline:none"
                                    autoHeight
                                    filter={filter}
                                    filterParams={filterParamsInHeader ? filterParamsInHeader : {
                                        buttons: ["reset", "apply"],
                                        
                                        filterOptions: ['contains', 'notContains'],
                                        suppressAndOrCondition: true,
                                        closeOnApply: true}}
                                    sortable={sortable}
                                    key={i}
                                    width={width}
                                    minWidth={minWidth}
                                    field={field}
                                    headerName={name}
                                    resizable
                                    editable={editable}
                                    onCellValueChanged={onUpdate}
                                    enableCellChangeFlash={true}
                                    cellEditor={cellEditorType}
                                    valueFormatter={valueFormatter}
                                    cellEditorParams={{ cellHeight: 50, values: editableOptions }}
                                    cellRenderer={cellRenderer}
                                    cellRendererSelector={cellRendererSelector}
                                    suppressColumnsToolPanel={suppressColumnsToolPanel}
                                    cellEditorSelector={cellEditorSelector}
                                    valueGetter={valueGetter}
                                    keyCreator={keyCreator}
                                />
                            )
                        )}
                    </AgGridReact>
                </div>
            </div>
        );
    }
}
