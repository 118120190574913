import axios from "../../utils/axiosInstance";

export const processInjectionsListReactive = async (count, pageCount, filters, sortModel) => {
    const response = await axios.post("/processInjectionsListReactive", {
        page: pageCount,
        count: count,
        filters: filters,
        sortModel: sortModel,
    });
    return response.data;
};
export const findByProcessInjectionIdListReactive = async (processInjectionsIds) => {
    const response = await axios.post("/findByProcessInjectionIdListReactive",{
        procInjectionIds: processInjectionsIds
    })
    return response.data
}

export const getProcessInjectionsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getProcessInjectionsGridFilterStateReactive", {});
    return response.data;
};

export const updateProcessInjectionsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateProcessInjectionsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateProcessInjectionsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateProcessInjectionsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getProcessInjectionsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getProcessInjectionsGridColumnStateReactive", {});
    return response.data;
};
export const updateProcessInjectionsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateProcessInjectionsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateProcessInjectionsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateProcessInjectionsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};