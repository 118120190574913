import axios from "../../utils/axiosInstance";


export const getIncidentFriendlyNamesReactive = async (incidentIds) => {
    const response = await axios.post("/getIncidentFriendlyNamesReactive",{
        incidentIds
    })
    return response.data
}

export const changeIncidentNameReactive = async (newName, groupId, incidentId) => {
    const response = await axios.post("/changeIncidentNameReactive", {
        "newName": newName,
        "zenGroupId": groupId,
        "id": incidentId
    });
    return response;
};

export const incidentTimeSeriesListReactive = async (count, pageCount, sortModel, filters) => {
    const response = await axios.post("/incidentTimeSeriesListReactive", {
        page: pageCount,
        count: count,
        filters: filters,
        sortModel: sortModel,
    });
    return response.data;
};

export const getDetailRowsForIncidentTimeSeriesReactive = async (agentId, zenGroupId, dateCreatedRounded, pathEncoded, programArgumentsEncoded) => {
    const response = await axios.post("/getDetailRowsForIncidentTimeSeriesReactive", {
        agentId,
        zenGroupId,
        dateCreatedRounded,
        pathEncoded,
        programArgumentsEncoded
    });
    return response.data;
};

export const whitelistPathExistsReactive = async (path, zenGroupId, programArguments) => {
    const response = await axios.post("/whitelistPathExistsReactive", {
        path: path,
        zenGroupId: zenGroupId,
        programArguments: programArguments
    });
    return response.data;
};

export const findByIncidentIdListReactive = async (ids) => {
    const response = await axios.post("/findByIncidentIdListReactive",{
        ids
    })
    return response.data
}
export const findSuggestedPathsReactive = async (path) => {
    const response = await axios.post("/findSuggestedPathsReactive", {
        path
    });
    return response.data;
};
export const userAddWhitelistsFromIncidentsPageReactive = async (pathsList, zenGroupId, programArguments) => {
    const response = await axios.post("/userAddWhitelistsFromIncidentsPageReactive", {
        pathsList: pathsList,
        zenGroupId: zenGroupId,
        programArguments: programArguments
    });
    return response.data;
};
export const submitIncidentForReviewReactive = async (incidentId) => {
    return await axios.post("/submitIncidentForReviewReactive", {
        incidentId
    });
};
export const incidentsPerGroupCountReactive = async (includeWhitelistedIncidents) => {
    const response = await axios.post("/incidentsPerGroupCountReactive",{
        includeWhitelistedIncidents: includeWhitelistedIncidents
    })
    return response.data
}

export const getIncidentsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getIncidentsGridFilterStateReactive", {});
    return response.data;
};

export const updateIncidentsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateIncidentsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateIncidentsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateIncidentsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};
export const userSetIncidentAsNotSilentReactive = async (incidentId) => {
    const response = await axios.post("/userSetIncidentAsNotSilentReactive", {
        incidentId: incidentId
    });
    return response.data;
};
export const findSuggestedSilentIncidentPathsReactive = async (path, incidentId) => {
    const response = await axios.post("/findSuggestedSilentIncidentPathsReactive", {
        path: path,
        incidentId: incidentId
    });
    return response.data;
};
export const createSilentIncidentsFromIncidentsPageReactive = async (pathsList, incidentId, programArguments) => {
    const response = await axios.post("/createSilentIncidentsFromIncidentsPageReactive", {
        pathsList: pathsList,
        incidentId: incidentId,
        programArguments: programArguments
    });
    return response.data;
};
export const findSilentIncidentNamesForSingleIncidentIdReactive = async (incidentId) => {
    const response = await axios.post("/findSilentIncidentNamesForSingleIncidentIdReactive", {
        incidentId: incidentId
    });
    return response.data;
};

export const getIncidentsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getIncidentsGridColumnStateReactive", {});
    return response.data;
};
export const updateIncidentsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateIncidentsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateIncidentsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateIncidentsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const checkIncidentHasRelatedProcessInjectionsAndCreationsReactive = async (zenGroupId, uniqueProcessIdentifier, childPath) => {
    const response =  await axios.post("/checkIncidentHasRelatedProcessInjectionsAndCreationsReactive", {
        zenGroupId: zenGroupId,
        uniqueProcessIdentifier: uniqueProcessIdentifier,
        childPath: childPath
    });
    return response.data;
};