import axios from "../../utils/axiosInstance";

export const getPartnerDealsReactive = async () => {
    const response = await axios.post("/getPartnerDealsReactive", {
    });
    return response.data;
};

export const newPartnerDealReactive = async (req) => {
    return await axios.post("/newPartnerDealReactive", req)
};
export const createNewPartnerDealAndSendInvoiceReactive = async (req) => {
    return await axios.post("/createNewPartnerDealAndSendInvoiceReactive", req)
};
export const partnerSendInvoiceOnlyForPurchaseLicensesReactive = async (zenGroupId, desktopLicenses, serverLicenses) => {
    return await axios.post("/partnerSendInvoiceOnlyForPurchaseLicensesReactive", {
        zenGroupId: zenGroupId,
        desktopLicenseCount: desktopLicenses,
        serverLicenseCount: serverLicenses
    })
};
export const createNewPartnerDealAndSendInvoiceForPurchaseLicensesReactive = async (req) => {
    return await axios.post("/createNewPartnerDealAndSendInvoiceForPurchaseLicensesReactive", req)
};
export const disablePartnerDealReactive = async (dealId) => {
    return await axios.post("/disablePartnerDealReactive", {
        partnerDealIdToDisable: dealId,
    })
};
export const enablePartnerDealReactive = async (dealId) => {
    return await axios.post("/enablePartnerDealReactive", {
        partnerDealIdToEnable: dealId,
    })
};
export const createNewPartnerDealAndReturnStripeSessionIdReactive = async (req) => {
    const response = await axios.post("/createNewPartnerDealAndReturnStripeSessionIdReactive", req)
    return response.data;
};
export const completePartnerDealAndSendInvoiceForPurchasingLicensesReactive = async (partnerDealId, zenGroupId, desktopLicenses, serverLicenses) => {
    return await axios.post("/completePartnerDealAndSendInvoiceForPurchasingLicensesReactive", {
        partnerDealId: partnerDealId,
        zenGroupId: zenGroupId,
        desktopLicenseCount: desktopLicenses,
        serverLicenseCount: serverLicenses
    })
};
export const changePartnerDealNameReactive = async (newName, partnerDealId) => {
    return await axios.post("/changePartnerDealNameReactive", {
        newName: newName,
        partnerDealId: partnerDealId
    });
};
export const removePartnerDealNameReactive = async (partnerDealId) => {
    return await axios.post("/removePartnerDealNameReactive", {
        partnerDealId: partnerDealId
    });
};

export const getDealsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getDealsGridFilterStateReactive", {});
    return response.data;
};

export const updateDealsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateDealsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateDealsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateDealsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getDealsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getDealsGridColumnStateReactive", {});
    return response.data;
};
export const updateDealsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateDealsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateDealsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateDealsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};